import {
  FormProvider,
  Icon,
  Loadable,
  SelectField,
  TextField,
} from '@fleet/shared';
import { useForm } from '@fleet/shared/form';
import { Button } from '@fleet/shared/mui';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { Grid, Stack, Typography } from '@mui/material';
import { CommentsTable } from 'components/CommentsTable';
import {
  BookingCommentPayload,
  RequestContactPerson,
  UnaccompaniedMinorRequestPayload,
} from 'dto/booking';
import { ClassificationGroup } from 'dto/classification';
import {
  addComment,
  addUnaccompaniedMinorRequest,
  getComments,
  getUnaccompaniedMinorRequests,
  updateComment,
  updateUnaccompaniedMinorRequest,
} from 'features/booking/bookingActions';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { commentsLoadingSelector } from 'features/loading/loadingSelectors';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransLabel } from 'i18n/trans/label';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTitle } from 'i18n/trans/title';
import _omit from 'lodash/omit';
import { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import { IS_DS_AT, IS_IMS_AT } from 'utils/flags';
import { getPassengersNames, getTripAdmissions } from 'utils/trip';

interface CommentsProps {
  isSalesFlow?: boolean;
}

export interface PreparedCommentPayload extends BookingCommentPayload {
  id?: string;
  admissionIds?: Array<string>;
  request?: Record<
    string,
    {
      passengerAge?: number;
      contactPersonAtOrigin?: RequestContactPerson;
      contactPersonAtDestination?: RequestContactPerson;
    }
  >;
}

interface PreparedTicketOption {
  label: string;
  value: string;
  passengerName: string;
}

const UNACCOMPANIED_MINOR_SR = 'UNMR';

export const Comments: FC<CommentsProps> = ({ isSalesFlow }) => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const booking = useSelector(currentBookingSelector);
  const loading = useSelector(commentsLoadingSelector);
  const passengersOptions = useMemo(
    () =>
      booking?.passengers.map(({ id, firstName, lastName }) => ({
        value: id,
        label: [firstName, lastName].map(({ value }) => value).join(' '),
      })) ?? [],
    [booking?.passengers]
  );
  const ticketOptions = useMemo(() => {
    const options =
      booking?.bookedTrips.reduce<Array<PreparedTicketOption>>(
        (acc, trip) => [
          ...acc,
          ...getTripAdmissions(trip).map(
            ({ id, passengerIds, coveredLegIds }) => {
              const admissionLegs = trip.legs.filter(({ id }) =>
                coveredLegIds.includes(id)
              );
              const { departureTime, originStop } = admissionLegs[0];
              const { arrivalTime, destinationStop } =
                admissionLegs[admissionLegs.length - 1];
              return {
                value: id,
                label: [
                  [departureTime, originStop.name],
                  [arrivalTime, destinationStop.name],
                ]
                  .map(
                    ([date, stopName]) =>
                      `${formatDate(date, currentDateTimeFormat)} ${stopName}`
                  )
                  .join(' - '),
                passengerName: getPassengersNames(booking, passengerIds[0]),
              };
            }
          ),
        ],
        []
      ) ?? [];
    return options.sort((a, b) =>
      a.passengerName.localeCompare(b.passengerName)
    );
  }, [booking]);
  const commentTypeOptions = useClassificationOptions(
    ClassificationGroup.COMMENT_TYPE
  );

  const preparedTypeOptions = useMemo(
    () =>
      IS_IMS_AT
        ? commentTypeOptions.concat([
            {
              label: 'Unaccompanied Minor SSR',
              value: UNACCOMPANIED_MINOR_SR,
            },
          ])
        : commentTypeOptions,
    [commentTypeOptions]
  );
  const commentLevelOptions = useClassificationOptions(
    ClassificationGroup.COMMENT_LEVEL
  );
  const bookingId = isSalesFlow ? booking!.id : id;

  const refreshComments = useCallback(
    async () =>
      await Promise.all(
        [
          dispatch(getComments(bookingId)),
          IS_IMS_AT && dispatch(getUnaccompaniedMinorRequests(bookingId)),
        ].filter(Boolean)
      ),
    [bookingId, dispatch]
  );

  const onCommentSubmit = useCallback(
    async ({ admissionIds, request, ...rest }: PreparedCommentPayload) => {
      let batchPayload;
      const isEditSubmit = !!rest.id;
      if (admissionIds?.length) {
        batchPayload = [
          ...(admissionIds?.map((admissionId) => ({
            admissionId,
            ...request?.[admissionId],
            ...rest,
          })) ?? ([] as Array<BookingCommentPayload>)),
        ];
      } else {
        batchPayload = [rest];
      }

      if (!batchPayload) return;

      await Promise.all(
        batchPayload.map((values) => {
          // @ts-ignore
          if (values.contactPersonAtDestination) {
            const preparedPayload = _omit(values, 'type');
            return dispatch(
              (isEditSubmit
                ? updateUnaccompaniedMinorRequest
                : addUnaccompaniedMinorRequest)(
                preparedPayload as unknown as UnaccompaniedMinorRequestPayload
              )
            );
          } else if (values.id) {
            return dispatch(
              updateComment({
                id: values.id,
                comment: values.comment,
              })
            );
          } else {
            return dispatch(addComment(values as BookingCommentPayload));
          }
        })
      );
      await refreshComments();
    },
    [refreshComments, dispatch]
  );
  const getTicketPassengerName = useCallback(
    (admissionId: string) => {
      return ticketOptions.find(({ value }) => value === admissionId)!
        .passengerName;
    },
    [ticketOptions]
  );

  const {
    form,
    handleSubmit: handleAddSubmit,
    valid,
    values: { id: editCommentId, level, type, admissionIds },
  } = useForm<PreparedCommentPayload>({
    destroyOnUnregister: true,
    onSubmit: onCommentSubmit,
    subscription: {
      values: true,
      valid: true,
    },
  });
  const isUnacomopaniedMinorRequest = useMemo(
    () => type === UNACCOMPANIED_MINOR_SR,
    [type]
  );

  const onCommentEdit = useCallback(
    (comment: PreparedCommentPayload) => {
      form.reset(comment);
    },
    [form]
  );

  useEffect(() => {
    isSalesFlow && refreshComments();
  }, [isSalesFlow, refreshComments]);

  return (
    <Loadable loading={loading}>
      <Grid container columns={3} spacing={3}>
        <Grid item xs={3}>
          <CommentsTable
            onCommentEdit={onCommentEdit}
            editCommentId={editCommentId}
          />
        </Grid>
        <Grid item xs={2}>
          <FormProvider form={form}>
            <form
              onSubmit={async (event) => {
                await handleAddSubmit(event);
                valid && form.restart({});
              }}
            >
              <Stack spacing={2}>
                <Grid container columns={2} spacing={2}>
                  <Grid item xs={2}>
                    <Typography variant="subtitle">
                      <TransTitle
                        i18nKey={editCommentId ? 'editComment' : 'addComment'}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <SelectField
                      label={<TransField i18nKey="commentType" />}
                      name="type"
                      required
                      options={preparedTypeOptions}
                      disabled={!!editCommentId}
                    />
                  </Grid>
                  {!isUnacomopaniedMinorRequest && (
                    <Grid item xs={1}>
                      <SelectField
                        label={<TransField i18nKey="commentLevel" />}
                        name="level"
                        required
                        options={commentLevelOptions}
                        disabled={!!editCommentId}
                      />
                    </Grid>
                  )}
                  {level === 'COMMENT_LEVEL.PASSENGER' && (
                    <Grid item xs={2}>
                      <SelectField
                        label={<TransField i18nKey="passengers" />}
                        name="passengerIds"
                        required
                        multiple
                        disabled={!!editCommentId}
                        options={passengersOptions}
                        withCheckboxes
                        disableCloseOnSelect
                      />
                    </Grid>
                  )}
                  {(level === 'COMMENT_LEVEL.TICKET' ||
                    isUnacomopaniedMinorRequest) && (
                    <Grid item xs={2}>
                      <SelectField
                        label={<TransField i18nKey="tickets" />}
                        name="admissionIds"
                        required
                        multiple
                        disabled={!!editCommentId}
                        options={ticketOptions}
                        withCheckboxes
                        disableCloseOnSelect
                        groupBy={(option) =>
                          (option as unknown as PreparedTicketOption)
                            .passengerName
                        }
                      />
                    </Grid>
                  )}
                  {isUnacomopaniedMinorRequest &&
                    admissionIds?.map((admissionsId: string) => (
                      <Fragment key={admissionsId}>
                        <Grid item xs={2}>
                          <Typography
                            variant="subtitle"
                            sx={{ mb: 1 }}
                            component="div"
                          >
                            {getTicketPassengerName(admissionsId)}
                          </Typography>
                          <TextField
                            label={<TransLabel i18nKey="age" />}
                            required
                            name={`request[${admissionsId}].passengerAge`}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2" sx={{ mb: 1 }}>
                            <TransSubtitle i18nKey="contactPersonAtOrigin" />
                          </Typography>
                          <Stack direction="row" spacing={1}>
                            <TextField
                              label={<TransField i18nKey="firstName" />}
                              required
                              name={`request[${admissionsId}].contactPersonAtOrigin.firstName`}
                            />
                            <TextField
                              label={<TransField i18nKey="lastName" />}
                              required
                              name={`request[${admissionsId}].contactPersonAtOrigin.lastName`}
                            />
                            <TextField
                              label={<TransField i18nKey="phone" />}
                              required
                              name={`request[${admissionsId}].contactPersonAtOrigin.phoneNumber`}
                            />
                            <TextField
                              label={<TransField i18nKey="email" />}
                              email
                              required
                              name={`request[${admissionsId}].contactPersonAtOrigin.email`}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2" sx={{ mb: 1 }}>
                            <TransSubtitle i18nKey="contactPersonAtDestination" />
                          </Typography>
                          <Stack direction="row" spacing={1}>
                            <TextField
                              label={<TransField i18nKey="firstName" />}
                              required
                              name={`request[${admissionsId}].contactPersonAtDestination.firstName`}
                            />
                            <TextField
                              label={<TransField i18nKey="lastName" />}
                              required
                              name={`request[${admissionsId}].contactPersonAtDestination.lastName`}
                            />
                            <TextField
                              label={<TransField i18nKey="phone" />}
                              required
                              name={`request[${admissionsId}].contactPersonAtDestination.phoneNumber`}
                            />
                            <TextField
                              label={<TransField i18nKey="email" />}
                              required
                              email
                              name={`request[${admissionsId}].contactPersonAtDestination.email`}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            label={
                              <TransField i18nKey="specialRequestComment" />
                            }
                            name={`request[${admissionsId}].comment`}
                            multiline
                            rows={3}
                          />
                        </Grid>
                      </Fragment>
                    ))}
                  {!isUnacomopaniedMinorRequest && (
                    <Grid item xs={2}>
                      <TextField
                        label={<TransField i18nKey="comment" />}
                        {...(IS_DS_AT && { required: true })}
                        name="comment"
                        multiline
                        rows={3}
                      />
                    </Grid>
                  )}

                  <Grid item xs={2}>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      spacing={1}
                    >
                      <Button
                        variant="text"
                        label={<TransButton i18nKey="resetFields" />}
                        onClick={() => form.restart({})}
                      />
                      <Button
                        variant="contained"
                        type="submit"
                        startIcon={<Icon name="check" />}
                        label={
                          <TransButton
                            i18nKey={
                              editCommentId ? 'saveChanges' : 'postComment'
                            }
                          />
                        }
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </Loadable>
  );
};
