import {
  Button,
  FormProvider,
  Icon,
  SelectField,
  useForm,
} from '@fleet/shared';
import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { RefundReason } from 'dto/booking';
import {
  confirmRefund,
  deleteRefundOffers,
  getBooking,
  getHistory,
  initiateRefund,
} from 'features/booking/bookingActions';
import {
  currentBookingSelector,
  selectFulfillmentIdsSelection,
  selectRefundOffers,
} from 'features/booking/bookingSelectors';
import { currentBookingLoadingSelector } from 'features/loading/loadingSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransTitle } from 'i18n/trans/title';
import _isEqual from 'lodash/isEqual';
import { FC, useCallback, useMemo, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { ModalWrap } from 'routes/bookingDetails/modal/ModalWrap';
import { PassengersSelection } from 'routes/bookingDetails/modal/PassengersSelection';
import { useDispatch, useSelector } from 'store/utils';
import { replaceUnicodeChars } from 'utils/common';
import { IS_DS_AT } from 'utils/flags';

interface RefundModalProps {
  submitDisabled: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(
  (theme) => ({
    modalPaper: {
      overflow: 'inherit',
      '&:not($detailsStep)': {
        minWidth: 700,
      },
    },
    detailsStep: {
      width: 400,
    },
    refundDetails: {
      position: 'absolute',
      left: '100%',
      top: 0,
      background: theme.palette.background.default,
      boxShadow: theme.shadows[2],
      zIndex: -1,
    },
  }),
  {
    name: 'RefundModal',
  }
);
export const RefundModal: FC<RefundModalProps> = ({
  submitDisabled,
  onClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const booking = useSelector(currentBookingSelector)!;
  const { id } = booking;
  const loading = useSelector(currentBookingLoadingSelector);
  const refundOffers = useSelector(selectRefundOffers, _isEqual);
  const fulfillmentIds = useSelector(selectFulfillmentIdsSelection, _isEqual);
  const [step, setStep] = useState(submitDisabled ? 2 : 1);

  const onReasonSubmit = useCallback(
    async ({ refundReason }) => {
      try {
        await dispatch(
          initiateRefund({
            bookingId: id,
            fulfillmentIds: fulfillmentIds,
            overruleCode: refundReason,
          })
        ).unwrap();
        setStep(2);
      } catch (e) {
        onClose();
      }
    },
    [onClose, dispatch, id, fulfillmentIds]
  );

  const onRefundSubmit = useCallback(async () => {
    await dispatch(
      confirmRefund({
        bookingId: id,
        refundOfferIds: refundOffers!.map(({ id }) => id),
      })
    );
    onClose();
    await Promise.all([dispatch(getBooking(id)), dispatch(getHistory(id))]);
  }, [dispatch, id, refundOffers, onClose]);

  const handleCancel = useCallback(async () => {
    await dispatch(deleteRefundOffers());
    onClose();
  }, [dispatch, onClose]);

  const { form, handleSubmit } = useForm({
    onSubmit: onReasonSubmit,
  });

  const reasonOptions = useMemo(
    () =>
      Object.values(RefundReason)
        .filter(
          (reason) =>
            ![
              RefundReason.TECHNICAL_FAILURE,
              RefundReason.EXTERNAL_COMPENSATION,
              ...(IS_DS_AT
                ? [
                    RefundReason.DELAY_COMPENSATION,
                    RefundReason.DISRUPTION,
                    RefundReason.JOURNEY_OBSOLETE,
                  ]
                : []),
            ].includes(reason)
        )
        .map((key) => ({
          label: replaceUnicodeChars(
            renderToString(<TransField i18nKey={key} />)
          ),
          value: key === RefundReason.CLIENT_WISH ? '' : key,
        })),
    []
  );

  return (
    <ModalWrap
      open={!loading}
      onClose={handleCancel}
      title={
        <Typography variant="subtitle">
          <TransTitle i18nKey="refundSelected" />
        </Typography>
      }
      maxWidth="lg"
      classes={{
        paper: classNames(classes.modalPaper, {
          [classes.detailsStep]: step === 1,
        }),
      }}
    >
      {step === 1 && (
        <FormProvider form={form}>
          <Stack
            component="form"
            name="refundDetails"
            onSubmit={handleSubmit}
            spacing={3}
          >
            <SelectField
              options={reasonOptions}
              name="refundReason"
              label={<TransField i18nKey="refundReason" />}
            />
            <Stack direction="row" justifyContent="flex-end" spacing={1}>
              <Button variant="text" onClick={onClose}>
                <TransButton i18nKey="cancel" />
              </Button>
              <Button variant="contained" type="submit">
                <TransButton i18nKey="next" />
              </Button>
            </Stack>
          </Stack>
        </FormProvider>
      )}
      {step === 2 && (
        <PassengersSelection
          type="refund"
          submitIcon={<Icon name="arrow-right" />}
          submitLabel={<TransButton i18nKey="confirmRefund" />}
          onSubmit={onRefundSubmit}
          submitDisabled={submitDisabled}
          onClose={handleCancel}
        />
      )}
    </ModalWrap>
  );
};
