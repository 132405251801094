import { api } from '@fleet/shared';
import { Pagination } from '@fleet/shared/dto/pagination';
import { createAction } from '@reduxjs/toolkit';
import {
  AdditionalOffer,
  BookingComment,
  BookingCommentPayload,
  BookingDetails,
  BookingRefundOffer,
  BookingReleaseOffer,
  BookingSearchFilter,
  BookingsSearchResult,
  CustomerNotification,
  HistoryEvent,
  OnHoldBookingOffer,
  RefundReason,
  TravelAccount,
  UnaccompaniedMinorRequest,
  UnaccompaniedMinorRequestPayload,
  Warnings,
} from 'dto/booking';
import {
  PassengerOfferSelection,
  PassengerSpecification,
  PromoCode,
} from 'dto/trip';
import {
  bookingAdmissionsSelector,
  currentBookingIdSelector,
  currentBookingSelector,
  selectRefundOffers,
  selectReleaseOffers,
} from 'features/booking/bookingSelectors';
import i18n from 'i18n';
import _pick from 'lodash/pick';
import { stringify } from 'qs';
import { createAsyncThunk } from 'store/utils';
import { runInSequence } from 'utils/common';

export const setBookingFilter =
  createAction<Partial<BookingSearchFilter>>('booking/setFilter');

export const setOnHoldBookingTime = createAction<string>(
  'booking/setOnHoldBookingTime'
);

export const setCurrentBooking = createAction<BookingDetails>(
  'booking/setCurrentBooking'
);
export const clearExchangeOperations = createAction(
  'booking/clearExchangeOperations'
);

export const resetCurrentBooking = createAction('booking/resetCurrentBooking');

export const resetAdmissionSelection = createAction(
  'booking/resetAdmissionSelection'
);

interface PassengerSelection {
  passengerId: string;
  type: 'admission' | 'ancillary' | 'fulfillment';
  selection: Array<string>;
}
export const updateBookingPartsSelection = createAction<PassengerSelection>(
  'booking/updateBookingPartsSelection'
);

export const updateBookingPartsSelectionBulk = createAction<
  PassengerSelection[]
>('booking/updateBookingPartsSelectionBulk');

export const searchBookings = createAsyncThunk<
  Pagination<BookingsSearchResult>,
  Partial<BookingSearchFilter>
>('booking/search', async (filter, { getState }) => {
  const filterParams = filter || getState().booking.filter;
  const { bookingsSearchResults, totalCount, offset } = (
    await api.post('/bookings-search/custom', filterParams)
  ).data;
  return {
    items: bookingsSearchResults,
    offset,
    limit: filterParams.pagination?.limit,
    totalCount,
  };
});

export const getBooking = createAsyncThunk<BookingDetails, string | undefined>(
  'booking/getBooking',
  async (id, { getState }) => {
    return (
      await api.get(`/bookings/${id ?? currentBookingIdSelector(getState())}`)
    ).data;
  }
);

export const getTravelAccount = createAsyncThunk<
  TravelAccount | undefined,
  void
>('booking/getTravelAccount', async (_, { getState }) => {
  const state = getState();
  const [travelPassAdmission] = bookingAdmissionsSelector(state);
  const [fulfillment] = travelPassAdmission.fulfillments;
  return (
    await api.get<{ travelAccount: TravelAccount }>(
      `/travel-accounts${stringify(
        {
          issuer: fulfillment.issuer,
          travelAccount: fulfillment.controlNumber,
        },
        { addQueryPrefix: true }
      )}`
    )
  ).data.travelAccount;
});

interface PostBookingPayload {
  passengerSpecifications: PassengerSpecification[];
  offers: Array<{
    id: string;
    passengerExternalReferences: Array<string>;
    selections?: Array<PassengerOfferSelection>;
    alliances?: Array<string>;
  }>;
  promotionCodes?: Array<Omit<PromoCode, 'issuingCarrierCode'>>;
}

export const postBooking = createAsyncThunk<BookingDetails, PostBookingPayload>(
  'booking/postBooking',
  async (payload, { dispatch }) => {
    const {
      booking: { id },
      warnings,
    } = (await api.post('/bookings', payload)).data;
    const booking = await dispatch(getBooking(id)).unwrap();
    return {
      ...booking,
      warnings,
    };
  }
);

export interface UpdateTravelPassPayload {
  validUntil: string;
  numberOfUsages?: number;
}

export const updateTravelPass = createAsyncThunk<
  TravelAccount,
  UpdateTravelPassPayload & { number: string }
>(
  'booking/updateTravelPass',
  async ({ number, ...payload }) =>
    (
      await api.patch<{ travelAccount: TravelAccount }>(
        `/travel-accounts/${number}`,
        payload
      )
    ).data.travelAccount
);

export interface UpdateBookingPayload {
  selectionType?: string;
  placeSelections: Array<{
    reservationId: string;
    places: Array<{
      coachNumber: string;
      placeNumber: string;
      passengerRef: string;
    }>;
    tripLegCoverage: {
      tripId: string;
      legId: string;
    };
  }>;
}

export const updateBooking = createAsyncThunk<void, UpdateBookingPayload>(
  'booking/updateBooking',
  async (payload, { getState }) => {
    const bookingId = getState().booking!.current!.id!;
    await api.patch(`/bookings/${bookingId}`, payload);
  }
);

export const deleteBooking = createAsyncThunk<
  void,
  { bookingId?: string } | undefined
>('booking/deleteBooking', async (payload, { getState }) => {
  return (
    await api.delete(
      `/bookings/${payload?.bookingId ?? currentBookingIdSelector(getState())}`
    )
  ).data;
});

export const triggerBookingFulfillment = createAsyncThunk<
  BookingDetails,
  { bookingId: string; shouldWaitForExchangeGetBooking?: boolean }
>(
  'booking/triggerFulfillment',
  async ({ bookingId, shouldWaitForExchangeGetBooking }) => {
    return (
      await api.post(
        `/bookings/${bookingId}/fulfillments${stringify(
          {
            shouldWaitForExchangeGetBooking,
          },
          { addQueryPrefix: true }
        )}`,
        undefined,
        {
          headers: {
            'Accept-Language': i18n.language,
          },
        }
      )
    ).data;
  }
);

export const initiateRefund = createAsyncThunk<
  Array<BookingRefundOffer>,
  {
    bookingId: string;
    fulfillmentIds: Array<string>;
    overruleCode?: RefundReason;
  }
>(
  'booking/initiateRefund',
  async ({ bookingId, fulfillmentIds, ...payload }) => {
    return (
      await api.post(`/bookings/${bookingId}/refund-offers`, {
        fulfillmentIds,
        ...payload,
      })
    ).data.refundOffers;
  }
);

export const confirmRefund = createAsyncThunk<
  void,
  { bookingId: string; refundOfferIds: Array<string> }
>('booking/confirmRefund', async ({ bookingId, refundOfferIds }) => {
  await runInSequence(
    refundOfferIds.map(
      (id) => () =>
        api.patch(`/bookings/${bookingId}/refund-offers/${id}`, {
          status: 'CONFIRMED',
        })
    )
  );
});

export const deleteRefundOffers = createAsyncThunk(
  'booking/deleteRefundOffers',
  async (_, { getState }) => {
    const refundOffers = selectRefundOffers(getState()) ?? [];
    await Promise.all(
      refundOffers.map(({ id }) =>
        api.delete(
          `/bookings/${currentBookingIdSelector(
            getState()
          )}/refund-offers/${id}`
        )
      )
    );
  }
);

export const deleteReleaseOffers = createAsyncThunk(
  'booking/deleteReleaseOffers',
  async (_, { getState }) => {
    const refundOffers = selectReleaseOffers(getState()) ?? [];
    await Promise.all(
      refundOffers.map(({ id }) =>
        api.delete(
          `/bookings/${currentBookingIdSelector(
            getState()
          )}/release-offers/${id}`
        )
      )
    );
  }
);

export const initiateRelease = createAsyncThunk<
  Array<BookingReleaseOffer>,
  {
    bookingId: string;
    fulfillmentIds: Array<string>;
    overruleCode?: RefundReason;
  }
>(
  'booking/initiateRelease',
  async ({ bookingId, fulfillmentIds, overruleCode }) => {
    return (
      await api.post(`/bookings/${bookingId}/release-offers`, {
        fulfillmentIds,
        overruleCode,
      })
    ).data.releaseOffers;
  }
);

export const confirmRelease = createAsyncThunk<
  void,
  { bookingId: string; releaseOfferIds: Array<string> }
>('booking/confirmRelease', async ({ bookingId, releaseOfferIds }) => {
  await runInSequence(
    releaseOfferIds.map(
      (id) => () =>
        api.patch(`/bookings/${bookingId}/release-offers/${id}`, {
          status: 'CONFIRMED',
        })
    )
  );
});

export const sendConfirmation = createAsyncThunk<
  void,
  {
    bookingId: string;
    emailsOverride?: Array<string>;
    phoneNumbersOverride?: Array<{ number: string }>;
    includeTickets?: boolean;
    url: 'purchase-confirmation' | 'ticket-delivery';
    passengers?: Array<{ passengerId: string }>;
  }
>(
  'booking/sendConfirmation',
  async ({ url, ...payload }) =>
    (await api.post(`/notifications/${url}/send`, payload)).data
);

export const sendTicketDelivery = createAsyncThunk<
  void,
  {
    bookingId: string;
    includeTaxInvoices?: boolean;
    passengers?: Array<{ passengerId: string }>;
    purchaser?: {
      sendSms: boolean;
      sendEmail: boolean;
      emailOverride: Array<string>;
      phoneNumberOverride: Array<string>;
    };
  }
>(
  'booking/sendTicketDelivery',
  async (payload) =>
    (await api.post(`/notifications/ticket-delivery-v2/send`, payload)).data
);

export const getComments = createAsyncThunk<BookingComment[], string>(
  'booking/getComments',
  async (bookingId) => {
    return (await api.get(`/bookings/${bookingId}/comments`)).data.items;
  }
);
export const addComment = createAsyncThunk<
  BookingComment,
  BookingCommentPayload
>('booking/addComment', async (payload, { getState }) => {
  return (
    await api.post(
      `/bookings/${currentBookingIdSelector(getState())}/comments`,
      payload
    )
  ).data;
});

export const updateComment = createAsyncThunk<
  BookingComment,
  Pick<BookingCommentPayload, 'comment'> & { id: string }
>('booking/updateComment', async ({ id, ...payload }, { getState }) => {
  return (
    await api.patch(
      `/bookings/${currentBookingIdSelector(getState())}/comments/${id}`,
      payload
    )
  ).data;
});

export const deleteComment = createAsyncThunk<void, string>(
  'booking/deleteComment',
  async (id, { getState }) => {
    return (
      await api.delete(
        `/bookings/${currentBookingIdSelector(getState())}/comments/${id}`
      )
    ).data;
  }
);

export const getUnaccompaniedMinorRequests = createAsyncThunk<
  UnaccompaniedMinorRequest[],
  string
>('booking/getUnaccompaniedMinorRequests', async (bookingId) => {
  return (
    await api.get(
      `/bookings/${bookingId}/unaccompanied-minor-special-service-requests`
    )
  ).data.items;
});

export const addUnaccompaniedMinorRequest = createAsyncThunk<
  UnaccompaniedMinorRequest,
  UnaccompaniedMinorRequestPayload
>('booking/addUnaccompaniedMinorRequest', async (payload, { getState }) => {
  return (
    await api.post(
      `/bookings/${currentBookingIdSelector(
        getState()
      )}/unaccompanied-minor-special-service-requests`,
      payload
    )
  ).data;
});

export const updateUnaccompaniedMinorRequest = createAsyncThunk<
  UnaccompaniedMinorRequest,
  UnaccompaniedMinorRequestPayload
>(
  'booking/updateUnaccompaniedMinorRequest',
  async ({ id, ...payload }, { getState }) => {
    return (
      await api.patch(
        `/bookings/${currentBookingIdSelector(
          getState()
        )}/unaccompanied-minor-special-service-requests/${id}`,
        _pick(payload, [
          'comment',
          'passengerAge',
          'contactPersonAtOrigin',
          'contactPersonAtDestination',
        ])
      )
    ).data;
  }
);

export const deleteUnaccompaniedMinorRequest = createAsyncThunk<void, string>(
  'booking/deleteUnaccompaniedMinorRequest',
  async (id, { getState }) => {
    return (
      await api.delete(
        `/bookings/${currentBookingIdSelector(
          getState()
        )}/unaccompanied-minor-special-service-requests/${id}`
      )
    ).data;
  }
);

export const getAdditionalOffers = createAsyncThunk<
  Array<AdditionalOffer>,
  Array<string> | undefined
>('booking/getAdditionalOffers', async (bookedOfferIds, { getState }) => {
  const booking = getState().booking.current!;
  return (
    await api.post<{ items: Array<AdditionalOffer> }>(
      `/bookings/${booking.id}/booked-offers/additional-offers/search`,
      {
        bookedOfferIds:
          bookedOfferIds ??
          booking.bookedTrips.reduce<Array<string>>(
            (acc, { bookedOffers }) => [
              ...acc,
              ...bookedOffers.map(({ id }) => id),
            ],
            []
          ),
      }
    )
  ).data.items;
});

export const startExchange = createAsyncThunk<
  void,
  PostBookingPayload['offers']
>(
  'booking/startExchange',
  async (offers, { getState }) =>
    (
      await api.post(
        `/bookings/${currentBookingIdSelector(getState())}/exchange-operations`,
        { exchangeOffers: offers }
      )
    ).data
);

export const cancelExchangeOperations = createAsyncThunk(
  'booking/cancelExchangeOperations',
  async (_, { getState, dispatch }) => {
    const state = getState();
    const booking = currentBookingSelector(state);
    if (!booking?.exchangeOperations.length) return;
    await runInSequence(
      booking.exchangeOperations.map(
        ({ id }) =>
          () =>
            api.delete(`/bookings/${booking.id}/exchange-operations/${id}`)
      )
    );
    dispatch(getBooking(booking!.id));
  }
);

export const addAdditionalOfferToBooking = createAsyncThunk<
  void,
  {
    bookedOfferId: string;
    offerId: string;
    ancillaryId: string;
    passengerRefs: Array<string>;
    tripCoverage: {
      tripId: string;
      journeyReference: string;
      coveredLegIds: Array<string>;
    };
  }
>(
  'booking/addAdditionalOfferToBooking',
  async ({ bookedOfferId, ...payload }, { getState }) => {
    const booking = getState().booking.current!;
    return (
      await api.post(
        `/bookings/${booking.id}/booked-offers/${bookedOfferId}/ancillaries`,
        payload
      )
    ).data;
  }
);

export const removeAdditionalOffer = createAsyncThunk<
  void,
  {
    bookedOfferId: string;
    ancillaryId: string;
  }
>(
  'booking/removeAdditionalOffer',
  async ({ bookedOfferId, ancillaryId }, { getState }) => {
    const booking = getState().booking.current!;
    return (
      await api.delete(
        `/bookings/${booking.id}/booked-offers/${bookedOfferId}/ancillaries/${ancillaryId}`
      )
    ).data;
  }
);

export const getHistory = createAsyncThunk<HistoryEvent[], string>(
  'booking/getHistory',
  async (bookingId) => {
    return (await api.get(`/bookings/${bookingId}/extended-history`)).data
      .events;
  }
);

export const getNotifications = createAsyncThunk<
  CustomerNotification[],
  string
>('booking/getNotifications', async (bookingId) => {
  return (
    await api.get<{
      warnings: Warnings;
      customerCommunications: Array<CustomerNotification>;
    }>(`/bookings/${bookingId}/customer-communication-history`)
  ).data.customerCommunications;
});

export const addOfferToBooking = createAsyncThunk<
  {
    booking: { id: string; number: string };
    validToUtc: string;
  },
  PostBookingPayload & { bookingId: string }
>('booking/addOfferToBooking', async ({ bookingId, ...payload }) => {
  return (await api.post(`/bookings/${bookingId}/booked-offers`, payload)).data;
});

export const removeOfferFromBooking = createAsyncThunk<
  { isBookingDeleted: boolean },
  { bookingId: string; ids: Array<string> }
>('booking/removeOfferFromBooking', async ({ bookingId, ids }) => {
  return (
    await api.delete(`/bookings/${bookingId}/offers?ids=${ids.join(',')}`)
  ).data;
});

export const putBookingOnHold = createAsyncThunk<BookingDetails, string>(
  'booking/putBookingOnHold',
  async (onHoldOfferId, { getState }) => {
    const bookingId = getState().booking!.current!.id!;
    return (
      await api.patch<BookingDetails>(
        `/bookings/${bookingId}/on-hold-booking/${onHoldOfferId}`
      )
    ).data;
  }
);

export const makeOnHoldBookingOffer = createAsyncThunk<OnHoldBookingOffer>(
  'booking/makeOnHoldBookingOffer',
  async (_, { getState }) => {
    const state = getState();
    const bookingId = currentBookingIdSelector(state)!;

    return (
      await api.post<{ onHoldOffer: OnHoldBookingOffer }>(
        `/bookings/${bookingId}/on-hold-booking`,
        { isPaycon: false }
      )
    ).data.onHoldOffer;
  }
);

export const patchNewOnHoldBookingTime = createAsyncThunk<
  never,
  { validUntil: string; offerId?: string }
>(
  'booking/patchNewOnHoldBookingTime',
  async ({ validUntil, offerId }, { getState }) => {
    const bookingId = getState().booking!.current!.id!;
    return (
      await api.patch<never>(`/bookings/${bookingId}/valid-until`, {
        validUntil: validUntil,
        onHoldOfferId: offerId,
      })
    ).data;
  }
);

export const removeFeeFromBooking = createAsyncThunk<void, string[]>(
  'booking/removeFeeFromBooking',
  async (feeIds, { getState }) => {
    const bookingId = getState().booking!.current!.id!;

    return (
      await api.post<void>(`/bookings/${bookingId}/fees/bulk-delete`, feeIds)
    ).data;
  }
);

export const removePassengersFromBooking = createAsyncThunk<
  { isBookingDeleted: boolean },
  { bookingId: string; refs: Array<string> }
>('booking/removePassengersFromBooking', async ({ bookingId, refs }) => {
  return (
    await api.delete(`/bookings/${bookingId}/passengers?refs=${refs.join(',')}`)
  ).data;
});

export const removeAdmissionsFromPassenger = createAsyncThunk<
  { isBookingDeleted: boolean },
  { bookingId: string; ids: Array<string> }
>('booking/removeAdmissionsFromPassenger', async ({ bookingId, ids }) => {
  return (
    await api.delete(`/bookings/${bookingId}/admissions?ids=${ids.join(',')}`)
  ).data;
});
