import React, { useEffect } from 'react';

const DynamicFavicon: React.FC = () => {
  useEffect(() => {
    const link: HTMLLinkElement =
      (document.querySelector("link[rel*='icon']") as HTMLLinkElement) ||
      document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = `${process.env.PUBLIC_URL}/favicon-${
      process.env.REACT_APP_IS_DS_INVOLVED!.toString() === 'true' ? 'ds' : 'ims'
    }.ico?${new Date().getTime()}`;
    document.getElementsByTagName('head')[0].appendChild(link);
  }, []);

  return null;
};

export default DynamicFavicon;
