import { Icon } from '@fleet/shared';
import { IconButton, Button, Stack } from '@mui/material';
import { FC } from 'react';
import { formatDate } from '@fleet/shared/utils/date';
import { currentLocaleConfiguration } from "@fleet/shared/i18n";

interface DaysSelectorProps {
  value: Date;
  onChange: (direction: 'prev'|'next') => void;
}

export const DaysSelector: FC<DaysSelectorProps> = ({
  value,
  onChange,
}) => {
  return (
    <Stack direction="row" flex={1} justifyContent="center" alignItems="center">
      <IconButton
        onClick={() => onChange('prev')}
        color="inherit"
      >
        <Icon name="arrow-left" />
      </IconButton>
      <Button variant="text" sx={{ fontWeight: 'bold' }}>
        {formatDate(value, `EEE, ${currentLocaleConfiguration.dateFormat}`)}
      </Button>
      <IconButton
        onClick={() => onChange('next')}
        color="inherit"
      >
        <Icon name="arrow-right" />
      </IconButton>
    </Stack>
  );
};
