import { SelectField } from '@fleet/shared';
import { useStopSearchSelect } from 'hooks/useStopSearchSelect';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FC, ReactNode } from 'react';

interface StopsSelectFieldProps {
  label: ReactNode;
  name: string;
}
export const StopsSelectField: FC<StopsSelectFieldProps> = ({
  label,
  name,
}) => {
  const { onSearch, options, isLoading, noOptionsTranslationCode } =
    useStopSearchSelect({});

  return (
    <SelectField
      label={label}
      name={name}
      onInputChange={onSearch}
      options={options}
      noOptionsText={<TransSubtitle i18nKey={noOptionsTranslationCode} />}
      loading={isLoading}
      loadingText={<TransSubtitle i18nKey="loading" />}
      disableClearable={false}
    />
  );
};
