import { Icon, Tooltip } from '@fleet/shared';
import { TextField } from '@fleet/shared/form';
import { Stack } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TransParagraph } from 'i18n/trans/paragraph';
import React, { FC } from 'react';
import { useValidatePhoneNumber } from 'utils/overview';

interface AdditionalRecipientFieldProps {
  type: 'sms' | 'email';
  name: string;
}

export const AdditionalRecipientField: FC<AdditionalRecipientFieldProps> = ({
  type,
  name,
}) => {
  const validatePhoneNumber = useValidatePhoneNumber();
  return (
    <>
      {type === 'sms' ? (
        <TextField
          type="text"
          placeholder="+"
          name={name}
          label={
            <Stack direction="row" sx={{ display: 'inline-flex' }}>
              <TransField i18nKey="mobileNumber" />
              &nbsp;
              <Tooltip content={<TransParagraph i18nKey="phoneSearchHint" />}>
                <Icon name="info-circle" />
              </Tooltip>
            </Stack>
          }
          required
          validate={validatePhoneNumber}
        />
      ) : (
        <TextField
          required
          email
          name={name}
          label={<TransField i18nKey="additionalEmailRecipient" />}
        />
      )}
    </>
  );
};
