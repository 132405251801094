import { Layout, Loadable } from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import { CardHeader } from '@fleet/shared/mui';
import { BookingsSearchForm } from 'routes/bookingsSearch/BookingsSearchForm';
import { BookingsTable } from 'routes/bookingsSearch/BookingsTable';
import { Divider } from '@mui/material';
import { bookingSearchLoadingSelector } from 'features/loading/loadingSelectors';
import { useSelector } from 'store/utils';

export const BookingSearch = () => {
  const loading = useSelector(bookingSearchLoadingSelector);
  return (
    <Loadable loading={loading}>
      <Layout
        sx={{ minHeight: 'unset !important' }}
        header={
          <CardHeader
            sx={{ padding: '16px 24px' }}
            title={<TransTitle i18nKey="bookingSearch" />}
          />
        }
      >
        <>
          <BookingsSearchForm />
          <Divider />
          <BookingsTable />
        </>
      </Layout>
    </Loadable>
  );
};
