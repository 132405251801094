import { Checkbox } from '@fleet/shared';
import { Typography } from '@mui/material';
import { BookingRefundOffer, RefundReason } from 'dto/booking';
import {
  confirmRelease,
  deleteRefundOffers,
  deleteReleaseOffers,
  getBooking,
  getHistory,
  initiateRefund,
  initiateRelease,
} from 'features/booking/bookingActions';
import {
  currentBookingSelector,
  selectActiveFulfillmentIdsSelection,
  selectReleaseOffers,
} from 'features/booking/bookingSelectors';
import { currentBookingLoadingSelector } from 'features/loading/loadingSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransTitle } from 'i18n/trans/title';
import _isEqual from 'lodash/isEqual';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { ModalWrap } from 'routes/bookingDetails/modal/ModalWrap';
import { PassengersSelection } from 'routes/bookingDetails/modal/PassengersSelection';
import { useDispatch, useSelector } from 'store/utils';
import { useSelectedAdmissionFulfillmentStatusOnHold } from 'utils/common';

interface CancelModalProps {
  isAdmissionOnHold: boolean;
  submitDisabled: boolean;
  onClose: () => void;
}

export const CancelModal: FC<CancelModalProps> = ({
  isAdmissionOnHold,
  submitDisabled,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [isExternalCompensation, setIsExternalCompensation] = useState(false);
  const [refundOffers, setRefundOffers] = useState<Array<BookingRefundOffer>>(
    []
  );
  const loading = useSelector(currentBookingLoadingSelector);
  const { id, bookedTrips } = useSelector(currentBookingSelector)!;
  const activeFulfillmentIds = useSelector(
    selectActiveFulfillmentIdsSelection,
    _isEqual
  );
  const isSelectedAdmissionFulfillmentOnHold =
    useSelectedAdmissionFulfillmentStatusOnHold(
      bookedTrips,
      activeFulfillmentIds
    );
  const releaseOffers = useSelector(selectReleaseOffers, _isEqual);

  const handleCancel = useCallback(async () => {
    await dispatch(deleteReleaseOffers());
    onClose();
  }, [dispatch, onClose]);

  const initRelease = useCallback(
    async (overruleCode?: RefundReason) => {
      try {
        const promises = [];
        promises.push(
          dispatch(
            initiateRelease({
              bookingId: id,
              fulfillmentIds: activeFulfillmentIds,
              overruleCode,
            })
          ).unwrap()
        );
        if (!isAdmissionOnHold && !isSelectedAdmissionFulfillmentOnHold) {
          promises.push(
            dispatch(
              initiateRefund({
                bookingId: id,
                fulfillmentIds: activeFulfillmentIds,
                overruleCode,
              })
            ).unwrap()
          );
        }
        try {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [_, refundOffersResponse] = await Promise.all(promises);
          !isAdmissionOnHold &&
            setRefundOffers(refundOffersResponse as Array<BookingRefundOffer>);
        } catch (e) {
          throw e;
        } finally {
          dispatch(deleteRefundOffers());
        }
      } catch (e) {
        onClose();
      }
    },
    [
      dispatch,
      id,
      activeFulfillmentIds,
      isAdmissionOnHold,
      onClose,
      isSelectedAdmissionFulfillmentOnHold,
    ]
  );

  const handleExternalCompensationChange = useCallback(
    async ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
      try {
        await dispatch(deleteReleaseOffers()).unwrap();
        await initRelease(
          checked ? RefundReason.EXTERNAL_COMPENSATION : undefined
        );
        setIsExternalCompensation(checked);
      } catch (error) {}
    },
    [dispatch, initRelease]
  );

  const onReleaseSubmit = useCallback(async () => {
    onClose();
    await dispatch(
      confirmRelease({
        bookingId: id,
        releaseOfferIds: releaseOffers!.map(({ id }) => id),
      })
    );
    await Promise.all([dispatch(getBooking(id)), dispatch(getHistory(id))]);
  }, [dispatch, id, onClose, releaseOffers]);

  useEffect(() => {
    !submitDisabled && initRelease();
  }, [submitDisabled, initRelease]);

  return (
    <ModalWrap
      open={!loading}
      onClose={handleCancel}
      title={
        <Typography variant="subtitle">
          <TransTitle i18nKey="cancelSelected" />
        </Typography>
      }
      maxWidth="xl"
    >
      <PassengersSelection
        type="cancel"
        submitLabel={<TransButton i18nKey="cancelSelected" />}
        onSubmit={onReleaseSubmit}
        submitDisabled={submitDisabled}
        onClose={handleCancel}
        refundOffers={refundOffers}
      >
        <Checkbox
          checked={isExternalCompensation}
          label={<TransField i18nKey="EXTERNAL_COMPENSATION" />}
          onChange={handleExternalCompensationChange}
          disabled={submitDisabled}
          inline
        />
      </PassengersSelection>
    </ModalWrap>
  );
};
