import { alertProviderProps } from '@fleet/shared/components/Alert';
import { Loader, ThemeProvider } from '@fleet/shared/mui';
import { App } from 'App';
import AxiosErrorsInterceptorWrapper from 'wrappers/AxiosErrorsInterceptorWrapper';
import { initializeSentry } from 'helpers/sentryConfig';
import 'i18n';
import 'index.scss';
import React, { StrictMode, Suspense } from 'react';
import { Provider as AlertProvider } from 'react-alert';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import { AuthProvider, baseOidcConfig } from '@fleet/shared';

initializeSentry();
const isMonitorSession =
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_MONITOR_SESSION;

ReactDOM.render(
  <StrictMode>
    <ThemeProvider>
      <Suspense fallback={<Loader active size="fullscreen" />}>
        <Provider store={store}>
          <AuthProvider
            {...{ ...baseOidcConfig, monitorSession: isMonitorSession }}
          >
            <AlertProvider {...alertProviderProps}>
              <AxiosErrorsInterceptorWrapper>
                <App />
              </AxiosErrorsInterceptorWrapper>
            </AlertProvider>
          </AuthProvider>
        </Provider>
      </Suspense>
    </ThemeProvider>
  </StrictMode>,
  document.getElementById('root')
);
