import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { differenceInCalendarDays } from 'date-fns';
import { FC, useMemo } from 'react';

interface DaysAfterCountProps {
  className?: string;
  startDate: string;
  endDate: string;
}
const useStyles = makeStyles(
  (theme) => ({
    root: {
      borderRadius: '50%',
      width: '1.25rem',
      height: '1.25rem',
      display: 'inline-flex',
      background: theme.palette.primary.light,
    },
    daysNr: {
      margin: 'auto',
      color: theme.palette.primary.main,
      fontSize: '0.625rem',
      lineHeight: '1rem',
      '&:before': {
        content: '"+"',
      },
    },
  }),
  { name: 'DaysAfterCount' }
);

export const DaysAfterCount: FC<DaysAfterCountProps> = ({
  className,
  startDate,
  endDate,
}) => {
  const classes = useStyles();
  const days = useMemo(
    () => differenceInCalendarDays(new Date(endDate), new Date(startDate)),
    [endDate, startDate]
  );
  if (!days) return null;

  return (
    <Box className={classNames(classes.root, className)}>
      <span className={classes.daysNr}>{days}</span>
    </Box>
  );
};
