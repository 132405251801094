import { Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      boxShadow: theme.shadows[3],
      position: 'absolute',
      background: 'white',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1,
      padding: '1rem',
      justifyContent: 'flex-end',
    },
  }),
  { name: 'ControlsWrap' }
);

export const ModalControlsWrap: FC<{}> = ({ children }) => {
  const classes = useStyles();
  return (
    <Stack className={classes.root} direction="row" spacing={1}>
      {children}
    </Stack>
  );
};
