import {
  FieldArray,
  RadioGroupField,
  SelectOption,
  SwitchField,
  Theme,
  useFormContext,
} from '@fleet/shared';
import { CheckboxGroupField, SelectField, TextField } from '@fleet/shared/form';
import { useField } from '@fleet/shared/form/hooks/useField';
import { Icon, Switch } from '@fleet/shared/mui';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ClassificationGroup } from 'dto/classification';
import {
  CustomerCard,
  PassengerCardState,
  PassengerSpecification,
  SearchBarFormValues,
} from 'dto/trip';
import { cardsSelector } from 'features/classification/classificationSelectors';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransLabel } from 'i18n/trans/label';
import { TransSubtitle } from 'i18n/trans/subtitle';
import _groupBy from 'lodash/groupBy';
import _mapValues from 'lodash/mapValues';
import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useSelector } from 'store/utils';
import { IS_DS_AT, IS_IMS_AT } from 'utils/flags';
import { useApplyAllCardsToggle } from 'hooks/useApplyAllCardsToggle';
import { PassengerType } from 'dto/booking';

interface PassengerCardProps {
  number: number;
  width: number;
  name: string;
  onRemove: () => void;
  totalPassengers: number;
}

const useStyles = makeStyles<Theme, PassengerCardProps>(
  (theme) => ({
    root: {
      border: `1px solid ${theme.palette.divider}`,
      width: ({ width }) => `${width}px`,
      background: theme.palette.background.default,
      height: 'fit-content',
      '& .MuiCardContent-root': {
        padding: '1rem',
      },
    },
    card: {
      overflow: 'visible',
      width: '100%',
      position: 'relative',
      background: 'white',
      padding: '0.5rem',
      '& $deleteBtn': {
        padding: '0.25rem',
        background: theme.palette.error.main,
        color: 'white!important',
        position: 'absolute',
        top: -4,
        right: -8,
      },
    },
    accordion: {},
    deleteBtn: {
      '&:disabled': {
        background: theme.palette.action.disabledBackground,
        color: theme.palette.action.disabled,
      },
    },
    addBtn: {
      margin: 0,
      padding: 0,
    },
  }),
  {
    name: 'PassengerCard',
  }
);

enum PRM_NEEDS {
  WHEELCHAIR = 'WHEELCHAIR',
  WHEELCHAIR_AND_SEAT = 'WHEELCHAIR_AND_SEAT',
  EASY_ACCESS = 'EASY_ACCESS',
}

export const PassengerCard: FC<PassengerCardProps> = (props) => {
  const TRAVEL_PASS_TYPE = 'CARD_TYPE.TRAVEL_PASS';
  const MULTI_RIDE_TYPE = 'CARD_TYPE.MULTI_RIDE';
  const form = useFormContext<Partial<SearchBarFormValues>>();
  const {
    input: {
      value: { prmNeeds, concession, type, ...restSpecification },
      onChange,
    },
  } = useField<string, PassengerSpecification>(props.name, form, {
    subscription: { value: true },
  });
  const cardState: PassengerCardState | undefined =
    form.getState().values.cardState;
  const cards = useSelector(cardsSelector);
  const carrierOptions = useClassificationOptions(ClassificationGroup.CARRIER);
  const { number, name, onRemove, totalPassengers } = props;
  const classes = useStyles(props);
  const [areDetailsShown, setDetailsShown] = useState(false);
  const checkIfCardIsRequired = useCallback(
    ({ code, type }) =>
      cards.find((card) => code === card.code && type === card.type.id)
        ?.cardIdRequired,
    [cards]
  );
  const cardsByType = useMemo(
    () => _groupBy(cards, (card) => card.type.id),
    [cards]
  );
  const cardTypeOptions = useMemo(
    () => [
      ...Object.keys(cardsByType).map((type) => ({
        value: type,
        label: cardsByType[type][0].type.name,
      })),
      {
        value: TRAVEL_PASS_TYPE,
        label: renderToString(<TransField i18nKey="travelPass" />),
      },
      ...(IS_DS_AT
        ? [
            {
              value: MULTI_RIDE_TYPE,
              label: renderToString(<TransField i18nKey="multiRide" />),
            },
          ]
        : []),
    ],
    [cardsByType]
  );
  const cardCodeOptionsMap = useMemo<Record<string, Array<SelectOption>>>(
    () =>
      _mapValues(cardsByType, (cards) =>
        cards
          .sort((a, b) => a.displayOrder - b.displayOrder)
          .map(({ code, name }) => ({
            label: name.text,
            value: code,
          }))
      ),
    [cardsByType]
  );
  const prmNeedsOptions = useMemo(() => {
    return (IS_DS_AT ? [PRM_NEEDS.WHEELCHAIR] : Object.values(PRM_NEEDS)).map(
      (key) => ({
        label: renderToString(<TransLabel i18nKey={key} />),
        value: key,
      })
    );
  }, []);
  const onPrmNeedsChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange({
        ...restSpecification,
        type,
        prmNeeds: e.target.checked ? [] : undefined,
      });
    },
    [onChange, restSpecification, type]
  );

  const { onToggleChange } = useApplyAllCardsToggle(form, cardState);

  const onPassengerTypeChange = useCallback(() => {
    if (type !== PassengerType.PERSON) {
      // @ts-ignore
      form.change(`passengerSpecifications[${number - 1}].cards`, []);
    }
  }, [form, number, type]);

  const isTotalPassengerOrPersonTypeSmallerThanTwo = useMemo(() => {
    const passengerSpecifications =
      form.getFieldState('passengerSpecifications')?.value || [];

    const numberOfPersonType = passengerSpecifications.reduce((acc, curr) => {
      return curr.type === PassengerType.PERSON ? acc + 1 : acc;
    }, 0);

    return totalPassengers < 2 || numberOfPersonType < 2;
  }, [form, totalPassengers]);

  const checkAndToggleCardState = useCallback(() => {
    const passengerSpecifications: PassengerSpecification[] =
      form.getFieldState('passengerSpecifications')?.value || [];

    if (!cardState) return;

    const firstPassengerCards = passengerSpecifications[0]?.cards || [];
    const firstPassengerCardPresenceMap: { [p: string]: boolean } =
      firstPassengerCards.reduce((acc: { [p: string]: boolean }, card) => {
        if (card.uniqueCardId) {
          acc[card.uniqueCardId] = false;
        }
        return acc;
      }, {});

    passengerSpecifications.slice(1).forEach((spec) => {
      spec.cards?.forEach((card) => {
        if (
          card.uniqueCardId &&
          firstPassengerCardPresenceMap.hasOwnProperty(card.uniqueCardId)
        ) {
          firstPassengerCardPresenceMap[card.uniqueCardId] = true; // Mark as found
        }
      });
    });

    const updatedCardToggleStateMap = Object.keys(
      cardState.cardToggleStateMap
    ).reduce((acc: { [key: string]: boolean }, cardId) => {
      acc[cardId] = firstPassengerCardPresenceMap.hasOwnProperty(cardId)
        ? firstPassengerCardPresenceMap[cardId]
        : false;
      return acc;
    }, {});

    form.change('cardState', {
      ...cardState,
      cardToggleStateMap: updatedCardToggleStateMap,
    });
  }, [form, cardState]);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Stack spacing={1} alignItems="flex-start">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: '100%' }}
          >
            <Typography variant="body1" fontWeight="bold">
              <TransSubtitle i18nKey="passengerTitle" values={{ number }} />
            </Typography>
            <Button
              startIcon={<Icon name="trash" />}
              sx={{ p: 0 }}
              onClick={() => {
                onRemove();
                checkAndToggleCardState();
              }}
            >
              <TransButton i18nKey="delete" />
            </Button>
          </Stack>
          {IS_IMS_AT && (
            <RadioGroupField
              inline
              onChange={onPassengerTypeChange}
              name={`${name}.type`}
              options={[
                {
                  value: PassengerType.PERSON,
                  label: <TransField i18nKey="person" />,
                },
                {
                  value: PassengerType.ACCOMP_DOG,
                  label: <TransField i18nKey="companionDog" />,
                },
              ]}
            />
          )}
          {type === PassengerType.PERSON && (
            <>
              <TextField
                name={`${name}.age`}
                parse={(val: string) => val || undefined}
                label={<TransField i18nKey="passengerAge" />}
              />
              {!concession && (
                <FieldArray<CustomerCard> name={`${name}.cards`}>
                  {({ fields }) => (
                    <>
                      {fields.value?.map((card, idx) => (
                        <Card className={classes.card} key={idx} elevation={0}>
                          <Stack spacing={1}>
                            <SelectField
                              required
                              name={`${fields.name}[${idx}].type`}
                              options={cardTypeOptions}
                              onChange={(type) => {
                                const currentCardValue = fields.value[idx];

                                const updatedCard = {
                                  ...currentCardValue,
                                  type: type,
                                  code: undefined,
                                  number: undefined,
                                };

                                form.change(
                                  // @ts-ignore
                                  `${fields.name}[${idx}]`,
                                  updatedCard
                                );
                              }}
                              defaultValue={cardTypeOptions[0]}
                              label={<TransField i18nKey="cardType" />}
                            />
                            {card.type === TRAVEL_PASS_TYPE ||
                            card.type === MULTI_RIDE_TYPE ? (
                              <>
                                <SelectField
                                  required
                                  name={`${fields.name}[${idx}].issuer`}
                                  options={carrierOptions}
                                  label={<TransField i18nKey="issuer" />}
                                />
                                <TextField
                                  name={`${fields.name}[${idx}].code`}
                                  label={<TransField i18nKey="cardCode" />}
                                  required
                                />
                                <TextField
                                  key={card.code}
                                  name={`${fields.name}[${idx}].number`}
                                  label={<TransField i18nKey="cardNumber" />}
                                  required
                                />
                                {number === 1 && (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    spacing={1}
                                  >
                                    <Typography variant="body2">
                                      <TransLabel i18nKey="applyCardToAllPassengers" />
                                    </Typography>
                                    <Switch
                                      disabled={
                                        !card.code ||
                                        isTotalPassengerOrPersonTypeSmallerThanTwo
                                      }
                                      checked={
                                        cardState?.cardToggleStateMap[
                                          card.uniqueCardId ?? ''
                                        ] || false
                                      }
                                      onChange={(e) =>
                                        onToggleChange(
                                          card,
                                          e.target.checked,
                                          idx
                                        )
                                      }
                                    />
                                  </Stack>
                                )}
                              </>
                            ) : (
                              <>
                                <SelectField
                                  required
                                  name={`${fields.name}[${idx}].code`}
                                  options={cardCodeOptionsMap[card.type]}
                                  label={<TransField i18nKey="cardName" />}
                                  onChange={(value) => {
                                    const selectedCard = cards.find(
                                      (card) => card.code === value
                                    );

                                    if (selectedCard?.issuer) {
                                      form.change(
                                        // @ts-ignore
                                        `${fields.name}[${idx}].issuer`,
                                        selectedCard.issuer.code
                                      );
                                    } else {
                                      form.change(
                                        // @ts-ignore
                                        `${fields.name}[${idx}].issuer`,
                                        undefined
                                      );
                                    }
                                  }}
                                />
                                <TextField
                                  key={card.code}
                                  name={`${fields.name}[${idx}].number`}
                                  label={<TransField i18nKey="cardNumber" />}
                                  required={checkIfCardIsRequired(card)}
                                />
                                {card.code?.includes('CONCESSION_TYPE') && (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    spacing={1}
                                  >
                                    <Typography variant="body2">
                                      <TransField i18nKey="useConcessionVouchers" />
                                    </Typography>
                                    <div>
                                      <SwitchField
                                        key={card.code}
                                        name={`${fields.name}[${idx}].useConcessionVouchers`}
                                        disabled={!card.number}
                                      />
                                    </div>
                                  </Stack>
                                )}

                                {number === 1 && (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    spacing={1}
                                  >
                                    <Typography variant="body2">
                                      <TransLabel i18nKey="applyCardToAllPassengers" />
                                    </Typography>
                                    <Switch
                                      disabled={
                                        !card.code ||
                                        isTotalPassengerOrPersonTypeSmallerThanTwo
                                      }
                                      checked={
                                        cardState?.cardToggleStateMap[
                                          card.uniqueCardId ?? ''
                                        ] || false
                                      }
                                      onChange={(e) =>
                                        onToggleChange(
                                          card,
                                          e.target.checked,
                                          idx
                                        )
                                      }
                                    />
                                  </Stack>
                                )}
                              </>
                            )}
                          </Stack>
                          <IconButton
                            disabled={
                              !!card.uniqueCardId &&
                              number === 1 &&
                              cardState?.cardToggleStateMap[card.uniqueCardId]
                            }
                            className={classes.deleteBtn}
                            onClick={() => {
                              fields.remove(idx);

                              setTimeout(() => checkAndToggleCardState(), 0);
                            }}
                          >
                            <Icon name="close" />
                          </IconButton>
                        </Card>
                      ))}
                      <Button
                        startIcon={<Icon name="add" />}
                        sx={{ px: 0 }}
                        onClick={() =>
                          fields.push({
                            type: cardTypeOptions[0].value,
                          })
                        }
                      >
                        <TransButton i18nKey="addPassengerCard" />
                      </Button>
                    </>
                  )}
                </FieldArray>
              )}
            </>
          )}
        </Stack>
      </CardContent>
      {type === PassengerType.PERSON && (
        <Accordion
          className={classes.accordion}
          disableGutters
          elevation={1}
          expanded={areDetailsShown}
          onChange={() => setDetailsShown(!areDetailsShown)}
        >
          <AccordionSummary>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Icon name={`chevron-${areDetailsShown ? 'down' : 'up'}`} />
              <Typography variant="body1">
                <TransLabel i18nKey="details" />
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
            >
              <Typography variant="body1">
                <TransLabel i18nKey="prmNeed" />
              </Typography>
              <Switch checked={!!prmNeeds} onChange={onPrmNeedsChange} />
            </Stack>
            {!!prmNeeds && (
              <CheckboxGroupField
                name={`${name}.prmNeeds`}
                options={prmNeedsOptions}
                margin="normal"
                required
              />
            )}
          </AccordionDetails>
        </Accordion>
      )}
    </Card>
  );
};
