import { FieldArray, Icon } from '@fleet/shared';
import { IconButton, Stack } from '@mui/material';
import { AdditionalRecipientField } from 'components/ticketFulfillment/AdditionalRecipientField';
import React, { FC } from 'react';

interface AdditionalRecipientsSelectorProps {
  name: string;
  type: 'sms' | 'email';
}

export const AdditionalRecipientsSelector: FC<AdditionalRecipientsSelectorProps> =
  ({ type, name }) => {
    return (
      <FieldArray name={name}>
        {({ fields }) => (
          <>
            {fields.value?.map((_, idx) => (
              <Stack direction="row" gap={2} maxWidth={500} key={idx}>
                <AdditionalRecipientField
                  type={type}
                  name={`${fields.name}[${idx}]`}
                />
                <IconButton
                  sx={{ alignSelf: 'flex-start', top: '24px' }}
                  onClick={() => fields.remove(idx)}
                >
                  <Icon name="close" />
                </IconButton>
              </Stack>
            ))}
          </>
        )}
      </FieldArray>
    );
  };

export const AdditionalRecipientsAddBtn = ({ name }: { name: string }) => {
  return (
    <FieldArray name={name}>
      {({ fields }) => (
        <IconButton
          sx={{
            height: '2rem',
            width: '2rem',
            marginLeft: 'auto',
            flexShrink: 0,
          }}
          onClick={() => fields.push('')}
        >
          <Icon name="plus" size={20} />
        </IconButton>
      )}
    </FieldArray>
  );
};
