const isVarTrue = (envVar?: string) => envVar?.toString() === 'true';

export const IS_DS_AT = isVarTrue(process.env.REACT_APP_IS_DS_INVOLVED);

export const IS_IMS_AT = !IS_DS_AT;

export const FEATURE_CONCESSION = isVarTrue(
  process.env.REACT_APP_FEATURE_CONCESSION
);

export const FEATURE_PDF_DOWNLOAD_DEFAULT = isVarTrue(
  process.env.REACT_APP_FEATURE_PDF_DOWNLOAD_DEFAULT
);
export const FEATURE_SEND_EMAIL_DEFAULT = isVarTrue(
  process.env.REACT_APP_FEATURE_SEND_EMAIL_DEFAULT
);
export const FEATURE_SEND_SMS_DEFAULT = isVarTrue(
  process.env.REACT_APP_FEATURE_SEND_SMS_DEFAULT
);

export const FEATURE_TICKET_DELIVERY_V2 = IS_DS_AT;
