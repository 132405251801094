import { Button } from '@fleet/shared';
import { Stack, Typography } from '@mui/material';
import { BookingRefundOffer } from 'dto/booking';
import { Price } from 'dto/trip';
import {
  bookingPartsSelector,
  currentBookingSelector,
  selectRefundOffers,
} from 'features/booking/bookingSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransParagraph } from 'i18n/trans/paragraph';
import { TransSubtitle } from 'i18n/trans/subtitle';
import _pickBy from 'lodash/pickBy';
import _uniq from 'lodash/uniq';
import { FC, ReactNode, useMemo } from 'react';
import { useSelector } from 'store/utils';
import { PassengerSelection } from './PassengerSelection';
import _isEmpty from 'lodash/isEmpty';

interface PassengersSelectionProps {
  type: 'refund' | 'cancel';
  submitDisabled?: boolean;
  submitIcon?: ReactNode;
  submitLabel: ReactNode;
  onSubmit: () => void;
  onClose: () => void;
  refundOffers?: BookingRefundOffer[];
}

export const PassengersSelection: FC<PassengersSelectionProps> = ({
  type,
  submitIcon,
  submitLabel,
  submitDisabled,
  onSubmit,
  onClose,
  children,
  refundOffers: customRefundOffers,
}) => {
  const booking = useSelector(currentBookingSelector)!;
  const selection = useSelector(bookingPartsSelector);
  const selectedPassengerIds = useMemo(() => {
    const sortOrder = booking.passengers.map(({ id }) => id);
    const { admission, fulfillment, ancillary } = selection;
    return _uniq(
      Object.keys(
        _pickBy(
          { ...admission, ...fulfillment, ...ancillary },
          (selection) => !_isEmpty(selection)
        )
      )
    ).sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b));
  }, [booking.passengers, selection]);
  const refundOffers = useSelector(selectRefundOffers);
  const preparedRefundOffers = useMemo(
    () => customRefundOffers ?? refundOffers,
    [customRefundOffers, refundOffers]
  );
  const totalRefundAmount = useMemo<Price | undefined>(() => {
    if (submitDisabled || !preparedRefundOffers) return;
    return preparedRefundOffers.reduce(
      (total, cur) => ({
        amount: total.amount + cur.refundableAmount.amount,
        currency: cur.refundableAmount.currency,
      }),
      { amount: 0, currency: '' }
    );
  }, [submitDisabled, preparedRefundOffers]);

  return (
    <Stack direction="column" spacing={3}>
      <Typography
        variant="body1"
        color={submitDisabled ? 'warning.main' : undefined}
      >
        <TransParagraph
          i18nKey={
            submitDisabled
              ? 'admissionSelectionCanceled'
              : type === 'refund'
              ? 'refundWarning'
              : 'cancelWarning'
          }
        />
      </Typography>
      <Stack direction="column">
        {selectedPassengerIds.map((passengerId) => (
          <PassengerSelection
            key={passengerId}
            passengerId={passengerId}
            type={type}
          />
        ))}
      </Stack>
      {children}
      <Stack
        justifyContent={totalRefundAmount ? 'space-between' : 'flex-end'}
        direction="row"
      >
        {totalRefundAmount && (
          <Typography variant="subtitle">
            <TransSubtitle
              i18nKey="totalRefundValue"
              values={{
                value: [
                  totalRefundAmount.amount,
                  totalRefundAmount.currency,
                ].join(' '),
              }}
            />
          </Typography>
        )}
        <Stack direction="row" justifyContent="flex-end" justifySelf="flex-end">
          <Button variant="text" onClick={onClose}>
            <TransButton i18nKey="close" />
          </Button>
          <Button
            variant="contained"
            startIcon={submitIcon}
            onClick={onSubmit}
            disabled={submitDisabled}
          >
            {submitLabel}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
