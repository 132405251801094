import {
  Button,
  CardHeader,
  formatDate,
  FormControl,
  FormProvider,
  formSubmit,
  Layout,
  SearchForm,
  SearchResult,
  Table,
  TableColumns,
  useForm,
  useTable,
} from '@fleet/shared';
import { SelectField, TextField } from '@fleet/shared/form';
import { CardContent, Divider, Stack, Typography } from '@mui/material';
import { ConcessionType, Voucher, VoucherFilters } from 'dto/concession';
import {
  getVouchers,
  setVoucherFilters,
} from 'features/concession/concessionActions';
import {
  vouchersFilterSelector,
  vouchersSelector,
} from 'features/concession/concessionSelectors';
import { vouchersLoading } from 'features/loading/loadingSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransLabel } from 'i18n/trans/label';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import { useCallback, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { useDispatch, useSelector } from 'store/utils';

export const Concessions = () => {
  const dispatch = useDispatch();
  const loading = useSelector(vouchersLoading);
  const vouchers = useSelector(vouchersSelector);
  const filter = useSelector(vouchersFilterSelector);

  const onSearch = useCallback(
    (filter: VoucherFilters) => {
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        await dispatch(getVouchers(filter));
      });
    },
    [dispatch]
  );

  const { form, handleSubmit } = useForm<VoucherFilters>({
    initialValues: filter,
    onSubmit: onSearch,
  });

  const resetFields = useCallback(() => {
    form.restart({});
    dispatch(setVoucherFilters({}));
  }, [dispatch, form]);

  const columns = useMemo<TableColumns<Voucher>>(
    () => [
      {
        accessor: 'id',
        Header: <TransTableHead i18nKey="voucherId" />,
      },
      {
        accessor: 'status',
        Header: <TransTableHead i18nKey="status" />,
      },
      {
        accessor: 'validFrom',
        Header: <TransTableHead i18nKey="validFrom" />,
        Cell: ({ value }) => formatDate(value),
      },
      {
        accessor: 'validTo',
        Header: <TransTableHead i18nKey="validTo" />,
        Cell: ({ value }) => formatDate(value),
      },
    ],
    []
  );

  const table = useTable<Voucher>({
    data: vouchers,
    columns,
  });

  return (
    <Layout
      header={<CardHeader title={<TransSubtitle i18nKey="concessions" />} />}
    >
      <Stack divider={<Divider />}>
        <SearchForm title={<TransSubtitle i18nKey="search" />}>
          <FormProvider form={form}>
            <Stack
              component="form"
              direction="row"
              justifyContent="space-between"
              onSubmit={handleSubmit}
            >
              <Stack direction="row" sx={{ width: '50%' }} spacing={2}>
                <TextField
                  name="concessionId"
                  disabled={loading}
                  label={<TransField i18nKey="concessionId" />}
                />
                <SelectField
                  name="concessionTypeId"
                  options={Object.values(ConcessionType).map((type) => ({
                    label: renderToString(<TransLabel i18nKey={type} />),
                    value: type,
                  }))}
                  label={<TransField i18nKey="concessionType" />}
                  disabled={loading}
                />
              </Stack>
              <Stack direction="row">
                <FormControl label="&nbsp;">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="text"
                    onClick={resetFields}
                  >
                    <TransButton i18nKey="resetFields" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button variant="contained" type="submit" icon="search">
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Stack>
          </FormProvider>
        </SearchForm>
        <SearchResult results={!!vouchers?.length} loading={loading}>
          <Table
            caption={
              <CardContent sx={{ p: 2 }}>
                <Stack direction="row" alignItems="center">
                  <Typography variant="subtitle" fontWeight="700">
                    <TransSubtitle i18nKey="searchResults" />
                  </Typography>

                  {vouchers?.length && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ ml: 2 }}
                    >
                      <TransSubtitle
                        i18nKey="concessionsResult"
                        values={{ count: vouchers.length }}
                        tOptions={{ postProcess: 'interval' }}
                      />
                    </Typography>
                  )}
                </Stack>
              </CardContent>
            }
            table={table}
          />
        </SearchResult>
      </Stack>
    </Layout>
  );
};
