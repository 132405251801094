import { Loadable } from '@fleet/shared';
import { Icon } from '@fleet/shared/mui';
import { Divider, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { AlertCard } from 'components/AlertCard';
import { LegOffers } from 'components/LegOffers';
import { LegRoute } from 'components/LegRoute';
import { BookingTripWithAdmissions, PlaceAllocation } from 'dto/booking';
import { Trip, TripLeg } from 'dto/trip';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { TransAlert } from 'i18n/trans/alert';
import { TransSubtitle } from 'i18n/trans/subtitle';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _groupBy from 'lodash/groupBy';
import { FC, Fragment, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'store/utils';
import {
  getAdmissionsPlaceAllocations,
  getLegTransferTime,
  getTripAdmissions,
  getTripsLegsWithOffers,
} from 'utils/trip';

const useStyles = makeStyles(
  (theme) => ({
    route: {
      flex: 1,
    },
    legInfoWrapper: {
      flex: 1,
      '&:empty': { flex: 0 },
    },
    routeWithOffers: {
      flexShrink: 0,
      width: '25%',
    },
    transferDivider: {
      lineHeight: 1,
      margin: '32px 0',
      '& .MuiDivider-wrapper': {
        padding: '0 16px',
      },
      '&:after, &:before': {
        borderTop: `thin solid ${theme.palette.warning.main}`,
      },
    },
    serviceText: {
      '&$withOffers': {
        maxWidth: 'calc(60% - 0.25rem)',
      },
    },
    withOffers: {},
  }),
  { name: 'JourneyInfo' }
);

interface JourneyInfoProps {
  loading?: boolean;
  reference?: string;
  trips: Array<Trip | BookingTripWithAdmissions>;
  showOffers?: boolean;
  showServiceTexts?: boolean;
  showFullDate?: boolean;
  showPlaces?: boolean;
  isOutbound?: boolean;
}

const JourneyInfo: FC<JourneyInfoProps> = ({
  loading,
  reference,
  trips,
  showOffers,
  showServiceTexts,
  showFullDate,
  showPlaces,
  isOutbound = false,
}) => {
  const classes = useStyles();
  const booking = useSelector(currentBookingSelector);
  const bookedTripsLegs = useMemo(
    () =>
      booking?.bookedTrips.reduce<Array<TripLeg>>(
        (acc, { legs }) => acc.concat(legs),
        []
      ) ?? [],
    [booking?.bookedTrips]
  );
  const getBookingTexts = useCallback(
    (leg: TripLeg, type: 'onDemandTexts' | 'serviceTexts'): Array<string> => {
      if (!booking) return leg[type];
      return bookedTripsLegs.find(({ id }) => id === leg.id)?.[type] ?? [];
    },
    [bookedTripsLegs, booking]
  );
  const legsWithOffers = useMemo(
    () => getTripsLegsWithOffers(trips as Trip[]),
    [trips]
  );
  const placeAllocationsByLeg = useMemo(() => {
    if (showOffers) return {};
    const placeAllocations = (trips as BookingTripWithAdmissions[]).reduce<
      Array<PlaceAllocation & { passengerIds: string[] }>
    >(
      (acc, trip) => [
        ...acc,
        ...getAdmissionsPlaceAllocations(getTripAdmissions(trip) ?? []),
      ],
      []
    );
    return _groupBy(placeAllocations, 'legId');
  }, [showOffers, trips]);

  return (
    <Loadable loading={!!loading}>
      {legsWithOffers.map(({ offers, ...leg }, idx) => {
        const [onDemandTexts, serviceTexts] = (
          ['onDemandTexts', 'serviceTexts'] as const
        ).map((textType) => getBookingTexts(leg, textType));

        return (
          <Fragment key={idx}>
            <LegRoute
              className={showOffers ? classes.routeWithOffers : classes.route}
              leg={leg}
              showFullDate={showFullDate}
              showPlaces={showPlaces}
              allocations={placeAllocationsByLeg[leg.id]}
              isOnGrayBg
            >
              <Stack spacing={0.5} className={classes.legInfoWrapper}>
                {showServiceTexts &&
                  [onDemandTexts, serviceTexts].some(
                    (texts) => !_isEmpty(texts)
                  ) && (
                    <Stack alignItems="flex-end" spacing={0.5} flex={1}>
                      {onDemandTexts.map((text, idx) => (
                        <AlertCard
                          key={idx}
                          title={<TransAlert i18nKey="advanceOrderRequired" />}
                          message={text}
                          className={classNames(classes.serviceText, {
                            [classes.withOffers]: showOffers,
                          })}
                        />
                      ))}
                      {serviceTexts.map((text) => (
                        <AlertCard
                          key={idx}
                          message={text}
                          className={classNames(classes.serviceText, {
                            [classes.withOffers]: showOffers,
                          })}
                        />
                      ))}
                    </Stack>
                  )}
                {showOffers && (
                  <LegOffers
                    isShown={!_isEqual(offers, legsWithOffers[idx - 1]?.offers)}
                    reference={reference}
                    leg={leg}
                    offers={offers}
                    isOutbound={isOutbound}
                  />
                )}
              </Stack>
            </LegRoute>
            {idx !== legsWithOffers.length - 1 && (
              <Divider
                className={classNames(classes.transferDivider, {
                  [classes.routeWithOffers]: showOffers,
                })}
              >
                <Stack direction="row" spacing={1}>
                  <Icon color="warning" name="transfer" />
                  <Typography variant="body2">
                    <TransSubtitle
                      i18nKey="transferTime"
                      values={{
                        time: getLegTransferTime(leg, legsWithOffers[idx + 1]),
                      }}
                    />
                  </Typography>
                </Stack>
              </Divider>
            )}
          </Fragment>
        );
      })}
    </Loadable>
  );
};

export default memo(JourneyInfo);
