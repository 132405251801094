import { api } from '@fleet/shared';
import { Vehicle } from '@fleet/widget/dto/vehicle';
import { createAction } from '@reduxjs/toolkit';
import { AvailabilityPreferences, Warnings } from 'dto/booking';
import {
  Journey,
  JourneyLink,
  PassengerOfferSelection,
  PaymentStatus,
  TravelPass,
  TravelPassSearchParams,
  TripLeg,
  TripOffer,
  TripSearchParams,
} from 'dto/trip';
import { currentBookingIdSelector } from 'features/booking/bookingSelectors';
import { stringify } from 'qs';
import { createAsyncThunk } from 'store/utils';
import { runInSequence } from 'utils/common';
import {
  checkIfOfferNeedsAdditionalDataCall,
  getOffersReservationInfo,
} from 'utils/trip';
import _partition from 'lodash/partition';

export const setAdditionalDataJourneyLoading = createAction<string>(
  'trip/setAdditionalDataJourneyLoading'
);

export const resetSearch = createAction('trip/resetSearch');

export const showTripStops = createAction<TripLeg | undefined>(
  'trip/showStops'
);

export type JourneyType = 'inbound' | 'outbound';

export const updateOfferSelection = createAction<{
  journeyType: JourneyType;
  offerId?: string;
  selections: Array<PassengerOfferSelection>;
}>('trip/updateOfferSelection');

export const setPdfDownload = createAction<boolean>('trip/setPdfDownload');

export const selectTripOffer = createAction<{
  journeyType: JourneyType;
  reference: string;
  offers: Array<TripOffer>;
}>('trip/selectTripOffer');

export const selectTravelPassOffer = createAction<TravelPass | undefined>(
  'trip/selectTravelPassOffer'
);

export const unselectOffer =
  createAction<{ offerId: string; journeyType: JourneyType }>(
    'trip/unselectOffer'
  );
export const clearOfferSelection = createAction<{
  offerId: string;
  journeyType: JourneyType;
}>('trip/clearOfferSelection');

interface SearchOffersResponse {
  journeys: Array<Journey>;
  links: Array<JourneyLink>;
  nextAvailableDepartureDate?: string;
  journeyType: string;
}

interface EnhancedTripSearchParams extends TripSearchParams {
  journeyType: JourneyType;
}
export const searchTrips = createAsyncThunk<
  SearchOffersResponse,
  EnhancedTripSearchParams
>('trip/searchTrips', async ({ journeyType, ...payload }) => ({
  ...(await api.post('/offers/search', payload)).data,
  journeyType,
}));

export const searchExchangeOffers = createAsyncThunk<
  SearchOffersResponse,
  TripSearchParams & {
    fulfillmentIds: Array<string>;
    bookingId: string;
    journeyType: JourneyType;
  }
>(
  'trip/searchExchangeOffers',
  async ({ bookingId, journeyType, ...payload }) => ({
    ...(await api.post(`/bookings/${bookingId}/exchange-offers`, payload)).data,
    journeyType,
  })
);

export const searchExchangeUpgradeOffers = createAsyncThunk<
  SearchOffersResponse,
  {
    passengerRefs: Array<string>;
    fulfillmentIds: Array<string>;
    currency?: string;
    journeyType: JourneyType;
  }
>(
  'trip/searchExchangeUpgradeOffers',
  async ({ journeyType, ...payload }, { getState }) => ({
    ...(
      await api.post(
        `/bookings/${currentBookingIdSelector(
          getState()
        )}/exchange-offers/upgrades`,
        payload
      )
    ).data,
    journeyType,
  })
);

export const showTripsResultPage = createAsyncThunk<
  {
    journeys: Array<Journey>;
    links: Array<JourneyLink>;
    journeyType: 'inbound' | 'outbound';
  },
  { page: 'next' | 'previous'; journeyType: 'inbound' | 'outbound' }
>('trip/showTripsResultPage', async ({ page, journeyType }, { getState }) => {
  const {
    trip: { links },
  } = getState();
  const resultsLink = links.find(({ rel }) => rel === page);
  const data = resultsLink ? (await api.get(resultsLink.href)).data : [];
  return { ...data, journeyType };
});

export const getJourneyAdditionalData = createAsyncThunk<
  Journey | undefined,
  Journey
>('trip/getJourneyAdditionalData', async (journey, { dispatch }) => {
  const hasMissingOfferInfo = journey.trips.some(({ offers }) =>
    offers.some(checkIfOfferNeedsAdditionalDataCall)
  );
  if (!hasMissingOfferInfo) return;
  dispatch(setAdditionalDataJourneyLoading(journey.reference));
  const preparedTrips = await Promise.all(
    journey.trips.map(async (trip) => {
      try {
        const [offersWithMissingInfo, rest] = _partition(
          trip.offers,
          checkIfOfferNeedsAdditionalDataCall
        );
        if (offersWithMissingInfo.length) {
          const preparedOffers = await getOffersReservationInfo(
            offersWithMissingInfo
          );
          return {
            ...trip,
            offers: [...preparedOffers, ...rest],
          };
        } else {
          return trip;
        }
      } catch (e) {
        return trip;
      }
    })
  );

  return {
    ...journey,
    trips: preparedTrips,
  };
});

export interface PassengerDetailsPayload {
  bookingId: string;
  passengerId: string;
  externalReference: string;
  firstName: string;
  lastName: string;
  gender?: string;
  birthDate?: string;
  email?: string;
  phone?: {
    number: string;
  };
}

export interface PurchaserDetailsPayload
  extends Omit<
    PassengerDetailsPayload,
    | 'bookingId'
    | 'passengerId'
    | 'externalReference'
    | 'address'
    | 'companyDetails'
  > {
  prefillPassengerId?: string;
  address: {
    zipCode: string;
    streetName: string;
    city: string;
    countryName: string;
  };
  company: {
    name: string;
    registrationNumber: string;
    taxId: string;
  };
}

const preparePhonePayload = ({
  phone,
  ...rest
}: Partial<PassengerDetailsPayload | PurchaserDetailsPayload>) => ({
  ...rest,
  ...(phone?.number && {
    phone: {
      number: phone.number,
    },
  }),
});
export const updatePassengersDetails = createAsyncThunk<
  void,
  Array<Partial<PassengerDetailsPayload>>
>('trip/updatePassengersDetails', async (passengers) => {
  await runInSequence(
    passengers.map(
      ({ bookingId, passengerId, ...payload }) =>
        () =>
          api.patch(
            `/bookings/${bookingId}/passengers/${passengerId}`,
            preparePhonePayload(payload)
          )
    )
  );
});

export const updatePurchaserDetails = createAsyncThunk<
  void,
  PurchaserDetailsPayload & { bookingId: string }
>('trip/updatePurchaserDetails', async ({ bookingId, ...payload }) => {
  return await api.patch(
    `/bookings/${bookingId}/purchaser`,
    preparePhonePayload(payload)
  );
});

export const searchStops = createAsyncThunk<
  { stops: Array<{ reference: string; name: string }> },
  string
>('trip/searchStops', async (search: string) => {
  return (
    await api.get(
      `/places${stringify(
        { prefix: search, numberOfResults: 8 },
        { addQueryPrefix: true }
      )}`
    )
  ).data;
});

export const searchTravelPasses = createAsyncThunk<
  { nonTripOffers: Array<TravelPass> },
  TravelPassSearchParams
>('trip/searchTravelPasses', async (payload) => {
  return (await api.post('/nontrip-offers/search', payload)).data;
});

export const payByLink = createAsyncThunk<
  void,
  {
    bookingId: string;
    firstName: string;
    lastName: string;
    email?: string;
    phoneNumber?: string;
  }
>('trip/payByLink', async ({ bookingId, ...payload }) => {
  return (
    await api.post(`/bookings/${bookingId}/pay/with/adyen/pay-by-link`, payload)
  ).data;
});

export const cancelPayments = createAsyncThunk<void, string | undefined>(
  'trip/cancelPayments',
  async (bookingId, { getState }) => {
    return (
      await api.post(
        `/bookings/${
          bookingId || currentBookingIdSelector(getState())
        }/payment-attempts/cancel`
      )
    ).data;
  }
);

export const getPaymentStatus = createAsyncThunk<
  PaymentStatus,
  string | undefined
>('trip/getPaymentStatus', async (bookingId, { getState }) => {
  return (
    await api.get<{ status: PaymentStatus }>(
      `/bookings/${
        bookingId || currentBookingIdSelector(getState())
      }/payment-status`
    )
  ).data.status;
});

export const clearPaymentStatus = createAction('trip/clearPaymentStatus');

export const getAvailabilitiesPreferences = createAsyncThunk<
  AvailabilityPreferences,
  { bookingId: string; bookedOfferId: string }
>(
  'trip/getAvailabilitiesPreferences',
  async ({ bookingId, bookedOfferId }, { getState }) => {
    return (
      await api.get(
        `/bookings/${
          bookingId || currentBookingIdSelector(getState())
        }/offerId/${bookedOfferId}/availabilities`
      )
    ).data;
  }
);

export enum TravelDirectionType {
  IN_DIRECTION = 'DIRECTION.IN_DIRECTION',
  OPPOSITE_DIRECTION = 'DIRECTION.OPPOSITE_DIRECTION',
  CHANGING = 'DIRECTION.CHANGING',
  STARING_IN_DIRECTION = 'DIRECTION.STARING_IN_DIRECTION',
  UNSPECIFIED = 'DIRECTION.UNSPECIFIED',
}
export interface PlaceMap extends Vehicle {
  direction: { id: TravelDirectionType };
  number: string;
}

export const getAvailabilitiesPlaceMap = createAsyncThunk<
  Array<PlaceMap>,
  { bookingId: string; reservationId: string }
>(
  'trip/getAvailabilitiesPlaceMap',
  async ({ bookingId, reservationId }, { getState }) => {
    return (
      await api.get<{
        warnings: Warnings;
        placeMaps: Array<PlaceMap>;
      }>(
        `/bookings/${
          bookingId || currentBookingIdSelector(getState())
        }/reservations/${reservationId}/place-map`
      )
    ).data.placeMaps;
  }
);
