import { Button, Icon, Modal } from '@fleet/shared';
import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LegInfo } from 'components/LegInfo';
import { Journey, TripLeg } from 'dto/trip';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransTitle } from 'i18n/trans/title';
import { FC, useMemo } from 'react';
import { useSelector } from 'store/utils';

interface ServiceTextsModalProps {
  journey?: Journey;
  onClose: () => void;
}

const useStyles = makeStyles(
  () => ({
    root: {
      '& .MuiPaper-root': {
        width: '30rem',
      },
      '& .MuiDialogActions-root .MuiButton-text': {
        display: 'none',
      },
    },
  }),
  { name: 'ServiceTextsModal' }
);

export const ServiceTextsModal: FC<ServiceTextsModalProps> = ({
  journey,
  onClose,
}) => {
  const classes = useStyles();
  const booking = useSelector(currentBookingSelector);
  const legsWithNotifications = useMemo<Array<TripLeg>>(
    () =>
      journey?.trips.reduce<Array<TripLeg>>(
        (legs, trip) => [
          ...legs,
          ...trip.legs.filter(({ serviceTexts, onDemandTexts }) =>
            [serviceTexts, onDemandTexts].some((texts) => texts.length)
          ),
        ],
        []
      ) ??
      booking?.bookedTrips.reduce<Array<TripLeg>>(
        (acc, { legs }) => acc.concat(legs),
        []
      ) ??
      [],
    [booking?.bookedTrips, journey?.trips]
  );
  return (
    <Modal
      className={classes.root}
      title={<TransTitle i18nKey="journeyNotifications" />}
      open
      onClose={onClose}
      actionButton={
        <Button variant="contained" onClick={onClose}>
          <TransButton i18nKey="close" />
        </Button>
      }
    >
      <Stack spacing={2.5}>
        {legsWithNotifications.map((leg) => (
          <Stack key={leg.id} spacing={1}>
            <LegInfo {...leg} />
            {leg.onDemandTexts.concat(leg.serviceTexts).map((text) => (
              <Stack key={text} direction="row" spacing={0.5} alignItems="top">
                <Icon
                  sx={{ flexShrink: 0, mt: 0.5 }}
                  name="info-circle"
                  color="warning"
                />
                <Typography>{text}</Typography>
              </Stack>
            ))}
          </Stack>
        ))}
      </Stack>
    </Modal>
  );
};
