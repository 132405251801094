import { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { TransTitle } from 'i18n/trans/title';
import { FormProvider, Icon, SelectField, Tooltip } from '@fleet/shared';
import { TransField } from 'i18n/trans/field';
import { TextField } from '@fleet/shared/form';
import { useSelector } from 'store/utils';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { prefillPayerFormData, useValidatePhoneNumber } from 'utils/overview';
import { PurchaserDetailsPayload } from 'features/trip/tripActions';
import { FormApi } from 'final-form';
import { TransParagraph } from 'i18n/trans/paragraph';

interface PayerDetailsFormProps {
  form: FormApi<PurchaserDetailsPayload, Partial<PurchaserDetailsPayload>>;
  handleSubmit: (
    event?: SyntheticEvent<HTMLFormElement, Event> | undefined
  ) => Promise<object | undefined> | undefined;
}

export const PayerDetailsForm: FC<PayerDetailsFormProps> = ({
  form,
  handleSubmit,
}) => {
  const booking = useSelector(currentBookingSelector)!;
  const validatePhoneNumber = useValidatePhoneNumber();
  const { purchaser, passengers } = booking;
  const passengerOptions = useMemo(
    () => [
      { label: '\u2015', value: '' },
      ...passengers.map(({ firstName, lastName, id }) => ({
        label: `${firstName.value} ${lastName.value}`,
        value: id,
      })),
    ],
    [passengers]
  );

  const handlePrefillData = useCallback(
    (passengerId) => {
      prefillPayerFormData(passengerId, passengers, form);
    },
    [passengers, form]
  );

  return (
    <>
      <Typography variant="h2">
        <TransTitle i18nKey="payerDetails" />
      </Typography>
      <FormProvider form={form}>
        <form onSubmit={handleSubmit} id="purchaserDetails">
          <Grid container columns={4} spacing={2} rowSpacing={2}>
            <Grid item xs={1}>
              <SelectField
                name="prefillPassengerId"
                label={<TransField i18nKey="prefillWithPassenger" />}
                labelPosition="top"
                options={passengerOptions}
                onChange={handlePrefillData}
              />
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={1}>
              <TextField
                required={purchaser?.firstName.isRequired}
                name="firstName"
                label={<TransField i18nKey="purchaserNameFirst" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                required={purchaser?.lastName.isRequired}
                name="lastName"
                label={<TransField i18nKey="purchaserNameLast" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                required={purchaser?.contactInformation.emailAddress.isRequired}
                email
                name="email"
                label={<TransField i18nKey="email" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                placeholder="+"
                required={purchaser?.contactInformation.phoneNumber.isRequired}
                name="phone.number"
                label={
                  <Stack direction="row" sx={{ display: 'inline-flex' }}>
                    <TransField i18nKey="mobileNumber" />
                    &nbsp;
                    <Tooltip
                      content={<TransParagraph i18nKey="phoneSearchHint" />}
                    >
                      <Icon name="info-circle" />
                    </Tooltip>
                  </Stack>
                }
                validate={validatePhoneNumber}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                required={purchaser?.address.isRequired}
                name="address.zipCode"
                label={<TransField i18nKey="zipCode" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                required={purchaser?.address.isRequired}
                name="address.streetName"
                label={<TransField i18nKey="address" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                required={purchaser?.address.isRequired}
                name="address.city"
                label={<TransField i18nKey="postalCity" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                required={purchaser?.address.isRequired}
                name="address.countryName"
                label={<TransField i18nKey="postalCountry" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                required={purchaser?.companyName.isRequired}
                name="company.name"
                label={<TransField i18nKey="companyName" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                required={purchaser?.companyRegistrationNumber.isRequired}
                name="company.registrationNumber"
                label={<TransField i18nKey="companyRegNr" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                required={purchaser?.vatNumber.isRequired}
                name="company.taxId"
                label={<TransField i18nKey="vatNumber" />}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};
