import { Icon } from '@fleet/shared';
import {
  Link,
  Stack,
  Step,
  StepIconProps,
  StepLabel,
  Stepper as MuiStepper,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { TransButton } from 'i18n/trans/button';
import { FC, ReactNode } from 'react';

const useStyles = makeStyles(
  (theme) => ({
    disabled: {
      cursor: 'not-allowed',
    },
    stepper: {
      margin: '1rem 0',
      '& .MuiStepConnector-root': {
        left: 'calc(-50% + 0.75rem)',
        right: 'calc(50% + 0.75rem)',
        '& .MuiStepConnector-line': {
          borderColor: theme.palette.divider,
        },
      },
    },
    step: {
      '&$active, &$completed': {
        '& $icon': {
          color: 'white',
        },
      },
      '&$active': {
        '& $stepLabel': {
          color: theme.palette.text.primary,
        },
        '& $icon': {
          fontWeight: 700,
          borderColor: theme.palette.text.primary,
          background: theme.palette.text.primary,
        },
      },
      '&$completed $icon': {
        background: theme.palette.primary.main,
      },
    },
    icon: {
      pointerEvents: 'none',
      position: 'relative',
      boxSizing: 'border-box',
      fontSize: '0.9375rem',
      width: 24,
      height: 24,
      textAlign: 'center',
      borderRadius: '50%',
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      background: theme.palette.common.white,
    },
    stepLabel: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
    active: {},
    completed: {},
  }),
  { name: 'Stepper' }
);

interface StepperProps {
  activeStepIdx: number;
  steps: Array<{
    label: ReactNode;
    value: string;
    disabled?: boolean;
  }>;
  goToStep: (idx: number) => void;
}
export const Stepper: FC<StepperProps> = ({
  activeStepIdx,
  steps,
  goToStep,
}) => {
  const classes = useStyles();
  return (
    <MuiStepper
      activeStep={activeStepIdx}
      alternativeLabel
      className={classes.stepper}
    >
      {steps.map(({ label, value, disabled }, idx) => (
        <Step key={value}>
          <StepLabel
            StepIconComponent={({ active, completed }: StepIconProps) => (
              <Stack
                alignItems="center"
                spacing={1}
                className={classNames(classes.step, {
                  [classes.active]: active,
                  [classes.completed]: completed,
                  [classes.disabled]: disabled,
                })}
              >
                <Typography variant="body1" className={classes.icon}>
                  {completed ? <Icon name="tick" color="white" /> : idx + 1}
                </Typography>
                <Typography variant="body1" className={classes.stepLabel}>
                  {label}
                  {completed && !disabled && (
                    <Link sx={{ ml: 0.5 }} onClick={() => goToStep(idx)}>
                      <TransButton i18nKey="edit" />
                    </Link>
                  )}
                </Typography>
              </Stack>
            )}
          ></StepLabel>
        </Step>
      ))}
    </MuiStepper>
  );
};
