import { useCallback } from 'react';

export enum LocalStorage {
  TABS = 'ticket.tabs',
  ACTIVE_TAB = 'tickets.activeTabIdx',
  RECENT_STOPS = 'tickets.recentStops',
  RECENT_TRIPS = 'tickets.recentTrips',
  RETAILER_SELECTION = 'user.retailerSelection',
  MISSING_TRANSLATIONS = 'ui.missingTranslations',
}

export const useLocalStorage = <T>(props: {
  key: LocalStorage;
  getDefaultValue: (lsData: T | null) => T;
  isSessionStorage?: boolean;
}) => {
  const { key, getDefaultValue, isSessionStorage } = props;
  const storage = isSessionStorage ? sessionStorage : localStorage;
  const lsData = storage.getItem(key);
  const parsedData: T | null = lsData && JSON.parse(lsData);
  const initialValue = getDefaultValue(parsedData);
  const setData = useCallback(
    (data: T) => {
      storage.setItem(key, JSON.stringify(data));
    },
    [key, storage]
  );

  return {
    initialValue,
    setData,
  };
};
