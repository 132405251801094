import { FC, useMemo } from 'react';
import { ServiceAttribute } from 'dto/trip';
import { Tooltip } from '@fleet/shared/mui';
import { Icon } from '@fleet/shared';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  () => ({
    root: {
      padding: '0 3px',
      boxSizing: 'content-box',
    },
  }),
  { name: 'ServiceAttributeIcon' }
);

export const ServiceAttributeIcon: FC<ServiceAttribute> = ({
  code,
  description,
}) => {
  const classes = useStyles();
  const iconName = useMemo(() => {
    switch (code) {
      case 'KOMAEK':
        return '1-class';
      case 'BOKEJ2':
        return '2-class-crossed';
      case 'KOMB':
      case 'KOM5':
      case 'KOMKLS':
        return '2-class';
      case 'KOMLUG':
        return '2-class-calm';
      case 'KOMEDJ':
        return 'pet-crossed';
      case 'KOMCYK':
        return 'bicycle';
      case 'KOMECY':
        return 'bicycle-crossed';
      case 'KOMRES':
      case 'KOMKRO':
        return 'restaurant';
      case 'KOMFRK':
      case 'KOMFRL':
        return 'breakfast-2-class';
      case 'KOMFRR':
      case 'KOMFRU':
        return 'breakfast-1-class';
      case 'KOM37':
      case 'KOMSER':
        return 'coffee-machine';
      case 'KOMKB1':
        return 'cafe-1-class';
      case 'KOMC':
        return 'couchette';
      case 'EVFEUB':
      case 'EVFEUJ':
        return 'eu-law';
      case 'EVFSFS':
        return 'sw-law';
      case 'KOMPLU':
        return 'food-1-class';
      case 'KOMIUK':
      case 'KOMINV':
        return 'wifi';
      case 'KOMBAG':
        return 'luggage';
      case 'BOKOBL':
        return 'reservation-mandatory';
      case 'KOMMAT':
        return 'food';
      case 'KOMMÅL':
        return 'food-plus';
      case 'KOM34':
        return 'bar';
      case 'KOMMOV':
        return 'movingo';
      case 'BOKEJB':
        return 'reservation-crossed';
      case 'KOMEJS':
        return 'coffee-machine-crossed';
      case 'KOMEAC':
        return 'ac-crossed';
      case 'KOMEWI':
        return 'wifi-crossed';
      case 'KOM74':
        return 'nonstop';
      case 'BOKFRI':
        return 'reservation-optional';
      case 'KOMEL':
        return 'power-socket';
      case 'KOMFUN':
      case 'KOMTAF':
        return 'breakfast';
      case 'KOMLYF':
        return 'wheelchair-ramp';
      default:
        return '';
    }
  }, [code]);

  if (!iconName) return null;

  return (
    <Tooltip content={description} key={code}>
      <Icon
        className={classes.root}
        name={iconName}
        size={['sw-law', 'eu-law'].includes(iconName) ? 24 : 16}
      />
    </Tooltip>
  );
};
