import { Selector } from '@reduxjs/toolkit';
import { isAfter } from 'date-fns';
import {
  BookingAdmission,
  BookingTripWithAdmissions,
  ExchangeOperation,
  PassengerFee,
} from 'dto/booking';
import _groupBy from 'lodash/groupBy';
import _keyBy from 'lodash/keyBy';
import _mapValues from 'lodash/mapValues';
import uniqBy from 'lodash/uniqBy';
import { RootState } from 'store';
import { createLoadingSelector, selector } from 'store/utils';
import { IS_DS_AT } from 'utils/flags';
import { prepareTrips } from 'utils/overview';
import {
  getBookingAdmissions,
  getHasJourneyNotifications,
  getOnDemandServiceTexts,
  getTripAdmissions,
  isAdmissionActive,
  isAdmissionInactive,
} from 'utils/trip';
import {
  addOfferToBooking,
  getAdditionalOffers,
  postBooking,
} from './bookingActions';

export const bookingsSearchSelector = selector((state) => state.booking.search);

export const bookingLoadingSelector = createLoadingSelector(
  postBooking,
  addOfferToBooking,
  getAdditionalOffers
);

export const currentBookingSelector = selector(
  (state: RootState) => state.booking.current
);

export const travelAccountSelector = selector(
  (state: RootState) => state.booking.travelAccount
);

export const bookingFeesSelector = selector((state: RootState) => {
  const isTravelPassBooking = isTravelPassBookingSelector(state);
  const booking = currentBookingSelector(state);
  if (!booking) return [];
  const fees =
    isTravelPassBooking && IS_DS_AT
      ? booking?.nonTripOffers.reduce<Array<PassengerFee>>(
          (acc, { fees }) => [...acc, ...fees],
          []
        )
      : booking.bookedTrips.reduce<Array<PassengerFee>>(
          (acc, { bookedOffers }) => [
            ...acc,
            ...bookedOffers.map(({ fees }) => fees).flat(),
          ],
          []
        );
  return fees.filter(({ status }) => !isAdmissionInactive({ status }));
});

export const bookedOffersFeesMapSelector = selector((state: RootState) => {
  const fees = bookingFeesSelector(state);
  return _keyBy(fees, 'id');
});

export const bookingSelectionFeesIdsSelector = selector((state: RootState) => {
  const admissions = bookingAdmissionsSelector(state);
  return admissions.reduce<Array<string>>(
    (acc, { reservations }) => [
      ...acc,
      ...reservations.reduce<Array<string>>(
        (ids, { feeRefs }) => [...ids, ...feeRefs],
        []
      ),
    ],
    []
  );
});

export const bookingTotalFeesSelector = selector((state: RootState) => {
  const fees = bookingFeesSelector(state);
  const selectionFeesIds = bookingSelectionFeesIdsSelector(state);
  const bookingFees = fees.filter(({ id }) => !selectionFeesIds.includes(id));
  const feesByDescription = _groupBy(bookingFees, 'description');
  const preparedFeesByDescription = _mapValues(
    feesByDescription,
    (fees: Array<PassengerFee>) =>
      fees.reduce((acc, { price }) => ({
        ...acc,
        price: {
          ...acc.price,
          amount: acc.price.amount + price.amount,
          vats: [...(acc.price.vats ?? []), ...(price.vats ?? [])],
        },
      }))
  );
  return Object.values(preparedFeesByDescription);
});

export const currentBookingIdSelector = selector(
  (state: RootState) => state.booking.current?.id
);

export const onHoldOfferSelector = selector(
  (state: RootState) => state.booking.onHoldOffer
);

export const bookingAdmissionsSelector = selector((state: RootState) =>
  getBookingAdmissions(state.booking.current)
);

export const bookingHistorySelector = selector(
  (state: RootState) => state.booking.history
);

export const bookingsFilterSelector = selector((state) => state.booking.filter);

export const bookingPartsSelector = selector(
  (state) => state.booking.selection
);

export const selectSelectedAdmissionsFulfillments = selector((state) => {
  const booking = currentBookingSelector(state)!;
  const { admission } = bookingPartsSelector(state);
  const admissionIds = Object.values(admission).flat();
  return getBookingAdmissions(booking)
    .filter(({ id }) => admissionIds.includes(id))
    .flatMap(({ fulfillments, reservations, fees, ancillaries }) => {
      const admissionFulfillments = [
        ...reservations,
        ...ancillaries,
        ...fees,
      ].flatMap(({ fulfillments }) => fulfillments);
      return uniqBy([...fulfillments, ...admissionFulfillments], 'id');
    });
});

export const selectActiveFulfillmentIdsSelection = selector((state) => {
  const { ancillary, fulfillment } = bookingPartsSelector(state);
  const fulfillents = selectSelectedAdmissionsFulfillments(state);
  const [ancillaryIds, fulfillmentIds] = [ancillary, fulfillment].map(
    (selection) => Object.values(selection).flat()
  );
  return fulfillents.length
    ? fulfillents.filter(isAdmissionActive).map(({ id }) => id)
    : [...ancillaryIds, ...fulfillmentIds];
});

export const selectFulfillmentIdsSelection = selector((state) => {
  const { ancillary, fulfillment } = bookingPartsSelector(state);
  const fulfillments = selectSelectedAdmissionsFulfillments(state);
  const [ancillaryIds, fulfillmentIds] = [ancillary, fulfillment].map(
    (selection) => Object.values(selection).flat()
  );

  return fulfillments.length
    ? fulfillments.map(({ id }) => id)
    : [...ancillaryIds, ...fulfillmentIds];
});

export const selectRefundOffers = selector(
  (state) => state.booking.refundOffers
);

export const selectReleaseOffers = selector(
  (state) => state.booking.releaseOffers
);

export const bookingPrebookedSelector = selector((state) => {
  const currentBooking = state.booking.current;
  return (
    !currentBooking ||
    currentBooking.bookedTrips
      .reduce<Array<BookingAdmission>>(
        (admissions, trip) => [...admissions, ...getTripAdmissions(trip)],
        []
      )
      .every(({ status }) => status === 'PREBOOKED')
  );
});

export const bookingExpiredSelector = selector((state) => {
  const isPrebooked = bookingPrebookedSelector(state);
  const currentBooking = state.booking.current;
  if (currentBooking) {
    const isBookingCompleted =
      currentBooking.confirmableUntil === '0001-01-01T00:00:00Z';

    return (
      !isBookingCompleted &&
      isPrebooked &&
      isAfter(new Date(), new Date(currentBooking.confirmableUntil))
    );
  }
});

export const bookingCommentsSelector = selector(
  (state) => state.booking.comments
);

export const bookingNotificationsSelector = selector(
  (state) => state.booking.customerNotifications
);

export const bookingUnaccompaniedMinorRequestsSelector = selector(
  (state) => state.booking.unmrRequests
);

export const isTravelPassBookingSelector = selector(
  (state) => state.booking.current?.bookedTrips.length === 0
);

export const travelPassAdmissionsSelector = selector(
  (state) =>
    state.booking.current?.nonTripOffers.reduce<Array<BookingAdmission>>(
      (acc, { admissions }) => [...acc, ...admissions],
      []
    ) ?? []
);

export const bookingNotificationsPresentSelector = selector((state) => {
  if (!state.booking.current) return;
  return (
    getHasJourneyNotifications(state.booking.current.bookedTrips) &&
    getOnDemandServiceTexts(state.booking.current.bookedTrips)
  );
});

export const bookingAdditionalOffersSelector = selector(
  (state) => state.booking.additionalOffers
);

export const onHoldBookingTimeSelector = selector(
  (state) => state.booking.onHoldBookingTime
);

export const exchangeOperationsSelector = selector(
  (state) => state.booking.current?.exchangeOperations ?? []
);

export const exchangeTripsSelector = selector((state) => {
  return exchangeOperationsSelector(state)
    .map(({ bookedTrips }) => bookedTrips)
    .flat();
});

export const exchangeOffersSelector = selector((state) => {
  const exchangeOperations = exchangeOperationsSelector(state);
  return exchangeOperations.reduce<ExchangeOperation['exchangeOffers']>(
    (acc, { exchangeOffers }) => [...acc, ...exchangeOffers],
    []
  );
});

export const currentTripsSelector: Selector<
  RootState,
  Array<BookingTripWithAdmissions>
> = selector((state) => {
  const booking = currentBookingSelector(state);
  if (booking) {
    const { bookedTrips, exchangeOperations } = booking;
    const exchangeTripIds = exchangeOperations.reduce<Array<string>>(
      (acc, { bookedTrips }) => [...acc, ...bookedTrips.map(({ id }) => id)],
      []
    );
    const trips = exchangeTripIds.length
      ? bookedTrips.filter(({ id }) => exchangeTripIds.includes(id))
      : bookedTrips;
    return prepareTrips(trips);
  }
  return [];
});
