import { Stack, Typography } from '@mui/material';
import { HintWrapper } from 'components/HintWrapper';
import { Fee } from 'dto/booking';
import { TransField } from 'i18n/trans/field';
import { FC, useMemo } from 'react';
import { formatCurrency } from 'utils/common';

interface PriceWithFeeProps {
  amount: number;
  currency: string;
  vats?: Fee['vats'];
  fee?: number;
  hideNull?: boolean;
}

export const PriceWithFee: FC<PriceWithFeeProps> = ({
  amount,
  currency,
  vats,
  fee,
  hideNull,
}) => {
  const totalFee = useMemo(
    () => fee || vats?.reduce((totalFee, fee) => totalFee + fee.amount, 0) || 0,
    [fee, vats]
  );
  const [total, totalExclFees, fees] = [
    amount,
    amount - totalFee,
    totalFee,
  ].map((value) => formatCurrency({ amount: value, currency }));

  const amounts = [
    {
      label: <TransField i18nKey="totalExclVat" />,
      value: totalExclFees,
    },
    {
      label: <TransField i18nKey="totalVAT" />,
      value: fees,
    },
  ];
  if (hideNull && amount === 0) return null;

  return (
    <HintWrapper
      tooltipPlacement="bottom-end"
      content={total}
      hint={
        <Typography variant="body2" sx={{ width: '11.625rem' }}>
          {amounts.map(({ label, value }) => (
            <Stack direction="row" justifyContent="space-between" key={value}>
              {label}
              <span>{value}</span>
            </Stack>
          ))}
        </Typography>
      }
    />
  );
};
