import { Box, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Fee } from 'dto/booking';
import { Price } from 'dto/trip';
import { exchangeOffersSelector } from 'features/booking/bookingSelectors';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FC, useMemo } from 'react';
import { useSelector } from 'store/utils';

interface ModifyFeesProps {}

const useStyles = makeStyles(
  (theme) => ({
    fee: {
      borderRadius: '0.25rem',
      gap: '2rem',
      border: `thin solid ${theme.palette.divider}`,
      padding: '0.5rem 4.25rem 0.5rem 1rem',
    },
    removeBtn: {
      minWidth: 0,
      padding: 0,
      '& .MuiButton-startIcon': {
        color: theme.palette.error.main,
      },
    },
  }),
  { name: 'ModifyFees' }
);
export const ModifyFees: FC<ModifyFeesProps> = () => {
  const classes = useStyles();
  const exchangeOffers = useSelector(exchangeOffersSelector);
  const { price, fee } = exchangeOffers.reduce<{
    fee: Array<Fee>;
    price: Array<Price>;
  }>(
    (acc, { exchangeFee, exchangePrice }) => {
      return {
        ...acc,
        price: [...acc.price, exchangePrice],
        fee: [...acc.fee, exchangeFee],
      };
    },
    { price: [], fee: [] }
  );

  const totals = useMemo(() => {
    const [totalFees, totalPrice] = [fee, price].map((amounts) =>
      amounts.reduce<number>((total, { amount }) => total + amount, 0)
    );
    const currency = exchangeOffers[0]?.exchangePrice.currency ?? '';
    return [
      {
        label: 'exchangePrice' as const,
        value: `${totalPrice} ${currency}`,
      },
      {
        label: 'exchangeFee' as const,
        value: `${totalFees} ${currency}`,
      },
    ];
  }, [exchangeOffers, fee, price]);

  return (
    <Stack spacing={1}>
      {totals.map(({ label, value }) => (
        <Stack
          key={label}
          className={classes.fee}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body1" fontWeight="bold">
            <TransSubtitle i18nKey={label} />
          </Typography>
          <Box flexGrow={1} />
          <Typography flexGrow={1} variant="h2" textAlign="right">
            {value}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};
