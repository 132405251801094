import { Icon } from '@fleet/shared';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
} from '@mui/material';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import { FC, ReactNode } from 'react';

interface CollapsibleProps extends Omit<AccordionProps, 'title'> {
  collapsible?: boolean;
  title: ReactNode;
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      margin: '0!important',
      '&:before': {
        display: 'none',
      },
      '&:not($collapsible)': {
        borderRadius: '0.25rem',
        boxShadow: 'none',
        pointerEvents: 'none',
      },
      '&&$collapsible': {
        marginTop: '1rem',
      },
    },
    details: {
      padding: '1rem 0.5rem',
      background: theme.palette.background.default,
      boxShadow:
        'inset 0 8px 8px -5px rgba(0, 0, 0, 0.1), inset 0 -8px 8px -8px rgba(0, 0, 0, 0.1)',
    },
    header: {
      height: '3.75rem',
      flexDirection: 'row-reverse',
      '& .MuiAccordionSummary-content': {
        margin: 0,
        display: 'block',
      },
      borderRadius: '0.25rem',
      '&.Mui-expanded': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    borders: {
      border: `thin solid ${theme.palette.divider}`,
      borderRadius: '0.1875rem',
    },
    collapsible: {},
  }),
  { name: 'Collapsible' }
);

export const Collapsible: FC<CollapsibleProps> = ({
  collapsible,
  title,
  children,
  ...props
}) => {
  const classes = useStyles();
  return (
    <div>
      <Accordion
        square={collapsible}
        classes={{
          root: classNames(classes.root, {
            [classes.collapsible]: collapsible,
          }),
        }}
        elevation={0}
        {...props}
      >
        <AccordionSummary
          classes={{
            root: classNames(classes.header, {
              [classes.borders]: collapsible,
            }),
          }}
          expandIcon={
            collapsible && (
              <Icon name="chevron-down" size={28} margin color="secondary" />
            )
          }
        >
          <Box py={0.5} pl={1.5}>
            {title}
          </Box>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.details }}>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
