import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FormApi } from 'final-form';
import {
  PassengerCardState,
  PassengerSpecification,
  SearchBarFormValues,
  CustomerCard,
} from 'dto/trip';

export const useApplyAllCardsToggle = (
  form: FormApi<
    Partial<SearchBarFormValues>,
    Partial<Partial<SearchBarFormValues>>
  >,
  cardState: PassengerCardState | undefined
) => {
  const getOrCreateUniqueId = useCallback(
    (card: CustomerCard, isChecked: boolean, index: number): string => {
      const uniqueCardId = card.uniqueCardId || uuidv4(); // Ensure uniqueCardId is never undefined
      if (!card.uniqueCardId && isChecked) {
        form.change(
          // @ts-ignore
          `passengerSpecifications[0].cards[${index}].uniqueCardId`,
          uniqueCardId
        );
      }
      return uniqueCardId;
    },
    [form]
  );

  const updateCardToggleStateMap = useCallback(
    (uniqueCardId: string, isChecked: boolean) => {
      const newCardToggleStateMap = {
        ...(cardState?.cardToggleStateMap || {}),
      };
      newCardToggleStateMap[uniqueCardId] = isChecked;

      form.change('cardState', {
        ...cardState,
        cardToggleStateMap: newCardToggleStateMap,
      });
    },
    [form, cardState]
  );

  const updatePassengerCards = useCallback(
    (uniqueCardId: string, isChecked: boolean, cardToApply: CustomerCard) => {
      const passengerSpecifications: PassengerSpecification[] =
        form.getFieldState('passengerSpecifications')?.value || [];
      if (isChecked) {
        const updatedSpecifications = passengerSpecifications.map(
          (spec, index) => {
            // Assuming the first passenger's cards are not to be modified
            if (index === 0) return spec;

            const cardAlreadyApplied = spec.cards?.some(
              (card) => card.uniqueCardId === uniqueCardId
            );
            if (!cardAlreadyApplied) {
              const clonedCard = { ...cardToApply, uniqueCardId: uniqueCardId };
              return { ...spec, cards: [...(spec.cards || []), clonedCard] };
            }
            return spec;
          }
        );

        form.batch(() => {
          updatedSpecifications.forEach((spec, idx) => {
            // @ts-ignore
            form.change(`passengerSpecifications[${idx}]`, spec);
          });
        });
      } else {
        const updatedSpecifications = passengerSpecifications.map(
          (spec: PassengerSpecification, index) => {
            if (index === 0) return spec;

            return {
              ...spec,
              cards: spec.cards?.filter(
                (card) => card.uniqueCardId !== uniqueCardId
              ),
            };
          }
        );

        form.batch(() => {
          updatedSpecifications.forEach((spec, idx) => {
            // @ts-ignore
            form.change(`passengerSpecifications[${idx}]`, spec);
          });
        });
      }
    },
    [form]
  );

  const onToggleChange = useCallback(
    (card: CustomerCard, isChecked: boolean, cardIndex: number) => {
      const uniqueCardId = getOrCreateUniqueId(card, isChecked, cardIndex);
      if (uniqueCardId) {
        updateCardToggleStateMap(uniqueCardId, isChecked);
        updatePassengerCards(uniqueCardId, isChecked, card); // Pass the card here
      }
    },
    [getOrCreateUniqueId, updateCardToggleStateMap, updatePassengerCards]
  );

  return { onToggleChange };
};
