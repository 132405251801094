import { PlaceAllocation } from 'dto/booking';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { TransLabel } from 'i18n/trans/label';
import { getLegDuration, getPassengersNames, getTimeString } from 'utils/trip';
import { LegInfo } from 'components/LegInfo';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { showTripStops } from 'features/trip/tripActions';
import { TransButton } from 'i18n/trans/button';
import { LegWrapper } from 'components/LegWrapper';
import { Stack, Typography } from '@mui/material';
import { TripLeg, TripStop } from 'dto/trip';
import { FC, useMemo } from 'react';
import { formatDate } from '@fleet/shared/utils/date';
import { Button, Icon, Tooltip } from '@fleet/shared';
import { useDispatch, useSelector } from 'store/utils';
import { DaysAfterCount } from 'components/DaysAfterCount';
import { makeStyles } from '@mui/styles';
import { renderToString } from 'react-dom/server';
import { TransParagraph } from 'i18n/trans/paragraph';

interface LegRouteProps {
  allocations?: Array<PlaceAllocation & { passengerIds: string[] }>;
  className?: string;
  leg: TripLeg;
  isOnGrayBg?: boolean;
  showFullDate?: boolean;
  showPlaces?: boolean;
}

const useStyles = makeStyles(
  () => ({
    legInfo: {
      flex: 1,
    },
    stopInfo: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '0.25rem',
      alignItems: 'center',
    },
    placeInfo: {
      '& .Icon-root': {
        width: '0.875rem',
        marginRight: '0.25rem',
      },
    },
  }),
  { name: 'LegRoute' }
);

interface LegInfoTooltipProps {
  tripStop: Omit<TripStop, 'timezone' | 'name' | 'code' | 'hasPrmSupport'>;
}

const LegInfoTooltip: FC<LegInfoTooltipProps> = ({ tripStop }) => {
  const {
    attributes,
    streetName,
    stateName,
    postalCode,
    countryName,
    cityName,
  } = tripStop;

  const content = (
    <Stack direction="column" gap={1}>
      <Typography variant="body1">
        <TransParagraph
          i18nKey="facilities"
          values={{ data: attributes?.join(', ') }}
        />
      </Typography>
      <Typography variant="body1">
        <TransParagraph
          i18nKey="address"
          values={{
            data: [streetName, postalCode, stateName, cityName, countryName]
              .filter(Boolean)
              .join(', '),
          }}
        />
      </Typography>
    </Stack>
  );

  return (
    <Tooltip content={content} placement="top">
      <Icon name="info-circle" sx={{ cursor: 'pointer' }} margin />
    </Tooltip>
  );
};

export const LegRoute: FC<LegRouteProps> = ({
  className,
  leg,
  isOnGrayBg,
  children,
  showFullDate,
  showPlaces,
  allocations,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const booking = useSelector(currentBookingSelector);
  const preparedAllocations = useMemo<
    Array<{
      passengerName: string;
      coachNumber?: string;
      placeIcon: 'seat' | 'compartment' | 'bed';
      placeNumber: string;
    }>
  >(() => {
    if (!allocations?.length) {
      return (
        booking?.passengers.map(({ id }) => ({
          passengerName: getPassengersNames(booking!, id),
          placeIcon: 'seat',
          placeNumber: renderToString(<TransLabel i18nKey="freeSeating" />),
        })) ?? []
      );
    }
    const [allocation] = allocations;
    const isCompartmentAllocation =
      allocations.length === 1 && allocation.passengerIds.length > 1;

    return (
      (isCompartmentAllocation
        ? allocation.passengerIds.map((id) => ({
            ...allocation,
            passengerIds: [id],
          }))
        : allocations
      ).map(
        ({
          passengerIds,
          accomodationType,
          accommodationSubType,
          reservedPlaces,
        }) => {
          const { coachNumber, placeNumber } = reservedPlaces[0];
          return {
            passengerName: getPassengersNames(booking!, passengerIds[0]),
            coachNumber,
            placeIcon:
              accomodationType === 'SEAT'
                ? 'seat'
                : accommodationSubType.includes('COMPARTMENT')
                ? 'compartment'
                : 'bed',
            placeNumber: placeNumber[0],
          };
        }
      ) ?? []
    );
  }, [allocations, booking]);
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      spacing={1}
    >
      <LegWrapper className={className}>
        <Stack spacing={3}>
          <Typography variant="body1" className={classes.stopInfo}>
            <b>{getTimeString(leg.departureTime)}</b>
            {showFullDate && (
              <Typography variant="body2" component="span">
                {`(${formatDate(leg.departureTime)})`}
              </Typography>
            )}
            <Stack direction="row" alignItems="center">
              <b> {leg.originStop.name}</b>
              <LegInfoTooltip tripStop={leg.originStop} />
            </Stack>
          </Typography>
          <Stack spacing={3}>
            <LegInfo isBgWhite={isOnGrayBg} {...leg} />
            <Stack direction="row" alignItems="center">
              <Icon margin width={10} name="clock" />
              <Typography variant="body2">
                <TransSubtitle
                  i18nKey="travelTime"
                  values={{ time: getLegDuration(leg) }}
                />
              </Typography>
              <Button
                onClick={() => dispatch(showTripStops(leg))}
                variant="text"
                sx={{
                  p: 0,
                  pl: 1,
                  minWidth: 0,
                  fontSize: '12px',
                  textDecoration: 'underline',
                }}
              >
                <TransButton i18nKey="viewStops" />
              </Button>
            </Stack>
            {showPlaces && (
              <Stack>
                {preparedAllocations?.map(
                  (
                    { coachNumber, passengerName, placeIcon, placeNumber },
                    idx
                  ) => (
                    <Stack
                      key={idx}
                      direction="row"
                      spacing={1}
                      className={classes.placeInfo}
                    >
                      <Typography variant="body2" fontWeight="bold">
                        <Icon name="profile" />
                        {passengerName}
                      </Typography>
                      {coachNumber && (
                        <Typography variant="body2">
                          <Icon name="train" />
                          {coachNumber}
                        </Typography>
                      )}
                      <Typography variant="body2">
                        <Icon name={placeIcon} />
                        {placeNumber}
                      </Typography>
                    </Stack>
                  )
                )}
              </Stack>
            )}
          </Stack>
          <Typography variant="body1" className={classes.stopInfo}>
            <b>{getTimeString(leg.arrivalTime)}</b>
            <DaysAfterCount
              startDate={leg.departureTime}
              endDate={leg.arrivalTime}
            />
            {showFullDate && (
              <Typography variant="body2" component="span">
                {`(${formatDate(leg.arrivalTime)})`}
              </Typography>
            )}
            <Stack direction="row" alignItems="center">
              <b>{leg.destinationStop.name}</b>
              <LegInfoTooltip tripStop={leg.destinationStop} />
            </Stack>
          </Typography>
        </Stack>
      </LegWrapper>
      {children}
    </Stack>
  );
};
