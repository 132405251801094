import { Button, Icon } from '@fleet/shared';
import { useModal } from '@fleet/shared/hooks';
import { Stack, Typography } from '@mui/material';
import { CartTotal } from 'components/CartTotal';
import { ModifyJourneyStepsContext } from 'components/ModifyJourneyStepsProvider';
import {
  bookingExpiredSelector,
  exchangeTripsSelector,
} from 'features/booking/bookingSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransTitle } from 'i18n/trans/title';
import { FC, ReactNode, useContext, useMemo } from 'react';
import { ModalControlsWrap } from 'routes/bookingDetails/modal/ModalControlsWrap';
import { AddonsTable } from 'routes/tickets/checkout/AddonsTable';
import { AncillaryModal } from 'routes/tickets/checkout/AncillaryModal';
import { useSelector } from 'store/utils';
import { getTripsAncillaries } from 'utils/trip';

interface AncillariesProps {
  goToNextStep: () => void;
  isModifyFlow?: boolean;
  submitLabel?: ReactNode;
}
export const Ancillaries: FC<AncillariesProps> = ({
  goToNextStep,
  isModifyFlow,
  submitLabel,
}) => {
  const { closeModal } = useContext(ModifyJourneyStepsContext);
  const { open, onOpen, onClose } = useModal();

  const isBookingExpired = useSelector(bookingExpiredSelector);
  const exchangeTrips = useSelector(exchangeTripsSelector);
  const exchangeAncillaries = useMemo(
    () => getTripsAncillaries(exchangeTrips),
    [exchangeTrips]
  );

  return (
    <>
      <Stack spacing={2} alignItems="flex-start">
        <Typography variant="h1">
          <TransTitle i18nKey="addons" />
        </Typography>
        <Button
          onClick={onOpen}
          label={<TransButton i18nKey="addAddon" />}
          startIcon={<Icon name="add" />}
        />
        <AddonsTable
          hiddenColumns={['status']}
          data={isModifyFlow ? exchangeAncillaries : undefined}
        />
      </Stack>
      {open && <AncillaryModal onClose={onClose} />}

      {isModifyFlow ? (
        <ModalControlsWrap>
          <Button
            variant="text"
            label={<TransButton i18nKey="cancel" />}
            onClick={closeModal}
          />
          <Button
            variant="contained"
            onClick={goToNextStep}
            label={
              <>
                <Icon name="arrow-right" sx={{ mr: 1 }} />
                {submitLabel}
              </>
            }
          />
        </ModalControlsWrap>
      ) : (
        <CartTotal>
          <>
            <Button
              variant="text"
              disabled={isBookingExpired}
              label={<TransButton i18nKey="resetFields" />}
            />
            <Button
              variant="contained"
              type="submit"
              form="passengerDetails"
              disabled={isBookingExpired}
              onClick={goToNextStep}
              label={
                <>
                  <Icon name="arrow-right" sx={{ mr: 1 }} />
                  {submitLabel}
                </>
              }
            />
          </>
        </CartTotal>
      )}
    </>
  );
};
