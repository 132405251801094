import {
  currentDateTimeFormat,
  formatDate,
  Icon,
  Modal,
  Table,
  TableColumns,
} from '@fleet/shared';
import { Link, Stack, Typography } from '@mui/material';
import { CustomerNotification } from 'dto/booking';
import {
  bookingNotificationsSelector,
  currentBookingSelector,
} from 'features/booking/bookingSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransLabel } from 'i18n/trans/label';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import { FC, useMemo, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useTable } from 'react-table';
import { NotificationDetails } from 'routes/bookingDetails/NotificationDetails';
import { useSelector } from 'store/utils';
import { getPassengersNames } from 'utils/trip';

interface CustomerCommunicationProps {}

export const CustomerCommunication: FC<CustomerCommunicationProps> = () => {
  const [showDetailsFor, setShowDetailsFor] = useState<
    CustomerNotification | undefined
  >();
  const detailsModalTitle = useMemo(() => {
    if (!showDetailsFor) return;
    const {
      isDeliveredByEmail,
      isDeliveredBySms,
      isDeliveredByPushNotification,
    } = showDetailsFor;
    if (isDeliveredByEmail)
      return <TransSubtitle i18nKey="notificationDetailsEmail" />;
    if (isDeliveredBySms)
      return <TransSubtitle i18nKey="notificationDetailsSms" />;
    if (isDeliveredByPushNotification)
      return <TransSubtitle i18nKey="notificationDetailsPush" />;
  }, [showDetailsFor]);
  const booking = useSelector(currentBookingSelector);
  const notifications = useSelector(bookingNotificationsSelector);
  const columns = useMemo<TableColumns<CustomerNotification>>(
    () => [
      {
        accessor: 'deliveredOn',
        Cell: ({ value }) => formatDate(value, currentDateTimeFormat),
        Header: <TransTableHead i18nKey="createdOn" />,
        width: '15rem',
      },
      {
        accessor: 'passengerId',
        Cell: ({ value }) => booking && getPassengersNames(booking, value),
        Header: <TransTableHead i18nKey="passenger" />,
        width: '20rem',
      },
      {
        id: 'summary',
        accessor: (notification) => {
          const {
            isDeliveredByEmail,
            isDeliveredBySms,
            isDeliveredByPushNotification,
            deliveredToEmail,
            emailSenderName,
            deliveredToNumber,
            smsSenderName,
            pushNotificationTitle,
          } = notification;
          return (
            <Stack gap={2} direction="row">
              <Link
                onClick={() => setShowDetailsFor(notification)}
                sx={{ cursor: 'pointer' }}
              >
                <Stack direction="row" alignItems="center" gap={0.5}>
                  <Icon name="visibility-on" />
                  <TransButton i18nKey="viewDetails" />
                </Stack>
              </Link>
              <Typography variant="body2">
                {isDeliveredByEmail &&
                  [
                    renderToString(<TransLabel i18nKey="email" />),
                    deliveredToEmail,
                    emailSenderName,
                  ].join(' · ')}
                {isDeliveredBySms &&
                  [
                    renderToString(<TransLabel i18nKey="sms" />),
                    deliveredToNumber,
                    smsSenderName,
                  ].join(' · ')}
                {isDeliveredByPushNotification &&
                  [
                    renderToString(<TransLabel i18nKey="notification" />),
                    pushNotificationTitle,
                  ].join(' · ')}
              </Typography>
            </Stack>
          );
        },
        Header: <TransTableHead i18nKey="summary" />,
        width: 'auto',
      },
    ],
    [booking]
  );

  const table = useTable({
    data: notifications,
    columns,
  });

  return (
    <>
      <Table
        table={table}
        getTableProps={{
          sx: {
            '& .MuiTableHead-root > tr': {
              backgroundColor: (theme) => theme.palette.background.default,
            },
          },
        }}
      />
      {showDetailsFor && (
        <Modal
          sx={{
            '& .MuiPaper-root': {
              width: '720px',
            },
          }}
          title={detailsModalTitle}
          open
          onClose={() => setShowDetailsFor(undefined)}
          cancelButton={false}
        >
          <NotificationDetails {...showDetailsFor} />
        </Modal>
      )}
    </>
  );
};
