import { Icon, IconProps, Tooltip } from '@fleet/shared';
import { Stack, TooltipProps, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface HintWrapperProps {
  content?: ReactNode;
  hint?: ReactNode;
  tooltipPlacement?: TooltipProps['placement'];
  iconPlacement?: 'start' | 'end';
  iconColor?: IconProps['color'];
}

export const HintWrapper: FC<HintWrapperProps> = ({
  content,
  hint,
  tooltipPlacement,
  iconPlacement = 'end',
  iconColor,
}) => {
  return (
    <Stack
      alignItems="center"
      {...(iconPlacement === 'end'
        ? {
            direction: 'row',
            justifyContent: 'flex-start',
          }
        : {
            direction: 'row-reverse',
            justifyContent: 'flex-end',
          })}
    >
      <Typography variant="body2" noWrap>
        {content}
      </Typography>
      {hint && (
        <Tooltip content={hint} placement={tooltipPlacement}>
          <Icon
            name="info-circle"
            color={iconColor}
            sx={{ cursor: 'pointer' }}
            margin
          />
        </Tooltip>
      )}
    </Stack>
  );
};
