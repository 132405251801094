import { activeTabSelector } from 'features/tabs/tabsSelector';
import { FC, ReactNode, useCallback, useMemo } from 'react';
import {
  alpha,
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import { TransParagraph } from 'i18n/trans/paragraph';
import { makeStyles } from '@mui/styles';
import { Button, formatDate, Icon } from '@fleet/shared';
import { nextAvailableDateSelector } from 'features/trip/tripSelector';
import { SearchType } from 'routes/tickets/SearchTabs';
import { useSelector } from 'store/utils';
import { TransButton } from 'i18n/trans/button';
import { TripSearchParams } from 'dto/trip';
import { FormApi } from 'final-form';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: 300,
    },
    content: {
      width: 250,
      margin: '0 auto',
      textAlign: 'center',
    },
    iconWrap: {
      width: 80,
      height: 80,
      borderRadius: '50%',
      background: alpha(theme.palette.warning.main, 0.1),
      color: alpha(theme.palette.warning.main, 0.8),
      display: 'inline-flex',
      margin: '32px 0',
    },
  }),
  {
    name: 'EmptyResults',
  }
);

interface EmptyResultsProps {
  message?: ReactNode;
  form?: FormApi<TripSearchParams, Partial<TripSearchParams>>;
  isOutbound?: boolean;
}

export const EmptyResults: FC<EmptyResultsProps> = ({
  message,
  form,
  isOutbound = false,
}) => {
  const nextAvailableDate = useSelector(nextAvailableDateSelector);
  const currentTab = useSelector(activeTabSelector);
  const isTicketSearch = useMemo(
    () => currentTab?.type === SearchType.tickets,
    [currentTab?.type]
  );
  const classes = useStyles();

  const onNextDepartureClick = useCallback(async () => {
    if (!nextAvailableDate || !isTicketSearch || !form) {
      return;
    }
    const outboundTime = nextAvailableDate.outbound;
    const inboundTime = nextAvailableDate.inbound;

    if (
      form.getState().values.arrivalTime &&
      new Date(outboundTime) > new Date(form.getState().values.arrivalTime!)
    ) {
      form.batch(() => {
        form.change('departureTime', outboundTime);
        form.change('arrivalTime', outboundTime);
      });
    } else {
      form.change(
        isOutbound ? 'departureTime' : 'arrivalTime',
        isOutbound ? outboundTime : inboundTime
      );
    }

    form.submit();
  }, [form, isTicketSearch, nextAvailableDate, isOutbound]);

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Box className={classes.iconWrap}>
          <Icon
            name="search"
            sx={{ m: 'auto', strokeWidth: '2px' }}
            size={20}
          />
        </Box>
        <Stack spacing={3}>
          <Typography color="text.secondary">
            {message ?? <TransParagraph i18nKey="emptyResults" />}
          </Typography>
          {(nextAvailableDate.outbound || nextAvailableDate.inbound) && (
            <Button
              variant="outlined"
              sx={{ mt: 3 }}
              startIcon={<Icon name="search" />}
              onClick={onNextDepartureClick}
            >
              <TransButton
                i18nKey={isOutbound ? 'nextDepartureOn' : 'nextArrivalOn'}
                values={{
                  date: formatDate(
                    new Date(
                      isOutbound
                        ? nextAvailableDate.outbound
                        : nextAvailableDate.inbound
                    )
                  ),
                }}
              />
            </Button>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
