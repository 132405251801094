import { api } from '@fleet/shared';
import { currentLocaleConfiguration } from '@fleet/shared/i18n';
import { Duration, format, getTime, intervalToDuration } from 'date-fns';
import download from 'downloadjs';
import {
  BookingAdmission,
  BookingAdmissionAncillary,
  BookingDetails,
  BookingDetailsPassenger,
  BookingFulfillment,
  BookingReservation,
  BookingTripWithAdmissions,
  FulfillmentStatus,
  PassengerFee,
  PlaceAllocation,
} from 'dto/booking';
import {
  Journey,
  PassengerSpecification,
  Price,
  PromoCode,
  ReservationLegCoverage,
  Trip,
  TripLeg,
  TripOffer,
} from 'dto/trip';
import { parse } from 'duration-fns';
import { SelectedOffers } from 'features/trip/tripReducer';
import i18n from 'i18n';
import _capitalize from 'lodash/capitalize';
import _groupBy from 'lodash/groupBy';
import _keyBy from 'lodash/keyBy';
import _lowerCase from 'lodash/lowerCase';
import _omitBy from 'lodash/omitBy';
import _startsWith from 'lodash/startsWith';
import { IS_DS_AT } from 'utils/flags';
import { prepareTrips } from 'utils/overview';

export const getTimeString = (dateTimeStr: string): string => {
  return format(
    getTime(new Date(dateTimeStr)),
    currentLocaleConfiguration.timeFormat
  );
};

export const getLegDuration = (trip: TripLeg) => {
  return parseDuration(parse(trip.duration));
};

export const getLegTransferTime = (tripA: TripLeg, tripB: TripLeg) => {
  return getDuration(tripA.arrivalTime, tripB.departureTime);
};

export const getDuration = (a: string, b: string) => {
  const duration = _omitBy(
    intervalToDuration({
      start: new Date(a),
      end: new Date(b),
    }),
    (v) => !v
  );

  return parseDuration(duration);
};

export const parseDuration = (duration: Duration) =>
  Object.keys(duration).reduce((acc, cur) => {
    const unitStr = cur === 'minutes' ? 'min' : cur.substring(0, 1);
    const unitValue = duration[cur as keyof Duration];
    return [
      acc,
      ...(unitValue ? [`${duration[cur as keyof Duration]}${unitStr}`] : []),
    ].join(' ');
  }, '');

export const getTripsLegsWithOffers = (
  trips: Array<Trip>
): Array<TripLeg & { offers: Array<TripOffer> }> =>
  trips.reduce<Array<TripLeg & { offers: Array<TripOffer> }>>((acc, trip) => {
    const { legs, offers } = trip;
    return [
      ...acc,
      ...legs.map((leg) => {
        return {
          ...leg,
          offers: offers?.filter(({ coveredLegIds }) =>
            coveredLegIds.includes(leg.id)
          ),
        };
      }),
    ];
  }, []);

export const getBookingJourneys = (booking?: BookingDetails) => {
  if (!booking) return [];
  const trips = booking.bookedTrips.filter((trip) =>
    getTripAdmissions(trip).every(
      (admission) => !isAdmissionInactive(admission)
    )
  );
  const journeysMap = _groupBy(trips, 'journeyRef');
  return Object.keys(journeysMap).map((reference) => ({
    reference,
    trips: journeysMap[reference],
  }));
};

export const getBookingDestinations = (
  booking?: BookingDetails
): Array<string> => {
  if (!booking) return [];
  const journeys = getBookingJourneys(booking);
  const lastJourney = journeys[journeys.length - 1];
  const lastTrip = lastJourney.trips[lastJourney.trips.length - 1];
  return [journeys[0].trips[0].originStop.name, lastTrip.destinationStop.name];
};

export const getOnDemandServiceTexts = (
  trips: Array<Trip | BookingTripWithAdmissions>
): Array<string> =>
  trips.reduce<Array<string>>(
    (texts, trip) => [
      ...texts,
      ...trip.legs.reduce<Array<string>>(
        (texts, leg) => [...texts, ...(leg.onDemandTexts ?? [])],
        []
      ),
    ],
    []
  );

export const getHasJourneyNotifications = (
  trips: Array<Trip | BookingTripWithAdmissions>
): boolean =>
  trips.some(({ legs }) =>
    legs.some(({ serviceTexts }) => !!serviceTexts?.length)
  );

export const getTripAdmissions = (
  { bookedOffers }: BookingTripWithAdmissions,
  ticketAdmissions?: boolean
): Array<BookingAdmission> =>
  (ticketAdmissions ? [bookedOffers[0]] : bookedOffers)?.reduce<
    Array<BookingAdmission>
  >((acc, { admissions }) => [...acc, ...admissions], []) ?? [];

export const getBookingAdmissions = (
  booking?: BookingDetails,
  ticketAdmissions?: boolean
): Array<BookingAdmission> => {
  if (!booking?.bookedTrips.length && !booking?.nonTripOffers?.length)
    return [];
  const { bookedTrips, nonTripOffers } = booking;
  return bookedTrips.length
    ? bookedTrips.reduce<Array<BookingAdmission>>(
        (admissions, trip) =>
          [...admissions, ...getTripAdmissions(trip, ticketAdmissions)].filter(
            ({ passengerIds }) => passengerIds
          ),
        []
      )
    : nonTripOffers.reduce<Array<BookingAdmission>>(
        (acc, { admissions }) => [...acc, ...admissions],
        []
      );
};

export const getReservationsFees = (
  reservations: Array<BookingReservation>,
  offerFeesMap: Record<string, PassengerFee>
): Array<PassengerFee> => {
  return reservations
    .reduce<Array<PassengerFee>>(
      (acc, { feeRefs }) => [
        ...acc,
        ...(feeRefs?.map((feeId) => offerFeesMap[feeId]) ?? []),
      ],
      []
    )
    .filter(Boolean);
};

export const getTotalPrice = (
  items: Array<{ price: Price; status?: string }>
) =>
  items
    .filter(
      ({ status }) =>
        status === undefined || status !== FulfillmentStatus.REFUNDED
    )
    .reduce<{
      amount: number;
      fee: number;
      currency: string;
    }>(
      (acc, { price }) => ({
        amount: acc.amount + price.amount,
        fee: price.vats!.reduce(
          (totalFee, vat) => totalFee + vat.amount,
          acc.fee
        ),
        currency: price.currency,
      }),
      {
        amount: 0,
        fee: 0,
        currency: '',
      }
    );

export const getAdmissionsTotalPrice = ({
  admissions,
  includeAncillaries = true,
  withFee = true,
  offerFeesMap,
}: {
  admissions: Array<BookingAdmission>;
  includeAncillaries?: boolean;
  withFee?: boolean;
  offerFeesMap?: Record<string, PassengerFee>;
}) => {
  const admissionParts = admissions.reduce<
    Array<{ price: Price; status?: FulfillmentStatus }>
  >(
    (reservations, admission) => [
      ...reservations,
      ...(admission.reservations ?? []),
      ...(includeAncillaries ? admission.ancillaries ?? [] : []),
      ...(withFee ? admission.fees : []),
      ...(offerFeesMap
        ? getReservationsFees(admission.reservations, offerFeesMap)
        : []),
    ],
    []
  );

  return getTotalPrice(
    [...admissions, ...admissionParts].filter(
      ({ status }) => !status || !isAdmissionInactive({ status })
    )
  );
};

export const downloadBookingTickets = async (booking?: BookingDetails) => {
  if (!booking) return;
  const admissions = getBookingAdmissions(booking, true);
  const documents = admissions.reduce<Array<BookingFulfillment>>(
    (acc, { fulfillments }) =>
      [...acc, ...fulfillments].filter(
        ({ downloadLink, documentType }: BookingFulfillment) =>
          downloadLink && documentType === 'TICKET'
      ),
    []
  );

  const docsToProcess = IS_DS_AT ? documents.slice(0, 1) : documents;

  for (const { downloadLink, documentFormat } of docsToProcess) {
    const response = await api.get(downloadLink, {
      responseType: 'blob',
      headers: {
        'Accept-Language': i18n.language,
      },
    });
    download(response.data, 'ticket.pdf', documentFormat);
  }
};

export const getReadablePropertyName = (code: string): string => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, propertyCode] = code.split('.');
  return _capitalize(_lowerCase(propertyCode ?? code));
};

export const getOffersReservationInfo = async (
  offers: Array<TripOffer>
): Promise<Array<TripOffer>> => {
  if (!offers.length) return [];
  const payload = offers.map(({ id, reservationOfferParts }) => ({
    offerId: id,
    reservationIds: reservationOfferParts.map(
      ({ reservationId }) => reservationId
    ),
  }));
  try {
    const { offerAvailabilities } = (
      await api.post<{
        offerAvailabilities: Array<{
          offerId: string;
          reservationLegCoverage: Array<ReservationLegCoverage>;
        }>;
      }>(`/availabilities/preferences/bulk`, {
        offers: payload,
      })
    ).data;
    const availabilitiesMap = _keyBy(
      offerAvailabilities,
      ({ offerId }) => offerId
    );
    return offers.map((offer) => ({
      ...offer,
      reservationLegCoverage:
        availabilitiesMap[offer.id]!.reservationLegCoverage,
    }));
  } catch (e) {
    return offers;
  }
};
export const checkIfOfferNeedsAdditionalDataCall = ({
  reservationOfferParts,
  reservationLegCoverage,
}: TripOffer) =>
  !reservationLegCoverage?.length &&
  reservationOfferParts.map(
    ({ reservationId, availablePlaces }) =>
      reservationId && !availablePlaces?.length
  ).length;

export const getTripsByLegIds = (booking: BookingDetails, ids: string[]) => {
  return booking?.bookedTrips.filter((bookedTrip) =>
    bookedTrip.legs
      .reduce<string[]>((legIds, { id }) => [...legIds, id], [])
      .some((id) => ids.includes(id))
  );
};

export const getTripsAncillaries = (trips: Array<BookingTripWithAdmissions>) =>
  trips.reduce<Array<BookingAdmissionAncillary>>(
    (acc, trip) => [
      ...acc,
      ...getTripAdmissions(trip).reduce<Array<BookingAdmissionAncillary>>(
        (ancillaries, admission) => [
          ...ancillaries,
          ...(admission.ancillaries ?? []),
        ],
        []
      ),
    ],
    []
  );

export const isCompartment = (admission: BookingAdmission) =>
  admission.reservations.find(({ placeAllocations }) =>
    placeAllocations?.accommodationSubType.includes('COMPARTMENT')
  );

export const canShowAdmissionPrice = (
  admission: BookingAdmission,
  passengerId: string,
  passengersOrder: Array<BookingDetailsPassenger>
) => {
  if (isCompartment(admission)) {
    const sortOrder = passengersOrder.map(({ id }) => id);
    const sortedPassengerIds = [...admission.passengerIds].sort(
      (a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b)
    );
    return sortedPassengerIds[0] === passengerId;
  }
  return true;
};

export const isSupplement = ({ type }: BookingAdmissionAncillary) =>
  type.toUpperCase() === 'SUPPLEMENT';

export const getAdmissionsPlaceAllocations = (
  admissions: Array<BookingAdmission>
): Array<PlaceAllocation & { passengerIds: Array<string> }> => {
  return admissions.reduce<
    Array<PlaceAllocation & { passengerIds: Array<string> }>
  >(
    (places, { reservations, passengerIds }) => [
      ...places,
      ...reservations.map(({ placeAllocations }) => ({
        ...placeAllocations,
        passengerIds,
      })),
    ],
    []
  );
};

export const getPassengersNames = (
  booking: BookingDetails,
  ...ids: Array<string>
) => {
  const passengerMap = _keyBy(booking.passengers, 'id');
  return ids
    .map((id) =>
      [passengerMap[id]?.firstName.value, passengerMap[id]?.lastName.value]
        .filter(Boolean)
        .join(' ')
    )
    .join(', ');
};

export const getTransportationLabel = (
  {
    carrier,
    serviceCode,
    lineNumber,
    serviceAttributes,
    publishedServiceName,
  }: TripLeg,
  fallbackCarrier: string
) => {
  const serviceBrand = serviceAttributes.find(({ code }) =>
    _startsWith(code, 'VRM')
  );
  return [
    carrier?.name ?? carrier?.code ?? fallbackCarrier,
    publishedServiceName ?? serviceCode ?? lineNumber,
    serviceBrand?.description,
  ]
    .filter(Boolean)
    .join(' ');
};

export const getPassengerJourneys = (booking?: BookingDetails) => {
  if (booking) {
    const passengerJourneyMap: {
      [p: string]: Array<BookingTripWithAdmissions>;
    } = {};
    const preparedTrips = prepareTrips(booking.bookedTrips);

    preparedTrips.forEach((bookedTrip) => {
      const admissions = bookedTrip.bookedOffers[0].admissions;

      admissions.forEach((admission) => {
        admission.passengerIds.forEach((passengerId) => {
          const matchingPassenger = booking.passengers.find(
            (p) => p.id === passengerId
          );
          if (matchingPassenger) {
            if (!passengerJourneyMap[passengerId]) {
              passengerJourneyMap[passengerId] = [];
            }
            passengerJourneyMap[passengerId].push(bookedTrip);
          }
        });
      });
    });

    return passengerJourneyMap;
  }
  return {};
};

export const createOfferPayloads = (
  tripResults: { outboundTrips: Journey[]; inboundTrips: Journey[] },
  selectedOutboundOffers: SelectedOffers,
  selectedInboundOffers: SelectedOffers | null,
  passengerSpecifications: PassengerSpecification[],
  promotionCodes: PromoCode[]
) => {
  const outboundPayloads = selectedOutboundOffers.trips.map(({ id }) => ({
    alliances: tripResults.outboundTrips.find(
      ({ reference }) => selectedOutboundOffers.reference === reference
    )?.alliances,
    id,
    passengerExternalReferences: passengerSpecifications.map(
      ({ externalReference }) => externalReference
    ),
    selections: selectedOutboundOffers.tripsSelectionMap[id],
  }));

  const inboundPayloads = selectedInboundOffers
    ? selectedInboundOffers.trips.map(({ id }) => ({
        alliances: tripResults.inboundTrips.find(
          ({ reference }) => selectedInboundOffers.reference === reference
        )?.alliances,
        id,
        passengerExternalReferences: passengerSpecifications.map(
          ({ externalReference }) => externalReference
        ),
        selections: selectedInboundOffers.tripsSelectionMap[id],
      }))
    : [];

  return {
    offers: [...outboundPayloads, ...inboundPayloads],
    passengerSpecifications,
    promotionCodes: promotionCodes.map(({ code }) => ({ code })),
  };
};

export const accumulateFees = (fees: Array<PassengerFee>) =>
  fees.reduce((acc, { price }) => ({
    ...acc,
    price: {
      ...acc.price,
      amount: acc.price.amount + price.amount,
      vats: [...(acc.price.vats ?? []), ...(price.vats ?? [])],
    },
  }));

export const isAdmissionInactive = ({
  status,
}: {
  status: FulfillmentStatus;
}) =>
  [FulfillmentStatus.EXCHANGED, FulfillmentStatus.REFUNDED].includes(status);

export const isAdmissionActive = ({ status }: { status: FulfillmentStatus }) =>
  !isAdmissionInactive({ status });
