import { SeatProperty } from 'dto/booking';
import { TransLabel } from 'i18n/trans/label';
import { FC, useMemo } from 'react';
import { Tooltip } from '@fleet/shared/mui';
import { Icon } from '@fleet/shared';
import { makeStyles } from '@mui/styles';

interface SeatPropertyIconProps {
  code: SeatProperty;
}

const useStyles = makeStyles(
  () => ({
    root: {
      padding: '0 3px',
      boxSizing: 'content-box',
    },
  }),
  { name: 'ServiceAttributeIcon' }
);

export const SeatPropertyIcon: FC<SeatPropertyIconProps> = ({ code }) => {
  const classes = useStyles();
  const iconName = useMemo(() => {
    switch (code) {
      case 'WITH_ANIMALS':
        return 'pet';
      case 'USB':
        return 'usb';
      case 'TABLE':
        return 'table';
      case 'SILENCE':
        return 'silence';
      case 'POWER':
        return 'power-socket';
      case 'NEAR_WHEELCHAIR':
      case 'NEAR_WHEELCHAIR_AREA':
        return 'near-wheelchair';
      case 'NEAR_BICYCLE_AREA':
        return 'near-bicycle';
      case 'NEAR_ANIMALS':
        return 'near-pet';
      case 'MIDDLE_SEAT':
        return 'middle-seat';
      case 'EASY_ACCESS':
        return 'accessibility';
      case 'WINDOW':
        return 'by-window';
      case 'AISLE':
        return 'by-aisle';
      case 'BLOCKED':
        return 'deactivate';
      case 'UNACCOMPANIED_MINOR':
        return 'unaccompanied-minor';
      default:
        return '';
    }
  }, [code]);

  if (!iconName) return null;

  return (
    <Tooltip content={<TransLabel i18nKey={code} />} key={code}>
      <Icon className={classes.root} name={iconName} />
    </Tooltip>
  );
};
