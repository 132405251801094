import { Tooltip } from '@fleet/shared/mui';
import { FC, ReactNode, useMemo, useRef } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface OverflowEllipsisProps extends TypographyProps {
  content: ReactNode;
  linesToShow?: number;
}

const useStyles = makeStyles(
  () => ({
    root: {
      display: '-webkit-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '-webkit-line-clamp': ({ linesToShow }: OverflowEllipsisProps) =>
        linesToShow,
      '-webkit-box-orient': 'vertical',
    },
  }),
  { name: 'OverflowEllipsis' }
);

export const OverflowEllipsis: FC<OverflowEllipsisProps> = (props) => {
  const {
    content,
    variant = 'body1',
    linesToShow = 1,
    ...typographyProps
  } = props;
  const classes = useStyles(props);
  const contentRef = useRef<HTMLElement>(null);
  const isOverflown = useMemo(() => {
    return (
      linesToShow > 1 ||
      (!!contentRef.current &&
        contentRef.current.scrollWidth > contentRef.current.clientWidth)
    );
  }, [content, contentRef.current]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Tooltip content={isOverflown ? content : null}>
      <Typography
        {...typographyProps}
        className={classes.root}
        variant={variant}
        ref={contentRef}
        noWrap={linesToShow < 2}
      >
        {content}
      </Typography>
    </Tooltip>
  );
};
