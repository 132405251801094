import {
  Icon,
  SearchResult,
  Table,
  TableColumns,
  useModal,
} from '@fleet/shared';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { ConfirmModal } from 'components/ConfirmModal';
import { PriceWithFee } from 'components/PriceWithFee';
import { PassengerFee } from 'dto/booking';
import {
  getBooking,
  removeFeeFromBooking,
} from 'features/booking/bookingActions';
import {
  bookingSelectionFeesIdsSelector,
  bookingTotalFeesSelector,
  currentBookingSelector,
} from 'features/booking/bookingSelectors';
import { TransParagraph } from 'i18n/trans/paragraph';
import { TransTableHead } from 'i18n/trans/table';
import { FC, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, useTable } from 'react-table';
import { useDispatch, useSelector } from 'store/utils';
import { getTripAdmissions, isAdmissionInactive } from 'utils/trip';

interface FeesProps {
  data?: Array<PassengerFee>;
}

const useStyles = makeStyles(
  (theme) => ({
    table: {
      '& thead > tr': {
        background: theme.palette.common.white,
      },
      '& tbody > tr': {
        background: theme.palette.background.default,
      },
    },
    strikeThrough: {
      textDecoration: 'line-through',
      opacity: 0.75,
    },
  }),
  {
    name: 'Fees',
  }
);
export const Fees: FC<FeesProps> = ({ data }) => {
  const bookingFees = useSelector(bookingTotalFeesSelector);
  const [selectedFeeId, setSelectedFeeId] = useState('');
  const dispatch = useDispatch();
  const { open, onClose, onOpen } = useModal();
  const booking = useSelector(currentBookingSelector);
  const selectionFeesIds = useSelector(bookingSelectionFeesIdsSelector);
  const isSalesFlow = Boolean(
    !useParams<{
      id: string;
    }>().id
  );
  const inactiveFeeIds = useMemo(() => {
    const inactiveAdmissions = booking?.bookedTrips
      .map((trip) => getTripAdmissions(trip))
      .flat()
      .filter((admission) => isAdmissionInactive(admission));
    return (
      inactiveAdmissions?.reduce<Array<string>>(
        (ids, { feeRefs, fees }) => [
          ...ids,
          ...feeRefs,
          ...fees.map(({ id }) => id),
        ],
        []
      ) ?? []
    );
  }, [booking?.bookedTrips]);

  const classes = useStyles();
  const preparedData = useMemo(() => data ?? bookingFees, [bookingFees, data]);
  const onRemoveBookingFee = useCallback(
    async (feeId: string) => {
      onClose();
      await dispatch(removeFeeFromBooking([feeId])).unwrap();
      await dispatch(getBooking(booking!.id));
    },
    [dispatch, onClose, booking]
  );
  const columns = useMemo<TableColumns<PassengerFee>>(
    () => [
      {
        id: 'removeFeeButton',
        width: 70,
        Cell: ({ row }: { row: Row<PassengerFee> }) => {
          if (selectionFeesIds.includes(row.original.id)) return null;
          return (
            <IconButton
              onClick={() => {
                setSelectedFeeId(row.original.id);
                onOpen();
              }}
            >
              <Icon size={16} name="trash" color="error" />
            </IconButton>
          );
        },
      },
      {
        accessor: 'description',
        width: 'auto',
        Header: <TransTableHead i18nKey="fee" />,
      },
      {
        id: 'price',
        Header: <TransTableHead i18nKey="price" />,
        accessor: ({ price }) => <PriceWithFee {...price} />,
      },
    ],
    [onOpen, selectionFeesIds]
  );

  const table = useTable({
    data: preparedData,
    columns,
    initialState: {
      hiddenColumns: isSalesFlow ? [] : ['removeFeeButton'],
    },
  });

  return (
    <SearchResult
      results={preparedData.length}
      loading={false}
      message={<TransParagraph i18nKey="emptyResults" />}
    >
      <Table
        table={table}
        classes={{ table: classes.table }}
        getRowProps={(_, { row }) => ({
          className: classNames({
            [classes.strikeThrough]: inactiveFeeIds.includes(row.original.id),
          }),
        })}
      />
      <ConfirmModal
        messageBody={<TransParagraph i18nKey="removeBookingFee" />}
        onConfirm={() => onRemoveBookingFee(selectedFeeId)}
        onClose={onClose}
        isOpen={open}
      />
    </SearchResult>
  );
};
