import {
  Button,
  DateField,
  FormField,
  FormProvider,
  getStartOfDate,
  Icon,
  SelectOption,
  useForm,
} from '@fleet/shared';
import { currentLocaleConfiguration } from '@fleet/shared/i18n';
import { Divider, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ModifyJourneyStepsContext } from 'components/ModifyJourneyStepsProvider';
import { SearchFilters } from 'components/searchBar/SearchFilters';
import { SearchStopsSelect } from 'components/searchBar/SearchStopsSelect';
import { SearchTextField } from 'components/searchBar/SearchTextField';
import { Journey, TripSearchParams } from 'dto/trip';
import {
  getAdditionalOffers,
  getBooking,
  startExchange,
} from 'features/booking/bookingActions';

import {
  bookingPartsSelector,
  currentBookingSelector,
  exchangeOffersSelector,
  selectActiveFulfillmentIdsSelection,
} from 'features/booking/bookingSelectors';
import { bookingModifyLoadingSelector } from 'features/loading/loadingSelectors';
import {
  searchExchangeOffers,
  searchExchangeUpgradeOffers,
} from 'features/trip/tripActions';
import {
  selectSelectedOffers,
  tripsSelector,
} from 'features/trip/tripSelector';
import { selectDefaultCurrency } from 'features/user/userSelector';
import { LocalStorage, useLocalStorage } from 'hooks/useLocalStorage';
import { useTripSearch } from 'hooks/useTripSearch';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransTitle } from 'i18n/trans/title';
import _isEqual from 'lodash/isEqual';
import _keyBy from 'lodash/keyBy';
import _pickBy from 'lodash/pickBy';
import {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { TableInstance } from 'react-table';
import { ModalControlsWrap } from 'routes/bookingDetails/modal/ModalControlsWrap';
import { TripsTable } from 'routes/tickets/searchResults/TripsTable';
import { useDispatch, useSelector } from 'store/utils';
import { IS_DS_AT } from 'utils/flags';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      paddingBottom: '4rem',
    },
    search: {
      boxShadow: theme.shadows[2],
      '& > .MuiFormControl-root, & > .MuiAutocomplete-root': {
        flex: 2,
      },
    },
    submitBtn: {
      flex: 1,
      borderRadius: 0,
    },
    controls: {
      boxShadow: theme.shadows[3],
      position: 'absolute',
      background: 'white',
      left: 0,
      right: 0,
      bottom: 0,
      padding: '1rem',
    },
  }),
  { name: 'SearchExchangeJourney' }
);

interface SearchExchangeJourneyProps {
  submitLabel: ReactNode;
}

export const SearchExchangeJourney: FC<SearchExchangeJourneyProps> = ({
  submitLabel,
}) => {
  const dispatch = useDispatch();
  const {
    passengerIds,
    closeModal,
    goToNextStep,
    type,
    selectedTripDirection,
    selectedTrips: [selectedTrip],
    selectedTripFulfillmentIds,
    modifyPassengerSpecifications,
  } = useContext(ModifyJourneyStepsContext);
  const booking = useSelector(currentBookingSelector);
  const loading = useSelector(bookingModifyLoadingSelector);
  const defaultCurrency = useSelector(selectDefaultCurrency);
  const fulfillmentIds = useSelector(
    selectActiveFulfillmentIdsSelection,
    _isEqual
  );
  const exchangeOffers = useSelector(exchangeOffersSelector, _isEqual);
  const { offersSelectionIncomplete } = useTripSearch();
  const tripResults = useSelector(tripsSelector);
  const tableRef = useRef<TableInstance<Journey>>();
  const { inbound: selectedInboundOffers, outbound: selectedOutboundOffers } =
    useSelector(selectSelectedOffers);
  const { initialValue: lsRecentStops } = useLocalStorage<Array<SelectOption>>({
    key: LocalStorage.RECENT_STOPS,
    getDefaultValue: (value) => value?.filter(({ label }) => label) || [],
  });
  const passengerRefs = useMemo(() => {
    const passengersMap = _keyBy(booking!.passengers, 'id');
    return passengerIds.map((id) => passengersMap[id]!.externalReference);
  }, [booking, passengerIds]);
  const selection = useSelector(bookingPartsSelector, _isEqual);
  const selectedPassengerRefs = useMemo(
    () =>
      Object.keys(
        _pickBy(selection.admission, (selection) => selection?.length)
      ).map(
        (passengerId) =>
          booking!.passengers.find(({ id }) => id === passengerId)!
            .externalReference
      ),
    [booking, selection.admission]
  );

  const initialValues = useMemo(
    () => ({
      originStop: {
        code: selectedTrip?.originStop.code,
      },
      destinationStop: {
        code: selectedTrip?.destinationStop.code,
      },
    }),
    [selectedTrip]
  );

  const classes = useStyles();
  const onSubmit = useCallback(
    async ({ searchCriteria, ...rest }: TripSearchParams) => {
      let payload;
      if (!searchCriteria) {
        payload = rest;
      } else {
        const { ptModesFilter, transfersFilter, carriersFilter } =
          searchCriteria;
        payload = {
          ...rest,
          searchCriteria: {
            ...(ptModesFilter?.ptModes.length && { ptModesFilter }),
            ...(carriersFilter?.carriers.length && { carriersFilter }),
            transfersFilter,
          },
        };
      }

      await dispatch(
        searchExchangeOffers({
          bookingId: booking!.id,
          fulfillmentIds: selectedTripFulfillmentIds,
          ...payload,
          passengerSpecifications: modifyPassengerSpecifications,
          currency: defaultCurrency!,
          journeyType: selectedTripDirection!,
        })
      );
    },
    [
      dispatch,
      booking,
      selectedTripFulfillmentIds,
      modifyPassengerSpecifications,
      defaultCurrency,
      selectedTripDirection,
    ]
  );
  const { form, handleSubmit, invalid } = useForm<TripSearchParams>({
    subscription: { invalid: true },
    initialValues,
    onSubmit,
  });

  const submitExchange = useCallback(async () => {
    const offerPayloads = [
      ...(selectedOutboundOffers?.trips ?? []).map(({ id }) => ({
        alliances: tripResults.outboundTrips.find(
          ({ reference }) => selectedOutboundOffers.reference === reference
        )?.alliances,
        id,
        passengerExternalReferences: selectedPassengerRefs,
        selections: selectedOutboundOffers.tripsSelectionMap[id],
      })),
      ...(selectedInboundOffers?.trips ?? []).map(({ id }) => ({
        alliances: tripResults.inboundTrips.find(
          ({ reference }) => selectedInboundOffers.reference === reference
        )?.alliances,
        id,
        passengerExternalReferences: selectedPassengerRefs,
        selections: selectedInboundOffers.tripsSelectionMap[id],
      })),
    ];

    await dispatch(startExchange(offerPayloads)).unwrap();
    const { exchangeOperations } = await dispatch(getBooking()).unwrap();
    await dispatch(
      getAdditionalOffers(
        exchangeOperations.reduce<Array<string>>(
          (acc, { exchangeOffers }) => [
            ...acc,
            ...exchangeOffers.map(({ offerId }) => offerId),
          ],
          []
        )
      )
    ).unwrap();
    goToNextStep();
  }, [
    dispatch,
    goToNextStep,
    selectedOutboundOffers,
    selectedInboundOffers,
    tripResults,
    selectedPassengerRefs,
  ]);

  const onSeatingUpgradeInit = useCallback(async () => {
    if (exchangeOffers.length) return;
    if (
      type !== 'seating' ||
      (!!tripResults.inboundTrips.length && !!tripResults.outboundTrips.length)
    )
      return;
    await dispatch(
      searchExchangeUpgradeOffers({
        fulfillmentIds,
        passengerRefs,
        currency: defaultCurrency,
        journeyType: selectedTripDirection!,
      })
    ).unwrap();
    tableRef.current?.toggleAllRowsExpanded(true);
  }, [
    defaultCurrency,
    dispatch,
    exchangeOffers.length,
    fulfillmentIds,
    passengerRefs,
    selectedTripDirection,
    tripResults.inboundTrips.length,
    tripResults.outboundTrips.length,
    type,
  ]);

  useEffect(() => {
    onSeatingUpgradeInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack className={classes.root}>
      <FormProvider form={form}>
        {type === 'journey' && (
          <Stack
            component="form"
            onSubmit={handleSubmit}
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            className={classes.search}
          >
            <FormField<string>
              required
              name="originStop.code"
              render={({ input }) => (
                <SearchStopsSelect
                  label={<TransField i18nKey="from" />}
                  onChange={input.onChange}
                  value={input.value}
                  recentStops={lsRecentStops}
                />
              )}
            />
            <FormField<string>
              required
              name="destinationStop.code"
              render={({ input }) => (
                <SearchStopsSelect
                  label={<TransField i18nKey="to" />}
                  onChange={input.onChange}
                  value={input.value}
                  recentStops={lsRecentStops}
                />
              )}
            />
            <DateField
              name="departureTime"
              dateFormat={`EEE, dd/MM ${currentLocaleConfiguration.timeFormat}`}
              shouldCloseOnSelect={false}
              showWeekNumbers
              showTimeInput
              minDate={getStartOfDate(new Date())}
              required
              isClearable={false}
              customInput={
                <SearchTextField
                  label={<TransField i18nKey="departureTime" />}
                />
              }
            />
            {IS_DS_AT && <SearchFilters />}
            <Button
              size="large"
              type="submit"
              className={classes.submitBtn}
              disabled={invalid}
              // loading={searchLoading}
              label={
                <Typography variant="h2">
                  <TransButton i18nKey="search" />
                </Typography>
              }
              // onClick={updateCurrentTabParams}
            />
          </Stack>
        )}
        {!!tripResults.outboundTrips.length && (
          <TripsTable
            tableRef={tableRef}
            journeys={tripResults.outboundTrips}
            isOutbound
            {...(type === 'seating' && {
              hideDaySelector: true,
              title: <TransTitle i18nKey="changeSeatClasses" />,
            })}
          />
        )}
        {!!tripResults.inboundTrips.length && (
          <TripsTable
            tableRef={tableRef}
            journeys={tripResults.inboundTrips}
            {...(type === 'seating' && {
              hideDaySelector: true,
              title: <TransTitle i18nKey="changeSeatClasses" />,
            })}
          />
        )}
      </FormProvider>
      <ModalControlsWrap>
        <Button
          variant="text"
          label={<TransButton i18nKey="cancel" />}
          onClick={closeModal}
        />
        <Button
          variant="contained"
          loading={loading}
          disabled={offersSelectionIncomplete}
          onClick={submitExchange}
          label={
            <>
              <Icon name="arrow-right" sx={{ mr: 1 }} />
              {submitLabel}
            </>
          }
        />
      </ModalControlsWrap>
    </Stack>
  );
};
