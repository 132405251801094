import {
  Button,
  currentDateTimeFormat,
  formatDate,
  Icon,
  Table,
  TableColumns,
  useRowSelectCheckbox,
} from '@fleet/shared';
import {
  Card,
  CardContent,
  CardHeader,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { PriceWithFee } from 'components/PriceWithFee';
import { BookingAdmissionAncillary } from 'dto/booking';
import {
  getBooking,
  removeAdditionalOffer,
} from 'features/booking/bookingActions';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransParagraph } from 'i18n/trans/paragraph';
import { TransTableHead } from 'i18n/trans/table';
import { TransTitle } from 'i18n/trans/title';
import _keyBy from 'lodash/keyBy';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRowSelect, useTable } from 'react-table';
import { useDispatch, useSelector } from 'store/utils';
import {
  getBookingAdmissions,
  getTripsAncillaries,
  isAdmissionInactive,
  isSupplement,
} from 'utils/trip';

interface AddonsTableProps {
  onRowSelectionUpdate?: (selection: Array<string>) => void;
  data?: Array<BookingAdmissionAncillary>;
  hiddenColumns?: Array<string>;
  selected?: Array<string>;
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
    },
    hidden: {
      display: 'none',
    },
    selectDisabled: {
      '& $selectionCell': {
        pointerEvents: 'none',
        opacity: 0,
      },
    },
    table: {
      '& thead > tr': {
        background: theme.palette.common.white,
      },
      '& tbody > tr': {
        background: theme.palette.background.default,
      },
    },
    deleteBtn: {
      fontSize: '0.75rem',
      color: theme.palette.error.main,
    },
    deleteConfirm: {
      '& > .MuiPaper-root': {
        width: 260,
      },
    },
    strikeThrough: {
      textDecoration: 'line-through',
      opacity: 0.75,
    },
    selectionCell: {},
  }),
  { name: 'AddonsTable' }
);

export const AddonsTable: FC<AddonsTableProps> = ({
  data,
  hiddenColumns = [],
  onRowSelectionUpdate,
  selected,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const booking = useSelector(currentBookingSelector)!;
  const bookingAdmissions = useMemo(
    () => getBookingAdmissions(booking),
    [booking]
  );
  const preparedSelectedRows = useMemo(
    () =>
      selected?.reduce((selection, id) => ({ ...selection, [id]: true }), {}) ??
      {},
    [selected]
  );
  const passengerNamesMap = useMemo(
    () =>
      booking.passengers.reduce<Record<string, string>>(
        (acc, { id, firstName, lastName }) => ({
          ...acc,
          [id]: `${firstName.value} ${lastName.value}`,
        }),
        {}
      ),
    [booking.passengers]
  );
  const preparedAddons = useMemo(
    () => data ?? getTripsAncillaries(booking.bookedTrips),
    [data, booking]
  );

  const addonsMap = useMemo(
    () => _keyBy(preparedAddons, 'id'),
    [preparedAddons]
  );

  const passengersByAddonIdMap = useMemo<Record<string, string>>(
    () =>
      Object.keys(addonsMap).reduce((acc, addonId) => {
        const currentAddonAdmissionPassengers = bookingAdmissions
          .find(({ ancillaries }) =>
            ancillaries.find(({ id }) => id === addonId)
          )!
          .passengerIds.map((passengerId) => passengerNamesMap[passengerId])
          .join(', ');
        return {
          ...acc,
          [addonId]: currentAddonAdmissionPassengers,
        };
      }, {}),
    [addonsMap, bookingAdmissions, passengerNamesMap]
  );
  const columns = useMemo<TableColumns<BookingAdmissionAncillary>>(
    () => [
      {
        id: 'status',
        Header: <TransTableHead i18nKey="status" />,
        accessor: ({ status }) => <TransField i18nKey={status} />,
      },
      {
        id: 'addon',
        accessor: ({ productSummary, productDescription, status }) => (
          <span
            className={classNames({
              [classes.strikeThrough]: isAdmissionInactive({ status }),
            })}
          >
            {productSummary ?? productDescription}
          </span>
        ),
        Header: <TransTableHead i18nKey="addon" />,
        width: 'auto',
      },
      {
        id: 'passenger',
        Header: <TransTableHead i18nKey="passenger" />,
        accessor: ({ id }) => passengersByAddonIdMap[id],
      },
      {
        accessor: 'type',
        Header: <TransTableHead i18nKey="type" />,
      },
      ...(['origin', 'destination'] as const).map((place) => ({
        id: place,
        Header: <TransTableHead i18nKey={place} />,
        accessor: ({ bookedOfferId }: BookingAdmissionAncillary) => {
          const { departureTime, arrivalTime, originStop, destinationStop } =
            booking.bookedTrips.find(({ bookedOffers }) =>
              bookedOffers.find(({ id }) => id === bookedOfferId)
            )!;
          return (
            <Stack direction="row" flexWrap="wrap">
              <Typography variant="body2" sx={{ mr: 0.5 }}>
                {formatDate(
                  place === 'origin' ? departureTime : arrivalTime,
                  currentDateTimeFormat
                )}
              </Typography>
              <Typography variant="body2" fontWeight="bold">
                {(place === 'origin' ? originStop : destinationStop).name}
              </Typography>
            </Stack>
          );
        },
        width: 'auto',
      })),
      {
        id: 'changeable',
        Header: <TransTableHead i18nKey="changeable" />,
        accessor: ({ exchangeable }) => (
          <Icon
            {...(exchangeable === 'NO'
              ? {
                  name: 'deactivate',
                  size: 14,
                  color: 'error',
                }
              : {
                  name: 'check-circle',
                  size: 16,
                  color: 'success',
                })}
          />
        ),
      },
      {
        id: 'refundable',
        Header: <TransTableHead i18nKey="refundable" />,
        accessor: ({ refundable }) => (
          <Icon
            {...(refundable === 'NO'
              ? {
                  name: 'deactivate',
                  size: 14,
                  color: 'error',
                }
              : {
                  name: 'check-circle',
                  size: 16,
                  color: 'success',
                })}
          />
        ),
      },
      {
        id: 'price',
        Header: <TransTableHead i18nKey="price" />,
        accessor: ({ price }) => <PriceWithFee {...price} />,
      },
    ],
    [booking.bookedTrips, classes.strikeThrough, passengersByAddonIdMap]
  );
  const table = useTable(
    {
      data: preparedAddons,
      columns,
      getRowId: ({ id }) => id,
      initialState: {
        selectedRowIds: preparedSelectedRows,
        hiddenColumns,
      },
    },
    useRowSelect,
    useRowSelectCheckbox
  );

  const selectedAncillaryIds = useMemo(
    () => Object.keys(table.state.selectedRowIds),
    [table.state.selectedRowIds]
  );
  const selectedFulfillmentIds = useMemo(
    () =>
      selectedAncillaryIds
        .map((addonId) =>
          preparedAddons
            .find(({ id }) => id === addonId)!
            .fulfillments.map(({ id }) => id)
        )
        .flat(),
    [preparedAddons, selectedAncillaryIds]
  );
  const onAddonsDelete = useCallback(async () => {
    await Promise.all(
      selectedAncillaryIds.map((id) =>
        dispatch(
          removeAdditionalOffer({
            ancillaryId: id,
            bookedOfferId: addonsMap[id].bookedOfferId,
          })
        )
      )
    );
    setShowDeleteConfirm(false);
    table.state.selectedRowIds = {};
    await dispatch(getBooking(booking.id));
  }, [addonsMap, booking.id, dispatch, selectedAncillaryIds, table.state]);

  useEffect(() => {
    onRowSelectionUpdate?.(selectedFulfillmentIds);
  }, [onRowSelectionUpdate, selectedFulfillmentIds]);

  return (
    <Stack spacing={1} className={classes.root}>
      <Stack
        direction="row"
        className={classNames({
          [classes.hidden]: hiddenColumns?.includes('selection'),
        })}
        justifyContent="flex-end"
      >
        {booking.status !== 'PAID' && (
          <Button
            variant="text"
            className={classes.deleteBtn}
            onClick={() => setShowDeleteConfirm(true)}
            disabled={!selectedAncillaryIds?.length}
            startIcon={<Icon name="trash" />}
          >
            <TransButton i18nKey="delete" />
          </Button>
        )}
        <Popover
          className={classes.deleteConfirm}
          open={showDeleteConfirm}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          onClose={() => setShowDeleteConfirm(false)}
        >
          <Card>
            <CardHeader
              title={<TransTitle i18nKey="deleteConfirmation" />}
              titleTypographyProps={{
                variant: 'h2',
              }}
              subheader={
                <Typography mt={2}>
                  <TransParagraph i18nKey="deleteAncillariesWarning" />
                </Typography>
              }
            />
            <CardContent>
              <Stack direction="row">
                <Button
                  variant="text"
                  onClick={() => setShowDeleteConfirm(false)}
                >
                  <TransButton i18nKey="cancel" />
                </Button>
                <Button sx={{ marginLeft: 'auto' }} onClick={onAddonsDelete}>
                  <TransButton i18nKey="confirm" />
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Popover>
      </Stack>
      <Table<BookingAdmissionAncillary>
        table={table}
        classes={{ table: classes.table }}
        getCellProps={(_, { cell }) => ({
          className: classNames({
            [classes.selectionCell]: cell.column.id === 'selection',
          }),
        })}
        getRowProps={(_, { row }) => ({
          className: classNames({
            [classes.selectDisabled]: isSupplement(row.original),
          }),
        })}
      />
    </Stack>
  );
};
