import { Button, Icon, useFormContext } from '@fleet/shared';
import { Table } from '@fleet/shared/components/Table';
import { formatDate, isoDateTimeFormat } from '@fleet/shared/utils/date';
import {
  Box,
  Card,
  CardHeader as MuiCardHeader,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { DaysAfterCount } from 'components/DaysAfterCount';
import { DaysSelector } from 'components/DaysSelector';
import { EmptyResults } from 'components/EmptyResults';
import JourneyInfo from 'components/JourneyInfo';
import { LegInfo } from 'components/LegInfo';
import { Tag } from 'components/Tag';
import { addDays, startOfDay } from 'date-fns';
import { Journey, TripOffer, TripSearchParams } from 'dto/trip';
import { parse, toMinutes } from 'duration-fns';
import { searchLoadingSelector } from 'features/loading/loadingSelectors';
import {
  getJourneyAdditionalData,
  selectTripOffer,
  showTripsResultPage,
} from 'features/trip/tripActions';
import {
  additionalJourneyDataMap,
  dataLoadingJourneyReference,
  makeSelectUnconfiguredTripOffer,
  tripLinksMapSelector,
  warningsSelector,
} from 'features/trip/tripSelector';
import { TransButton } from 'i18n/trans/button';
import { TransLabel } from 'i18n/trans/label';
import { TransParagraph } from 'i18n/trans/paragraph';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTitle } from 'i18n/trans/title';
import {
  FC,
  MouseEvent,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useField } from 'react-final-form-hooks';
import { Column, Row, TableInstance, useExpanded, useTable } from 'react-table';
import { AvailabilityModal } from 'routes/tickets/searchResults/AvailabilityModal';
import { CompartmentPreferencesModal } from 'routes/tickets/searchResults/CompartmentPreferencesModal';
import { ServiceTextsModal } from 'routes/tickets/searchResults/ServiceTextsModal';
import { useDispatch, useSelector } from 'store/utils';
import {
  checkIfOfferNeedsAdditionalDataCall,
  getDuration,
  getHasJourneyNotifications,
  getOnDemandServiceTexts,
  getTimeString,
} from 'utils/trip';
import { utcToZonedTime } from 'date-fns-tz';
import { isTranslatableWarning, WarningCodes } from 'utils/common';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      overflow: 'visible',
      marginBottom: '72px',
    },
    header: {
      padding: '1rem 2rem',
    },
    tableHeader: {
      height: 48,
      padding: '0px 32px',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.divider}`,
      boxSizing: 'border-box',
    },
    hidden: {
      display: 'none',
    },
    selectTripBtn: {
      cursor: 'pointer',
      background: theme.palette.background.default,
      borderBottom: 'none',
      borderRadius: 4,
      padding: '14px 26px 14px 12px',
    },
    link: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    cell: {
      '& > div': {
        padding: '0!important',
      },
      width: 'auto',
      borderBottom: `1px solid ${theme.palette.divider}`,
      padding: '8px!important',
      '&$timesCell': {
        width: 80,
      },
      '&$controlCell': {
        width: 180,
        paddingRight: '16px!important',
      },
      '&$resplusCell': {
        paddingLeft: '0!important',
        width: 14,
      },
      '&$tagsCell': {
        width: 120,
      },
      '&$summaryCell': {
        width: 280,
      },
    },
    changes: {
      '&:empty': {
        display: 'none',
      },
      '&:before': {
        content: '"("',
      },
      '&:after': {
        content: '")"',
      },
      '& :nth-child(2):before': {
        content: '" + "',
      },
    },
    controlCell: {},
    controlCellHidden: {},
    resplusCell: {},
    summaryCell: {},
    timesCell: {},
    tagsCell: {},
    disabledRow: {
      background: theme.palette.background.default,
      cursor: 'not-allowed',
    },
    subRow: {
      position: 'relative',
    },
    subRowCell: {
      padding: 32,
      background: theme.palette.background.default,
    },
    expandBtn: {
      px: 0,
      fontSize: theme.typography.body2.fontSize,
      lineHeight: 1,
      textDecoration: 'underline',
      padding: 0,
      minWidth: 0,
    },
    legInfo: {
      marginRight: '8px',
    },
    rowExpanded: {
      '& $link': {
        '&.MuiLoadingButton-loading': {
          display: 'none',
        },
      },
    },
  }),
  { name: 'TripsTable' }
);

interface TripsTableProps {
  journeys: Array<Journey>;
  isOutbound?: boolean;
  onNextDay?: () => void;
  onPrevDay?: () => void;
  isEmpty?: boolean;
  tableRef?: MutableRefObject<TableInstance<Journey> | undefined>;
  title?: ReactNode;
  hideDaySelector?: boolean;
}

export const TripsTable: FC<TripsTableProps> = ({
  tableRef,
  isOutbound = false,
  journeys,
  title = <TransTitle i18nKey="searchResults" />,
  hideDaySelector,
}) => {
  const journeyWithDataMap = useSelector(additionalJourneyDataMap);
  const loadingJourneyDataFor = useSelector(dataLoadingJourneyReference);
  const [showNotificationsFor, setShowNotificationsFor] = useState<Journey>();
  const [showAvailabilityFor, setShowAvailabilityFor] = useState<string>();
  const unconfiguredOfferFn = makeSelectUnconfiguredTripOffer(
    isOutbound ? 'outbound' : 'inbound'
  );
  const unconfiguredOffer = useSelector(unconfiguredOfferFn);
  const fieldName = isOutbound ? 'departureTime' : 'arrivalTime';
  const loading = useSelector(searchLoadingSelector);
  const form = useFormContext<TripSearchParams>();
  const {
    input: { value: selectedDayStr },
  } = useField(fieldName, form);
  const [selectedDay, setSelectedDay] = useState(
    startOfDay(new Date(selectedDayStr))
  );
  const handleChangeDay = useCallback(
    (direction: 'prev' | 'next') => {
      const secondDateFieldName = isOutbound ? 'arrivalTime' : 'departureTime';
      const { [fieldName]: mainDate = '', [secondDateFieldName]: secondDate } =
        form.getState().values;
      const newDate = formatDate(
        addDays(selectedDay, direction === 'next' ? 1 : -1),
        isoDateTimeFormat
      );

      if (
        (isOutbound && new Date(newDate) > new Date(mainDate)) ||
        (!isOutbound && new Date(newDate) < new Date(mainDate))
      ) {
        secondDate && form.change(secondDateFieldName, newDate);
      }
      form.change(fieldName, newDate);
      form.submit();
    },
    [form, selectedDay, fieldName, isOutbound]
  );

  const classes = useStyles();
  const dispatch = useDispatch();
  const resultLinksMap = useSelector(tripLinksMapSelector);
  const warnings = useSelector(warningsSelector);
  const emptyResults = useMemo(
    () =>
      !loading &&
      !journeys.length &&
      !resultLinksMap.previous &&
      !resultLinksMap.next,
    [loading, resultLinksMap.next, resultLinksMap.previous, journeys.length]
  );

  const fastestJourneyDuration = useMemo(() => {
    const [fastestJourney] = [...journeys].sort(
      (a, b) => toMinutes(parse(a.duration)) - toMinutes(parse(b.duration))
    );
    return fastestJourney?.duration;
  }, [journeys]);
  const lowestJourneyPrice = useMemo(() => {
    const [lowestPriceJourney] = [...journeys]
      .filter(({ lowestPrice }) => lowestPrice)
      .sort((a, b) => a.lowestPrice.amount - b.lowestPrice.amount);
    return lowestPriceJourney?.lowestPrice.amount;
  }, [journeys]);
  const getJourneyLegs = useCallback(
    (journey: Journey) => journey.trips.map(({ legs }) => legs).flat(),
    []
  );
  const checkIfAvailabilityInfoPresent = useCallback(({ trips }: Journey) => {
    return trips
      .reduce<Array<TripOffer>>(
        (offers, trip) => [...offers, ...trip.offers],
        []
      )
      .some(
        (offer) =>
          offer.reservationLegCoverage.length ||
          checkIfOfferNeedsAdditionalDataCall(offer)
      );
  }, []);

  const selectOnlyOffers = useCallback(
    ({ trips, isOutbound, reference }: Journey) => {
      if (trips.every(({ offers }) => offers.length === 1)) {
        dispatch(
          selectTripOffer({
            reference,
            journeyType: isOutbound ? 'outbound' : 'inbound',
            offers: trips.reduce<Array<TripOffer>>(
              (offers, trip) => [...offers, ...trip.offers],
              []
            ),
          })
        );
      }
    },
    [dispatch]
  );

  const getClickHandler = useCallback(
    (handler: () => void) => (e: MouseEvent) => {
      e.stopPropagation();
      handler();
    },
    []
  );

  const isDepartureTimePast = useCallback(
    (departureTimeIsoString: string, timeZone: string) => {
      const now = new Date();
      const nowInTimeZone = utcToZonedTime(now, timeZone);

      return new Date(departureTimeIsoString) < nowInTimeZone;
    },
    []
  );

  const hasMissingData = useCallback(
    (journey: Journey) =>
      journey.trips.some(({ offers }) =>
        offers.some(checkIfOfferNeedsAdditionalDataCall)
      ) && !journeyWithDataMap[journey.reference],
    [journeyWithDataMap]
  );

  const handleShowAvailability = useCallback(
    async (journey: Journey) => {
      setShowAvailabilityFor(journey.reference);
      if (hasMissingData(journey)) {
        await dispatch(getJourneyAdditionalData(journey));
      }
    },
    [dispatch, hasMissingData]
  );

  useEffect(() => {
    selectedDayStr && setSelectedDay(startOfDay(new Date(selectedDayStr)));
  }, [selectedDayStr]);

  const columns = useMemo<Column<Journey>[]>(
    () => [
      {
        id: 'resplus',
        accessor: ({ alliances }) =>
          alliances?.includes('RESPLUS') ? (
            <Icon name="resplus" height={53} width={15} />
          ) : null,
      },
      {
        id: 'times',
        accessor: (row) => (
          <Stack spacing={0.5}>
            <Typography variant="subtitle">
              {getTimeString(row.departureTime)}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle">
                {getTimeString(row.arrivalTime)}
              </Typography>
              <DaysAfterCount
                startDate={row.departureTime}
                endDate={row.arrivalTime}
              />
            </Stack>
          </Stack>
        ),
      },
      {
        id: 'summary',
        accessor: (row) => (
          <Stack spacing={0.5}>
            <Typography variant="subtitle">{row.originStop.name}</Typography>
            <Typography variant="subtitle">
              {row.destinationStop.name}
            </Typography>
          </Stack>
        ),
      },
      {
        id: 'details',
        accessor: (row) => (
          <>
            <Stack direction="row" spacing={1} sx={{ mb: 0.5 }}>
              <Typography>
                {getDuration(row.departureTime, row.arrivalTime)}
              </Typography>
              <Typography
                fontWeight="600"
                color="warning.main"
                className={classes.changes}
              >
                {[
                  row.transfers && (
                    <span key="transfers">
                      <TransSubtitle
                        i18nKey="changes"
                        values={{
                          count: row.transfers,
                        }}
                        tOptions={{ postProcess: 'interval' }}
                      />
                    </span>
                  ),
                  !!getOnDemandServiceTexts(row.trips).length && (
                    <span key="onDemand">
                      <TransSubtitle i18nKey="onDemandTransport" />
                    </span>
                  ),
                ].filter(Boolean)}
              </Typography>
              {getHasJourneyNotifications(row.trips) && (
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={0.25}
                  className={classes.link}
                  onClick={getClickHandler(() => setShowNotificationsFor(row))}
                >
                  <Icon name="info-circle" />
                  <Typography>
                    <TransSubtitle i18nKey="notifications" />
                  </Typography>
                </Stack>
              )}
              {checkIfAvailabilityInfoPresent(row) && (
                <Button
                  className={classes.link}
                  onClick={getClickHandler(() => handleShowAvailability(row))}
                  loading={loadingJourneyDataFor === row.reference}
                  variant="text"
                  sx={{
                    p: 0,
                    pl: 1,
                    minWidth: 0,
                    textDecoration: 'underline',
                  }}
                >
                  <TransButton i18nKey="detailedAvailability" />
                </Button>
              )}
            </Stack>
            <Stack direction="row" rowGap={1} flexWrap="wrap">
              {getJourneyLegs(row).map((leg) => (
                <LegInfo key={leg.id} {...leg} className={classes.legInfo} />
              ))}
              {}
            </Stack>
          </>
        ),
      },
      {
        id: 'tags',
        accessor: ({ duration, lowestPrice }) => {
          const isLowestPrice =
            lowestPrice && lowestJourneyPrice === lowestPrice.amount;
          return (
            <Stack sx={{ '& > span': { alignSelf: 'flex-end' } }} spacing="4px">
              {duration === fastestJourneyDuration && (
                <Tag bold text={<TransSubtitle i18nKey="fastest" />} />
              )}
              {isLowestPrice && (
                <Tag bold text={<TransSubtitle i18nKey="lowestPrice" />} />
              )}
            </Stack>
          );
        },
      },
      {
        id: 'status',
        accessor: ({ originStop: { timezone }, departureTime }) => {
          if (!timezone) return;
          const hasDeparted = isDepartureTimePast(departureTime, timezone);

          return (
            hasDeparted && (
              <Tag
                icon={<Icon name="error-circle" color="white" />}
                bold
                color="error"
                text={<TransSubtitle i18nKey="departed" />}
              />
            )
          );
        },
      },
      {
        id: 'controls',
        accessor: (journey) => {
          if (!journey.lowestPrice) {
            const journeyTripIds = journey?.trips?.map(({ id }) => id);
            const warningCode = warnings?.find(({ detail }) =>
              journeyTripIds.includes(detail)
            )?.code as WarningCodes;

            return (
              <Typography color="warning.main" align="center" fontWeight="bold">
                <TransLabel
                  i18nKey={
                    isTranslatableWarning(warningCode)
                      ? warningCode
                      : 'notAvailable'
                  }
                />
              </Typography>
            );
          }
          return (
            <MuiCardHeader
              classes={{
                root: classes.selectTripBtn,
              }}
              title={
                <Typography color="text.primary" fontWeight="bold">
                  <TransButton i18nKey="selectTrip" />
                </Typography>
              }
              subheader={
                <Typography color="text.secondary" noWrap>
                  <TransSubtitle
                    i18nKey="priceFrom"
                    values={{
                      price: journey.lowestPrice.amount,
                      currency: journey.lowestPrice.currency,
                    }}
                  />
                </Typography>
              }
              action={<Icon name="chevron-right" color="black" size={20} />}
              onClick={() => {
                selectOnlyOffers(journey);
              }}
            />
          );
        },
      },
    ],
    [
      classes.changes,
      classes.link,
      classes.legInfo,
      classes.selectTripBtn,
      getClickHandler,
      checkIfAvailabilityInfoPresent,
      loadingJourneyDataFor,
      getJourneyLegs,
      handleShowAvailability,
      lowestJourneyPrice,
      fastestJourneyDuration,
      isDepartureTimePast,
      warnings,
      selectOnlyOffers,
    ]
  );
  const renderSubRow = useCallback(
    (row: Row<Journey>) => {
      const { reference, trips, lowestPrice } = row.original;
      return (
        <JourneyInfo
          loading={loadingJourneyDataFor === reference}
          reference={reference}
          trips={journeyWithDataMap[reference]?.trips || trips}
          isOutbound={isOutbound}
          showOffers={!!lowestPrice}
          showServiceTexts
        />
      );
    },
    [isOutbound, journeyWithDataMap, loadingJourneyDataFor]
  );

  const table = useTable<Journey>(
    {
      data: useMemo(() => journeys, [journeys]),
      columns,
    },
    useExpanded
  );

  const rowPropsGetter = useCallback(
    (_, { row }: { row: Row<Journey> }) => ({
      className: classNames({
        [classes.rowExpanded]: row.isExpanded,
      }),
      onClick: async () => {
        !row.isExpanded &&
          hasMissingData(row.original) &&
          (await dispatch(getJourneyAdditionalData(row.original)));
        row.toggleRowExpanded(!row.isExpanded);
      },
    }),
    [hasMissingData, classes.rowExpanded, dispatch]
  );

  useEffect(() => {
    if (tableRef) {
      tableRef.current = table;
    }
  }, [table, tableRef]);

  //Set the selected day only once so that dates in the result won't change when customer changes search date fields
  useEffect(() => {
    if (loading) {
      setSelectedDay(new Date(selectedDayStr));
    }
  }, [loading, selectedDayStr]);

  if (emptyResults)
    return (
      <Card sx={{ overflow: 'visible', marginBottom: '72px' }}>
        <Box className={classes.header}>
          <Typography variant="h1">
            <TransTitle i18nKey="searchResults" />
          </Typography>
        </Box>
        <Divider />
        <EmptyResults
          isOutbound={isOutbound}
          form={form}
          message={<TransParagraph i18nKey="emptyTicketResults" />}
        />
      </Card>
    );

  return (
    <Card sx={{ overflow: 'visible', marginBottom: '72px' }}>
      {isOutbound && (
        <>
          <Box className={classes.header}>
            <Typography variant="h1">{title}</Typography>
          </Box>
          <Divider />
        </>
      )}
      <Stack direction="row" className={classes.tableHeader} spacing={2}>
        <Typography variant="subtitle">
          <TransSubtitle i18nKey={isOutbound ? 'outbound' : 'inbound'} />
        </Typography>
        <Typography variant="body2" color="secondary.main">
          <TransSubtitle
            i18nKey="journeysFound"
            values={{ num: journeys.length }}
          />
        </Typography>
        {!hideDaySelector && (
          <DaysSelector value={selectedDay} onChange={handleChangeDay} />
        )}
      </Stack>

      <Table
        table={table}
        getSubRow={renderSubRow}
        getSubRowProps={{
          className: classes.subRow,
        }}
        getHeaderGroupProps={{
          className: classes.hidden,
        }}
        getRowProps={rowPropsGetter}
        getCellProps={(_, { cell }) => ({
          className: classNames(classes.cell, {
            [classes.resplusCell]: cell.column.id === 'resplus',
            [classes.controlCell]: cell.column.id === 'controls',
            [classes.hidden]: cell.column.id === 'tags',
            [classes.summaryCell]: cell.column.id === 'summary',
            [classes.timesCell]: cell.column.id === 'times',
          }),
        })}
        getSubRowCellProps={{
          className: classes.subRowCell,
        }}
      />

      <Stack
        direction="row"
        justifyContent="center"
        p={1}
        component={Card}
        spacing={3}
      >
        <Button
          onClick={() =>
            dispatch(
              showTripsResultPage({
                page: resultLinksMap.previous.rel,
                journeyType: isOutbound ? 'outbound' : 'inbound',
              })
            )
          }
          variant="text"
          className={classes.expandBtn}
          startIcon={<Icon name="chevron-left" />}
          disabled={!resultLinksMap.previous}
        >
          <TransButton i18nKey="prev" />
        </Button>
        <Button
          onClick={() =>
            dispatch(
              showTripsResultPage({
                page: resultLinksMap.next.rel,
                journeyType: isOutbound ? 'outbound' : 'inbound',
              })
            )
          }
          variant="text"
          className={classes.expandBtn}
          endIcon={<Icon name="chevron-right" />}
          disabled={!resultLinksMap.next}
        >
          <TransButton i18nKey="next" />
        </Button>
      </Stack>

      {showNotificationsFor && (
        <ServiceTextsModal
          journey={showNotificationsFor}
          onClose={() => setShowNotificationsFor(undefined)}
        />
      )}
      {unconfiguredOffer && (
        <CompartmentPreferencesModal offer={unconfiguredOffer} />
      )}
      {showAvailabilityFor && (
        <AvailabilityModal
          journey={
            journeyWithDataMap[showAvailabilityFor] ||
            journeys.find(({ reference }) => reference === showAvailabilityFor)!
          }
          onClose={() => setShowAvailabilityFor(undefined)}
        />
      )}
    </Card>
  );
};
