import { RadioGroup } from '@fleet/shared/mui';
import { Typography } from '@mui/material';
import { TransLabel } from 'i18n/trans/label';
import { TransTitle } from 'i18n/trans/title';
import { Dispatch, FC, SetStateAction } from 'react';
import { IS_IMS_AT } from 'utils/flags';

interface PaymentMethodProps {
  paymentMethod?: PaymentMethod;
  setPaymentMethod: Dispatch<SetStateAction<PaymentMethod | undefined>>;
}

export enum PaymentMethod {
  external = 'SALES_POINT_PAYMENT_TYPE.EXTERNAL_3RD_PARTY',
  adyen = 'SALES_POINT_PAYMENT_TYPE.ADYEN_PAY_BY_LINK',
}

export const PaymentMethods: FC<PaymentMethodProps> = ({
  paymentMethod,
  setPaymentMethod,
}) => {
  return (
    <>
      <Typography variant="h2">
        <TransTitle i18nKey="paymentMethod" />
      </Typography>
      <RadioGroup
        inline
        options={[
          {
            value: PaymentMethod.external,
            label: <TransLabel i18nKey="external" />,
          },
          ...(IS_IMS_AT
            ? [
                {
                  value: PaymentMethod.adyen,
                  label: <TransLabel i18nKey="payByLink" />,
                },
              ]
            : []),
        ]}
        onChange={(val) => setPaymentMethod(val as PaymentMethod)}
        defaultValue={paymentMethod}
      />
    </>
  );
};
