import {
  Button,
  CheckboxField,
  Field,
  Icon,
  TextField,
  useFormContext,
} from '@fleet/shared';

import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import {
  AdditionalRecipientsAddBtn,
  AdditionalRecipientsSelector,
} from 'components/ticketFulfillment/AdditionalRecipientsSelector';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransParagraph } from 'i18n/trans/paragraph';
import React, { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'store/utils';
import { useValidatePhoneNumber } from 'utils/overview';

interface TicketFulfillmentSelectionProps {
  type: 'sms' | 'email';
}

const useStyles = makeStyles(
  (theme) => ({
    noShrink: {
      flexShrink: 0,
    },
    actionBtn: {
      width: '2rem',
      height: '2rem',
    },
    selectionControl: {
      minWidth: 'none',
      fontWeight: 'bold',
      color: theme.palette.text.secondary,
      padding: 0,
    },
    selectionRow: {
      '& .MuiFormControl-root > .MuiFormHelperText-root': {
        position: 'absolute',
        top: '100%',
      },
    },
    error: {
      fontSize: '0.75rem',
      color: theme.palette.error.main,
    },
    fulfillmentDestination: {
      '&:empty': {
        display: 'none',
      },
    },
    passengerRow: {
      '& .MuiCheckbox-root + p': {
        flex: 1,
      },
    },
  }),
  { name: 'TicketFulfillmentSelection' }
);

export const TicketFulfillmentSelection: FC<TicketFulfillmentSelectionProps> =
  ({ type }) => {
    const validatePhoneNumber = useValidatePhoneNumber();
    const classes = useStyles();
    const form = useFormContext();
    const { passengers } = useSelector(currentBookingSelector)!;
    const [activeOverridePassengerId, setActiveOverridePassengerId] =
      useState<string>();
    const isEmailSelection = type === 'email';
    const selectionField = isEmailSelection ? 'sendEmail' : 'sendSms';
    const overrideExtraField = useMemo(
      () => (isEmailSelection ? 'emailOverride' : 'phoneNumberOverride'),
      [isEmailSelection]
    );
    const overridePassengerField = useMemo(
      () =>
        isEmailSelection ? 'currentEmailOverride' : 'currentPhoneOverride',
      [isEmailSelection]
    );

    const toggleEdit = useCallback(
      (passengerId: string) => {
        const passengerIdx = passengers.findIndex(
          ({ id }) => id === passengerId
        );
        if (!activeOverridePassengerId) {
          setActiveOverridePassengerId(passengerId);
        } else if (
          form.getFieldState(
            [`passengers[${passengerIdx}]`, overridePassengerField].join('.')
          )?.invalid
        ) {
        } else {
          setActiveOverridePassengerId(undefined);
        }
      },
      [activeOverridePassengerId, form, overridePassengerField, passengers]
    );

    const toggleSelectAll = useCallback(
      (shouldSelectAll?: boolean) => {
        form.batch(() => {
          passengers.forEach((_, idx) =>
            form.change(
              [`passengers[${idx}]`, selectionField].join('.'),
              !!shouldSelectAll
            )
          );
        });
      },
      [form, passengers, selectionField]
    );

    return (
      <>
        <Stack gap={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle" color="primary.main">
              <TransButton i18nKey="sendTicketsToPassengers" />
            </Typography>
            <Stack direction="row" spacing={1}>
              <Button
                variant="text"
                className={classes.selectionControl}
                onClick={() => toggleSelectAll(true)}
              >
                <TransButton i18nKey="selectAll" />
              </Button>
              <Button
                variant="text"
                className={classes.selectionControl}
                onClick={() => toggleSelectAll()}
              >
                <TransButton i18nKey="none" />
              </Button>
            </Stack>
          </Stack>
          {passengers.map(
            (
              {
                id,
                firstName,
                lastName,
                contactInformation: { emailAddress, phoneNumber },
              },
              idx
            ) => {
              const isEditActive = id === activeOverridePassengerId;
              const selectionFieldPath = [
                `passengers[${idx}]`,
                type === 'email' ? 'sendEmail' : 'sendSms',
              ].join('.');
              const currentOverridePath = [
                `passengers[${idx}]`,
                overridePassengerField,
              ].join('.');
              const currentExtraOverridePath = [
                `passengers[${idx}]`,
                overrideExtraField,
              ].join('.');

              return (
                <Field
                  name={currentOverridePath}
                  render={({ input }) => {
                    const currentValue = isEmailSelection
                      ? emailAddress.value
                      : phoneNumber.value;
                    const overrideValue = input.value;
                    const missingPhoneNumber =
                      type === 'sms' && !phoneNumber.value && !overrideValue;
                    const fulfillmentDestination =
                      overrideValue || currentValue;
                    return (
                      <Fragment key={idx}>
                        <Stack direction="row" className={classes.selectionRow}>
                          <CheckboxField
                            disabled={missingPhoneNumber || isEditActive}
                            name={selectionFieldPath}
                            label={
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                className={classes.passengerRow}
                              >
                                <span
                                  className={classes.noShrink}
                                >{`${firstName.value} ${lastName.value}`}</span>
                                {isEditActive ? (
                                  <TextField
                                    name={currentOverridePath}
                                    {...(type === 'email'
                                      ? { email: true }
                                      : { validate: validatePhoneNumber })}
                                  />
                                ) : (
                                  <span
                                    className={classes.fulfillmentDestination}
                                  >
                                    {fulfillmentDestination
                                      ? `(${fulfillmentDestination})`
                                      : undefined}
                                  </span>
                                )}
                              </Stack>
                            }
                          />
                          <IconButton
                            className={classNames(
                              classes.actionBtn,
                              classes.noShrink
                            )}
                            onClick={() => toggleEdit(id)}
                          >
                            <Icon name={isEditActive ? 'check' : 'edit'} />
                          </IconButton>
                          <AdditionalRecipientsAddBtn
                            name={currentExtraOverridePath}
                          />
                          {isEditActive && (
                            <IconButton
                              className={classNames(
                                classes.actionBtn,
                                classes.noShrink
                              )}
                              onClick={() => {
                                form.change(currentOverridePath, undefined);
                                !isEmailSelection &&
                                  form.change(selectionFieldPath, false);
                                toggleEdit(id);
                              }}
                            >
                              <Icon name="close" />
                            </IconButton>
                          )}
                        </Stack>
                        {missingPhoneNumber && (
                          <span className={classes.error}>
                            <TransParagraph i18nKey="missingPhoneTicketFulfillment" />
                          </span>
                        )}
                        <Divider />
                        <Stack pl={4} spacing={2}>
                          <AdditionalRecipientsSelector
                            name={currentExtraOverridePath}
                            type={type}
                          />
                        </Stack>
                      </Fragment>
                    );
                  }}
                />
              );
            }
          )}
        </Stack>
      </>
    );
  };
