import { Icon, Loadable, useFormContext, useModal } from '@fleet/shared';
import { Button } from '@fleet/shared/mui';
import { alpha } from '@mui/material/styles';
import { CartTotal } from 'components/CartTotal';
import { PassengerSpecification, PromoCode } from 'dto/trip';
import {
  addOfferToBooking,
  getAdditionalOffers,
  getBooking,
  postBooking,
} from 'features/booking/bookingActions';
import {
  bookingLoadingSelector,
  currentBookingSelector,
} from 'features/booking/bookingSelectors';
import { searchLoadingSelector } from 'features/loading/loadingSelectors';
import { activeTabSelector } from 'features/tabs/tabsSelector';
import { resetSearch } from 'features/trip/tripActions';
import {
  selectSelectedOffers,
  tripsSelector,
} from 'features/trip/tripSelector';
import { useTripSearch } from 'hooks/useTripSearch';
import { TransButton } from 'i18n/trans/button';
import { FC, useCallback, useMemo } from 'react';
import { useField } from 'react-final-form-hooks';
import { useHistory } from 'react-router-dom';
import { TripsTable } from 'routes/tickets/searchResults/TripsTable';
import { useDispatch, useSelector } from 'store/utils';
import { createOfferPayloads, getBookingDestinations } from 'utils/trip';
import { renderToString } from 'react-dom/server';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { SelectPassengersModal } from 'components/SelectPassengersModal';
import { v4 } from 'uuid';
import { IS_IMS_AT } from 'utils/flags';
import { updateTab } from 'features/tabs/tabsActions';

interface TripSearchResultsProps {}

export const TripSearchResults: FC<TripSearchResultsProps> = () => {
  const dispatch = useDispatch();
  const currentTab = useSelector(activeTabSelector);
  const history = useHistory();
  const form = useFormContext();
  const loading = useSelector(searchLoadingSelector);
  const {
    input: { value: passengerSpecificationsInput },
  } = useField<Array<PassengerSpecification>>('passengerSpecifications', form);
  const {
    input: { value: promotionCodes },
  } = useField<Array<PromoCode>>('promotionCodes', form);
  const tripResults = useSelector(tripsSelector);
  const postBookingLoading = useSelector(bookingLoadingSelector);
  const booking = useSelector(currentBookingSelector);
  const { inbound: selectedInboundOffers, outbound: selectedOutboundOffers } =
    useSelector(selectSelectedOffers);
  const { offersTotal, offersSelectionIncomplete } = useTripSearch();
  const shouldShowCartTotal =
    !!offersTotal?.currency ||
    booking?.provisionalPrice ||
    currentTab?.addingJourneysBeforeCheckout;
  const { open, onOpen, onClose } = useModal();
  const isRoundTrip = !!tripResults.inboundTrips.length;
  const hasSelectedAnyOffer = isRoundTrip
    ? !!selectedInboundOffers.trips.length &&
      !!selectedOutboundOffers.trips.length
    : !!selectedOutboundOffers.trips.length;
  const shownTotal = useMemo(() => {
    return currentTab?.addingJourneysBeforeCheckout && booking?.provisionalPrice
      ? {
          ...offersTotal,
          amount: booking.provisionalPrice.amount + offersTotal.amount,
        }
      : offersTotal;
  }, [booking, currentTab?.addingJourneysBeforeCheckout, offersTotal]);
  const postBookingHandler = useCallback(async () => {
    if (
      !hasSelectedAnyOffer &&
      currentTab?.bookingId &&
      !currentTab.addingJourneysBeforeCheckout
    ) {
      await dispatch(getBooking(booking!.id)).unwrap();
      await dispatch(getAdditionalOffers()).unwrap();
      return history.push('/search/checkout');
    }

    if (!hasSelectedAnyOffer && currentTab?.addingJourneysBeforeCheckout) {
      await dispatch(getBooking(booking!.id)).unwrap();
      await dispatch(getAdditionalOffers()).unwrap();
      dispatch(
        updateTab({
          activeStep: currentTab?.lastActiveStep || 0,
          addingAdditionalJourneyToCurrentPassenger: false,
        })
      );
      return history.push('/search/checkout');
    }

    let passengerSpecifications = [...passengerSpecificationsInput];

    if (
      currentTab?.addingJourneysBeforeCheckout &&
      !currentTab?.addingAdditionalJourneyToCurrentPassenger &&
      currentTab.activeStep === -1
    ) {
      const existingReferences = new Set(
        booking?.passengers.map((p) => p.externalReference) || []
      );
      passengerSpecifications = passengerSpecifications.filter(
        (p) => !existingReferences.has(p.externalReference)
      );
    }

    const payload = createOfferPayloads(
      tripResults,
      selectedOutboundOffers,
      selectedInboundOffers,
      passengerSpecifications,
      promotionCodes
    );

    let tabParams;
    if (currentTab?.addingJourneysBeforeCheckout) {
      tabParams = {
        activeStep: 0,
        addingAdditionalJourneyToCurrentPassenger: false,
        name: renderToString(<TransSubtitle i18nKey="multipleJourneys" />),
      };
      await dispatch(
        addOfferToBooking({
          bookingId: currentTab.bookingId!,
          ...payload,
        })
      ).unwrap();
      await dispatch(getBooking(booking!.id)).unwrap();
    } else {
      const newBooking = await dispatch(postBooking(payload)).unwrap();
      tabParams = {
        name: getBookingDestinations(newBooking).join(' - '),
        bookingId: newBooking.id,
        activeStep: 0,
      };
    }
    await dispatch(getAdditionalOffers());
    dispatch(updateTab(tabParams));
    dispatch(resetSearch());
    history.push('/search/checkout');
  }, [
    hasSelectedAnyOffer,
    selectedInboundOffers,
    selectedOutboundOffers,
    promotionCodes,
    tripResults,
    passengerSpecificationsInput,
    currentTab,
    history,
    dispatch,
    booking,
  ]);

  const handleOnPassengerSelectionModalSubmit = useCallback(
    async (
      selectedOptions: Array<string>,
      isAddingToCurrentPassenger: boolean
    ) => {
      const passengerSpecifications =
        currentTab?.params?.passengerSpecifications;
      if (!passengerSpecifications) {
        return null;
      }
      const isSelectedPassengerInBooking = booking?.passengers.some((p) =>
        selectedOptions.some((s) => p.externalReference === s)
      );

      if (!!booking?.passengers.length && isSelectedPassengerInBooking) {
        const selectedSpecification = booking.passengers
          .filter((passenger) =>
            selectedOptions.includes(passenger.externalReference)
          )
          .map((passenger) => ({
            type: 'PERSON',
            externalReference: passenger.externalReference,
            cards: passenger.cards,
            age: passenger.age,
            prmNeeds: passenger.prmNeeds.value,
          }));

        form.change('passengerSpecifications', selectedSpecification);
      }

      const payload = createOfferPayloads(
        tripResults,
        selectedOutboundOffers,
        selectedInboundOffers,
        passengerSpecifications,
        promotionCodes
      );

      if (currentTab?.bookingId) {
        try {
          await dispatch(
            addOfferToBooking({
              bookingId: currentTab.bookingId!,
              ...payload,
            })
          ).unwrap();
          await dispatch(getBooking(currentTab.bookingId!)).unwrap();
          dispatch(
            updateTab({
              addingAdditionalJourneyToCurrentPassenger:
                isAddingToCurrentPassenger,
              addingJourneysBeforeCheckout: true,
              name: renderToString(
                <TransSubtitle i18nKey="multipleJourneys" />
              ),
              params: form.getState().values,
            })
          );
        } catch (e) {}
      } else {
        const booking = await dispatch(postBooking(payload)).unwrap();
        dispatch(
          updateTab({
            bookingId: booking.id,
            addingAdditionalJourneyToCurrentPassenger:
              isAddingToCurrentPassenger,
            addingJourneysBeforeCheckout: true,
            name: getBookingDestinations(booking).join(' - '),
            params: form.getState().values,
          })
        );
      }

      dispatch(resetSearch());
      form.reset({
        passengerSpecifications: isAddingToCurrentPassenger
          ? form.getState().values.passengerSpecifications
          : [
              {
                type: 'PERSON',
                externalReference: v4(),
              },
            ],
        departureTime: new Date().toISOString(),
        promotionCodes: [],
        corporateCodes: [],
      });
      history.replace('/search');
      onClose();
    },
    [
      selectedOutboundOffers,
      selectedInboundOffers,
      promotionCodes,
      history,
      onClose,
      currentTab?.params,
      currentTab.bookingId,
      dispatch,
      tripResults,
      booking,
      form,
    ]
  );

  return (
    <Loadable loading={loading}>
      <TripsTable journeys={tripResults.outboundTrips} isOutbound />
      {currentTab?.params?.arrivalTime && (
        <TripsTable journeys={tripResults.inboundTrips} />
      )}

      {shouldShowCartTotal && (
        <CartTotal offersTotal={shownTotal} isDark>
          {hasSelectedAnyOffer && IS_IMS_AT && (
            <>
              <Button
                startIcon={<Icon name="plus" />}
                sx={{ color: 'white' }}
                variant="text"
                onClick={onOpen}
              >
                <TransButton i18nKey="addAnotherJourney" />
              </Button>
              {open && (
                <SelectPassengersModal
                  open={open}
                  onClose={onClose}
                  onSubmit={handleOnPassengerSelectionModalSubmit}
                />
              )}
            </>
          )}
          <Button
            loading={postBookingLoading}
            disabled={
              offersSelectionIncomplete &&
              !currentTab?.addingJourneysBeforeCheckout &&
              !currentTab?.bookingId
            }
            sx={{
              background: 'white!important',
              '&:hover': {
                boxShadow: (theme) =>
                  [
                    alpha(theme.palette.action.hover, 0.2),
                    theme.palette.common.white,
                  ]
                    .map((color) => `inset 0 0 0 2rem ${color}`)
                    .join(','),
              },
            }}
            variant="outlined"
            label={<TransButton i18nKey="continueCheckout" />}
            onClick={postBookingHandler}
          />
        </CartTotal>
      )}
    </Loadable>
  );
};
