import { ClassificationGroup } from 'dto/classification';
import { useSelector } from 'store/utils';
import { classificationsSelector } from 'features/classification/classificationSelectors';
import { useMemo } from 'react';

export const useClassificationOptions = (groupName: ClassificationGroup) => {
  const classifications = useSelector(classificationsSelector);
  return useMemo(
    () =>
      (classifications[groupName] ?? []).map(({ id, name, ...rest }) => ({
        value: id,
        label: name,
        ...rest,
      })),
    [classifications, groupName]
  );
};
