import {
  Button,
  DateField,
  FieldArray,
  FormProvider,
  Icon,
  ReadOnlyField,
  TextField,
  useForm,
} from '@fleet/shared';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CartTotal } from 'components/CartTotal';
import { HintWrapper } from 'components/HintWrapper';
import { JourneySummaries } from 'components/JourneySummaries';
import { Tag, TagGroup } from 'components/Tag';
import { BookingAdmission } from 'dto/booking';
import { getBooking } from 'features/booking/bookingActions';
import {
  bookingExpiredSelector,
  currentBookingSelector,
} from 'features/booking/bookingSelectors';
import { passengerUpdateLoading } from 'features/loading/loadingSelectors';
import { updatePassengersDetails } from 'features/trip/tripActions';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransLabel } from 'i18n/trans/label';
import { TransParagraph } from 'i18n/trans/paragraph';
import { TransTitle } from 'i18n/trans/title';
import _isEqual from 'lodash/isEqual';
import _isNumber from 'lodash/isNumber';
import _pickBy from 'lodash/pickBy';
import _uniq from 'lodash/uniq';
import { FC, Fragment, ReactNode, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { useValidatePhoneNumber } from 'utils/overview';
import { getBookingAdmissions, getPassengerJourneys } from 'utils/trip';

interface PassengerDetailsProps {
  goToNextStep: () => void;
  submitLabel: ReactNode;
}

interface PassengerData {
  passengerId: string;
  externalReference: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  email: string;
  phone: string;
}

const useStyles = makeStyles(
  (theme) => ({
    copyBtn: {
      color: theme.palette.primary.main,
      background: theme.palette.action.hover,
      '&:hover': {
        background: theme.palette.action.hover,
      },
      '&:disabled': {
        color: '#838895',
        background: theme.palette.background.default,
      },
    },
  }),
  { name: 'PassengerDetails' }
);

interface PassengersForm {
  passengers: Array<Partial<PassengerData>>;
}
export const PassengerDetails: FC<PassengerDetailsProps> = ({
  goToNextStep,
  submitLabel,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const booking = useSelector(currentBookingSelector);
  const bookingAdmissions = useMemo(
    () => getBookingAdmissions(booking),
    [booking]
  );
  const isBookingExpired = useSelector(bookingExpiredSelector);
  const loading = useSelector(passengerUpdateLoading);
  // const genderOptions = useMemo(
  //   () =>
  //     Object.values(GenderOptions).map((key) => ({
  //       label: renderToString(<TransLabel i18nKey={key} />),
  //       value: key,
  //     })),
  //   []
  // );
  const getPassengerReductions = useCallback(
    (passengerId: string) => {
      const passengerAdmissions = bookingAdmissions.filter(({ passengerIds }) =>
        passengerIds.includes(passengerId)
      );
      return _uniq(
        passengerAdmissions.reduce<BookingAdmission['appliedReductions']>(
          (acc, { appliedReductions }) => [
            ...acc,
            ...appliedReductions.filter(
              ({ passengerRef }) =>
                !passengerRef || passengerRef === passengerId
            ),
          ],
          []
        )
      );
    },
    [bookingAdmissions]
  );
  const initialValues = useMemo<PassengersForm>(
    () => ({
      passengers:
        booking?.passengers.map(
          ({
            id,
            externalReference,
            firstName,
            lastName,
            birthDate,
            contactInformation,
          }) => ({
            passengerId: id,
            externalReference,
            firstName: firstName.value,
            lastName: lastName.value,
            birthDate: birthDate,
            email: contactInformation.emailAddress.value,
            phone: contactInformation.phoneNumber.value,
          })
        ) ?? [],
    }),
    [booking?.passengers]
  );
  const validatePhoneNumber = useValidatePhoneNumber();
  const passengerJourneys = useMemo(() => {
    return getPassengerJourneys(booking);
  }, [booking]);

  const onSubmit = useCallback(
    async ({ passengers }: PassengersForm) => {
      const changedPassengers = passengers.filter(
        (passenger, idx) => !_isEqual(passenger, initialValues.passengers[idx])
      );

      try {
        goToNextStep();

        if (changedPassengers.length) {
          await dispatch(
            updatePassengersDetails(
              changedPassengers.map(({ phone, ...passenger }) => ({
                ..._pickBy(passenger),
                bookingId: booking!.id,
                phone: {
                  number: phone!,
                },
              }))
            )
          ).unwrap();
          await dispatch(getBooking(booking!.id)).unwrap();
        }
      } catch (e) {}
    },
    [booking, dispatch, goToNextStep, initialValues.passengers]
  );

  const { form, handleSubmit, values } = useForm<PassengersForm>({
    subscription: { invalid: true, values: true },
    initialValues,
    onSubmit,
  });

  const clearFields = useCallback(() => {
    const { getState, restart } = form;
    restart({
      passengers: getState().values.passengers.map(
        ({ externalReference, passengerId }) => ({
          externalReference,
          passengerId,
        })
      ),
    });
  }, [form]);

  const copyFieldsToNextPassenger = useCallback(
    (passengerIdx: number) => {
      const passengerToCopy = values.passengers[passengerIdx];
      const idxOffset = passengerIdx + 1;
      const nextPassengers = values.passengers.slice(idxOffset);
      form.batch(() => {
        nextPassengers.forEach((passenger, idx) => {
          // @ts-ignore
          form.change<PassengerData>(`passengers[${idx + idxOffset}]`, {
            ...passengerToCopy,
            ..._pickBy(passenger, Boolean),
          });
        });
      });
    },
    [form, values.passengers]
  );

  const getGroupedAppliedPassengerTypes = (
    bookingAdmissions: BookingAdmission[]
  ): { [key: string]: string[] } => {
    const groupedTypes: { [key: string]: string[] } = {};
    bookingAdmissions.forEach((admission) => {
      admission.appliedPassengerTypes.forEach((type) => {
        const ref = type.passengerRef;
        if (!groupedTypes[ref]) {
          groupedTypes[ref] = [];
        }
        if (!groupedTypes[ref].includes(type.description)) {
          groupedTypes[ref].push(type.description);
        }
      });
    });
    return groupedTypes;
  };

  const isPassengerDataFilled = useCallback(
    (passengerIdx: number) => {
      const { firstName, lastName, email } = values.passengers[passengerIdx];
      return [firstName, lastName, email].every(Boolean);
    },
    [values]
  );
  const groupedAppliedPassengerTypes = useMemo(
    () => getGroupedAppliedPassengerTypes(bookingAdmissions),
    [bookingAdmissions]
  );
  return (
    <>
      <FormProvider form={form}>
        <Typography variant="h1">
          <TransTitle i18nKey="passengerDetails" />
        </Typography>
        <form onSubmit={handleSubmit} id="passengerDetails">
          <FieldArray name="passengers">
            {({ fields }) =>
              fields.value?.map((_, idx) => {
                const {
                  id,
                  contactInformation: { emailAddress, phoneNumber },
                  firstName,
                  lastName,
                  age,
                  prmNeeds,
                } = booking!.passengers[idx];
                const passengerSpecificJourneys = passengerJourneys[id] || [];
                const appliedPassengerType = groupedAppliedPassengerTypes[id];
                const appliedPassengerTypeText =
                  appliedPassengerType?.join(', ');
                const passengersQty = booking!.passengers.length;
                const passengerReductions = getPassengerReductions(id);
                return (
                  <Fragment key={idx}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={0.5}
                      py={1}
                    >
                      <Typography variant="h2" mr={1}>
                        {`${idx + 1}/${fields.value.length}`}
                      </Typography>
                      {_isNumber(age) && (
                        <Tag
                          variant="body2"
                          title={<TransLabel i18nKey="age" />}
                          text={age}
                        />
                      )}
                      {appliedPassengerTypeText && (
                        <Tag
                          variant="body2"
                          color="icons"
                          subColor="hover"
                          title={<TransLabel i18nKey="passengerType" />}
                          text={appliedPassengerTypeText}
                        />
                      )}
                      {passengerReductions.map(({ type, name, code }) => (
                        <TagGroup
                          key={name}
                          title={<TransLabel i18nKey={type} />}
                          texts={[name ?? code]}
                          color="success"
                          subColor="main"
                          variant="body2"
                        />
                      ))}
                      <TagGroup
                        title={<TransLabel i18nKey="prmNeed" />}
                        texts={prmNeeds?.value?.map((code) => (
                          <TransLabel i18nKey={code} />
                        ))}
                        color="action"
                        subColor="hoverText"
                        variant="body2"
                      />
                    </Stack>
                    <Box mb={3}>
                      {passengerSpecificJourneys.map((trip) => (
                        <JourneySummaries
                          passengerSpecificTrip={trip}
                          key={trip.id}
                        />
                      ))}
                    </Box>
                    <Grid container columns={4} spacing={2} rowSpacing={2}>
                      <Grid item xs={1}>
                        <TextField
                          required={firstName.isRequired}
                          name={`${fields.name}[${idx}].firstName`}
                          label={<TransField i18nKey="passengerNameFirst" />}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <TextField
                          required={lastName.isRequired}
                          name={`${fields.name}[${idx}].lastName`}
                          label={<TransField i18nKey="passengerNameLast" />}
                        />
                      </Grid>
                      {/*BR-47181 - hide gender temporarily*/}
                      {/*<Grid item xs={1}>*/}
                      {/*  <SelectField*/}
                      {/*    showEmptyOption*/}
                      {/*    required={gender.isRequired}*/}
                      {/*    name={`${fields.name}[${idx}].gender`}*/}
                      {/*    label={<TransField i18nKey="gender" />}*/}
                      {/*    options={genderOptions}*/}
                      {/*  />*/}
                      {/*</Grid>*/}
                      <Grid item xs={1}>
                        <DateField
                          yearDropdownItemNumber={100}
                          name={`${fields.name}[${idx}].birthDate`}
                          label={<TransField i18nKey="birthday" />}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <TextField
                          required={emailAddress.isRequired}
                          email
                          name={`${fields.name}[${idx}].email`}
                          label={<TransField i18nKey="email" />}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <TextField
                          placeholder="+"
                          required={phoneNumber.isRequired}
                          validate={validatePhoneNumber}
                          name={`${fields.name}[${idx}].phone`}
                          label={<TransField i18nKey="mobileNumber" />}
                        />
                      </Grid>
                      {passengersQty > 1 && idx !== passengersQty - 1 && (
                        <Grid item xs={1}>
                          <ReadOnlyField
                            label="&nbsp;"
                            contentComponent="div"
                            value={
                              <Stack
                                direction="row"
                                justifyContent="flex-end"
                                width="100%"
                              >
                                <HintWrapper
                                  iconPlacement="start"
                                  hint={
                                    <TransParagraph i18nKey="copyPassengerDataHint" />
                                  }
                                />
                                <Button
                                  variant="text"
                                  className={classes.copyBtn}
                                  onClick={() => copyFieldsToNextPassenger(idx)}
                                  disabled={!isPassengerDataFilled(idx)}
                                  startIcon={<Icon name="clone" />}
                                  label={
                                    <TransButton i18nKey="copyPassengerDataBelow" />
                                  }
                                />
                              </Stack>
                            }
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Fragment>
                );
              })
            }
          </FieldArray>
        </form>
      </FormProvider>
      <CartTotal>
        <>
          <Button
            variant="text"
            onClick={clearFields}
            disabled={isBookingExpired}
            label={<TransButton i18nKey="clearFields" />}
          />
          <Button
            variant="contained"
            type="submit"
            form="passengerDetails"
            loading={loading}
            disabled={isBookingExpired}
            label={
              <>
                <Icon name="arrow-right" sx={{ mr: 1 }} />
                {submitLabel}
              </>
            }
          />
        </>
      </CartTotal>
    </>
  );
};
