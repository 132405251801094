import { alpha } from '@mui/material/styles';
import { Typography, Stack } from '@mui/material';
import { Tooltip } from '@fleet/shared/mui';
import { Icon } from '@fleet/shared';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { TransLabel } from 'i18n/trans/label';
import { FC, ReactNode, useCallback, useMemo } from 'react';
import { TransportType } from 'dto/trip';
import { renderToString } from 'react-dom/server';
import _capitalize from 'lodash/capitalize';
import _startCase from 'lodash/startCase';

interface TransportBadgeProps {
  ptMode: {
    code: TransportType;
    name: string;
  };
  label?: ReactNode;
  small?: boolean;
  rounded?: boolean;
}

const useStyles = makeStyles(
  () => ({
    root: {
      width: 'fit-content',
      padding: '0.25rem 0.5rem',
      '& .Icon-root': {
        marginTop: '0.125rem',
        flexShrink: 0,
      },
    },
    small: {
      padding: '0 0.5rem',
    },
    rounded: {
      borderRadius: '0.1875rem',
    },
  }),
  { name: 'TransportBadge' }
);

export const TransportBadge: FC<TransportBadgeProps> = ({
  ptMode,
  label,
  rounded = true,
  small,
}) => {
  const classes = useStyles();
  const badgeBgBaseColor = useMemo(() => {
    switch (ptMode?.code) {
      case TransportType.HIGH_SPEED_TRAIN:
      case TransportType.INTERCITY_REGIONAL:
      case TransportType.LOCAL_TRAIN:
        return '#4B00FF';
      case TransportType.BUS:
      case TransportType.LOCAL_BUS:
      case TransportType.LONG_DISTANCE:
        return '#FF6B00';
      case TransportType.AIR_TRAFFIC:
        return '#0019FD';
      case TransportType.SUBWAY:
      case TransportType.TRAM:
      case TransportType.TRAMS:
      case TransportType.UNDERGROUND:
        return '#FF0000';
      case TransportType.FERRY:
        return '#00F0FF';
      case TransportType.TAXI:
      case TransportType.SHARED_TAXI:
        return '#FAFF00';
      default: {
        return '#4B00FF';
      }
    }
  }, [ptMode?.code]);
  const badgeIcon = useMemo(() => {
    switch (ptMode?.code) {
      case TransportType.HIGH_SPEED_TRAIN:
      case TransportType.INTERCITY_REGIONAL:
      case TransportType.LOCAL_TRAIN:
        return 'train';
      case TransportType.BUS:
      case TransportType.LONG_DISTANCE:
      case TransportType.LOCAL_BUS:
        return 'bus';
      case TransportType.TRAMS:
      case TransportType.TRAM:
      case TransportType.SUBWAY:
      case TransportType.UNDERGROUND:
        return 'metro';
      case TransportType.FERRY:
        return 'ferry';
      case TransportType.TAXI:
      case TransportType.SHARED_TAXI:
        return 'taxi';
      case TransportType.AIR_TRAFFIC:
        return 'airport';
      default: {
        return 'train';
      }
    }
  }, [ptMode?.code]);

  const getPtModeTranslation = useCallback((key: TransportType) => {
    try {
      return renderToString(<TransLabel i18nKey={key} />);
    } catch (error) {
      return _capitalize(_startCase(key));
    }
  }, []);

  const preparedLabel = useMemo(
    () =>
      [label, ptMode?.code && getPtModeTranslation(ptMode.code)]
        .filter(Boolean)
        .join(' - '),
    [getPtModeTranslation, label, ptMode?.code]
  );

  return (
    <Tooltip content={preparedLabel}>
      <Stack
        direction="row"
        alignItems="top"
        className={classNames(classes.root, {
          [classes.rounded]: rounded,
          [classes.small]: small,
        })}
        sx={{
          boxShadow: [alpha(badgeBgBaseColor, 0.1), 'white']
            .map((color) => `inset 0 0 0 2rem ${color}`)
            .join(','),
        }}
        spacing={0.5}
      >
        {badgeIcon && <Icon size={12} name={badgeIcon} />}
        <Typography variant="body2" fontWeight={700}>
          {preparedLabel}
        </Typography>
      </Stack>
    </Tooltip>
  );
};
