import { Icon, Tooltip } from '@fleet/shared/mui';
import { makeStyles } from '@mui/styles';
import { useStopSearchSelect } from 'hooks/useStopSearchSelect';
import { FC, useCallback, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { ReadOnlyField, SwitchField, useFormContext } from '@fleet/shared';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { CheckboxGroupField, SelectField } from '@fleet/shared/form';
import { TransportBadge } from 'components/TransportBadge';
import { useField } from '@fleet/shared/form/hooks/useField';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { useSelector } from 'store/utils';
import { selectPtModes } from 'features/classification/classificationSelectors';
import { SearchBarFormValues, TransportType, TripSearchParams } from 'dto/trip';
import classNames from 'classnames';

interface SearchFiltersProps {}

const useStyles = makeStyles((theme) => ({
  section: {
    padding: '0 8px',
    maxWidth: 64,
    width: '100%',
    boxSizing: 'border-box',
  },
  card: {
    maxHeight: 'calc(100vh - 14rem)',
    overflowY: 'scroll',
  },
  filterControl: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > .MuiInputLabel-root': {
      marginBottom: 0,
      color: theme.palette.text.primary,

      '& ~ *:not(.MuiSwitch-root)': {
        width: '55%',
      },
    },
  },
  carriersToggle: {
    alignItems: 'center',
    '& .MuiInputLabel-root': {
      margin: 0,
    },
    '& .MuiSwitch-root': {
      height: '1.5rem',
      margin: 0,
    },
  },
  switchLabel: {
    '&:not($active)': {
      opacity: 0.5,
    },
  },
  active: {},
  carriersSwitch: {},
  accordionRoot: {
    '&:before': {
      display: 'none',
    },
  },
  accordionDetails: {
    background: theme.palette.background.default,
  },
  transportFilterControls: {
    '& .MuiButton-root': {
      fontSize: '0.75rem',
      padding: 0,
      minWidth: 0,
    },
  },
  transportBadge: {
    borderRadius: '2px',
  },
}));

export const SearchFilters: FC<SearchFiltersProps> = () => {
  const classes = useStyles();
  const [controlsShown, setControlsShown] = useState(false);
  const [meansOfTransportShown, setMeansOfTransportShown] = useState(false);
  const carrierOptions = useClassificationOptions(ClassificationGroup.CARRIER);
  const ptModes = useSelector(selectPtModes);
  const transportOptions = useMemo(
    () =>
      ptModes
        .filter(({ id }) => id !== 'AIR_TRAFFIC')
        .map(({ id, name }) => ({
          label: (
            <TransportBadge ptMode={{ code: id as TransportType, name }} />
          ),
          value: id,
        })),
    [ptModes]
  );
  const allTransportSelection = useMemo(
    () => transportOptions.map(({ value }) => value),
    [transportOptions]
  );

  const { options, onSearch } = useStopSearchSelect({ wait: 200 });
  const form = useFormContext<Partial<SearchBarFormValues>>();
  const {
    input: { value: searchCriteria, onChange },
  } = useField<'searchCriteria', TripSearchParams['searchCriteria']>(
    'searchCriteria',
    form,
    {
      subscription: { value: true },
    }
  );
  const {
    input: { value: transferStopValue },
  } = useField<'via.viaStop.code', string>('via.viaStop.code', form, {
    subscription: { value: true },
  });

  const appliedFiltersNumber = useMemo(() => {
    if (searchCriteria) {
      const { ptModesFilter, ...rest } = searchCriteria;
      return (
        Object.keys(rest).length +
        (transferStopValue ? 1 : 0) +
        (ptModesFilter?.ptModes.length ? 1 : 0)
      );
    }
  }, [searchCriteria, transferStopValue]);
  const onClickOutside = useCallback(() => {
    if (controlsShown) {
      setControlsShown(false);
    }
  }, [controlsShown]);

  const resetHandler = useCallback(() => {
    form.restart({
      ...form.getState().values,
      searchCriteria: undefined,
      via: undefined,
    });
  }, [form]);
  const handleTransportationSelection = useCallback(
    (selectionType: 'all' | 'none') => {
      onChange({
        ...searchCriteria,
        ptModesFilter: {
          ptModes: selectionType === 'all' ? allTransportSelection : [],
        },
      });
    },
    [onChange, searchCriteria, allTransportSelection]
  );

  return (
    <Tooltip
      arrow={false}
      open={controlsShown}
      theme="light"
      placement="bottom-end"
      disableFocusListener
      offset={[0, 0]}
      PopperProps={{
        sx: {
          zIndex: 1299,
          '& > .MuiTooltip-tooltipPlacementBottom': {
            maxWidth: 'none',
            width: 373,
            padding: 0,
            marginTop: '8px',
          },
        },
      }}
      content={
        <ClickAwayListener onClickAway={onClickOutside}>
          <Card className={classes.card}>
            <CardContent sx={{ pb: 0 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 1 }}
              >
                <Typography variant="subtitle">
                  <TransSubtitle i18nKey="filters" />
                </Typography>
                <Button onClick={resetHandler} variant="text">
                  <TransButton i18nKey="clearAll" />
                </Button>
              </Stack>
              <Stack spacing={2}>
                <SelectField
                  disableClearable={false}
                  className={classes.filterControl}
                  name="searchCriteria.transfersFilter.transferLimit"
                  label={<TransField i18nKey="transferLimit" />}
                  options={[1, 2, 3, 4, 5].map((num) => ({
                    label: `${num}`,
                    value: num,
                  }))}
                />
                <SelectField
                  onInputChange={onSearch}
                  className={classes.filterControl}
                  name="via.viaStop.code"
                  label={<TransField i18nKey="transferStation" />}
                  options={options}
                />
                <SelectField
                  disableClearable={false}
                  className={classes.filterControl}
                  name="searchCriteria.transfersFilter.minTransferTime"
                  label={<TransField i18nKey="minTransferTime" />}
                  options={[15, 30, 45, 60].map((min) => ({
                    label: `${min}min`,
                    value: min,
                  }))}
                />
                <SelectField
                  className={classes.filterControl}
                  label={<TransField i18nKey="carrier" />}
                  name="searchCriteria.carriersFilter.carriers"
                  options={carrierOptions}
                  multiple
                />
                <ReadOnlyField
                  labelPosition="left"
                  label={<TransField i18nKey="carrierInclude" />}
                  className={classes.carriersToggle}
                  value={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography
                        variant="body2"
                        className={classNames(classes.switchLabel, {
                          [classes.active]:
                            !searchCriteria?.carriersFilter?.exclude,
                        })}
                      >
                        <TransField i18nKey="include" />
                      </Typography>
                      <SwitchField
                        className={classes.carriersSwitch}
                        name="searchCriteria.carriersFilter.exclude"
                      />
                      <Typography
                        variant="body2"
                        className={classNames(classes.switchLabel, {
                          [classes.active]:
                            searchCriteria?.carriersFilter?.exclude,
                        })}
                      >
                        <TransField i18nKey="exclude" />
                      </Typography>
                    </Stack>
                  }
                />
              </Stack>
            </CardContent>
            <Accordion
              disableGutters
              elevation={1}
              expanded={meansOfTransportShown}
              onChange={() => setMeansOfTransportShown(!meansOfTransportShown)}
              classes={{
                root: classes.accordionRoot,
              }}
            >
              <AccordionSummary>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Icon
                    name={`chevron-${meansOfTransportShown ? 'down' : 'up'}`}
                  />
                  <Typography variant="body2">
                    <TransSubtitle i18nKey="meansOfTransport" />
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordionDetails }}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  spacing={1}
                  className={classes.transportFilterControls}
                >
                  <Button
                    variant="text"
                    onClick={() => handleTransportationSelection('all')}
                  >
                    <TransButton i18nKey="selectAll" />
                  </Button>
                  <Button
                    variant="text"
                    onClick={() => handleTransportationSelection('none')}
                  >
                    <TransButton i18nKey="none" />
                  </Button>
                </Stack>
                <CheckboxGroupField
                  name="searchCriteria.ptModesFilter.ptModes"
                  defaultValue={allTransportSelection}
                  useSwitchCheckbox
                  labelPlacement="start"
                  options={transportOptions}
                />
              </AccordionDetails>
            </Accordion>
          </Card>
        </ClickAwayListener>
      }
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        onClick={() => setControlsShown(true)}
        className={classes.section}
      >
        <Icon name="filter" size={24} />
        <Typography variant="body2">
          <TransSubtitle i18nKey="filters" />
          {!!appliedFiltersNumber && `(${appliedFiltersNumber})`}
        </Typography>
      </Stack>
    </Tooltip>
  );
};
