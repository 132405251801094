import { SeatProperty } from 'dto/booking';
import { Journey, ReservationLegCoverage, TripOffer } from 'dto/trip';
import { TransLabel } from 'i18n/trans/label';
import { FC, useCallback, useMemo } from 'react';
import { TransTitle } from 'i18n/trans/title';
import { Button } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { Icon, Modal } from '@fleet/shared';
import { makeStyles } from '@mui/styles';
import { useModal } from '@fleet/shared/hooks';
import { getReadablePropertyName, getTripsLegsWithOffers } from 'utils/trip';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { LegRoute } from 'components/LegRoute';
import _uniqBy from 'lodash/uniqBy';
import { renderToString } from 'react-dom/server';

interface AvailabilityModalProps {
  journey: Journey;
  onClose: () => void;
}

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      margin: 0,
      maxWidth: '90vw',
      width: 1300,
      '& .MuiDialogActions-root .MuiButton-text': {
        display: 'none',
      },
    },
    availabilityWrap: {
      background: theme.palette.background.default,
      '& .MuiTypography-root': {
        lineBreak: 'auto',
      },
    },
    availabilityCard: {
      background: theme.palette.common.white,
      padding: '0.5rem',
    },
    legRoute: {
      flex: '33%',
    },
  }),
  { name: 'AvailabilityModal' }
);

export const AvailabilityModal: FC<AvailabilityModalProps> = ({
  journey,
  onClose,
}) => {
  const classes = useStyles();
  const { open } = useModal({ open: true });
  const legsWithOffers = useMemo(
    () =>
      getTripsLegsWithOffers(journey.trips).filter(
        ({ offers }) =>
          !offers.every(
            ({ reservationLegCoverage }) => !reservationLegCoverage.length
          )
      ),
    [journey.trips]
  );
  const getOfferTitle = useCallback(
    ({ serviceClass, travelClass, offerAccommodationType }: TripOffer) => {
      const title = [travelClass, serviceClass.name]
        .map(getReadablePropertyName)
        .join(' / ');
      return [title, getReadablePropertyName(offerAccommodationType)].join(' ');
    },
    []
  );
  const filterOffers = useCallback(
    (offers: Array<TripOffer>, legId: string) =>
      _uniqBy(
        offers.filter((offer) =>
          offer.reservationLegCoverage.some(
            (coverage) => coverage.legId === legId
          )
        ),
        getOfferTitle
      ),
    [getOfferTitle]
  );
  return (
    <Modal
      classes={{
        paper: classes.paper,
      }}
      title={<TransTitle i18nKey="detailedAvailability" />}
      open={open}
      onClose={onClose}
      actionButton={
        <Button
          variant="contained"
          startIcon={<Icon name="close" />}
          onClick={onClose}
        >
          <TransButton i18nKey="close" />
        </Button>
      }
    >
      <Stack spacing={2}>
        {legsWithOffers.map(({ offers, ...leg }) => (
          <LegRoute
            leg={leg}
            isOnGrayBg
            key={leg.id}
            className={classes.legRoute}
          >
            <Grid
              container
              columns={5}
              spacing={2}
              className={classes.availabilityWrap}
            >
              {filterOffers(offers, leg.id).map((offer, idx) => (
                <Grid key={idx} item xs={1}>
                  <AvailabilityCard
                    legId={leg.id}
                    title={getOfferTitle(offer)}
                    reservationLegCoverage={offer.reservationLegCoverage}
                  />
                </Grid>
              ))}
            </Grid>
          </LegRoute>
        ))}
      </Stack>
    </Modal>
  );
};

interface AvailabilityCardProps {
  legId: string;
  title: string;
  reservationLegCoverage: ReservationLegCoverage[];
}

const AvailabilityCard: FC<AvailabilityCardProps> = ({
  legId,
  title,
  reservationLegCoverage,
}) => {
  const classes = useStyles();

  // The totalNumericAvailability now reflects the sum of availabilities across
  // unique placeProperties sets for the specified legId, without duplication.
  const totalNumericAvailability = useMemo(() => {
    const uniqueLegEntries = new Map();

    reservationLegCoverage.forEach((coverage) => {
      const { placeProperties, legId: coverageLegId } = coverage;
      if (
        placeProperties &&
        legId.split('|')[1] === coverageLegId.split('|')[1]
      ) {
        const propertiesKey = [...placeProperties].sort().join('|');
        if (!uniqueLegEntries.has(propertiesKey)) {
          uniqueLegEntries.set(propertiesKey, coverage.numericAvailability);
        }
      }
    });

    return Array.from(uniqueLegEntries.values()).reduce(
      (sum, numericAvailability) => sum + numericAvailability,
      0
    );
  }, [reservationLegCoverage, legId]);

  const filteredProperties = useMemo(() => {
    const properties = reservationLegCoverage.filter(
      ({ placeProperties }) => placeProperties?.length
    );
    return _uniqBy(properties, ({ placeProperties }) =>
      placeProperties.join(',')
    );
  }, [reservationLegCoverage]);

  const getPropertiesTranslation = useCallback(
    (placeProperties: SeatProperty[]) => {
      try {
        return placeProperties
          .map((property) => {
            try {
              return renderToString(<TransLabel i18nKey={property} />);
            } catch (e) {
              return getReadablePropertyName(property);
            }
          })
          .join(', ');
      } catch (e) {
        placeProperties.join(', ');
      }
    },
    []
  );
  return (
    <Stack className={classes.availabilityCard} spacing={0.5}>
      <Stack direction="row" justifyContent="space-between" spacing={0.75}>
        {[title, totalNumericAvailability].map((value) => (
          <Typography key={value} variant="body1" fontWeight="bold">
            {value}
          </Typography>
        ))}
      </Stack>

      {!!filteredProperties.length && <Divider />}
      {filteredProperties.map(
        ({ placeProperties, numericAvailability }, idx) => (
          <Stack
            key={idx}
            direction="row"
            justifyContent="space-between"
            spacing={0.5}
          >
            <Typography variant="body2">
              {getPropertiesTranslation(placeProperties)}
            </Typography>
            <Typography variant="body2">{numericAvailability}</Typography>
          </Stack>
        )
      )}
    </Stack>
  );
};
