import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { selector } from 'store/utils';

export const activeTabIdxSelector = selector(
  (state: RootState) => state.tabs.activeTabIdx
);

export const tabsSelector = selector((state: RootState) => state.tabs.list);

export const activeTabSelector = createSelector(
  activeTabIdxSelector,
  tabsSelector,
  (activeTabIdx, tabs) => tabs[activeTabIdx]
);
