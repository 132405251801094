import { FC, useMemo } from 'react';
import { FormProvider } from '@fleet/shared';
import { Box, Grid } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TextField, useForm } from '@fleet/shared/form';
import {
  PurchaserDetailsPayload,
  updatePurchaserDetails,
} from 'features/trip/tripActions';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { Button } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { purchaserUpdateLoading } from 'features/loading/loadingSelectors';
import { getBooking } from 'features/booking/bookingActions';

interface PayerDataProps {}

export const PayerData: FC<PayerDataProps> = () => {
  const dispatch = useDispatch();
  const booking = useSelector(currentBookingSelector)!;
  const loading = useSelector(purchaserUpdateLoading);
  const { purchaser } = booking;
  const initialValues = useMemo(
    () => ({
      firstName: purchaser.firstName.value,
      lastName: purchaser.lastName.value,
      email: purchaser.contactInformation.emailAddress.value,
      phone: {
        number: purchaser.contactInformation.phoneNumber.value,
      },
      address: {
        zipCode: purchaser.address.zipCode,
        streetName: purchaser.address.streetName,
        city: purchaser.address.city,
        countryName: purchaser.address.countryName,
      },
      company: {
        name: purchaser.companyName.value,
        registrationNumber: purchaser.companyRegistrationNumber.value,
        taxId: purchaser.vatNumber.value,
      },
    }),
    [purchaser]
  );

  const onSubmit = useCallback(
    async ({ phone, address, company, ...values }) => {
      await dispatch(
        updatePurchaserDetails({
          bookingId: booking.id,
          phone: {
            ...phone,
          },
          ...(Object.keys(address).some((field) => address[field]) && {
            address,
          }),
          ...(company &&
            Object.keys(company).some((field) => company[field]) && {
              company,
            }),
          ...values,
        })
      ).unwrap();
      await dispatch(getBooking(booking!.id)).unwrap();
    },
    [dispatch, booking]
  );
  const { form, handleSubmit } = useForm<PurchaserDetailsPayload>({
    initialValues,
    onSubmit,
  });

  return (
    <FormProvider form={form}>
      <form onSubmit={handleSubmit}>
        <Grid container columns={4} spacing={2} rowSpacing={2}>
          <Grid item xs={1}>
            <TextField
              required={purchaser?.firstName.isRequired}
              name="firstName"
              label={<TransField i18nKey="purchaserNameFirst" />}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              required={purchaser?.lastName.isRequired}
              name="lastName"
              label={<TransField i18nKey="purchaserNameLast" />}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              required={purchaser?.contactInformation.emailAddress.isRequired}
              email
              name="email"
              label={<TransField i18nKey="email" />}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              required={purchaser?.contactInformation.phoneNumber.isRequired}
              name="phone.number"
              label={<TransField i18nKey="mobileNumber" />}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              required={purchaser?.address.isRequired}
              name="address.zipCode"
              label={<TransField i18nKey="zipCode" />}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              required={purchaser?.address.isRequired}
              name="address.streetName"
              label={<TransField i18nKey="address" />}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              required={purchaser?.address.isRequired}
              name="address.city"
              label={<TransField i18nKey="postalCity" />}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              required={purchaser?.address.isRequired}
              name="address.countryName"
              label={<TransField i18nKey="postalCountry" />}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              required={purchaser?.companyName.isRequired}
              name="company.name"
              label={<TransField i18nKey="companyName" />}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              required={purchaser?.companyRegistrationNumber.isRequired}
              name="company.registrationNumber"
              label={<TransField i18nKey="companyRegNr" />}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              required={purchaser?.vatNumber.isRequired}
              name="company.taxId"
              label={<TransField i18nKey="vatNumber" />}
            />
          </Grid>
        </Grid>
        <Box mt={2} textAlign="right">
          <Button
            variant="contained"
            type="submit"
            loading={loading}
            label={<TransButton i18nKey="saveChanges" />}
          />
        </Box>
      </form>
    </FormProvider>
  );
};
