import { Icon } from '@fleet/shared/mui';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BookingAdmission, BookingAdmissionAncillary } from 'dto/booking';
import {
  bookingAdmissionsSelector,
  bookingPartsSelector,
  currentBookingSelector,
} from 'features/booking/bookingSelectors';
import { TransSubtitle } from 'i18n/trans/subtitle';
import _intersection from 'lodash/intersection';
import _isEmpty from 'lodash/isEmpty';
import { FC, useCallback, useMemo } from 'react';
import {
  PassengerAdmissionsColumns,
  PassengerAdmissionsTable,
} from 'routes/bookingDetails/PassengerAdmissionsTable';
import { TravelPassTable } from 'routes/bookingDetails/TravelPassTable';
import { useSelector } from 'store/utils';
import { AddonsTable } from '../../tickets/checkout/AddonsTable';

interface PassengerFulfillmentSelectionProps {
  type: 'refund' | 'cancel';
  passengerId: string;
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      boxShadow: 'none',
      '&:before': {
        display: 'none',
      },
    },
    content: {
      padding: '16px 8px',
      background: theme.palette.background.default,
      boxShadow:
        'inset 0 8px 8px -5px rgba(0, 0, 0, 0.1), inset 0 -8px 8px -8px rgba(0, 0, 0, 0.1)',
    },
    header: {
      flexDirection: 'row-reverse',
    },
  }),
  { name: 'PassengerSelection' }
);

export const PassengerSelection: FC<PassengerFulfillmentSelectionProps> = ({
  type,
  passengerId,
}) => {
  const selection = useSelector(bookingPartsSelector);
  const booking = useSelector(currentBookingSelector)!;
  const { passengers } = booking;
  const bookingAdmissions = useSelector(bookingAdmissionsSelector);
  const getAdmissionsAncillaries = useCallback(
    (admissions: Array<BookingAdmission>): Array<BookingAdmissionAncillary> =>
      admissions.reduce<Array<BookingAdmissionAncillary>>(
        (ancillaries, admission) => [...ancillaries, ...admission.ancillaries],
        []
      ),
    []
  );

  const passengerSelectedAdmissions = useMemo(
    () =>
      bookingAdmissions.filter(({ id }) =>
        selection.admission[passengerId]?.includes(id)
      ),
    [bookingAdmissions, passengerId, selection.admission]
  );
  const passengerSelectedAncillaries = useMemo(() => {
    if (passengerSelectedAdmissions.length) {
      return getAdmissionsAncillaries(passengerSelectedAdmissions);
    }
    const bookingAncillaries = getAdmissionsAncillaries(bookingAdmissions);
    return bookingAncillaries.filter(({ fulfillments }) =>
      selection.ancillary[passengerId]?.includes(fulfillments[0].id)
    );
  }, [
    bookingAdmissions,
    getAdmissionsAncillaries,
    passengerId,
    passengerSelectedAdmissions,
    selection.ancillary,
  ]);

  const selectedTravelPass = useMemo(() => {
    if (_isEmpty(selection.fulfillment)) return;
    const selectedFulfillmentIds = selection.fulfillment[passengerId];
    return bookingAdmissions.find(
      ({ passengerIds, fulfillments }) =>
        passengerIds.includes(passengerId) &&
        _intersection(
          fulfillments.map(({ id }) => id),
          selectedFulfillmentIds
        ).length
    );
  }, [bookingAdmissions, passengerId, selection.fulfillment]);

  const passengerName = useMemo(() => {
    const passenger = passengers.find(({ id }) => id === passengerId)!;
    return [passenger.firstName.value, passenger.lastName.value].join(' ');
  }, [passengers, passengerId]);
  const classes = useStyles();

  return (
    <Accordion disableGutters classes={{ root: classes.root }} defaultExpanded>
      <AccordionSummary
        classes={{ root: classes.header }}
        expandIcon={
          <Icon name="chevron-down" size={24} margin color="secondary" />
        }
      >
        <Stack direction="row" alignItems="baseline" spacing={1}>
          <Typography variant="subtitle">{passengerName}</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.content }}>
        {!!passengerSelectedAdmissions.length && (
          <Stack spacing={0.5} mb={1.5}>
            <Typography pl={1} variant="body1" fontWeight="bold">
              <TransSubtitle i18nKey="admissions" />
            </Typography>
            <PassengerAdmissionsTable
              hiddenColumns={[
                PassengerAdmissionsColumns.selection,
                PassengerAdmissionsColumns.productName,
                PassengerAdmissionsColumns.appliedPassengerType,
                PassengerAdmissionsColumns.passengerCards,
                PassengerAdmissionsColumns.flexibility,
                ...(type === 'refund'
                  ? []
                  : [PassengerAdmissionsColumns.refundableAmount]),
              ]}
              currentPassengerId={passengerId}
              data={passengerSelectedAdmissions}
            />
          </Stack>
        )}
        {!!passengerSelectedAncillaries.length && (
          <Stack spacing={0.5}>
            <Typography pl={1} variant="body1" fontWeight="bold">
              <TransSubtitle i18nKey="addons" />
            </Typography>
            <AddonsTable
              hiddenColumns={[
                'selection',
                'passenger',
                'changeable',
                'refundable',
              ]}
              data={passengerSelectedAncillaries}
            />
          </Stack>
        )}
        {selectedTravelPass && <TravelPassTable data={[selectedTravelPass]} />}
      </AccordionDetails>
    </Accordion>
  );
};
