import { Icon } from '@fleet/shared/mui';
import { Alert, AlertColor, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { FC, ReactNode } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    border: ({ type = 'warning' }: AlertCardProps) =>
      `thin solid ${theme.palette[type].main}`,
    color: theme.palette.text.primary,
    padding: '0.25rem 2rem 0.25rem 1rem',
    '& > .MuiAlert-icon': {
      paddingTop: '0.625rem',
    },
    '& .MuiAlert-message': {
      flex: 1,
    },
  },
}));

interface AlertCardProps {
  title?: ReactNode;
  message: ReactNode;
  type?: AlertColor;
  className?: string;
  inline?: boolean;
}
export const AlertCard: FC<AlertCardProps> = (props) => {
  const classes = useStyles(props);
  const { title, message, className, inline, type = 'warning' } = props;
  return (
    <Alert
      severity={type}
      iconMapping={{
        success: <Icon name="check-circle" size={22} />,
        warning: <Icon name="alert-circle" size={22} />,
        error: <Icon name="deactivate" size={22} />,
      }}
      className={classNames(classes.root, className)}
    >
      <Stack
        {...(inline
          ? {
              direction: 'row',
              spacing: 1,
            }
          : { spacing: 0.5 })}
      >
        {title && <Typography variant="subtitle">{title}</Typography>}
        <Typography variant="paragraph">{message}</Typography>
      </Stack>
    </Alert>
  );
};
