import { createReducer } from '@reduxjs/toolkit';
import {
  getCards,
  getCarriers,
  getCommentLevels,
  getCommentTypes,
  getElements,
  getPlaces,
  getPosTypes,
  getPtModes,
  getRetailers,
} from 'features/classification/classificationActions';
import { Card, Classification } from 'dto/classification';
import { ElementResponse } from '@fleet/widget/dto/element';
import { IS_DS_AT } from 'utils/flags';

const initialState: {
  STOP: Array<Classification>;
  PT_MODE: Array<Classification>;
  CARRIER: Array<Classification>;
  CARD: Array<Card>;
  COMMENT_TYPE: Array<Classification>;
  COMMENT_LEVEL: Array<Classification>;
  ELEMENTS: Array<ElementResponse>;
  RETAILER: Array<Classification>;
  POINT_OF_SALE_TYPE: Array<Classification>;
  SALES_CHANNEL: Array<Classification>;
} = {
  STOP: [],
  PT_MODE: [],
  CARRIER: [],
  CARD: [],
  COMMENT_TYPE: [],
  COMMENT_LEVEL: [],
  ELEMENTS: [],
  RETAILER: [],
  POINT_OF_SALE_TYPE: [],
  SALES_CHANNEL: [],
};
export const classificationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCards.fulfilled, (state, action) => {
      state.CARD = action.payload;
    })
    .addCase(getCarriers.fulfilled, (state, action) => {
      state.CARRIER = action.payload;
    })
    .addCase(getPtModes.fulfilled, (state, action) => {
      state.PT_MODE = action.payload;
    })
    .addCase(getPlaces.fulfilled, (state, action) => {
      state.STOP = action.payload;
    })
    .addCase(getCommentLevels.fulfilled, (state, action) => {
      state.COMMENT_LEVEL = action.payload;
    })
    .addCase(getCommentTypes.fulfilled, (state, action) => {
      state.COMMENT_TYPE = action.payload;
    })
    .addCase(getElements.fulfilled, (state, action) => {
      state.ELEMENTS = action.payload;
    })
    .addCase(getRetailers.fulfilled, (state, action) => {
      state.RETAILER = action.payload;
    })
    .addCase(getPosTypes.fulfilled, (state, action) => {
      if (IS_DS_AT) {
        state.POINT_OF_SALE_TYPE = action.payload;
      } else {
        state.SALES_CHANNEL = action.payload;
      }
    });
});
