import { Icon } from '@fleet/shared';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TypographyProps,
} from '@mui/material';
import { FC, ReactNode } from 'react';
import { DialogProps } from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

interface ModalWrapProps extends Omit<DialogProps, 'title'> {
  title: ReactNode;
  titleVariant?: TypographyProps['variant'];
  onClose: () => void;
  fullscreen?: boolean;
  withFooter?: boolean;
}

const useStyles = makeStyles(
  () => ({
    root: {},
    fullscreen: {
      padding: '1.5rem',
    },
    pBottom: {
      paddingBottom: '5rem',
    },
  }),
  {
    name: 'ModalWrap',
  }
);

export const ModalWrap: FC<ModalWrapProps> = ({
  onClose,
  title,
  children,
  withFooter,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      classes={{
        root: classNames({ [classes.fullscreen]: props.fullScreen }),
      }}
      {...props}
    >
      <DialogTitle component={Stack} flexDirection="row" alignItems="center">
        <Box sx={{ flex: 1 }}>{title}</Box>
        <IconButton onClick={onClose}>
          <Icon name="close" size={24} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classNames({ [classes.pBottom]: withFooter })}>
        {children}
      </DialogContent>
    </Dialog>
  );
};
