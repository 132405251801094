import { FormProvider } from '@fleet/shared';
import { Checkbox } from '@fleet/shared/mui/Checkbox';
import { Box, Stack, Typography } from '@mui/material';
import { EmailFulfillment } from 'components/ticketFulfillment/EmailFulfillment';
import { SmsFulfillment } from 'components/ticketFulfillment/SmsFulfillment';
import { setPdfDownload } from 'features/trip/tripActions';
import { downloadPdfFlagSelector } from 'features/trip/tripSelector';
import {
  selectEmailSendAvailable,
  selectSmsSendAvailable,
} from 'features/user/userSelector';
import { FormApi } from 'final-form';
import { TransLabel } from 'i18n/trans/label';
import { TransTitle } from 'i18n/trans/title';
import { Dispatch, FC, ReactNode, SetStateAction, useMemo } from 'react';
import { TicketSelectionPayload } from 'routes/tickets/checkout/Overview';
import { useDispatch, useSelector } from 'store/utils';

interface TicketFulfillmentProps {
  isSaleFlow?: boolean;
  isPassengerDetailsFormInvalid: boolean;
  purchaserInfo: {
    purchaserEmail: string | undefined;
    purchaserPhoneNumber: string | undefined;
  };
  ticketFulfillmentForm: FormApi<
    TicketSelectionPayload,
    Partial<TicketSelectionPayload>
  >;
  isEmailSelected: boolean;
  setEmailSelected: Dispatch<SetStateAction<boolean>>;
  isSmsSelected: boolean;
  setSmsSelected: Dispatch<SetStateAction<boolean>>;
}

interface FulfillmentOptionBase {
  labelKey: 'pdfDownload' | 'email' | 'sms';
  checked: boolean;
  disabled?: boolean;
}

interface TicketFulfillmentMethod extends FulfillmentOptionBase {
  key: string;
  action: (checked: boolean) => void;
  renderComponent?: () => ReactNode;
}

export const TicketFulfillment: FC<TicketFulfillmentProps> = ({
  isPassengerDetailsFormInvalid,
  purchaserInfo,
  ticketFulfillmentForm,
  isSaleFlow,
  isEmailSelected,
  isSmsSelected,
  setEmailSelected,
  setSmsSelected,
}) => {
  const dispatch = useDispatch();
  const downloadPdf = useSelector(downloadPdfFlagSelector);
  const emailSendAvailable = useSelector(selectEmailSendAvailable);
  const smsSendAvailable = useSelector(selectSmsSendAvailable);
  const ticketFulfillmentMethods: TicketFulfillmentMethod[] = useMemo(
    () => [
      {
        key: 'pdf',
        labelKey: 'pdfDownload',
        checked: downloadPdf,
        action: (value: boolean) => dispatch(setPdfDownload(value)),
      },
      ...((emailSendAvailable
        ? [
            {
              key: 'email',
              labelKey: 'email',
              checked: isEmailSelected,
              action: () => setEmailSelected(!isEmailSelected),
              disabled: isPassengerDetailsFormInvalid,
              renderComponent: () => (
                <EmailFulfillment
                  isSaleFlow={isSaleFlow}
                  purchaserEmail={purchaserInfo.purchaserEmail}
                  isInline
                />
              ),
            },
          ]
        : []) as TicketFulfillmentMethod[]),
      ...((smsSendAvailable
        ? [
            {
              key: 'sms',
              labelKey: 'sms',
              checked: isSmsSelected,
              action: () => setSmsSelected(!isSmsSelected),
              renderComponent: () => (
                <SmsFulfillment
                  isInline
                  purchaserPhone={purchaserInfo.purchaserPhoneNumber}
                />
              ),
            },
          ]
        : []) as TicketFulfillmentMethod[]),
    ],
    [
      downloadPdf,
      emailSendAvailable,
      isEmailSelected,
      isPassengerDetailsFormInvalid,
      smsSendAvailable,
      isSmsSelected,
      dispatch,
      setEmailSelected,
      isSaleFlow,
      purchaserInfo.purchaserEmail,
      purchaserInfo.purchaserPhoneNumber,
      setSmsSelected,
    ]
  );
  return (
    <FormProvider form={ticketFulfillmentForm}>
      <form id="ticketFullfilmentForm">
        <Typography variant="h2">
          <TransTitle i18nKey="ticketFulfillment" />
        </Typography>
        <Stack direction="row" spacing={3} mt={2} sx={{ width: 'fit-content' }}>
          {ticketFulfillmentMethods.map((method) => (
            <Checkbox
              key={method.key}
              checked={method.checked}
              label={<TransLabel i18nKey={method.labelKey} />}
              onChange={(e) => method.action(e.target.checked)}
              inline
              disabled={method.disabled}
            />
          ))}
        </Stack>
        {ticketFulfillmentMethods.map(
          (method) =>
            method.checked &&
            !method.disabled &&
            method.renderComponent && (
              <Box key={`content-${method.key}`} mt={2}>
                {method.renderComponent()}
              </Box>
            )
        )}
      </form>
    </FormProvider>
  );
};
