import { Icon, Loadable, Modal } from '@fleet/shared';
import { useForm } from '@fleet/shared/form';
import { Button } from '@fleet/shared/mui';
import { Divider, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PayerDetailsForm } from 'components/PayerDetailsForm';
import { PaymentMethod, PaymentMethods } from 'components/PaymentMethods';
import { TicketFulfillment } from 'components/ticketFulfillment/TicketFulfillment';
import { BookingAdmission } from 'dto/booking';
import { getBooking } from 'features/booking/bookingActions';
import {
  bookedOffersFeesMapSelector,
  bookingExpiredSelector,
  currentBookingSelector,
} from 'features/booking/bookingSelectors';
import { bookingCheckoutLoadingSelector } from 'features/loading/loadingSelectors';
import { PurchaserDetailsPayload } from 'features/trip/tripActions';
import {
  downloadPdfFlagSelector,
  paymentStatusSelector,
} from 'features/trip/tripSelector';
import { TransAlert } from 'i18n/trans/alert';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransTitle } from 'i18n/trans/title';
import { FC, useCallback, useMemo, useState } from 'react';
import { useAlert } from 'react-alert';
import { JourneyOverview } from 'routes/tickets/checkout/JourneyOverview';
import { TicketSelectionPayload } from 'routes/tickets/checkout/Overview';
import { PayByLinkModal } from 'routes/tickets/checkout/PayByLinkModal';
import { useDispatch, useSelector } from 'store/utils';
import { formatCurrency } from 'utils/common';
import { FEATURE_TICKET_DELIVERY_V2 } from 'utils/flags';
import {
  prepareTrips,
  useConfirmationSend,
  usePurchaserInfo,
  useSubmitBooking,
} from 'utils/overview';
import {
  downloadBookingTickets,
  getAdmissionsTotalPrice,
  getBookingAdmissions,
} from 'utils/trip';
import { v4 } from 'uuid';

const useStyles = makeStyles(
  () => ({
    paper: {
      margin: 0,
      maxWidth: 'none',
      width: '900px',
    },
    '&.MuiDialogActions-root': {
      boxShadow: '0px 20px 32px 0px rgba(0, 0, 0, 0.5)',
    },
  }),
  { name: 'CheckoutOverviewModal' }
);

interface CheckoutOverviewModalProps {
  onClose: () => void;
  isOpen: boolean;
  selectedAdmissions: BookingAdmission[];
  submitDisabled: boolean;
}

export const PayOnHoldBookingModal: FC<CheckoutOverviewModalProps> = ({
  onClose,
  isOpen,
  selectedAdmissions,
  submitDisabled,
}) => {
  const [isEmailSelected, setEmailSelected] = useState(false);
  const [isSmsSelected, setSmsSelected] = useState(false);
  const selectedAdmissionIds = useMemo(
    () => selectedAdmissions.map((adm) => adm.id),
    [selectedAdmissions]
  );
  const dispatch = useDispatch();
  const alert = useAlert();
  const loading = useSelector(bookingCheckoutLoadingSelector);
  const classes = useStyles();
  const paymentStatus = useSelector(paymentStatusSelector);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const booking = useSelector(currentBookingSelector)!;
  const isBookingExpired = useSelector(bookingExpiredSelector);
  const downloadPdf = useSelector(downloadPdfFlagSelector);
  const preparedTrips = useMemo(
    () => prepareTrips(booking.bookedTrips),
    [booking]
  );
  const offerFeesMap = useSelector(bookedOffersFeesMapSelector);
  const admissionPrice = useMemo(
    () =>
      getAdmissionsTotalPrice({ admissions: selectedAdmissions, offerFeesMap }),
    [selectedAdmissions, offerFeesMap]
  );

  const offerPartIds = useMemo(() => {
    const numberOfAdmissions = getBookingAdmissions(booking).length;

    if (numberOfAdmissions === 1) {
      return [];
    }

    if (selectedAdmissionIds.length === numberOfAdmissions) {
      return [];
    }

    return selectedAdmissionIds;
  }, [booking, selectedAdmissionIds]);

  const handleConfirmationSend = useConfirmationSend();
  const { form: ticketFulfillmentForm } = useForm<TicketSelectionPayload>({
    initialValues: useMemo(
      () =>
        FEATURE_TICKET_DELIVERY_V2
          ? {
              passengers:
                booking?.passengers.map(({ id, contactInformation }) => ({
                  passengerId: id,
                  currentEmail: contactInformation.emailAddress.value,
                  currentPhone: contactInformation.phoneNumber.value,
                  sendSms: false,
                  sendEmail: false,
                })) ?? [],
            }
          : {},
      [booking?.passengers]
    ),
    onSubmit: handleConfirmationSend,
    subscription: { invalid: true },
  });
  const showSuccessPage = useCallback(async () => {
    const updatedBooking = await dispatch(getBooking(booking.id)).unwrap();
    alert.success(
      <TransAlert i18nKey="bookingIsPaid" values={{ id: booking.id }} />
    );
    downloadPdf && downloadBookingTickets(updatedBooking);
    onClose();
  }, [booking, dispatch, alert, onClose, downloadPdf]);
  const submitBooking = useSubmitBooking({
    bookingId: booking.id,
    ticketFulfillmentForm,
    paymentMethod,
    showSuccessPage,
  });
  const {
    form: payerDetailsForm,
    handleSubmit,
    values,
    invalid,
  } = useForm<PurchaserDetailsPayload>({
    subscription: { values: true, invalid: true },
    onSubmit: submitBooking,
  });
  const handleOnClose = useCallback(() => {
    setPaymentMethod(undefined);
    payerDetailsForm.restart();
    ticketFulfillmentForm.restart();
    onClose();
  }, [onClose, ticketFulfillmentForm, payerDetailsForm]);
  const purchaserInfo = usePurchaserInfo(payerDetailsForm, values);

  return (
    <Modal
      classes={{
        paper: classes.paper,
      }}
      title={<TransTitle i18nKey="paySelectedAdmissions" />}
      open={isOpen}
      onClose={handleOnClose}
      actionButton={
        <>
          <Button
            variant="text"
            onClick={() => {
              payerDetailsForm.restart();
              setEmailSelected(false);
              setSmsSelected(false);
            }}
            label={<TransButton i18nKey="resetFields" />}
            disabled={isBookingExpired}
          />
          <Button
            variant="contained"
            form="purchaserDetails"
            type="submit"
            disabled={!paymentMethod || isBookingExpired || submitDisabled}
            startIcon={<Icon name="check" />}
          >
            <TransButton i18nKey="confirmTransaction" />
          </Button>
        </>
      }
    >
      <Loadable loading={loading}>
        <Stack spacing={2} divider={<Divider />}>
          {preparedTrips.map((trip, idx) => (
            <JourneyOverview
              isOverview
              trip={trip}
              key={trip.id}
              selectedAdmissions={selectedAdmissions}
              isPayOnHoldBooking
            />
          ))}
          <PayerDetailsForm
            form={payerDetailsForm}
            handleSubmit={handleSubmit}
          />
          <TicketFulfillment
            ticketFulfillmentForm={ticketFulfillmentForm}
            isPassengerDetailsFormInvalid={invalid}
            purchaserInfo={purchaserInfo}
            isSmsSelected={isSmsSelected}
            setSmsSelected={setSmsSelected}
            setEmailSelected={setEmailSelected}
            isEmailSelected={isEmailSelected}
          />
          <PaymentMethods
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
          />
          {!!paymentStatus && (
            <PayByLinkModal onPaymentSuccess={() => showSuccessPage()} />
          )}
          <Stack direction="row" alignItems="center">
            <Icon name="cart" size="24px" />
            <Typography variant="h2" sx={{ mr: '8px' }}>
              <TransField i18nKey="total" />:
            </Typography>
            <Typography variant="h2">
              {formatCurrency(admissionPrice)}
            </Typography>
          </Stack>
        </Stack>
      </Loadable>
    </Modal>
  );
};
