import {
  api,
  Button,
  CardHeader,
  DateField,
  formatDate,
  FormProvider,
  Layout,
  Loadable,
  RadioGroupField,
  ReadOnlyField,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import { CardContent, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import download from 'downloadjs';
import { ClassificationGroup } from 'dto/classification';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTitle } from 'i18n/trans/title';
import _mapValues from 'lodash/mapValues';
import { stringify } from 'qs';
import { useCallback, useMemo, useState } from 'react';
import { useAlert } from 'react-alert';

interface AgentSalesPayload {
  fileType: 'pdf' | 'xlsx';
  from: string;
  to?: string;
}

interface PassengerManifestPayload {
  fileType: 'pdf';
  departureDate: string;
  serviceCode: string;
  organizationId: string;
}

const useStyles = makeStyles(
  () => ({
    reportsRow: {
      width: '80vw',
      gap: '0.5rem',
      marginBottom: '2rem',
    },
    formRow: {
      gap: '2rem',
    },
    submitControls: {
      flexBasis: '75%',
      minWidth: '12.5rem',
      '& > .MuiTypography-root': {
        justifyContent: 'flex-end',
      },
    },
  }),
  { name: 'SalesReports' }
);

export const SalesReports = () => {
  const alert = useAlert();
  const classes = useStyles();
  const todayDate = useMemo(() => new Date().toISOString(), []);
  const [loading, setLoading] = useState(false);
  const carrierOptions = useClassificationOptions(ClassificationGroup.CARRIER);
  const handleReportDownload = useCallback(
    async (url: string, fileName: string) => {
      setLoading(true);
      try {
        const fileData = (
          await api.get(url, {
            responseType: 'arraybuffer',
            validateStatus: null,
            headers: {
              'Content-Type': 'application/json',
            },
            transformResponse: (response, _, status) => {
              if (status === 400) {
                const errorResponse = String.fromCharCode.apply(
                  null,
                  new Uint8Array(response) as unknown as number[]
                );
                try {
                  const errors = JSON.parse(errorResponse)?.errors as Array<{
                    message: string;
                  }>;
                  errors.forEach(({ message }) =>
                    alert.show(message, {
                      type: 'error',
                      timeout: 5000,
                    })
                  );
                  return null;
                } catch (e) {}
              }

              return response;
            },
          })
        ).data;

        fileData && download(fileData, fileName);
      } catch (e) {}
      setLoading(false);
    },
    [alert]
  );

  const onAgentSalesSubmit = useCallback(
    async (payload: AgentSalesPayload) => {
      const { fileType, from, to } = payload;
      handleReportDownload(
        `/reports/sales${stringify(
          {
            fileType,
            ..._mapValues(
              { from, to },
              (date) => date && formatDate(date, 'yyyy-MM-dd')
            ),
          },
          { addQueryPrefix: true }
        )}`,
        `Sales_report_${formatDate(new Date(), 'dd.MM.yyyy_HH.mm')}.${fileType}`
      );
    },
    [handleReportDownload]
  );

  const onPassengerManifestSubmit = useCallback(
    async (payload: PassengerManifestPayload) => {
      handleReportDownload(
        `/reports/passenger-manifest${stringify(payload, {
          addQueryPrefix: true,
        })}`,
        `Passenger_manifest_${formatDate(new Date(), 'dd.MM.yyyy_HH.mm')}.${
          payload.fileType
        }`
      );
    },
    [handleReportDownload]
  );

  const { form: agentSalesForm, handleSubmit: agentSalesSubmit } =
    useForm<AgentSalesPayload>({
      initialValues: {
        from: todayDate,
        to: todayDate,
        fileType: 'pdf',
      },
      onSubmit: onAgentSalesSubmit,
    });

  const { form: passengerManifestForm, handleSubmit: passengerManifestSubmit } =
    useForm<PassengerManifestPayload>({
      initialValues: {
        departureDate: todayDate,
        fileType: 'pdf',
      },
      onSubmit: onPassengerManifestSubmit,
    });

  return (
    <Loadable loading={loading}>
      <Layout
        header={
          <CardHeader
            sx={{ padding: '16px 24px' }}
            title={<TransSubtitle i18nKey="salesReports" />}
          />
        }
      >
        <CardContent>
          <FormProvider form={agentSalesForm}>
            <Stack className={classes.reportsRow}>
              <Typography variant="subtitle">
                <TransTitle i18nKey="agentSalesReport" />
              </Typography>
              <Stack
                direction="row"
                className={classes.formRow}
                component="form"
                onSubmit={agentSalesSubmit}
              >
                <DateField
                  name="from"
                  required
                  label={<TransField i18nKey="dateFrom" />}
                />
                <DateField name="to" label={<TransField i18nKey="dateTo" />} />
                <RadioGroupField
                  name="fileType"
                  label={<TransField i18nKey="fileFormat" />}
                  options={[
                    {
                      value: 'pdf',
                      label: 'PDF',
                    },
                    {
                      value: 'xlsx',
                      label: 'Excel',
                    },
                  ]}
                  inline
                />
                <ReadOnlyField
                  className={classes.submitControls}
                  contentComponent="div"
                  value={
                    <Stack direction="row">
                      <Button
                        disabled={loading}
                        variant="text"
                        label={<TransButton i18nKey="resetFields" />}
                        onClick={() => agentSalesForm.reset()}
                      />
                      <Button
                        loading={loading}
                        label={<TransButton i18nKey="download" />}
                        type="submit"
                      />
                    </Stack>
                  }
                  label={<>&nbsp;</>}
                />
              </Stack>
            </Stack>
          </FormProvider>

          <FormProvider form={passengerManifestForm}>
            <Stack className={classes.reportsRow}>
              <Typography variant="subtitle">
                <TransTitle i18nKey="passengerManifest" />
              </Typography>
              <Stack
                direction="row"
                className={classes.formRow}
                component="form"
                onSubmit={passengerManifestSubmit}
              >
                <DateField
                  name="departureDate"
                  required
                  showTimeInput
                  label={<TransField i18nKey="departureDateTime" />}
                />
                <SelectField
                  name="organizationId"
                  required
                  options={carrierOptions}
                  label={<TransField i18nKey="carrier" />}
                />
                <TextField
                  name="serviceCode"
                  required
                  label={<TransField i18nKey="serviceCode" />}
                />
                <RadioGroupField
                  name="fileType"
                  label={<TransField i18nKey="fileFormat" />}
                  options={[
                    {
                      value: 'pdf',
                      label: 'PDF',
                    },
                    {
                      value: 'xlsx',
                      label: 'Excel',
                    },
                  ]}
                  inline
                />
                <ReadOnlyField
                  className={classes.submitControls}
                  contentComponent="div"
                  value={
                    <Stack direction="row">
                      <Button
                        disabled={loading}
                        variant="text"
                        label={<TransButton i18nKey="resetFields" />}
                        onClick={() => passengerManifestForm.reset()}
                      />
                      <Button
                        loading={loading}
                        label={<TransButton i18nKey="download" />}
                        type="submit"
                      />
                    </Stack>
                  }
                  label={<>&nbsp;</>}
                />
              </Stack>
            </Stack>
          </FormProvider>
        </CardContent>
      </Layout>
    </Loadable>
  );
};
