export enum ConcessionType {
  PENSIONER = 'CONCESSION_TYPE.PENSIONER',
  SENIOR = 'CONCESSION_TYPE.SENIOR',
  EXDEFENCE = 'CONCESSION_TYPE.EXDEFENCE',
  VISION_IMPAIRED = 'CONCESSION_TYPE.VISION_IMPAIRED',
  BLINDED_SOLDIER = 'CONCESSION_TYPE.BLINDED_SOLDIER',
  CURRENT_EMPLOYEE = 'CONCESSION_TYPE.CURRENT_EMPLOYEE',
  CURRENT_EMPLOYEE_INTERSTATE = 'CONCESSION_TYPE.CURRENT_EMPLOYEE_INTERSTATE',
  FORMER_EMPLOYEE = 'CONCESSION_TYPE.FORMER_EMPLOYEE',
  TCEC_STUDENT = 'CONCESSION_TYPE.TCEC_STUDENT',
  OTHER_STUDENT = 'CONCESSION_TYPE.OTHER_STUDENT',
  DVA = 'CONCESSION_TYPE.DVA',
  JOB_SEEKER = 'CONCESSION_TYPE.JOB_SEEKER',
}

export interface Concession {
  id: string;
  concessionTypeId: ConcessionType;
  firstName: string;
  lastName: string;
  state: string;
  dateOfBirth: string;
  instituteId: string;
  businessGroup: string;
  passType: string;
}

export interface VoucherFilters {
  concessionId?: string;
  concessionTypeId?: string;
}

export interface Voucher {
  id: string;
  status: string;
  validFrom: string;
  validTo: string;
}

export interface ValidatedConcession {
  id: string;
  result: 'VALID' | 'INVALID' | 'PENDING' | 'ERROR';
  code: string;
  vouchers: Array<Voucher>;
}
