import {
  Button,
  currentDateTimeFormat,
  formatDate,
  Table,
} from '@fleet/shared';
import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { Tag } from 'components/Tag';
import { BookingComment, RequestContactPerson } from 'dto/booking';
import {
  deleteComment,
  deleteUnaccompaniedMinorRequest,
  getComments,
  getUnaccompaniedMinorRequests,
} from 'features/booking/bookingActions';
import {
  bookingCommentsSelector,
  bookingUnaccompaniedMinorRequestsSelector,
  currentBookingSelector,
} from 'features/booking/bookingSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransLabel } from 'i18n/trans/label';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import { FC, useCallback, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { Column, Row, useTable } from 'react-table';
import { PreparedCommentPayload } from 'routes/bookingDetails/tabs/Comments';
import { useDispatch, useSelector } from 'store/utils';
import {
  getBookingAdmissions,
  getPassengersNames,
  getTripsByLegIds,
} from 'utils/trip';

interface PreparedComment extends BookingComment {
  passengerAge?: number;
  contactPersonAtOrigin?: RequestContactPerson;
  contactPersonAtDestination?: RequestContactPerson;
}

const useStyles = makeStyles(
  (theme) => ({
    controls: {
      '& .MuiButton-root': {
        padding: 0,
      },
    },
    controlsCell: {
      '& > div': {
        padding: '0!important',
      },
    },
    table: {
      tableLayout: 'fixed',
      '& thead > tr': {
        background: theme.palette.background.default,
      },
      '& td, & th > div': {
        minHeight: 48,
      },
      '& td, & th': {
        '& > div': {
          paddingTop: '0.5rem!important',
          paddingBottom: '0.5rem!important',
        },
      },
    },
    cell: {
      verticalAlign: 'top',
      '& .MuiButton-text': {
        textDecoration: 'underline',
        fontSize: '0.875rem',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
        minWidth: 0,
      },
    },
    alignRight: {
      '& *': {
        flex: 1,
      },
      textAlign: 'right',
    },
  }),
  {
    name: 'Comments',
  }
);

interface CommentsTableProps {
  onCommentEdit: (comment: PreparedCommentPayload) => void;
  editCommentId?: string;
}

export const CommentsTable: FC<CommentsTableProps> = ({
  onCommentEdit,
  editCommentId,
}) => {
  const booking = useSelector(currentBookingSelector);
  const classes = useStyles();
  const dispatch = useDispatch();
  const comments = useSelector(bookingCommentsSelector);
  const unaccompaniedMinorRequests = useSelector(
    bookingUnaccompaniedMinorRequestsSelector
  );
  const bookingAdmissions = useMemo(
    () => getBookingAdmissions(booking),
    [booking]
  );
  const passengersAdmissionMap = useMemo(() => {
    const passengerIdsMap = booking!.passengers.reduce<Record<string, string>>(
      (acc, { id }) => ({
        ...acc,
        [id]: '',
      }),
      {}
    );
    return bookingAdmissions.reduce(
      (acc, { id, passengerIds: [passengerId], fulfillments }) => ({
        ...acc,
        [id]: passengerId,
        ...fulfillments.reduce(
          (acc, { id }) => ({
            ...acc,
            [id]: passengerId,
          }),
          {}
        ),
      }),
      passengerIdsMap
    );
  }, [booking, bookingAdmissions]);

  const getTicketByAdmissionId = useCallback(
    (admissionId) => {
      const admission = bookingAdmissions.find(({ id }) => id === admissionId);
      if (admission) {
        const { coveredLegIds } = admission;
        const trips = getTripsByLegIds(booking!, coveredLegIds);
        const [firstTrip, lastTrip] = [trips[0], trips[trips.length - 1]];
        return (
          <Stack direction="row" spacing={0.5} flexWrap="wrap">
            <Typography variant="body2" fontWeight="bold">
              <TransTableHead i18nKey="ticket" />:
            </Typography>
            {[
              [firstTrip.departureTime, firstTrip.originStop.name],
              [lastTrip.arrivalTime, lastTrip.destinationStop.name],
            ].map(([time, stop], idx) => (
              <Stack key={idx} direction="row" spacing={0.5}>
                <Typography variant="body2">
                  {formatDate(time, currentDateTimeFormat)}
                </Typography>
                <Typography variant="body2" fontWeight="bold">
                  {idx ? stop : [stop, ' - '].join('')}
                </Typography>
              </Stack>
            ))}
          </Stack>
        );
      }
    },
    [booking, bookingAdmissions]
  );

  const getPassengerData = useCallback(
    ({
      passengerId,
      admissionId,
    }: {
      passengerId?: string;
      admissionId?: string;
    }) => {
      const preparedPassengerId =
        passengerId || (admissionId && passengersAdmissionMap[admissionId]);
      if (!preparedPassengerId) return {};
      return {
        name: getPassengersNames(booking!, preparedPassengerId),
        age: booking!.passengers.find(({ id }) => id === preparedPassengerId)
          ?.age,
      };
    },
    [booking, passengersAdmissionMap]
  );

  const preparedComments = useMemo(
    () =>
      [...comments, ...unaccompaniedMinorRequests].sort(
        (a, b) =>
          new Date(a.createInfo.createdOn).valueOf() -
          new Date(b.createInfo.createdOn).valueOf()
      ) as PreparedComment[],
    [comments, unaccompaniedMinorRequests]
  );
  const dateFormat = useCallback(
    (date) => formatDate(date, currentDateTimeFormat),
    []
  );
  const getContactPersonInfo = useCallback(
    ({ firstName, lastName, phoneNumber, email }: RequestContactPerson) =>
      [`${firstName} ${lastName}`, phoneNumber, email].join(' · '),
    []
  );

  const getDeleteHandler = useCallback(
    ({ id, contactPersonAtOrigin }: PreparedComment) =>
      async () => {
        if (!!contactPersonAtOrigin) {
          await dispatch(deleteUnaccompaniedMinorRequest(id));
          await dispatch(getUnaccompaniedMinorRequests(booking!.id));
        } else {
          await dispatch(deleteComment(id));
          await dispatch(getComments(booking!.id));
        }
      },
    [booking, dispatch]
  );

  const getEditHandler = useCallback(
    (comment: PreparedComment) => () => {
      const {
        id,
        type,
        level,
        passengerAge,
        passengerId,
        admissionId,
        ...rest
      } = comment;
      const commonPayload = {
        admissionIds: [admissionId].filter(Boolean),
      };
      const preparedPayload = passengerAge
        ? {
            id,
            type: 'UNMR',
            level: 'COMMENT_LEVEL.TICKET',
            ...commonPayload,
            request: {
              [admissionId!]: {
                passengerAge,
                ...rest,
              },
            },
          }
        : {
            id,
            type: type?.id,
            level: level?.id,
            ...(passengerId && {
              passengerIds: [passengerId],
            }),
            ...commonPayload,
            ...rest,
          };

      onCommentEdit(preparedPayload as PreparedCommentPayload);
    },
    [onCommentEdit]
  );
  const columns = useMemo<Column<PreparedComment>[]>(
    () => [
      {
        id: 'commentType',
        accessor: ({ type }) =>
          type?.name ?? renderToString(<TransLabel i18nKey="unmrSsr" />),
        Header: <TransTableHead i18nKey="commentType" />,
      },
      {
        id: 'commentLevel',
        accessor: ({ level }) =>
          level?.name ?? renderToString(<TransLabel i18nKey="passenger" />),
        Header: <TransTableHead i18nKey="commentLevel" />,
        width: '7rem',
      },
      {
        id: 'agent',
        accessor: ({ createInfo }) => createInfo.userName,
        Header: <TransTableHead i18nKey="agent" />,
        width: '7rem',
      },
      {
        id: 'passengerId',
        Cell: ({ row }: { row: Row<PreparedComment> }) => {
          const { name, age } = getPassengerData(row.original);
          return (
            <Stack alignItems="flex-start" spacing={0.5}>
              <Typography variant="body2">{name}</Typography>
              {age && (
                <Tag
                  variant="body2"
                  title={<TransLabel i18nKey="age" />}
                  text={age}
                />
              )}
            </Stack>
          );
        },
        Header: <TransTableHead i18nKey="passenger" />,
        width: '8rem',
      },
      {
        id: 'comment',
        Header: <TransTableHead i18nKey="commentData" />,
        Cell: ({ row }: { row: Row<PreparedComment> }) => {
          const {
            comment,
            contactPersonAtOrigin,
            admissionId,
            contactPersonAtDestination,
          } = row.original;
          const isUnacomopaniedMinorRequest =
            contactPersonAtOrigin && contactPersonAtDestination;
          return (
            <Stack spacing={1}>
              {isUnacomopaniedMinorRequest &&
                (
                  [
                    'contactPersonAtOrigin',
                    'contactPersonAtDestination',
                  ] as const
                ).map((key) => (
                  <Stack key={key}>
                    <Typography variant="body2" fontWeight="bold">
                      <TransSubtitle i18nKey={key} />
                    </Typography>
                    <Stack direction="row">
                      {getContactPersonInfo(
                        { contactPersonAtOrigin, contactPersonAtDestination }[
                          key
                        ]
                      )}
                    </Stack>
                  </Stack>
                ))}
              {!isUnacomopaniedMinorRequest &&
                admissionId &&
                getTicketByAdmissionId(admissionId)}
              <Typography variant="body2">{comment}</Typography>
            </Stack>
          );
        },
        width: '30rem',
      },
      {
        id: 'createdModifiedOn',
        Cell: ({ row }: { row: Row<PreparedComment> }) => {
          const { createInfo, lastChangeInfo } = row.original;
          return (
            <Stack alignItems="end">
              <Typography variant="body2" noWrap>
                <TransLabel
                  i18nKey="created"
                  values={{ date: dateFormat(createInfo.createdOn) }}
                />
              </Typography>
              <Typography variant="body2" noWrap color="text.secondary">
                <TransLabel
                  i18nKey="lastModified"
                  values={{ date: dateFormat(lastChangeInfo.changedOn) }}
                />
              </Typography>
            </Stack>
          );
        },
        Header: <TransTableHead i18nKey="createdModifiedOn" />,
      },
      {
        id: 'controls',
        Cell: ({ row }: { row: Row<PreparedComment> }) =>
          editCommentId !== row.original.id && (
            <Stack direction="row" className={classes.controls}>
              <Button
                variant="text"
                label={<TransButton i18nKey="edit" />}
                onClick={getEditHandler(row.original)}
              />
              <Button
                variant="text"
                label={<TransButton i18nKey="delete" />}
                onClick={getDeleteHandler(row.original)}
              />
            </Stack>
          ),
        width: '7rem',
      },
    ],
    [
      getPassengerData,
      getTicketByAdmissionId,
      getContactPersonInfo,
      dateFormat,
      editCommentId,
      classes.controls,
      getEditHandler,
      getDeleteHandler,
    ]
  );

  const table = useTable<PreparedComment>({
    data: preparedComments,
    columns,
  });

  return (
    <Table
      getTableProps={{ className: classes.table }}
      getHeaderProps={(_, { column }) => ({
        className: classNames({
          [classes.alignRight]: column.id === 'createdModifiedOn',
        }),
      })}
      getCellProps={(_, { cell }) => ({
        className: classNames(classes.cell, {
          [classes.controlsCell]: cell.column.id === 'controls',
        }),
      })}
      table={table}
    />
  );
};
