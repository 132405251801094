import { Autocomplete, Box, Stack } from '@mui/material';
import { useStopSearchSelect } from 'hooks/useStopSearchSelect';
import { SearchTextField } from './SearchTextField';
import { Paper, SelectOption } from '@fleet/shared/mui';
import {
  FC,
  FocusEvent,
  FocusEventHandler,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { renderToString } from 'react-dom/server';

interface SearchStopsSelectProps {
  label: ReactNode;
  className?: string;
  onChange: (arg?: string | number | null) => void;
  value?: string | number;
  recentStops: Array<SelectOption>;
}

const useStyles = makeStyles(
  (theme) => ({
    option: {
      '&&': {
        padding: '8px 16px',
      },
      '&&.Mui-focused': {
        background: theme.palette.primary.light,
      },
    },
    optionInfo: {
      alignSelf: 'flex-start',
      padding: '0 4px',
      display: 'inline-block',
      fontSize: '10px',
      borderRadius: '2px',
      background: theme.palette.primary.light,
    },
    groupLabel: {
      padding: '0 0.5rem',
      lineHeight: '2.5rem',
      fontWeight: 'bold',
      fontSize: '1rem',
      background: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
  }),
  {
    name: 'SearchStopsSelect',
  }
);

export const SearchStopsSelect: FC<SearchStopsSelectProps> = ({
  className,
  label,
  onChange,
  value,
  recentStops,
}) => {
  const classes = useStyles();
  const { onSearch, options, inputValue, isLoading, noOptionsTranslationCode } =
    useStopSearchSelect({
      value,
    });
  const recentStopsMemoized = useMemo(() => recentStops, [recentStops]);
  const currentOptions = useMemo(() => options ?? [], [options]);

  const selectValue = useMemo(() => {
    if (!value) return null;
    let selectedOption = (options ?? []).find(
      (option) => option.value === value
    );
    if (!selectedOption) {
      selectedOption = recentStops.find((option) => option.value === value);
    }
    return selectedOption ?? null;
  }, [options, recentStops, value]);

  const getOnFocusHandler = useCallback(
    (onFocusHandler?: FocusEventHandler<HTMLInputElement>) =>
      (e: FocusEvent<HTMLInputElement>) => {
        onFocusHandler?.(e);
        value && onChange();
      },

    [onChange, value]
  );

  return (
    <Autocomplete
      freeSolo={!!value}
      filterOptions={(x) => x}
      className={className}
      loading={isLoading}
      loadingText={<TransSubtitle i18nKey="loading" />}
      classes={{
        groupLabel: classes.groupLabel,
      }}
      onChange={(e, val) => onChange((val as SelectOption)?.value)}
      onInputChange={onSearch}
      renderOption={(props, option) => (
        // use value as key instead of label
        <li
          {...props}
          key={option.value}
          className={classNames(props.className, classes.option)}
        >
          <Stack>
            <Box className={classes.optionInfo}>{option.value}</Box>
            {option.label}
          </Stack>
        </li>
      )}
      options={inputValue ? currentOptions : recentStopsMemoized}
      groupBy={
        options.length
          ? undefined
          : () => renderToString(<TransSubtitle i18nKey="recentSearches" />)
      }
      noOptionsText={<TransSubtitle i18nKey={noOptionsTranslationCode} />}
      popupIcon={null}
      clearIcon={null}
      value={selectValue || inputValue}
      renderInput={({ inputProps: { onFocus, ...inputProps }, ...params }) => (
        <SearchTextField
          label={label}
          {...params}
          inputProps={{
            ...inputProps,
            onFocus: getOnFocusHandler(onFocus),
          }}
        />
      )}
      PaperComponent={(props) => (
        <Paper
          {...props}
          sx={{
            mt: 1,
            '& .MuiAutocomplete-listbox': {
              padding: 0,
            },

            '& .MuiAutocomplete-option': {
              padding: '6px 8px',
            },
          }}
        />
      )}
    />
  );
};
