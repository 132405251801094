import { createAsyncThunk } from 'store/utils';
import { createAction } from '@reduxjs/toolkit';
import { v4 } from 'uuid';
import { api } from '@fleet/shared';
import _reverse from 'lodash/reverse';
import { ModuleItem } from '@fleet/shared/components/Header';

export { type ModuleItem }

export type UserUnit = {
  id: string;
  name: string;
};

export interface UserOrganization extends UserUnit {
  units: Array<UserUnit & { code: string, isSendingSmsAllowed?: boolean }>;
  pointsOfSale: Array<UserUnit>;
}

export interface User {
  id: string;
  name: string;
  primaryOrganization: UserUnit;
  primaryUnit: UserUnit & { code: string, isSendingSmsAllowed?: boolean };
  primaryPointOfSale: UserUnit;
  organizations: Array<UserOrganization>;
}

export interface RecentTrip {
  origin: {
    code: string;
    name: string;
  };
  destination: {
    code: string;
    name: string;
  };
}

export interface BookmarkedTrip extends RecentTrip {
  id: string;
}

export interface PosConfiguration {
  pointOfSaleId: string;
  pointOfSaleName: string;
  currencies: Array<string>;
  purchaseConfirmationEnabled: boolean;
  ticketDeliveryEnabled: boolean;
}

interface RetailerSelection {
  organizationId?: string;
  salesUnitCode?: string;
  imsSalesUnitId?: string;
  pointOfSaleId?: string;
  salesApplication?: string;
}

export const setShowRetalerModal = createAction<boolean>(
  'user/setShowRetalerModal'
);

export const selectDefaultCurrency = createAction<string>(
  'user/selectDefaultCurrency'
);

export const selectRetailer = createAction<RetailerSelection>(
  'user/selectRetailer'
);

export const getUser = createAsyncThunk<User, void>(
  'user/getUser',
  async () => (await api.get('/users')).data
);

export const getFavoriteTrips = createAsyncThunk<Array<BookmarkedTrip>>(
  'user/getFavoriteTrips',
  async () =>
    _reverse(
      (await api.get<{ items: Array<BookmarkedTrip> }>('/favorite-stops')).data
        .items
    )
);

export const addTripToFavorites = createAsyncThunk<
  BookmarkedTrip,
  { origin: string; destination: string }
>(
  'user/addTripToFavorites',
  async (stopPair) => (await api.post('/favorite-stops', stopPair)).data
);

export const removeTripFromFavorites = createAsyncThunk<void, string>(
  'user/removeTripFromFavorites',
  async (pairId) => (await api.delete(`/favorite-stops/${pairId}`)).data
);

export const getPosConfiguration = createAsyncThunk<
  PosConfiguration,
  string | undefined
>(
  'user/getPosConfiguration',
  async (posId) =>
    // use random id if not provided
    (await api.get(`/agent-tool/${posId ?? v4()}/configuration`)).data
);

export const getApplications = createAsyncThunk<Array<ModuleItem>, void>(
  'user/getApplications',
  async () =>
    (
      await api.get('/api/applications', {
        baseURL: process.env.REACT_APP_AUTH_URL,
      })
    ).data
);
