import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import { selector } from 'store/utils';
import { RootState } from 'store';
import { IS_DS_AT, IS_IMS_AT } from 'utils/flags';

export const selectUser = selector((state: RootState) => state.user.current);

interface UserUnits {
  organizationId?: string;
  pointOfSaleId?: string;
  salesApplication?: string;
  salesUnitCode?: string;
  imsSalesUnitId?: string;
}

export const selectUserUnits = selector((state: RootState) => {
  const {
    organizationId,
    salesUnitCode,
    pointOfSaleId,
    imsSalesUnitId,
    salesApplication,
  } = state.user;

  const userUnits: UserUnits = {
    organizationId,
    pointOfSaleId,
    salesApplication,
  };

  if (salesUnitCode) {
    userUnits.salesUnitCode = salesUnitCode;
  } else if (imsSalesUnitId) {
    userUnits.imsSalesUnitId = imsSalesUnitId;
  }

  return userUnits;
});

export const selectFavoriteTrips = selector(
  (state: RootState) => state.user.favoriteTrips
);

export const selectShowRetailerModal = selector(
  (state: RootState) => state.user.showRetailerModal
);

export const selectPosConfiguration = selector(
  (state: RootState) => state.user.posConfiguration
);

export const selectSmsSendAvailable = selector((state: RootState) => {
  if (IS_IMS_AT) return true;
  const { organizationId, salesUnitCode, current } = state.user ?? {};
  const organization = current?.organizations.find(
    ({ id }) => id === organizationId
  );
  const activeUnit = organization?.units.find(
    ({ code }) => code === salesUnitCode
  );
  return activeUnit?.isSendingSmsAllowed;
});

export const selectEmailSendAvailable = selector((state: RootState) => {
  const conf = selectPosConfiguration(state);
  return !!(conf?.purchaseConfirmationEnabled || conf?.ticketDeliveryEnabled);
});

export const selectRequestorHeaderContent = selector((state: RootState) => {
  const userUnits = selectUserUnits(state);
  return _isEmpty(userUnits)
    ? ''
    : window.btoa(JSON.stringify(_omit(userUnits, 'organizationId')));
});

export const selectDefaultCurrency = selector(
  (state: RootState) => state.user.defaultCurrency
);

export const selectApplications = selector(
  (state: RootState) => state.user.applications
);
