import { OnHoldBookingOffer } from 'dto/booking';
import React, {
  FC,
  MouseEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
} from 'react';
import { Icon } from '@fleet/shared/mui';
import { alpha, Box, Tab, Tabs, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { OverflowEllipsis } from 'components/OverflowEllipsis';
import { PassengerCardState, TripSearchParams } from 'dto/trip';
import {
  deleteBooking,
  resetCurrentBooking,
} from 'features/booking/bookingActions';
import {
  addTab,
  closeTab,
  resetTab,
  setActiveTabIdx,
} from 'features/tabs/tabsActions';
import {
  activeTabIdxSelector,
  activeTabSelector,
  tabsSelector,
} from 'features/tabs/tabsSelector';
import { LocalStorage } from 'hooks/useLocalStorage';
import { TransButton } from 'i18n/trans/button';
import { useDispatch, useSelector } from 'store/utils';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { PurchaserDetailsPayload } from 'features/trip/tripActions';

const useStyles = makeStyles(
  (theme) => ({
    tabsIndicator: {
      display: 'none',
    },
    tab: {
      height: 40,
      textTransform: 'none',
      borderRadius: '.25rem .25rem 0 0',
      flexDirection: 'row',
      '&:hover': {
        color: theme.palette.primary.main,
      },
      '&.Mui-selected': {
        boxShadow: `0 2px 8px 0 ${alpha(theme.palette.common.black, 0.1)}`,
        backgroundColor: theme.palette.common.white,
      },
    },
  }),
  {
    name: 'SearchTabsProvider',
  }
);

export enum SearchType {
  tickets = 'tickets',
  travelPass = 'travelPass',
}

export type SearchTab = {
  activeStep: number;
  name?: string;
  summary?: {
    title: string;
    description: string;
  };
  params?: Partial<TripSearchParams>;
  cardState?: PassengerCardState;
  type: SearchType;
  purchaserData?: PurchaserDetailsPayload;
  bookingId?: string;
  isCompleted?: boolean;
  isCPPPayment?: boolean;
  addingAdditionalJourneyToCurrentPassenger?: boolean;
  addingJourneysBeforeCheckout?: boolean;
  lastActiveStep?: number;
  redirectToOverview?: boolean;
  modificationType?:
    | 'journey'
    | 'seatingAndAncillary'
    | 'seatingOnly'
    | 'addAncillaries'
    | 'addPassengers'
    | 'addSegments';
  onHoldOffer?: OnHoldBookingOffer;
};

interface SearchTabsProps {
  onTabChange: (nextTab: SearchTab) => void;
}

export const SearchTabs: FC<SearchTabsProps> = ({ onTabChange }) => {
  const dispatch = useDispatch();
  const activeTabIdx = useSelector(activeTabIdxSelector);
  const activeTab = useSelector(activeTabSelector);
  const tabs = useSelector(tabsSelector);
  const booking = useSelector(currentBookingSelector);
  const classes = useStyles();

  const getCloseTabHandler = useCallback(
    (idx: number) => async (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      const tabToClose = tabs[idx];

      if (tabToClose.bookingId && !tabToClose.isCompleted) {
        dispatch(resetTab(idx));
        await dispatch(
          deleteBooking({ bookingId: tabToClose.bookingId })
        ).unwrap();
      } else {
        dispatch(resetCurrentBooking());
      }

      dispatch(closeTab(idx));
    },
    [dispatch, tabs]
  );

  const tabChangeHandler = useCallback(
    (event: SyntheticEvent, nextIdx: number) => {
      if (!tabs[nextIdx]) return;

      if (booking && booking.id !== tabs[nextIdx].bookingId) {
        dispatch(resetCurrentBooking());
      }

      dispatch(setActiveTabIdx(nextIdx));
    },
    [booking, dispatch, tabs]
  );

  useEffect(() => {
    activeTab && onTabChange(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    sessionStorage.setItem(LocalStorage.TABS, JSON.stringify(tabs));
  }, [tabs]);

  useEffect(() => {
    sessionStorage.setItem(
      LocalStorage.ACTIVE_TAB,
      JSON.stringify(activeTabIdx)
    );
  }, [activeTabIdx]);

  return (
    <Tabs
      value={activeTabIdx}
      onChange={tabChangeHandler}
      classes={{
        indicator: classes.tabsIndicator,
      }}
    >
      {tabs.map(({ name }, idx) => (
        <Tab
          key={idx}
          value={idx}
          classes={{ root: classes.tab }}
          icon={
            <Box aria-label="close" onClick={getCloseTabHandler(idx)}>
              <Icon name="close" size={14} />
            </Box>
          }
          iconPosition="end"
          label={
            <OverflowEllipsis
              variant="subtitle"
              content={name || <TransButton i18nKey="newBooking" />}
              sx={{
                maxWidth: 150,
              }}
            />
          }
        />
      ))}
      <Tab
        icon={<Icon name="add" />}
        iconPosition="start"
        label={
          <Typography variant="subtitle">
            <TransButton i18nKey="newBooking" />
          </Typography>
        }
        onClick={() => {
          dispatch(resetCurrentBooking());
          dispatch(addTab());
        }}
      />
    </Tabs>
  );
};
