import {
  FormProvider,
  Modal,
  SelectField,
  useAuth,
  useForm,
  Button,
} from '@fleet/shared';
import { useModal } from '@fleet/shared/hooks';
import { Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  getPosConfiguration,
  selectRetailer,
  setShowRetalerModal,
  UserOrganization,
  UserUnit,
} from 'features/user/userActions';
import {
  selectShowRetailerModal,
  selectUser,
  selectUserUnits,
} from 'features/user/userSelector';
import { History } from 'history';
import { LocalStorage, useLocalStorage } from 'hooks/useLocalStorage';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransTitle } from 'i18n/trans/title';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import { IS_DS_AT, IS_IMS_AT } from 'utils/flags';

interface RetailerForm {
  organizationId: string;
  salesUnitCode?: string;
  imsSalesUnitId?: string;
  pointOfSaleId?: string;
}

const useStyles = makeStyles(
  () => ({
    root: {
      '& .MuiBackdrop-root': {
        background: 'rgba(0, 0, 0, 0.5)',
      },
    },
    paper: {
      margin: 0,
      maxWidth: 'none',
      width: 400,
    },
  }),
  { name: 'RetailerModal' }
);

const FORM_ID = 'retailer-selection';

interface RetailerModalProps {
  onOrganizationChange: (history: History) => Promise<void>;
}

export const RetailerModal: FC<RetailerModalProps> = ({
  onOrganizationChange,
}) => {
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const history = useHistory();
  const unitFieldName = IS_DS_AT ? 'salesUnitCode' : 'imsSalesUnitId';
  const { initialValue: lsUserUnits, setData } =
    useLocalStorage<RetailerForm | null>({
      key: LocalStorage.RETAILER_SELECTION,
      getDefaultValue: (v) => v,
      isSessionStorage: true,
    });
  const classes = useStyles();
  const retailerModalShown = useSelector(selectShowRetailerModal);
  const { open, onOpen } = useModal({ open: false });
  const user = useSelector(selectUser)!;
  const userUnits = useSelector(selectUserUnits);
  const dispatch = useDispatch();
  const prepareOptions = useCallback(
    (units?: Array<UserOrganization | UserUnit>) =>
      units?.map(({ name, id }) => ({ label: name, value: id })) ?? [],
    []
  );

  const handleRetailerSelect = useCallback(
    async (payload: RetailerForm) => {
      if (loading) return;
      try {
        setLoading(true);
        const { organizationId, pointOfSaleId } = payload;
        const selectedOrg = user?.organizations?.find(
          ({ id }) => id === organizationId
        );
        const prevOrganization = userUnits.organizationId;
        const salesApplication = selectedOrg?.pointsOfSale.find(
          (pos) => pos.id === pointOfSaleId
        )?.name;

        if (prevOrganization && organizationId !== prevOrganization) {
          await onOrganizationChange(history);
        }

        const posConfig = await dispatch(
          getPosConfiguration(pointOfSaleId)
        ).unwrap();

        const preparedPayload = {
          ...payload,
          salesApplication: salesApplication ?? posConfig.pointOfSaleName,
        };

        setData(preparedPayload);
        dispatch(selectRetailer(preparedPayload));

        dispatch(setShowRetalerModal(false));
      } catch (e) {}
      setLoading(false);
    },
    [
      loading,
      userUnits.organizationId,
      user?.organizations,
      dispatch,
      setData,
      onOrganizationChange,
      history,
    ]
  );

  const getSingleOption = useCallback(
    (options: Array<{ code?: string; id?: string }> | undefined) => {
      if (options?.length === 1) return options[0];
    },
    []
  );

  const initialValues = useMemo(() => {
    const primaryOrgId = user.primaryOrganization?.id;
    const organization =
      user.organizations.find(({ id }) => id === primaryOrgId) ??
      user.organizations[0];
    const unitValue =
      user.primaryUnit?.code ?? getSingleOption(organization?.units)?.code;

    return lsUserUnits?.organizationId
      ? {
          ...lsUserUnits,
          [unitFieldName]: lsUserUnits[unitFieldName] ?? unitValue,
        }
      : {
          organizationId: organization?.id,
          [unitFieldName]: unitValue,
          pointOfSaleId:
            user.primaryPointOfSale?.id ??
            getSingleOption(organization?.pointsOfSale)?.id,
        };
  }, [getSingleOption, lsUserUnits, user, unitFieldName]);

  const { form, handleSubmit, values } = useForm<RetailerForm>({
    onSubmit: handleRetailerSelect,
    initialValues,
    subscription: { values: true },
  });

  useEffect(() => {
    if (
      retailerModalShown &&
      !userUnits.organizationId &&
      !!lsUserUnits?.organizationId
    ) {
      handleRetailerSelect(lsUserUnits);
    } else if (retailerModalShown) {
      onOpen();
    }
  }, [
    dispatch,
    handleRetailerSelect,
    lsUserUnits,
    onOpen,
    retailerModalShown,
    userUnits,
  ]);

  const handleOrganizationChange = useCallback(() => {
    form.batch(() => {
      const fieldsToReset = ['pointOfSaleId', unitFieldName];
      fieldsToReset.forEach((field) =>
        form.change(field as keyof RetailerForm, undefined)
      );
    });
  }, [form, unitFieldName]);

  const currentOrganization = useMemo(
    () => user?.organizations?.find(({ id }) => id === values.organizationId),
    [user?.organizations, values.organizationId]
  );
  const unitOptions =
    currentOrganization?.units.map(({ name, code }) => ({
      label: name,
      value: code,
    })) ?? [];

  const pointOfSaleOptions = prepareOptions(currentOrganization?.pointsOfSale);
  const organizationOptions = prepareOptions(user?.organizations);

  const onCloseHandler = useCallback(() => {
    if (!userUnits.organizationId) {
      auth.signoutRedirect();
    }
    if (form.getState().invalid && !userUnits.organizationId)
      return form.submit();
    dispatch(setShowRetalerModal(false));
  }, [auth, dispatch, form, userUnits]);

  return (
    <Modal
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
      title={<TransTitle i18nKey="chooseRetailer" />}
      open={open}
      onClose={onCloseHandler}
      closeButton={false}
      actionButton={
        <Button
          form={FORM_ID}
          type="submit"
          variant="contained"
          loading={loading}
        >
          <TransButton i18nKey="confirm" />
        </Button>
      }
    >
      <FormProvider form={form}>
        <Stack
          component="form"
          id={FORM_ID}
          onSubmit={handleSubmit}
          spacing={2}
        >
          <SelectField
            name="organizationId"
            label={<TransField i18nKey="selectRetailer" />}
            options={organizationOptions}
            onChange={handleOrganizationChange}
            required
          />
          <SelectField
            name={unitFieldName}
            label={<TransField i18nKey="selectUnit" />}
            disabled={!values.organizationId}
            options={unitOptions}
            required
          />
          <SelectField
            name="pointOfSaleId"
            label={<TransField i18nKey="selectPointOfSale" />}
            disabled={!values.organizationId}
            options={pointOfSaleOptions}
            required={IS_IMS_AT}
          />
        </Stack>
      </FormProvider>
    </Modal>
  );
};
