import { Stack, Typography } from '@mui/material';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { Icon } from '@fleet/shared';
import { makeStyles } from '@mui/styles';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import _isEmpty from 'lodash/isEmpty';
import { FC, useMemo } from 'react';
import { DaysAfterCount } from 'components/DaysAfterCount';
import { useSelector } from 'store/utils';
import { prepareTrips } from 'utils/overview';
import { BookingTripWithAdmissions } from 'dto/booking';
import { TripStop } from 'dto/trip';

const useStyles = makeStyles(() => ({
  icon: {
    width: '34px',
    flexShrink: 0,
  },
}));

interface JourneySummariesProps {
  passengerSpecificTrip?: BookingTripWithAdmissions;
  idx?: number;
}

interface SummaryContent {
  id: string;
  alliances: string[];
  originStop: TripStop;
  departureTime: string;
  destinationStop: TripStop;
  arrivalTime: string;
}

const SummaryContent: FC<SummaryContent> = ({
  id,
  departureTime,
  arrivalTime,
  originStop,
  destinationStop,
  alliances,
}) => {
  const classes = useStyles();
  return (
    <Stack key={id} direction="row" alignItems="center" spacing={1}>
      <Typography variant="body2">
        {[departureTime, arrivalTime]
          .map((date) => formatDate(date, currentDateTimeFormat))
          .join(' - ')}
      </Typography>
      <DaysAfterCount startDate={departureTime} endDate={arrivalTime} />
      <Icon className={classes.icon} name="journey-changeover" />
      <Typography variant="body2" sx={{ fontWeight: 700 }}>
        {[originStop.name, destinationStop.name].join(' - ')}
      </Typography>
      {alliances.includes('RESPLUS') && (
        <Icon name="resplus-horizontal" height={15} width={53} />
      )}
    </Stack>
  );
};

export const JourneySummaries: FC<JourneySummariesProps> = ({
  passengerSpecificTrip,
}) => {
  const booking = useSelector(currentBookingSelector);
  const trips = useMemo(() => prepareTrips(booking?.bookedTrips), [booking]);
  const [origin, destination] = useMemo(
    () => (_isEmpty(trips) ? [] : [trips[0], trips[trips.length - 1]]),
    [trips]
  );

  if (!origin || !destination) return null;

  return (
    <Stack spacing={1}>
      {passengerSpecificTrip ? (
        <SummaryContent
          id={passengerSpecificTrip.id}
          alliances={passengerSpecificTrip.alliances}
          originStop={passengerSpecificTrip.originStop}
          departureTime={passengerSpecificTrip.departureTime}
          destinationStop={passengerSpecificTrip.destinationStop}
          arrivalTime={passengerSpecificTrip.arrivalTime}
        />
      ) : (
        trips.map(
          ({
            id,
            alliances,
            originStop,
            departureTime,
            destinationStop,
            arrivalTime,
          }) => (
            <SummaryContent
              key={id}
              id={id}
              alliances={alliances}
              originStop={originStop}
              departureTime={departureTime}
              destinationStop={destinationStop}
              arrivalTime={arrivalTime}
            />
          )
        )
      )}
    </Stack>
  );
};
