import { Divider, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Stepper } from 'components/Stepper';
import { BookingTripWithAdmissions, FulfillmentStatus } from 'dto/booking';
import { PassengerSpecification } from 'dto/trip';
import {
  bookingAdditionalOffersSelector,
  bookingAdmissionsSelector,
  currentBookingSelector,
} from 'features/booking/bookingSelectors';
import { addTab, closeTab } from 'features/tabs/tabsActions';
import { JourneyType } from 'features/trip/tripActions';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import _intersection from 'lodash/intersection';
import _isEqual from 'lodash/isEqual';
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { renderToString } from 'react-dom/server';
import { DirectionSelect } from 'routes/bookingDetails/modal/modify/DirectionSelect';
import { SearchExchangeJourney } from 'routes/bookingDetails/modal/modify/SearchExchangeJourney';
import { Ancillaries } from 'routes/tickets/checkout/Ancillaries';
import { Overview } from 'routes/tickets/checkout/Overview';
import { SeatSelection } from 'routes/tickets/checkout/SeatSelection';
import { useDispatch, useSelector } from 'store/utils';
import { getTripAdmissions } from 'utils/trip';

interface ModifyJourneyStepsProviderProps {
  type: 'journey' | 'seating';
  selectedTrips: Array<BookingTripWithAdmissions>;
  passengerIds: Array<string>;
  closeModal: () => void;
}
interface ModifyJourneyStepsContextType {
  type: ModifyJourneyStepsProviderProps['type'];
  selectedTrips: Array<BookingTripWithAdmissions>;
  passengerIds: Array<string>;
  selectedTripFulfillmentIds: Array<string>;
  closeModal: () => void;
  goToNextStep: () => void;
  selectedTripId?: string;
  setSelectedTripId: Dispatch<SetStateAction<string | undefined>>;
  selectedTripDirection?: JourneyType;
  modifyPassengerSpecifications: Array<PassengerSpecification>;
}

export const ModifyJourneyStepsContext =
  createContext<ModifyJourneyStepsContextType>(
    {} as ModifyJourneyStepsContextType
  );

const useStyles = makeStyles(
  () => ({
    root: {},
    divider: {
      margin: '0.5rem 0 2rem 0',
    },
  }),
  { name: 'ModifyJourneyStepsProvider' }
);

export const ModifyJourneyStepsProvider: FC<ModifyJourneyStepsProviderProps> =
  ({ type, closeModal, passengerIds, selectedTrips }) => {
    const [activeStep, setActiveStep] = useState(0);
    const booking = useSelector(currentBookingSelector)!;
    const dispatch = useDispatch();
    const classes = useStyles();
    const bookingAdmissions = useSelector(bookingAdmissionsSelector, _isEqual);
    const [selectedTripId, setSelectedTripId] = useState<string | undefined>(
      selectedTrips.length === 1 ? selectedTrips[0].id : undefined
    );
    const additionalOffers = useSelector(bookingAdditionalOffersSelector);
    const selectedTripDirection = useMemo<JourneyType>(
      () =>
        selectedTripId &&
        selectedTrips.find(({ id }) => id === selectedTripId)!.isOutbound
          ? 'outbound'
          : 'inbound',
      [selectedTripId, selectedTrips]
    );
    const selectedTripFulfillmentIds = useMemo(() => {
      if (!selectedTripId) return [];
      return getTripAdmissions(
        selectedTrips.find(({ id }) => id === selectedTripId)!
      ).reduce<Array<string>>(
        (ids, { fulfillments, passengerIds: admissionPassengerIds }) => [
          ...ids,
          ...(_intersection(admissionPassengerIds, passengerIds).length
            ? fulfillments.map(({ id }) => id)
            : []),
        ],
        []
      );
    }, [selectedTripId, selectedTrips, passengerIds]);

    const steps = useMemo(
      () =>
        type === 'journey'
          ? ([
              'direction',
              'searchJourney',
              'seatSelection',
              'addons',
              'overview',
            ] as const)
          : ([
              ...(selectedTrips.length > 1 ? ['direction' as const] : []),
              'changeSeatClass',
              'seatSelection',
              'addons',
              'overview',
            ] as const),
      [type, selectedTrips.length]
    );

    const componentsMap = useMemo(
      () => ({
        direction: DirectionSelect,
        searchJourney: SearchExchangeJourney,
        changeSeatClass: SearchExchangeJourney,
        seatSelection: SeatSelection,
        addons: Ancillaries,
        overview: Overview,
      }),
      []
    );

    const goToNextStep = useCallback(() => {
      const shouldSkipStep =
        steps[activeStep] === 'seatSelection' && !additionalOffers?.length;
      const nextStepIdx = activeStep + (shouldSkipStep ? 2 : 1);
      if (nextStepIdx < steps.length) {
        setActiveStep(nextStepIdx);
      }
    }, [activeStep, additionalOffers, steps]);

    const modifyPassengerSpecifications = useMemo<PassengerSpecification[]>(
      () =>
        booking.passengers
          .filter(({ id }) => passengerIds.includes(id))
          .map(
            ({ externalReference, age, prmNeeds, cards, type = 'PERSON' }) => {
              return {
                age,
                cards,
                externalReference,
                prmNeeds: prmNeeds.value,
                type,
              };
            }
          ),
      [booking.passengers, passengerIds]
    );
    const activeStepName = useMemo(
      () => steps[activeStep],
      [activeStep, steps]
    );

    const disabledSteps = useMemo(
      () =>
        bookingAdmissions.some(
          ({ status }) => status === FulfillmentStatus.PREBOOKED
        )
          ? ['direction', 'searchJourney', 'changeSeatClass']
          : [],
      [bookingAdmissions]
    );

    const CurrentStepComponent = useCallback(() => {
      const Component = componentsMap[activeStepName];
      const submitLabel =
        activeStepName === 'overview' ? (
          <TransButton i18nKey="confirmModification" />
        ) : (
          <TransButton i18nKey="continue" />
        );
      return (
        <Component
          goToNextStep={goToNextStep}
          isModifyFlow
          submitLabel={submitLabel}
        />
      );
    }, [activeStepName, componentsMap, goToNextStep]);

    const value: ModifyJourneyStepsContextType = useMemo(
      () => ({
        type,
        selectedTrips,
        closeModal,
        goToNextStep,
        passengerIds,
        selectedTripFulfillmentIds,
        selectedTripId,
        setSelectedTripId,
        selectedTripDirection,
        modifyPassengerSpecifications,
      }),
      [
        type,
        selectedTrips,
        closeModal,
        passengerIds,
        goToNextStep,
        selectedTripFulfillmentIds,
        selectedTripId,
        selectedTripDirection,
        modifyPassengerSpecifications,
      ]
    );

    useEffect(() => {
      dispatch(addTab());

      return () => {
        dispatch(closeTab());
      };
    }, [dispatch]);

    return (
      <Stack className={classes.root}>
        <Stepper
          activeStepIdx={activeStep}
          goToStep={setActiveStep}
          steps={steps.map((step) => ({
            label: renderToString(<TransSubtitle i18nKey={step} />),
            value: step,
            disabled: disabledSteps.includes(step),
          }))}
        />
        <Divider flexItem className={classes.divider} />
        <ModifyJourneyStepsContext.Provider value={value}>
          <CurrentStepComponent />
        </ModifyJourneyStepsContext.Provider>
      </Stack>
    );
  };
