import { FieldArray, FormProvider, Modal, useForm } from '@fleet/shared';
import { useModal } from '@fleet/shared/hooks';
import { Button } from '@fleet/shared/mui';
import { Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ModifyJourneyStepsContext } from 'components/ModifyJourneyStepsProvider';
import { TagGroup } from 'components/Tag';
import { PRM_NEEDS, SeatProperty } from 'dto/booking';
import { PassengerOfferSelection, TripOffer } from 'dto/trip';
import { activeTabSelector } from 'features/tabs/tabsSelector';
import {
  clearOfferSelection,
  updateOfferSelection,
} from 'features/trip/tripActions';
import {
  selectSelectedOffers,
  tripsSelector,
} from 'features/trip/tripSelector';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransLabel } from 'i18n/trans/label';
import { TransTitle } from 'i18n/trans/title';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import { FC, useCallback, useContext, useMemo } from 'react';
import { SeatPropertiesSelectField } from 'routes/tickets/searchResults/SeatPropertiesSelectField';
import { useDispatch, useSelector } from 'store/utils';

interface SeatPreferenceModalProps {
  offer: TripOffer;
  onClose: () => void;
}

type Selection = PassengerOfferSelection & {
  placePropertiesStr?: string;
};

interface SeatPreferenceForm {
  selections: Array<Selection>;
}

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      margin: 0,
      maxWidth: 'none',
      width: 776,
    },
    number: {
      color: theme.palette.text.secondary,
      '& span': {
        color: theme.palette.text.primary,
        marginRight: 4,
      },
    },
  }),
  { name: 'SeatPreferenceModal' }
);

const FORM_ID = 'seat-preferences';

export const SeatPreferenceModal: FC<SeatPreferenceModalProps> = ({
  offer,
  onClose,
}) => {
  const { outbound: selectedOutboundOffers, inbound: selectedInboundOffers } =
    useSelector(selectSelectedOffers);
  const { reservationOfferParts, id, reservationLegCoverage } = offer;
  const { open } = useModal({ open: true });
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentTab = useSelector(activeTabSelector);
  const { modifyPassengerSpecifications } = useContext(
    ModifyJourneyStepsContext
  );
  const { inboundTrips } = useSelector(tripsSelector);
  const isRoundTrip = !!inboundTrips.length;
  const hasSelectedAnyOffer = isRoundTrip
    ? !!selectedInboundOffers.trips.length &&
      !!selectedOutboundOffers.trips.length
    : !!selectedOutboundOffers.trips.length;
  const journeyType = selectedOutboundOffers.trips.some(
    (offer) => offer.id === id
  )
    ? 'outbound'
    : 'inbound';
  const passengerSpecifications = useMemo(
    () =>
      modifyPassengerSpecifications ??
      currentTab!.params!.passengerSpecifications!,
    [currentTab, modifyPassengerSpecifications]
  );

  const onSubmit = useCallback(
    (payload: SeatPreferenceForm) => {
      const preparedSelections = payload.selections
        .filter(({ placePropertiesStr }) => placePropertiesStr)
        .map(({ placePropertiesStr, ...rest }) => ({
          ...rest,
          placeProperties: (placePropertiesStr?.split(',') ??
            []) as SeatProperty[],
        }));
      const emptySelection = preparedSelections.every(
        ({ placeProperties }) => !placeProperties?.length
      );
      if (emptySelection) {
        dispatch(clearOfferSelection({ offerId: offer.id, journeyType }));
      } else {
        dispatch(
          updateOfferSelection({
            journeyType,
            offerId: offer.id,
            selections: preparedSelections,
          })
        );
      }
      onClose();
    },
    [dispatch, offer.id, onClose, journeyType]
  );
  const propertiesPerReservation = useMemo(() => {
    const reservationLegCoverageMap = groupBy(
      reservationLegCoverage,
      'reservationId'
    );
    return mapValues(reservationLegCoverageMap, (legCoverages) =>
      legCoverages
        .map(({ placeProperties }) => placeProperties)
        .filter((props) => props?.length)
    );
  }, [reservationLegCoverage]);

  const initialValues = useMemo<SeatPreferenceForm>(() => {
    const reservationsData = keyBy(reservationLegCoverage, 'reservationId');
    const defaultValues = reservationOfferParts.map(
      ({ availablePlaces, passengerRefs, reservationId }) => {
        const { accommodationType, accommodationSubType, legId, tripId } =
          availablePlaces[0] || reservationsData[reservationId];
        return {
          passengerIds: passengerRefs,
          tripLegCoverage: {
            tripId,
            legId,
          },
          placeProperties: [],
          accommodationType,
          accommodationSubType,
          reservationId,
          placePropertiesStr: '',
        };
      }
    );
    const savedSelections = [
      ...(selectedOutboundOffers.tripsSelectionMap[id] ?? []),
      ...(selectedInboundOffers.tripsSelectionMap[id] ?? []),
    ]?.map(({ placeProperties, ...rest }) => ({
      placePropertiesStr: placeProperties.join(','),
      placeProperties,
      ...rest,
    }));
    return {
      selections: savedSelections?.length ? savedSelections : defaultValues,
    };
  }, [
    id,
    reservationOfferParts,
    selectedInboundOffers.tripsSelectionMap,
    selectedOutboundOffers.tripsSelectionMap,
    reservationLegCoverage,
  ]);

  const { form, handleSubmit, values } = useForm<SeatPreferenceForm>({
    onSubmit,
    initialValues,
    subscription: { values: true },
  });

  const applyCurrentPreferenceToOffers = useCallback(() => {
    dispatch(updateOfferSelection({ ...values, journeyType }));
  }, [dispatch, values, journeyType]);
  return (
    <Modal
      classes={{
        paper: classes.paper,
      }}
      title={<TransTitle i18nKey="seatPreference" />}
      open={open}
      onClose={onClose}
      cancelButton={false}
      closeButton={false}
      actionButton={
        <Stack direction="row" spacing={2}>
          {hasSelectedAnyOffer && (
            <Button variant="outlined" onClick={applyCurrentPreferenceToOffers}>
              <TransButton i18nKey="applySeatPreferenceToAll" />
            </Button>
          )}
          <Button variant="contained" form={FORM_ID} type="submit">
            <TransButton i18nKey="confirmSelection" />
          </Button>
        </Stack>
      }
    >
      <FormProvider form={form}>
        <Grid
          component="form"
          id={FORM_ID}
          container
          columns={2}
          spacing={3}
          rowSpacing={3}
          onSubmit={handleSubmit}
        >
          <FieldArray<PassengerOfferSelection> name="selections">
            {({ fields }) =>
              fields.value?.map((value, idx, values) => {
                const { reservationId } = value;
                const passengerOrder = passengerSpecifications.map(
                  ({ externalReference }) => externalReference
                );
                const multipleLegSelection =
                  values.length > passengerSpecifications.length;
                const { passengerIds } = value;
                const prmNeeds = passengerSpecifications.find(
                  ({ externalReference }) =>
                    passengerIds.includes(externalReference)
                )!.prmNeeds;

                return (
                  <Grid item key={idx} xs={1}>
                    <Stack spacing={2}>
                      <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" alignItems="center" gap={0.5}>
                          <Typography
                            variant="subtitle"
                            className={classes.number}
                          >
                            <span>{`${
                              passengerOrder.indexOf(passengerIds[0]) + 1
                            }/${passengerSpecifications.length}`}</span>
                            <TransLabel i18nKey="passenger" />
                          </Typography>
                          {multipleLegSelection && (
                            <Typography variant="body1" color="text.secondary">
                              <TransLabel
                                i18nKey="legNumberHint"
                                values={{
                                  number:
                                    Math.floor(
                                      idx / passengerSpecifications.length
                                    ) + 1,
                                }}
                              />
                            </Typography>
                          )}
                        </Stack>
                        {prmNeeds && (
                          <TagGroup
                            title={<TransLabel i18nKey="prmNeed" />}
                            texts={prmNeeds.map((code) => (
                              <TransLabel i18nKey={code as PRM_NEEDS} />
                            ))}
                            color="action"
                            subColor="hoverText"
                            variant="body2"
                          />
                        )}
                      </Stack>
                      <SeatPropertiesSelectField
                        label={<TransField i18nKey="preference" />}
                        name={`${fields.name}[${idx}].placePropertiesStr`}
                        availableProperties={
                          propertiesPerReservation[reservationId]
                        }
                      />
                    </Stack>
                  </Grid>
                );
              })
            }
          </FieldArray>
        </Grid>
      </FormProvider>
    </Modal>
  );
};
