import { SelectField } from '@fleet/shared';
import { SeatProperty } from 'dto/booking';
import { TransLabel } from 'i18n/trans/label';
import { FC, ReactNode, useCallback, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { getReadablePropertyName } from 'utils/trip';

interface SeatPropertiesSelectFieldProps {
  multiple?: boolean;
  label: ReactNode;
  name: string;
  availableProperties: Array<Array<SeatProperty>>;
}
export const SeatPropertiesSelectField: FC<SeatPropertiesSelectFieldProps> = ({
  label,
  name,
  multiple,
  availableProperties,
}) => {
  const getPropertiesTranslation = useCallback(
    (placeProperties: SeatProperty[]) => {
      try {
        return placeProperties
          .map((property) => {
            try {
              return renderToString(<TransLabel i18nKey={property} />);
            } catch (e) {
              return getReadablePropertyName(property);
            }
          })
          .join(', ');
      } catch (e) {
        placeProperties.join(', ');
      }
    },
    []
  );

  const propertiesOptions = useMemo(
    () =>
      availableProperties.map((placeProperties) => ({
        label: getPropertiesTranslation(placeProperties),
        value: placeProperties.join(','),
      })),
    [availableProperties, getPropertiesTranslation]
  );

  return (
    <SelectField
      label={label}
      name={name}
      showEmptyOption={!multiple}
      multiple={multiple}
      placeholder={renderToString(<TransLabel i18nKey="noPreference" />)}
      disabled={!propertiesOptions.length}
      options={propertiesOptions}
    />
  );
};
