import { createAction } from '@reduxjs/toolkit';
import { SearchTab } from 'routes/tickets/SearchTabs';

export const addTab = createAction<SearchTab | undefined>('tabs/addTab');

export const closeTab = createAction<number | undefined>('tabs/closeTab');
export const resetTab = createAction<number | undefined>('tabs/resetTab');

export const updateTab = createAction<Partial<SearchTab> & { tabIdx?: number }>(
  'tabs/updateTab'
);

export const setActiveTabIdx = createAction<number>('tabs/setActiveTabIdx');
