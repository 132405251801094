import { Stack } from '@mui/material';
import { FC } from 'react';
import { TripLeg } from 'dto/trip';
import { TransportBadge } from 'components/TransportBadge';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { renderToString } from 'react-dom/server';
import { ServiceAttributeIcon } from 'components/ServiceAttributeIcon';
import { getTransportationLabel } from 'utils/trip';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      borderRadius: '0.1875rem',
      overflow: 'hidden',
      cursor: 'pointer',
      color: theme.palette.text.primary + '!important',
    },
    amenityWrap: {
      background: theme.palette.background.default,
    },
    whiteBg: {
      background: theme.palette.common.white,
    },
  }),
  { name: 'LegInfo' }
);

export const LegInfo: FC<
  TripLeg & { isBgWhite?: boolean; className?: string }
> = (props) => {
  const { className, isBgWhite, ...leg } = props;
  const { serviceAttributes, ptMode } = leg;
  const classes = useStyles();
  return (
    <Stack
      direction="row"
      alignItems="stretch"
      className={classNames(classes.root, className)}
      flexWrap="wrap"
    >
      <TransportBadge
        ptMode={ptMode}
        label={getTransportationLabel(
          leg,
          renderToString(<TransSubtitle i18nKey="unknownCarrier" />)
        )}
        rounded={!serviceAttributes.length}
      />
      <Stack
        direction="row"
        alignItems="center"
        className={classNames(classes.amenityWrap, {
          [classes.whiteBg]: isBgWhite,
        })}
      >
        {serviceAttributes.map((attr) => (
          <ServiceAttributeIcon key={attr.code} {...attr} />
        ))}
      </Stack>
    </Stack>
  );
};
