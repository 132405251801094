import {
  ConfirmButton,
  Icon,
  Table,
  TableColumns,
  Tooltip,
  useTable,
} from '@fleet/shared';
import { IndeterminateCheckbox } from '@fleet/shared/hooks/useRowSelectCheckbox';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { Box, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { HintWrapper } from 'components/HintWrapper';
import { PriceWithFee } from 'components/PriceWithFee';
import { BookingAdmission } from 'dto/booking';
import { TripLeg } from 'dto/trip';
import {
  getBooking,
  removeAdmissionsFromPassenger,
  resetCurrentBooking,
} from 'features/booking/bookingActions';
import {
  bookedOffersFeesMapSelector,
  currentBookingSelector,
} from 'features/booking/bookingSelectors';
import { resetTab } from 'features/tabs/tabsActions';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransLabel } from 'i18n/trans/label';
import { TransParagraph } from 'i18n/trans/paragraph';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import { TransTitle } from 'i18n/trans/title';
import _capitalize from 'lodash/capitalize';
import _startCase from 'lodash/startCase';
import { FC, Fragment, useCallback, useMemo } from 'react';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom';
import { CellProps, useRowSelect, useSortBy } from 'react-table';
import { useDispatch, useSelector } from 'store/utils';
import {
  canShowAdmissionPrice,
  getAdmissionsTotalPrice,
  isAdmissionInactive,
} from 'utils/trip';

export enum PassengerAdmissionsColumns {
  selection = 'selection',
  status = 'status',
  ticketNumber = 'ticketNumber',
  origin = 'origin',
  destination = 'destination',
  productName = 'productName',
  appliedPassengerType = 'appliedPassengerType',
  passengerCards = 'passengerCards',
  appliedCodes = 'appliedCodes',
  flexibility = 'flexibility',
  price = 'price',
  refundableAmount = 'refundableAmount',
}

interface PassengerAdmissionsTableProps {
  isOverview?: boolean;
  selected?: Array<string>;
  currentPassengerId: string;
  data: Array<BookingAdmission>;
  onRowSelectionUpdate?: (selection: Array<string>) => void;
  hiddenColumns?: Array<
    PassengerAdmissionsColumns[keyof PassengerAdmissionsColumns]
  >;
}

const useStyles = makeStyles(
  (theme) => ({
    selectionDisabled: {
      '& > td:first-of-type': {
        cursor: 'not-allowed',
        '& label': {
          pointerEvents: 'none',
        },
      },
    },
    strikeThrough: {
      textDecoration: 'line-through',
      opacity: 0.75,
    },
    table: {
      '& thead > tr': {
        background: theme.palette.common.white,
      },
      '& tbody > tr': {
        background: theme.palette.background.default,
      },
    },
    cancelled: {},
  }),
  {
    name: 'PassengerAdmissionsTable',
  }
);
export const PassengerAdmissionsTable: FC<PassengerAdmissionsTableProps> = ({
  selected,
  data,
  onRowSelectionUpdate,
  hiddenColumns,
  currentPassengerId,
  isOverview,
}) => {
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { bookedTrips, passengers, id } = useSelector(currentBookingSelector)!;
  const offerFeesMap = useSelector(bookedOffersFeesMapSelector);
  const preparedSelectedRows = useMemo(
    () =>
      selected?.reduce((selection, id) => ({ ...selection, [id]: true }), {}) ??
      {},
    [selected]
  );
  const dataIds = useMemo(() => data.map(({ id }) => id), [data]);
  const bookingLegs = useMemo(
    () =>
      bookedTrips.reduce<Array<TripLeg>>(
        (acc, trip) => [...acc, ...trip.legs],
        []
      ),
    [bookedTrips]
  );
  const getAdmissionLeg = useCallback(
    (coveredLegIds: Array<string>, type: 'origin' | 'destination') => {
      const admissionLegs = coveredLegIds
        .map((legId) => bookingLegs.find(({ id }) => id === legId)!)
        .sort(
          (a, b) =>
            new Date(a.departureTime).getTime() -
            new Date(b.departureTime).getTime()
        );
      if (type === 'origin') return admissionLegs[0];
      else return admissionLegs[admissionLegs.length - 1];
    },
    [bookingLegs]
  );

  const getPassengerTypeByAdmission = useCallback(
    (admissionId, passengerId) => {
      const admission = data.find((admission) => admission.id === admissionId);

      if (admission) {
        const passengerTypeEntry = admission.appliedPassengerTypes.find(
          (passengerType) => passengerType.passengerRef === passengerId
        );
        return passengerTypeEntry ? passengerTypeEntry.description : undefined;
      }

      return undefined;
    },
    [data]
  );

  const columns = useMemo<TableColumns<BookingAdmission>>(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => {
          const { onChange, ...props } = getToggleAllRowsSelectedProps();
          return (
            <IndeterminateCheckbox
              {...props}
              onChange={(e) =>
                isOverview
                  ? onChange?.(e)
                  : onRowSelectionUpdate?.(props.checked ? [] : dataIds)
              }
            />
          );
        },
        Cell: ({ row }: CellProps<BookingAdmission>) => {
          const { onChange, ...props } = row.getToggleRowSelectedProps();

          return (
            <IndeterminateCheckbox
              {...props}
              onChange={(e) => {
                if (isOverview) {
                  onChange?.(e);
                } else {
                  onRowSelectionUpdate?.(
                    props.checked
                      ? selected!.filter((id) => id !== row.original.id)
                      : selected!.concat(row.original.id)
                  );
                }
              }}
            />
          );
        },
        width: 40,
      },
      {
        id: 'departureTime',
        accessor: ({ coveredLegIds }: BookingAdmission) =>
          getAdmissionLeg(coveredLegIds, 'origin').departureTime,
      },
      {
        id: PassengerAdmissionsColumns.status,
        Header: <TransTableHead i18nKey="status" />,
        accessor: ({ status }) => <TransField i18nKey={status} />,
        width: 90,
      },
      {
        id: PassengerAdmissionsColumns.ticketNumber,
        Header: <TransTableHead i18nKey="ticketNr" />,
        accessor: ({ fulfillments }) =>
          fulfillments.map(({ controlNumber }) => controlNumber).join(', '),
      },
      ...(['origin', 'destination'] as const).map((place) => ({
        id: PassengerAdmissionsColumns[place],
        Header: <TransTableHead i18nKey={place} />,
        accessor: ({ coveredLegIds, status }: BookingAdmission) => {
          const leg = getAdmissionLeg(coveredLegIds, place);
          return (
            leg && (
              <Stack
                className={classNames({
                  [classes.strikeThrough]: isAdmissionInactive({ status }),
                })}
              >
                <Typography variant="body2">
                  {formatDate(
                    leg[place === 'origin' ? 'departureTime' : 'arrivalTime'],
                    currentDateTimeFormat
                  )}
                </Typography>
                <Typography variant="body2" fontWeight="bold">
                  {
                    leg[place === 'origin' ? 'originStop' : 'destinationStop']
                      .name
                  }
                </Typography>
              </Stack>
            )
          );
        },
      })),
      {
        id: PassengerAdmissionsColumns.productName,
        Header: <TransTableHead i18nKey="productName" />,
        accessor: ({
          summary,
          productSummary,
          productDescriptiveTexts,
        }: BookingAdmission) => (
          <HintWrapper
            iconPlacement="start"
            iconColor="warning"
            content={productSummary || summary}
            hint={
              !!productDescriptiveTexts?.length && (
                <Stack>
                  {productDescriptiveTexts.map(({ description }, idx) => (
                    <Typography key={idx}>{description}</Typography>
                  ))}
                </Stack>
              )
            }
          />
        ),
      },
      {
        id: PassengerAdmissionsColumns.appliedPassengerType,
        Header: <TransTableHead i18nKey="passengerType" />,
        accessor: (admission: BookingAdmission) => {
          return getPassengerTypeByAdmission(admission.id, currentPassengerId);
        },
      },
      {
        id: PassengerAdmissionsColumns.passengerCards,
        Header: <TransTableHead i18nKey="passengerCards" />,
        accessor: ({ appliedReductions }) => (
          <Typography variant="body2">
            {appliedReductions
              .filter(({ type }) => type === 'CARD')
              .map(({ name }) => name)}
          </Typography>
        ),
      },
      {
        id: PassengerAdmissionsColumns.appliedCodes,
        Header: <TransTableHead i18nKey="appliedCodes" />,
        accessor: ({ appliedReductions }) => (
          <Stack>
            {appliedReductions
              .filter(({ type }) => type !== 'CARD')
              .map(({ type, code }) => (
                <Tooltip
                  key={code}
                  content={
                    <Typography>
                      <TransLabel i18nKey={type} />
                    </Typography>
                  }
                >
                  <Stack direction="row">
                    <Icon name="voucher" margin />
                    <Typography variant="body2">{code}</Typography>
                  </Stack>
                </Tooltip>
              ))}
          </Stack>
        ),
      },
      {
        id: PassengerAdmissionsColumns.flexibility,
        Header: <TransTableHead i18nKey="flexibility" />,
        accessor: ({ flexibility, ...admission }) => (
          <HintWrapper
            content={_capitalize(_startCase(flexibility))}
            hint={
              <Stack direction="row" spacing={0.5}>
                {(['exchangeable', 'refundable'] as const).map((field) => (
                  <Fragment key={field}>
                    <Typography variant="body2" fontWeight="bold">
                      <TransSubtitle i18nKey={field} />:
                    </Typography>
                    <Typography variant="body2">
                      <TransLabel i18nKey={admission[field]} />
                    </Typography>
                  </Fragment>
                ))}
              </Stack>
            }
          />
        ),
        width: 80,
      },
      {
        id: PassengerAdmissionsColumns.price,
        Header: <TransTableHead i18nKey="price" />,
        accessor: (admission) =>
          canShowAdmissionPrice(admission, currentPassengerId, passengers) && (
            <PriceWithFee
              {...getAdmissionsTotalPrice({
                admissions: [admission],
                offerFeesMap,
              })}
            />
          ),
        width: '7rem',
      },
    ],
    [
      onRowSelectionUpdate,
      dataIds,
      selected,
      isOverview,
      getAdmissionLeg,
      classes.strikeThrough,
      getPassengerTypeByAdmission,
      currentPassengerId,
      passengers,
      offerFeesMap,
    ]
  );

  const table = useTable(
    {
      data: useMemo(() => data, [data]),
      columns,
      getRowId: ({ id }) => id,
      useControlledState: (state) => ({
        ...state,
        ...(!isOverview && { selectedRowIds: preparedSelectedRows }),
        sortBy: [{ id: 'departureTime', desc: false }],
        hiddenColumns: ['departureTime', ...(hiddenColumns as Array<string>)],
      }),
    },
    useSortBy,
    useRowSelect
  );

  const handleRowsDeleted = useCallback(async () => {
    const selectedIds = table.selectedFlatRows.map((row) => row.original.id);

    const { isBookingDeleted } = await dispatch(
      removeAdmissionsFromPassenger({ bookingId: id!, ids: selectedIds })
    ).unwrap();

    if (isBookingDeleted) {
      alert.info(<TransParagraph i18nKey="bookingRemovedNoPassenger" />);
      dispatch(resetTab());
      dispatch(resetCurrentBooking());
      history.replace('/search');
    } else {
      await dispatch(getBooking(id)).unwrap();
    }
  }, [table.selectedFlatRows, id, dispatch, alert, history]);

  return (
    <>
      {isOverview && (
        <Box
          sx={{ marginLeft: 'auto', marginRight: '0', width: 'fit-content' }}
        >
          <ConfirmButton
            onConfirm={handleRowsDeleted}
            variant="text"
            color="error"
            startIcon={<Icon name="delete" />}
            disabled={!Object.keys(table.state.selectedRowIds).length}
            title={<TransTitle i18nKey="deleteConfirmation" />}
            description={<TransParagraph i18nKey="deleteSelectedPassengers" />}
          >
            <TransButton i18nKey="delete" />
          </ConfirmButton>
        </Box>
      )}
      <Table table={table} classes={{ table: classes.table }} />
    </>
  );
};
