import { makeStyles } from '@mui/styles';
import { FC, ReactNode } from 'react';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: 'auto',
    '&$small': {
      alignItems: 'center',
    },
    '&$small $connectIcon': {
      height: 12,
    },
  },
  tripIcon: {
    marginRight: 16,
    '& $tripPoint': {
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      border: `2px solid ${theme.palette.divider}`,
      boxSizing: 'border-box',
      '&:last-of-type': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& $connectIcon': {
      width: '2px',
      background: theme.palette.divider,
      margin: '0 auto',
      height: 'calc(100% - 24px)',
    },
  },
  tripIconHorizontal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 8,
    marginLeft: 8,
    '& $tripPoint': {
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      border: `2px solid ${theme.palette.divider}`,
      boxSizing: 'border-box',
      '&:last-of-type': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& $connectIcon': {
      width: '10px',
      height: '3px!important',
      background: theme.palette.divider,
    },
  },
  tripPoint: {},
  connectIcon: {},
  tripInfo: {
    flex: 1,
  },
  small: {},
}));

interface TripInfoWrapperProps {
  className?: string;
  small?: boolean;
  horizontal?: boolean;
  children: ReactNode | ReactNode[];
}

export const LegWrapper: FC<TripInfoWrapperProps> = ({
  className,
  children,
  small,
  horizontal,
}) => {
  const classes = useStyles();
  return (
    <div
      className={classNames(classes.root, className, {
        [classes.small]: small,
      })}
    >
      {horizontal && Array.isArray(children) ? (
        <>
          <div className={classes.tripInfo}>
            <>{children[0]}</>
          </div>
          <div className={classes.tripIconHorizontal}>
            <div className={classes.tripPoint} />
            <div className={classes.connectIcon} />
            <div className={classes.tripPoint} />
          </div>
          <div className={classes.tripInfo}>
            <>{children[1]}</>
          </div>
        </>
      ) : (
        <>
          <div className={classNames(classes.tripIcon)}>
            <div className={classes.tripPoint} />
            <div className={classes.connectIcon} />
            <div className={classes.tripPoint} />
          </div>
          <div className={classes.tripInfo}>
            <>{children}</>
          </div>
        </>
      )}
    </div>
  );
};
