import { TabPanel, Tabs } from '@fleet/shared';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  BookingAdmissionAncillary,
  BookingDetailsPassenger,
  BookingTripWithAdmissions,
  PassengerFee,
} from 'dto/booking';
import { TripLeg } from 'dto/trip';
import {
  resetAdmissionSelection,
  updateBookingPartsSelection,
} from 'features/booking/bookingActions';
import {
  bookingPartsSelector,
  currentBookingSelector,
  isTravelPassBookingSelector,
} from 'features/booking/bookingSelectors';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FC, useCallback, useMemo } from 'react';
import { Fees } from 'routes/bookingDetails/Fees';
import {
  PassengerAdmissionsColumns,
  PassengerAdmissionsTable,
} from 'routes/bookingDetails/PassengerAdmissionsTable';
import { PassengerLegs } from 'routes/bookingDetails/passengerSubRow/PassengerLegs';
import { AddonsTable } from 'routes/tickets/checkout/AddonsTable';
import { useDispatch, useSelector } from 'store/utils';
import { getBookingAdmissions, getTripAdmissions } from 'utils/trip';
import { PassengerTravelPass } from '../tabs/PassengerTravelPass';
import { PassengerData } from './PassengerData';

interface PassengerTabsProps {
  row: BookingDetailsPassenger;
  isOverview?: boolean;
  trip?: BookingTripWithAdmissions;
  isPayOnHoldBooking?: boolean;
}

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      background: theme.palette.background.default,
      padding: '8px 16px',
    },
  }),
  {
    name: 'PassengerTabs',
  }
);

export const PassengerSubRow: FC<PassengerTabsProps> = ({
  row: { id: passengerId },
  isOverview,
  isPayOnHoldBooking,
  trip,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selection = useSelector(bookingPartsSelector);
  const isTravelPassBooking = useSelector(isTravelPassBookingSelector);
  const booking = useSelector(currentBookingSelector)!;
  const { bookedTrips, passengers } = booking;
  const preparedTrips = useMemo(
    () => (trip ? [trip] : bookedTrips),
    [bookedTrips, trip]
  );
  const fees = useMemo(
    () =>
      preparedTrips.reduce<Array<PassengerFee>>(
        (fees, { bookedOffers }) => [
          ...fees,
          ...bookedOffers.flatMap(({ fees }) => fees),
        ],
        []
      ),
    [preparedTrips]
  );
  const preparedAdmissions = useMemo(
    () =>
      trip
        ? getTripAdmissions(trip).filter(({ passengerIds }) => passengerIds)
        : getBookingAdmissions(booking),
    [booking, trip]
  );

  const currentPassenger = useMemo(
    () => passengers.find(({ id }) => id === passengerId)!,
    [passengers, passengerId]
  );
  const bookingLegs = useMemo(
    () => preparedTrips.map(({ legs }) => legs).flat(),
    [preparedTrips]
  );
  const passengerAdmissions = useMemo(
    () =>
      preparedAdmissions.filter(({ passengerIds }) =>
        passengerIds.includes(passengerId)
      ),
    [preparedAdmissions, passengerId]
  );
  const passengerLegs = useMemo(
    () =>
      passengerAdmissions.reduce<Array<TripLeg>>(
        (legs, { coveredLegIds, travelClass }) => {
          return [
            ...legs,
            ...coveredLegIds.map((legId) => ({
              ...bookingLegs.find(({ id }) => id === legId)!,
              travelClass,
            })),
          ].sort(
            (a, b) =>
              new Date(a.departureTime).getTime() -
              new Date(b.departureTime).getTime()
          );
        },
        []
      ),
    [bookingLegs, passengerAdmissions]
  );
  const passengerAncillaries = useMemo(
    () =>
      passengerAdmissions.reduce<Array<BookingAdmissionAncillary>>(
        (ancillaries, admission) => [...ancillaries, ...admission.ancillaries],
        []
      ),
    [passengerAdmissions]
  );

  const passengerFees = useMemo(() => {
    const passengerFeeIds = passengerAdmissions.reduce<Array<string>>(
      (ids, { feeRefs, reservations }) => [
        ...ids,
        ...feeRefs,
        ...reservations.map(({ feeRefs }) => feeRefs).flat(),
      ],
      []
    );
    return fees.filter(({ id }) => passengerFeeIds.includes(id));
  }, [fees, passengerAdmissions]);

  const onAdmissionSelectionUpdate = useCallback(
    (selection: Array<string>) => {
      if (isOverview) return;
      dispatch(
        updateBookingPartsSelection({
          passengerId,
          type: 'admission',
          selection,
        })
      );
    },
    [dispatch, isOverview, passengerId]
  );

  const onAncillariesSelectionUpdate = useCallback(
    (selection: Array<string>) => {
      if (isOverview) return;

      dispatch(
        updateBookingPartsSelection({
          passengerId,
          type: 'ancillary',
          selection,
        })
      );
    },
    [dispatch, isOverview, passengerId]
  );

  const tabs = useMemo(
    () =>
      isTravelPassBooking
        ? [
            {
              tab: <TransSubtitle i18nKey="details" />,
              component: <PassengerTravelPass passengerId={passengerId} />,
            },
            {
              tab: <TransSubtitle i18nKey="passengerData" />,
              component: <PassengerData value={currentPassenger} />,
            },
          ]
        : [
            {
              tab: <TransSubtitle i18nKey="admissions" />,
              component: (
                <PassengerAdmissionsTable
                  isOverview={isOverview}
                  onRowSelectionUpdate={onAdmissionSelectionUpdate}
                  hiddenColumns={[
                    PassengerAdmissionsColumns.refundableAmount,
                    ...(isOverview
                      ? [
                          ...(isPayOnHoldBooking
                            ? [PassengerAdmissionsColumns.selection]
                            : []),
                          PassengerAdmissionsColumns.status,
                          PassengerAdmissionsColumns.ticketNumber,
                        ]
                      : []),
                  ]}
                  selected={selection.admission[passengerId]}
                  data={passengerAdmissions}
                  currentPassengerId={passengerId}
                />
              ),
            },
            {
              tab: <TransSubtitle i18nKey="legInfo" />,
              component: (
                <PassengerLegs
                  passenger={currentPassenger}
                  data={passengerLegs}
                  passengerAdmissions={passengerAdmissions}
                />
              ),
            },
            {
              tab: <TransSubtitle i18nKey="purchasedAddons" />,
              component: (
                <AddonsTable
                  hiddenColumns={[
                    'passenger',
                    ...(isOverview ? ['status'] : []),
                  ]}
                  onRowSelectionUpdate={onAncillariesSelectionUpdate}
                  data={passengerAncillaries}
                  selected={selection.ancillary[passengerId]}
                />
              ),
            },
            {
              tab: <TransSubtitle i18nKey="fees" />,
              component: <Fees data={passengerFees} />,
            },
            {
              tab: <TransSubtitle i18nKey="passengerData" />,
              component: <PassengerData value={currentPassenger} />,
            },
          ],
    [
      isTravelPassBooking,
      currentPassenger,
      onAdmissionSelectionUpdate,
      isOverview,
      passengerId,
      passengerAdmissions,
      passengerLegs,
      onAncillariesSelectionUpdate,
      passengerAncillaries,
      passengerFees,
      selection,
      isPayOnHoldBooking,
    ]
  );

  return (
    <Box className={classes.wrapper}>
      <Tabs onChange={() => dispatch(resetAdmissionSelection())}>
        {tabs.map(({ tab, component }, idx) => (
          <TabPanel value={String(idx)} key={idx} label={tab}>
            {component}
          </TabPanel>
        ))}
      </Tabs>
    </Box>
  );
};
