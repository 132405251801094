import { createReducer } from '@reduxjs/toolkit';
import { Voucher, VoucherFilters } from 'dto/concession';
import {
  getVouchers,
  setVoucherFilters,
} from 'features/concession/concessionActions';

interface ConcessionState {
  vouchers: Array<Voucher>;
  vouchersFilter: VoucherFilters;
}

const initialState: ConcessionState = {
  vouchers: [],
  vouchersFilter: {},
};
export const concessionReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getVouchers.fulfilled, (state, action) => {
      state.vouchers = action.payload;
    })
    .addMatcher(
      (action) =>
        [getVouchers.pending.type, setVoucherFilters.type].includes(
          action.type
        ),
      (state, action) => {
        state.vouchersFilter = action.payload;
      }
    );
});
