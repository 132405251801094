import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import { IconButton, TabScrollButtonProps } from '@mui/material';
import { FC } from 'react';
import { Icon } from '@fleet/shared/mui';

const useStyles = makeStyles(
  (theme) => ({
    scrollBtnWrapper: {
      '&:empty': {
        display: 'none',
      },
      '& > .MuiButtonBase-root': {
        height: 32,
        background: alpha(theme.palette.primary.light, 0.05),
        '&.Mui-disabled': {
          display: 'none',
        },
        '&:hover': {
          background: alpha(theme.palette.primary.light, 0.1),
        },
      },
      '&:first-of-type': {
        backgroundImage: `linear-gradient(to left, rgba(246, 246, 246, 0),rgba(246, 246, 246, 0.9))`,
      },

      '&:last-of-type': {
        backgroundImage: `linear-gradient(to right, rgba(246, 246, 246, 0),rgba(246, 246, 246, 0.9))`,
      },
    },
  }),
  {
    name: 'TabsScrollButton',
  }
);

export const TabsScrollButton: FC<TabScrollButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.scrollBtnWrapper} onClick={props.onClick}>
      <IconButton>
        <Icon color="primary" name={`direction-${props.direction}`} />
      </IconButton>
    </div>
  );
};
