import { api } from '@fleet/shared';
import { createAction } from '@reduxjs/toolkit';
import {
  Concession,
  ValidatedConcession,
  Voucher,
  VoucherFilters,
} from 'dto/concession';
import { stringify } from 'qs';
import { createAsyncThunk } from 'store/utils';

export const validateConcessions = createAsyncThunk<
  Array<ValidatedConcession>,
  Array<Concession>
>(
  'concession/validateConcession',
  async (concessions) =>
    (
      await api.patch<{ concessions: Array<ValidatedConcession> }>(
        '/integrations/nsw/concessions/validate',
        {
          concessions,
        }
      )
    ).data.concessions
);

export const setVoucherFilters = createAction<VoucherFilters>(
  'concession/setVoucherFilters'
);

export const getVouchers = createAsyncThunk<Array<Voucher>, VoucherFilters>(
  'concession/getVouchers',
  async (filters: VoucherFilters) =>
    (
      await api.get<{ vouchers: Array<Voucher> }>(
        `/integrations/nsw/vouchers${stringify(filters, {
          addQueryPrefix: true,
        })}`
      )
    ).data.vouchers
);
