import {
  BookingAdmission,
  BookingTripWithAdmissions,
  FulfillmentStatus,
} from 'dto/booking';
import { useMemo } from 'react';
import { isAdmissionInactive } from 'utils/trip';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const moveArrElement = (array: Array<any>, from: number, to: number) => {
  const item = array[from];
  const length = array.length;
  const diff = from - to;

  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, to),
      item,
      ...array.slice(to, from),
      ...array.slice(from + 1, length),
    ];
  } else if (diff < 0) {
    // move right
    const targetIndex = to + 1;
    return [
      ...array.slice(0, from),
      ...array.slice(from + 1, targetIndex),
      item,
      ...array.slice(targetIndex, length),
    ];
  }
  return array;
};

export const runInSequence = async (functions: Array<Function>) => {
  return await functions.reduce(async (promise, func) => {
    await promise;
    return await func();
  }, Promise.resolve([]));
};

export const replaceUnicodeChars = (value: string) =>
  value.replace(/&#x([0-9a-f]+);/gi, (_, code) =>
    String.fromCharCode(parseInt(code, 16))
  );

export const formatCurrency = ({
  amount,
  currency,
}: {
  amount: number;
  currency: string;
}) => {
  const formattedAmount =
    currency === 'AUD'
      ? amount.toFixed(2).replace(/\.(\d)$/, '.$10')
      : Number(amount.toFixed(2));
  if (currency) {
    return [formattedAmount, currency].join(' ');
  } else {
    return formattedAmount;
  }
};

export const isSelectedAdmissionStatusOnHold = (
  selectedAdmissions: BookingAdmission[]
) =>
  selectedAdmissions.some(
    ({ fulfillments, status: admissionStatus }: BookingAdmission) => {
      const onHoldStatuses = ['ONHOLD', 'ON_HOLD'];
      return (
        onHoldStatuses.includes(admissionStatus) ||
        fulfillments.some(({ status }) => onHoldStatuses.includes(status))
      ); // Backend returns ONHOLD and ON_HOLD in different places.
    }
  );

export type WarningCodes =
  | 'NOT_ENOUGH_VOUCHERS'
  | 'SOLD_OUT'
  | 'NOT_ENOUGH_PLACES'
  | 'OFFER_PAX_TYPES_CANNOT_BE_SOLD_ALONE';

export const isTranslatableWarning = (code: string): code is WarningCodes => {
  return (
    [
      'NOT_ENOUGH_VOUCHERS',
      'SOLD_OUT',
      'NOT_ENOUGH_PLACES',
      'OFFER_PAX_TYPES_CANNOT_BE_SOLD_ALONE',
    ].indexOf(code) !== -1
  );
};

export const useSelectedAdmissionFulfillmentStatusOnHold = (
  bookedTrips: BookingTripWithAdmissions[],
  fulfillmentIds: string[]
) => {
  return useMemo(() => {
    return bookedTrips.some((bookedTrip) =>
      bookedTrip.bookedOffers.some((bookedOffer) =>
        bookedOffer.admissions.some((admission) => {
          const allFulfillments = [
            ...admission.fulfillments,
            ...admission.reservations.flatMap((res) => res.fulfillments),
            ...admission.ancillaries.flatMap((anc) => anc.fulfillments),
            ...admission.fees.flatMap((fee) => fee.fulfillments),
          ].filter((fulfillment) => !isAdmissionInactive(fulfillment));

          return allFulfillments.some(
            (fulfillment) =>
              fulfillmentIds.includes(fulfillment.id) &&
              fulfillment.status === ('ONHOLD' as FulfillmentStatus)
          );
        })
      )
    );
  }, [bookedTrips, fulfillmentIds]);
};
