import { Pagination } from '@fleet/shared/dto/pagination';
import { createReducer } from '@reduxjs/toolkit';
import {
  AdditionalOffer,
  BookingComment,
  BookingDetails,
  BookingRefundOffer,
  BookingReleaseOffer,
  BookingSearchFilter,
  BookingsSearchResult,
  CustomerNotification,
  HistoryEvent,
  OnHoldBookingOffer,
  TravelAccount,
  UnaccompaniedMinorRequest,
} from 'dto/booking';
import {
  clearExchangeOperations,
  confirmRefund,
  confirmRelease,
  deleteBooking,
  deleteRefundOffers,
  getAdditionalOffers,
  getBooking,
  getComments,
  getHistory,
  getNotifications,
  getTravelAccount,
  getUnaccompaniedMinorRequests,
  initiateRefund,
  initiateRelease,
  makeOnHoldBookingOffer,
  postBooking,
  putBookingOnHold,
  resetAdmissionSelection,
  resetCurrentBooking,
  searchBookings,
  setBookingFilter,
  setCurrentBooking,
  setOnHoldBookingTime,
  updateBookingPartsSelection,
  updateBookingPartsSelectionBulk,
  updateTravelPass,
} from 'features/booking/bookingActions';
import { selectRetailer } from 'features/user/userActions';
export interface BookingState {
  filter: Partial<BookingSearchFilter>;
  search?: Pagination<BookingsSearchResult>;
  current?: BookingDetails;
  selection: {
    admission: Record<string, Array<string>>;
    ancillary: Record<string, Array<string>>;
    fulfillment: Record<string, Array<string>>;
  };
  refundOffers?: Array<BookingRefundOffer>;
  releaseOffers?: Array<BookingReleaseOffer>;
  comments: Array<BookingComment>;
  customerNotifications: Array<CustomerNotification>;
  unmrRequests: Array<UnaccompaniedMinorRequest>;
  history: Array<HistoryEvent>;
  additionalOffers?: Array<AdditionalOffer>;
  onHoldBookingTime?: string;
  travelAccount?: TravelAccount;
  onHoldOffer?: OnHoldBookingOffer;
}
const initialState: BookingState = {
  filter: {},
  selection: {
    admission: {},
    ancillary: {},
    fulfillment: {},
  },
  comments: [],
  customerNotifications: [],
  unmrRequests: [],
  history: [],
};
export const bookingReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(searchBookings.fulfilled, (state, action) => {
      state.search = action.payload;
    })
    .addCase(selectRetailer, (state) => {
      state.search = undefined;
      state.filter = {};
    })
    .addCase(setBookingFilter, (state, action) => {
      state.filter = action.payload ?? {};
    })
    .addCase(setOnHoldBookingTime, (state, action) => {
      state.onHoldBookingTime = action.payload;
    })
    .addCase(updateBookingPartsSelection, (state, action) => {
      const { passengerId, type, selection } = action.payload;
      state.selection[type][passengerId] = selection;
    })
    .addCase(updateBookingPartsSelectionBulk, (state, action) => {
      action.payload.forEach(({ passengerId, type, selection }) => {
        state.selection[type][passengerId] = selection;
      });
    })
    .addCase(initiateRefund.fulfilled, (state, action) => {
      state.refundOffers = action.payload;
    })
    .addCase(initiateRelease.pending, (state) => {
      state.releaseOffers = undefined;
    })
    .addCase(initiateRelease.fulfilled, (state, action) => {
      state.releaseOffers = action.payload;
    })
    .addCase(getAdditionalOffers.fulfilled, (state, action) => {
      state.additionalOffers = action.payload;
    })
    .addCase(setCurrentBooking, (state, action) => {
      state.current = action.payload;
    })
    .addCase(resetCurrentBooking, (state) => {
      state.current = undefined;
      state.comments = [];
      state.selection = initialState.selection;
      state.additionalOffers = [];
    })
    .addCase(getComments.fulfilled, (state, action) => {
      state.comments = action.payload;
    })
    .addCase(getUnaccompaniedMinorRequests.fulfilled, (state, action) => {
      state.unmrRequests = action.payload;
    })
    .addCase(getHistory.fulfilled, (state, action) => {
      state.history = action.payload;
    })
    .addCase(getNotifications.fulfilled, (state, action) => {
      state.customerNotifications = action.payload;
    })
    .addCase(clearExchangeOperations, (state) => {
      if (state.current) {
        state.current.exchangeOperations = [];
      }
    })
    .addCase(makeOnHoldBookingOffer.fulfilled, (state, action) => {
      state.onHoldOffer = action.payload;
    })
    .addMatcher(
      (action) =>
        [
          getTravelAccount.fulfilled.type,
          updateTravelPass.fulfilled.type,
        ].includes(action.type),
      (state, action) => {
        state.travelAccount = action.payload;
      }
    )
    .addMatcher(
      (action) =>
        [resetCurrentBooking.type, deleteBooking.fulfilled.type].includes(
          action.type
        ),
      (state) => {
        state.current = undefined;
        state.comments = [];
        state.selection = initialState.selection;
        state.additionalOffers = undefined;
      }
    )
    .addMatcher(
      (action) =>
        [
          getBooking.fulfilled.type,
          postBooking.fulfilled.type,
          putBookingOnHold.fulfilled.type,
        ].includes(action.type),
      (state, action) => {
        state.onHoldOffer = undefined;
        state.current = action.payload;
      }
    )
    .addMatcher(
      (action) =>
        [
          confirmRefund.fulfilled.type,
          deleteRefundOffers.fulfilled.type,
          initiateRefund.pending.type,
        ].includes(action.type),
      (state) => {
        state.refundOffers = undefined;
      }
    )
    .addMatcher(
      (action) =>
        [
          confirmRefund.fulfilled.type,
          confirmRelease.fulfilled.type,
          resetAdmissionSelection.type,
        ].includes(action.type),
      (state) => {
        state.selection = initialState.selection;
      }
    );
});
