import { selector } from 'store/utils';
import { RootState } from 'store';
import _keyBy from 'lodash/keyBy';
import { createSelector } from '@reduxjs/toolkit';
import { OfferAccommodationType } from 'dto/trip';

export const tripsSelector = (state: RootState) => ({
  outboundTrips: state.trip.outbound,
  inboundTrips: state.trip.inbound,
});

export const warningsSelector = selector(
  (state: RootState) => state.trip.warnings
);

export const tripLinksMapSelector = selector((state: RootState) =>
  _keyBy(state.trip.links, 'rel')
);
export const travelPassesSelector = selector(
  (state: RootState) => state.trip.travelPasses
);

export const downloadPdfFlagSelector = selector(
  (state: RootState) => state.trip.downloadPdfTickets
);
export const showingStopsForSelector = selector(
  (state: RootState) => state.trip.showStopsFor
);

export const selectSelectedOffers = selector(
  (state: RootState) => state.trip.selectedOffers
);

export const makeSelectUnconfiguredTripOffer = (
  journeyType: 'outbound' | 'inbound'
) =>
  createSelector(selectSelectedOffers, (selectedOffers) => {
    const { trips, tripsSelectionMap } = selectedOffers[journeyType];
    return trips.find(
      ({ offerAccommodationType, id }) =>
        offerAccommodationType !== OfferAccommodationType.SEAT &&
        !tripsSelectionMap[id]
    );
  });

export const paymentStatusSelector = selector(
  (state: RootState) => state.trip.paymentStatus
);

export const nextAvailableDateSelector = selector(
  (state: RootState) => state.trip.nextAvailableDepartureDate
);

export const additionalJourneyDataMap = selector(
  (state: RootState) => state.trip.additionalJourneyDataMap
);

export const dataLoadingJourneyReference = selector(
  (state: RootState) => state.trip.loadingJourney
);
