import { createReducer } from '@reduxjs/toolkit';
import { SearchTab, SearchType } from 'routes/tickets/SearchTabs';
import {
  addTab,
  closeTab,
  resetTab,
  setActiveTabIdx,
  updateTab,
} from 'features/tabs/tabsActions';
import { LocalStorage } from 'hooks/useLocalStorage';

const prepareLsValues = (lsValue: string | null) => {
  try {
    return lsValue && JSON.parse(lsValue);
  } catch (e) {
    return null;
  }
};

const activeTabIdxLs = prepareLsValues(
  sessionStorage.getItem(LocalStorage.ACTIVE_TAB)
);
const tabsLs = prepareLsValues(sessionStorage.getItem(LocalStorage.TABS));

interface TabsState {
  activeTabIdx: number;
  list: Array<SearchTab>;
}

const emptyTab: SearchTab = {
  params: {},
  type: SearchType.tickets,
  activeStep: -1,
};

const initialState: TabsState = {
  activeTabIdx: activeTabIdxLs || 0,
  list: tabsLs || [emptyTab],
};

export const tabsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addTab, (state, action) => {
      const tabToAdd = action.payload ?? emptyTab;
      const nextTabs = [
        ...state.list.filter(
          ({ bookingId, isCompleted }) =>
            !isCompleted &&
            (!bookingId ||
              !tabToAdd.bookingId ||
              bookingId !== tabToAdd.bookingId)
        ),
        tabToAdd,
      ];

      state.list = nextTabs;
      state.activeTabIdx = nextTabs.length - 1;
    })
    .addCase(closeTab, (state, action) => {
      const idx = action.payload ?? state.activeTabIdx;
      if (state.list.length === 1) {
        state.list = [emptyTab];
        state.activeTabIdx = 0;
        return;
      } else {
        state.list = state.list.filter((tab, tabIdx) => idx !== tabIdx);
      }

      if (idx <= state.activeTabIdx) {
        state.activeTabIdx = state.activeTabIdx - 1;
      }
    })
    .addCase(resetTab, (state, action) => {
      const idx = action.payload ?? state.activeTabIdx;
      state.list = state.list.map((tab, tabIdx) =>
        idx === tabIdx ? emptyTab : tab
      );
    })
    .addCase(setActiveTabIdx, (state, action) => {
      const idx = action.payload;

      if (state.list[idx]) {
        state.activeTabIdx = idx;
      }
    })
    .addCase(updateTab, (state, action) => {
      const { tabIdx = state.activeTabIdx, ...updates } = action.payload;

      state.list = state.list.map((tab, idx) =>
        idx === tabIdx ? { ...tab, ...updates } : tab
      );
    });
});
