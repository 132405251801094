import { makeStyles } from '@mui/styles';
import { Icon } from '@fleet/shared/mui';
import { Stack, Button, Tab, Tabs, IconButton } from '@mui/material';
import { TabsScrollButton } from 'components/TabsScrollButton';
import { Modal, useFormContext } from '@fleet/shared';
import { useFieldArray } from '@fleet/shared/form/hooks/useFieldArray';
import { TransButton } from 'i18n/trans/button';
import { useCallback, useMemo, useState } from 'react';
import { SelectField, TextField } from '@fleet/shared/form';
import { TransField } from 'i18n/trans/field';
import { TransTitle } from 'i18n/trans/title';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from '@fleet/shared';
import { PromoCode } from 'dto/trip';
import classNames from 'classnames';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      alignItems: 'center',
      '& .MuiTab-root': {
        padding: 0,
        minHeight: 0,
        borderRadius: '2px',
        background: 'transparent',
        '&:not(:last-of-type)': {
          marginRight: '8px',
        },
      },
    },
    promoCode: {
      backgroundColor: theme.palette.success.main,
      padding: '2px 8px',
      '&, & .Icon-root:hover': {
        color: 'white',
      },
      '&$corporateCode': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    corporateCode: {},
    control: {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
      fontSize: '16px',
    },
  }),
  {
    name: 'PromoCodes',
  }
);

export const PromoCodes = () => {
  const classes = useStyles();
  const carrierOptions = useClassificationOptions(ClassificationGroup.CARRIER);
  const [currentModal, setCurrentModal] = useState<
    'promotionCodes' | 'corporateCodes' | undefined
  >();
  const form = useFormContext();
  const initialAddFormValues = useMemo(() => ({ codes: [{ code: '' }] }), []);
  const promoCodeFields = useFieldArray<'promotionCodes', PromoCode>(
    'promotionCodes',
    {
      form,
    }
  );
  const corporateCodeFields = useFieldArray<'corporateCodes', PromoCode>(
    'corporateCodes',
    {
      form,
    }
  );
  const currentFields = useMemo(() => {
    if (currentModal === 'promotionCodes') return promoCodeFields;
    if (currentModal === 'corporateCodes') return corporateCodeFields;
  }, [currentModal, promoCodeFields, corporateCodeFields]);

  const openAddModal = useCallback(
    (modalType: 'promotionCodes' | 'corporateCodes') => {
      setCurrentModal(modalType);
    },
    []
  );
  const onModalClose = useCallback(() => {
    setCurrentModal(undefined);
  }, []);

  const handleAddCodes = useCallback(
    ({ codes }: { codes: Array<PromoCode> }) => {
      codes.forEach((code) => currentFields?.fields.push(code));
      onModalClose();
    },
    [currentFields, onModalClose]
  );

  return (
    <>
      <Tabs
        value={false}
        variant="scrollable"
        scrollButtons="auto"
        ScrollButtonComponent={TabsScrollButton}
        className={classes.root}
      >
        {promoCodeFields.fields.value.map(({ code }, idx) => (
          <Tab
            key={`promoCode${idx}`}
            label={
              <Stack
                direction="row"
                className={classes.promoCode}
                spacing={0.5}
                alignItems="center"
              >
                <Icon name="voucher" />
                <div>{code}</div>
                <Icon
                  name="close"
                  onClick={() => promoCodeFields.fields.remove(idx)}
                />
              </Stack>
            }
          />
        ))}
        {corporateCodeFields.fields.value.map(({ code }, idx) => (
          <Tab
            key={`corporateCode${idx}`}
            label={
              <Stack
                direction="row"
                className={classNames(classes.promoCode, classes.corporateCode)}
                spacing={0.5}
                alignItems="center"
              >
                <Icon name="tag" />
                <div>{code}</div>
                <Icon
                  name="close"
                  onClick={() => corporateCodeFields.fields.remove(idx)}
                />
              </Stack>
            }
          />
        ))}
        <Tab
          className={classes.control}
          key="addPromoCode"
          onClick={() => openAddModal('promotionCodes')}
          label={
            <Stack direction="row" alignItems="center">
              <Icon name="add" margin />
              <TransButton i18nKey="promoCode" />
            </Stack>
          }
        />
        <Tab
          className={classes.control}
          key="addCorporateAgreementCode"
          onClick={() => openAddModal('corporateCodes')}
          label={
            <Stack direction="row" alignItems="center">
              <Icon name="add" margin />
              <TransButton i18nKey="corporateAgreementCode" />
            </Stack>
          }
        />
      </Tabs>
      <Modal
        title={
          <TransTitle
            i18nKey={
              currentModal === 'corporateCodes'
                ? 'addCorporateCode'
                : 'addPromoCode'
            }
          />
        }
        actionButton={
          <Button form="addForm" type="submit" variant="contained">
            <TransButton i18nKey="save" />
          </Button>
        }
        open={!!currentModal}
        onClose={onModalClose}
        maxWidth={false}
      >
        <Form<{ codes: Array<PromoCode> }>
          onSubmit={handleAddCodes}
          initialValues={initialAddFormValues}
          mutators={{ ...arrayMutators }}
          render={({ handleSubmit }) => (
            <Stack
              component="form"
              id="addForm"
              alignItems="flex-start"
              onSubmit={handleSubmit}
              spacing={1}
            >
              <FieldArray name="codes">
                {({ fields }) => (
                  <>
                    {fields.value?.map((_, idx) => (
                      <Stack key={idx} direction="row" sx={{ width: '100%' }}>
                        <Stack spacing={1} sx={{ flex: 1 }}>
                          {currentModal === 'corporateCodes' && (
                            <SelectField
                              required
                              name={`${fields.name}[${idx}].issuingCarrierCode`}
                              label={<TransField i18nKey="carrier" />}
                              options={carrierOptions}
                            />
                          )}

                          <TextField
                            required
                            name={`${fields.name}[${idx}].code`}
                            label={
                              <TransField
                                i18nKey={
                                  currentModal === 'corporateCodes'
                                    ? 'corporateCode'
                                    : 'promoCode'
                                }
                              />
                            }
                          />
                        </Stack>
                        {fields.value.length > 1 && (
                          <IconButton
                            sx={{ alignSelf: 'center' }}
                            onClick={() => fields.remove(idx)}
                          >
                            <Icon name="close" />
                          </IconButton>
                        )}
                      </Stack>
                    ))}
                    <Button
                      startIcon={<Icon name="add" />}
                      sx={{ px: 0 }}
                      onClick={() => fields.push({})}
                    >
                      <TransButton i18nKey="addCode" />
                    </Button>
                  </>
                )}
              </FieldArray>
            </Stack>
          )}
        />
      </Modal>
    </>
  );
};
