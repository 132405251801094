import { activeTabSelector } from 'features/tabs/tabsSelector';
import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { TransTitle } from 'i18n/trans/title';
import { Button, Checkbox, Modal } from '@fleet/shared';
import { TransButton } from 'i18n/trans/button';
import { useSelector } from 'store/utils';
import {
  bookingLoadingSelector,
  currentBookingSelector,
} from 'features/booking/bookingSelectors';
import { getPassengerJourneys } from 'utils/trip';
import { TransLabel } from 'i18n/trans/label';
import { Divider, Stack, Typography } from '@mui/material';
import { JourneySummaries } from 'components/JourneySummaries';
import { PassengerSpecification } from 'dto/trip';
import { BookingDetailsPassenger } from 'dto/booking';

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  {
    name: 'SelectPassengersModal',
  }
);

interface SelectPassengersModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (
    selectedOptions: Array<string>,
    isAddingToCurrentPassenger: boolean
  ) => void;
  isDashboard?: boolean;
}

const isPassengerSpecification = (
  passenger: PassengerSpecification | BookingDetailsPassenger
): passenger is PassengerSpecification => {
  return (passenger as PassengerSpecification).type !== undefined;
};

export const SelectPassengersModal: FC<SelectPassengersModalProps> = ({
  open,
  onClose,
  onSubmit,
  isDashboard = false,
}) => {
  const classes = useStyles();
  const postBookingLoading = useSelector(bookingLoadingSelector);
  const [selectedOptions, setSelectedOptions] = useState<Array<string>>([]);
  const [selectAll, setSelectAll] = useState(false);
  const currentTab = useSelector(activeTabSelector);
  const booking = useSelector(currentBookingSelector);
  const availablePassengers = useMemo(() => {
    const filteredPassengerSpecifications =
      currentTab?.params?.passengerSpecifications?.filter(
        (spec) =>
          !booking?.passengers?.some(
            (passenger) =>
              passenger.externalReference === spec.externalReference
          )
      ) ?? [];

    return [...filteredPassengerSpecifications, ...(booking?.passengers ?? [])];
  }, [currentTab, booking]);
  const passengerJourneys = useMemo(() => {
    return getPassengerJourneys(booking);
  }, [booking]);

  const passengerOptions = useMemo(() => {
    return (
      availablePassengers?.map((passenger) => ({
        label: (
          <Typography fontWeight="600">
            {isPassengerSpecification(passenger) ? (
              <TransLabel i18nKey="currentPassenger" />
            ) : passenger.firstName.value && passenger.lastName.value ? (
              `${passenger.lastName.value} ${passenger.firstName.value}`
            ) : (
              <TransLabel i18nKey="passenger" />
            )}
            {!isPassengerSpecification(passenger) &&
              passengerJourneys[passenger.id]?.map((trip) => (
                <JourneySummaries passengerSpecificTrip={trip} key={trip.id} />
              ))}
          </Typography>
        ),
        value: passenger.externalReference,
      })) ?? []
    );
  }, [passengerJourneys, availablePassengers]);

  const handleCheckboxChange = useCallback((value: string) => {
    setSelectedOptions((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
    );
  }, []);

  const handleSelectAllChange = useCallback(() => {
    if (selectAll) {
      setSelectedOptions([]);
    } else {
      setSelectedOptions(passengerOptions.map((option) => option.value));
    }
    setSelectAll(!selectAll);
  }, [selectAll, passengerOptions]);

  useEffect(() => {
    if (!currentTab?.bookingId && isDashboard) {
      onClose();
    }
  }, [currentTab?.bookingId, onClose, isDashboard]);

  useEffect(() => {
    setSelectAll(selectedOptions.length === passengerOptions.length);
  }, [selectedOptions, passengerOptions]);

  return (
    <Modal
      className={classes.root}
      title={<TransTitle i18nKey="passengerSelectionForNextJourney" />}
      open={open}
      onClose={onClose}
      actionButton={
        !!passengerOptions.length ? (
          <Button
            variant="contained"
            onClick={() => onSubmit(selectedOptions, true)}
            disabled={!selectedOptions.length}
            loading={postBookingLoading}
          >
            <TransButton i18nKey="confirm" />
          </Button>
        ) : (
          <></>
        )
      }
    >
      <Stack gap={2} direction="column" alignItems="center">
        {passengerOptions.length && booking?.id ? (
          <Checkbox
            checked={selectAll}
            onChange={handleSelectAllChange}
            label={<TransLabel i18nKey="selectAllPassengers" />}
          />
        ) : (
          <Button
            variant="text"
            sx={{ ml: 0, mr: 'auto' }}
            onClick={() =>
              onSubmit(
                [
                  currentTab!.params!.passengerSpecifications![0]
                    .externalReference!,
                ],
                true
              )
            }
          >
            <Typography fontWeight="600">
              <TransButton i18nKey="addJourneyToSamePassenger" />
            </Typography>
          </Button>
        )}
        {booking?.id &&
          passengerOptions.map((option) => (
            <Checkbox
              key={option.value}
              value={option.value}
              label={option.label}
              onChange={() => handleCheckboxChange(option.value)}
              checked={selectedOptions.includes(option.value)}
            />
          ))}
        <Divider sx={{ width: '100%' }} />
        <Button
          variant="text"
          sx={{ ml: 0, mr: 'auto' }}
          onClick={() =>
            isDashboard ? onClose() : onSubmit(selectedOptions, false)
          }
        >
          <Typography fontWeight="600">
            <TransButton i18nKey="addJourneyToNewPassenger" />
          </Typography>
        </Button>
      </Stack>
    </Modal>
  );
};
