import { Button, currentDateTimeFormat, formatDate, Icon } from '@fleet/shared';
import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { DaysAfterCount } from 'components/DaysAfterCount';
import { ModifyJourneyStepsContext } from 'components/ModifyJourneyStepsProvider';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTitle } from 'i18n/trans/title';
import { FC, ReactNode, useContext } from 'react';
import { ModalControlsWrap } from 'routes/bookingDetails/modal/ModalControlsWrap';

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      paddingBottom: '4rem',
    },
    icon: {
      width: 34,
      flexShrink: 0,
    },
    directionOption: {
      cursor: 'pointer',
      border: `thin solid ${theme.palette.divider}`,
      borderRadius: '0.25rem',
      padding: '0.5rem 0.75rem',
    },
    inbound: {
      transform: 'scale(-1, 1)',
    },
    selected: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  }),
  { name: 'ModifyJourneyStepsProvider' }
);

export const DirectionSelect: FC<{ submitLabel: ReactNode }> = ({
  submitLabel,
}) => {
  const classes = useStyles();
  const {
    closeModal,
    goToNextStep,
    selectedTrips,
    selectedTripId,
    setSelectedTripId,
  } = useContext(ModifyJourneyStepsContext);

  return (
    <>
      <Typography variant="h1" mb={3}>
        <TransTitle i18nKey="chooseModifyDirection" />
      </Typography>
      <Stack spacing={2} className={classes.wrapper}>
        {selectedTrips.map((trip) => {
          const {
            id,
            isOutbound,
            departureTime,
            arrivalTime,
            originStop,
            destinationStop,
            alliances,
          } = trip;
          const isSelected = selectedTripId === id;
          return (
            <Stack
              key={id}
              direction="row"
              className={classNames(classes.directionOption, {
                [classes.selected]: isSelected,
              })}
              onClick={() => setSelectedTripId(isSelected ? undefined : id)}
              alignItems="center"
              spacing={0.75}
            >
              <Icon
                name="oneway"
                size={24}
                className={classNames({
                  [classes.inbound]: !isOutbound,
                })}
              />
              <Stack>
                <Typography variant="subtitle">
                  <TransSubtitle
                    i18nKey={isOutbound ? 'outbound' : 'inbound'}
                  />
                </Typography>
                <Stack key={id} direction="row" alignItems="center" spacing={1}>
                  <Typography variant="body2">
                    {[departureTime, arrivalTime]
                      .map((date) => formatDate(date, currentDateTimeFormat))
                      .join(' - ')}
                  </Typography>
                  <DaysAfterCount
                    startDate={departureTime}
                    endDate={arrivalTime}
                  />
                  <Icon
                    className={classes.icon}
                    name={isSelected ? 'journey-light' : 'journey'}
                  />
                  <Typography variant="body2" sx={{ fontWeight: 700 }}>
                    {[originStop.name, destinationStop.name].join(' - ')}
                  </Typography>
                  {alliances.includes('RESPLUS') && (
                    <Icon name="resplus-horizontal" height={15} width={53} />
                  )}
                </Stack>
              </Stack>
            </Stack>
          );
        })}
        <ModalControlsWrap>
          <Button
            variant="text"
            onClick={closeModal}
            label={<TransButton i18nKey="cancel" />}
          />
          <Button
            variant="contained"
            onClick={goToNextStep}
            disabled={!selectedTripId}
            label={
              <>
                <Icon name="arrow-right" sx={{ mr: 1 }} />
                {submitLabel}
              </>
            }
          />
        </ModalControlsWrap>
      </Stack>
    </>
  );
};
