import { Button, Icon, Loader, Modal } from '@fleet/shared';
import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PaymentStatus } from 'dto/trip';
import {
  cancelPayments,
  clearPaymentStatus,
  getPaymentStatus,
} from 'features/trip/tripActions';
import { paymentStatusSelector } from 'features/trip/tripSelector';
import { TransButton } from 'i18n/trans/button';
import { TransLabel } from 'i18n/trans/label';
import { TransTitle } from 'i18n/trans/title';
import { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'store/utils';

const useStyles = makeStyles(
  () => ({
    paper: {
      margin: 0,
      maxWidth: 'none',
      width: 300,
    },
    content: {
      '& > .MuiModal-root': {
        background: 'transparent',
        position: 'static',
      },
    },
  }),
  { name: 'PayByLinkModal' }
);
export const PayByLinkModal: FC<{ onPaymentSuccess: () => void }> = ({
  onPaymentSuccess,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const paymentStatus = useSelector(paymentStatusSelector);
  const handleClose = useCallback(() => {
    dispatch(clearPaymentStatus());
  }, [dispatch]);
  const handleCancelPayment = useCallback(() => {
    handleClose();
    dispatch(cancelPayments());
  }, [dispatch, handleClose]);
  const pingPaymentStatus = useCallback(
    async () => await dispatch(getPaymentStatus()),
    [dispatch]
  );

  useEffect(() => {
    pingPaymentStatus();
  }, [pingPaymentStatus]);

  useEffect(() => {
    const pingInterval = setInterval(pingPaymentStatus, 5000);
    if ([PaymentStatus.paid, PaymentStatus.not_paid].includes(paymentStatus!)) {
      clearInterval(pingInterval);
      if (paymentStatus === PaymentStatus.paid) {
        handleClose();
        onPaymentSuccess();
      }
    }
    return () => clearInterval(pingInterval);
  }, [handleClose, onPaymentSuccess, paymentStatus, pingPaymentStatus]);

  const statusToTranslationMap = {
    [PaymentStatus.pending]: 'paymentPending' as const,
    [PaymentStatus.not_paid]: 'paymentFailed' as const,
    [PaymentStatus.paid]: 'paymentDone' as const,
  };

  return (
    <Modal
      classes={{
        paper: classes.paper,
      }}
      title={<TransTitle i18nKey="payment" />}
      open
      cancelButton={false}
      closeButton={false}
      actionButton={
        paymentStatus === PaymentStatus.pending ? (
          <Button variant="text" onClick={handleCancelPayment}>
            <TransButton i18nKey="cancelPayment" />
          </Button>
        ) : (
          <Button variant="text" onClick={handleClose}>
            <TransButton i18nKey="close" />
          </Button>
        )
      }
    >
      <Stack className={classes.content} alignItems="center" spacing={2}>
        {paymentStatus === PaymentStatus.pending && (
          <Loader active size="container" hideOverlay />
        )}
        {paymentStatus === PaymentStatus.paid && (
          <Icon name="check-circle" size={72} color="success" />
        )}
        {paymentStatus === PaymentStatus.not_paid && (
          <Icon name="alert-circle" size={72} color="warning" />
        )}

        <Typography>
          {paymentStatus && (
            <TransLabel i18nKey={statusToTranslationMap[paymentStatus]} />
          )}
        </Typography>
      </Stack>
    </Modal>
  );
};
