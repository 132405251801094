import { currentDateTimeFormat, formatDate } from '@fleet/shared';
import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CustomerNotification } from 'dto/booking';
import { TransField } from 'i18n/trans/field';
import { FC, useMemo } from 'react';

const useStyles = makeStyles(
  (theme) => ({
    detailsRow: {
      '& :first-child': {
        width: '4.375rem',
      },
      '& :last-child': {
        flex: 1,
      },
    },
    emailContent: {
      border: `thin solid ${theme.palette.divider}`,
      borderRadius: '0.25rem',
      padding: '0.5rem',
      '& p': {
        margin: 0,
      },
    },
  }),
  { name: 'NotificationDetails' }
);

export const NotificationDetails: FC<CustomerNotification> = ({
  deliveredOn,
  isDeliveredByEmail,
  isDeliveredBySms,
  isDeliveredByPushNotification,
  deliveredToEmail,
  deliveredToNumber,
  emailSubject,
  emailSenderName,
  emailSenderEmail,
  emailContent,
  smsSenderName,
  smsContent,
  pushNotificationTitle,
  pushNotificationContent,
}) => {
  const classes = useStyles();
  const fields = useMemo(() => {
    const emailFields = [
      {
        label: <TransField i18nKey="from" />,
        value: `${emailSenderName} <${emailSenderEmail}>`,
      },
      {
        label: <TransField i18nKey="to" />,
        value: deliveredToEmail,
      },
      {
        label: <TransField i18nKey="subject" />,
        value: emailSubject,
      },
      {
        label: <TransField i18nKey="sentOn" />,
        value: formatDate(deliveredOn, currentDateTimeFormat),
      },
    ];
    const smsFields = [
      {
        label: <TransField i18nKey="from" />,
        value: smsSenderName,
      },
      {
        label: <TransField i18nKey="to" />,
        value: deliveredToNumber,
      },
      {
        label: <TransField i18nKey="sentOn" />,
        value: formatDate(deliveredOn, currentDateTimeFormat),
      },
      {
        label: <TransField i18nKey="content" />,
        value: smsContent,
      },
    ];
    const pushFields = [
      {
        label: <TransField i18nKey="sentOn" />,
        value: formatDate(deliveredOn, currentDateTimeFormat),
      },
      {
        label: <TransField i18nKey="content" />,
        value: [pushNotificationTitle, pushNotificationContent].join(': '),
      },
    ];
    if (isDeliveredBySms) return smsFields;
    if (isDeliveredByEmail) return emailFields;
    if (isDeliveredByPushNotification) return pushFields;
  }, [
    deliveredOn,
    deliveredToEmail,
    deliveredToNumber,
    emailSenderEmail,
    emailSenderName,
    emailSubject,
    isDeliveredByEmail,
    isDeliveredByPushNotification,
    isDeliveredBySms,
    pushNotificationContent,
    pushNotificationTitle,
    smsContent,
    smsSenderName,
  ]);

  return (
    <Stack gap={1}>
      {fields && (
        <Stack gap={0.25}>
          {fields.map(({ label, value }, idx) => (
            <Stack
              direction="row"
              key={idx}
              className={classes.detailsRow}
              gap={0.5}
            >
              <Typography fontWeight="bold">{label}:</Typography>
              <Typography>{value}</Typography>
            </Stack>
          ))}
        </Stack>
      )}
      {emailContent && (
        <Stack gap={0.5}>
          <Typography fontWeight="bold">
            <TransField i18nKey="contentTextHtml" />
          </Typography>
          <div
            className={classes.emailContent}
            dangerouslySetInnerHTML={{ __html: emailContent }}
          />
        </Stack>
      )}
    </Stack>
  );
};
