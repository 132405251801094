import {
  Checkbox,
  FieldArray,
  FormProvider,
  TextField,
  useFormContext,
  Button,
  Icon,
} from '@fleet/shared';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import {
  AdditionalRecipientsAddBtn,
  AdditionalRecipientsSelector,
} from 'components/ticketFulfillment/AdditionalRecipientsSelector';
import { TicketFulfillmentSelection } from 'components/ticketFulfillment/TicketFulfillmentSelection';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransLabel } from 'i18n/trans/label';
import { TransSubtitle } from 'i18n/trans/subtitle';
import type { FC } from 'react';
import { SyntheticEvent, useCallback, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { useSelector } from 'store/utils';
import { FEATURE_TICKET_DELIVERY_V2 } from 'utils/flags';
import { useValidatePhoneNumber } from 'utils/overview';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      minWidth: '15rem',
    },
    bgGray: {
      background: theme.palette.background.default,
    },
    addBtn: {
      alignSelf: 'flex-end',
    },
  }),
  {
    name: 'SmsFulfillment',
  }
);

interface SmsFulfillmentProps {
  formId?: string;
  purchaserPhone?: string;
  isInline?: boolean;
  onSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => void;
}

export const SmsFulfillment: FC<SmsFulfillmentProps> = ({
  formId,
  onSubmit,
  isInline,
  purchaserPhone,
}) => {
  const validatePhoneNumber = useValidatePhoneNumber();
  const form = useFormContext();
  const { purchaser } = useSelector(currentBookingSelector)!;
  const classes = useStyles();
  const phone =
    purchaserPhone ?? purchaser?.contactInformation.phoneNumber.value;
  const sendToPayerLabel = useMemo(
    () =>
      renderToString(
        <TransLabel
          i18nKey="sendToPayer"
          values={{ postfix: phone && `(${phone})` }}
        />
      ),
    [phone]
  );
  const onSendToPayerChange = useCallback(
    (isChecked: boolean) => {
      form.change('smsConfirmationRecipient', isChecked ? [phone] : undefined);
    },
    [form, phone]
  );

  return (
    <Card elevation={0} className={classes.root}>
      <FormProvider form={form}>
        <CardContent
          id={formId}
          component="form"
          className={classNames({ [classes.bgGray]: isInline })}
          onSubmit={onSubmit}
        >
          {FEATURE_TICKET_DELIVERY_V2 ? (
            <Grid container columns={2} spacing={2} rowSpacing={2}>
              <Grid item xs={2}>
                <Typography variant="subtitle">
                  <TransSubtitle i18nKey="sendBookingConfirmationViaSms" />
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Stack spacing={2}>
                  <Typography variant="subtitle" color="primary.main">
                    <TransSubtitle i18nKey="smsRecipient" />
                  </Typography>
                  <Stack direction="row">
                    {phone && (
                      <Checkbox
                        onChange={(e) => onSendToPayerChange(e.target.checked)}
                        label={sendToPayerLabel}
                      />
                    )}
                    <AdditionalRecipientsAddBtn name="additionalSmsConfirmationRecipients" />
                  </Stack>
                  <Divider sx={{ my: 2 }} />
                  <AdditionalRecipientsSelector
                    name="additionalSmsConfirmationRecipients"
                    type="sms"
                  />
                </Stack>
              </Grid>
              <Grid item xs={1}>
                <TicketFulfillmentSelection type="sms" />
              </Grid>
            </Grid>
          ) : (
            <>
              <Typography variant="subtitle">
                <TransSubtitle i18nKey="sendBookingConfirmationViaSms" />
              </Typography>
              {phone && (
                <Checkbox
                  sx={{ mt: 3 }}
                  onChange={(e) => onSendToPayerChange(e.target.checked)}
                  label={sendToPayerLabel}
                />
              )}
              <FieldArray name="additionalSmsConfirmationRecipients">
                {({ fields }) => (
                  <>
                    {fields.value?.map((_, idx) => (
                      <Stack
                        direction="row"
                        gap={2}
                        mt={3}
                        mb={3}
                        maxWidth={500}
                        key={idx}
                      >
                        <TextField
                          type="text"
                          placeholder="+"
                          name={`${fields.name}[${idx}]`}
                          label={<TransField i18nKey="mobileNumber" />}
                          required
                          validate={validatePhoneNumber}
                        />
                        <IconButton
                          sx={{ alignSelf: 'flex-start', top: '24px' }}
                          onClick={() => fields.remove(idx)}
                        >
                          <Icon name="close" />
                        </IconButton>
                      </Stack>
                    ))}
                    <Divider sx={{ mb: 3, mt: 3 }} />
                    <Button
                      variant="contained"
                      startIcon={<Icon name="add" />}
                      onClick={() => fields.push('')}
                    >
                      <TransButton i18nKey="addNewRecipient" />
                    </Button>
                  </>
                )}
              </FieldArray>
            </>
          )}
        </CardContent>
      </FormProvider>
    </Card>
  );
};
