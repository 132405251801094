import { RootState } from 'store';
import { selector } from 'store/utils';

export const vouchersSelector = selector(
  (state: RootState) => state.concession.vouchers
);

export const vouchersFilterSelector = selector(
  (state: RootState) => state.concession.vouchersFilter
);
