import type { FC, ReactNode } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { Modal } from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  {
    name: 'ConfirmModal',
  }
);

interface ConfirmModalProps {
  messageBody: string | ReactNode;
  onConfirm: () => void;
  onClose: () => void;
  isOpen: boolean;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({
  messageBody,
  onConfirm,
  isOpen,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <Modal
      title={<TransTitle i18nKey="removeBookingFee" />}
      className={classes.root}
      actionButton={
        <Button onClick={onConfirm} variant="contained">
          <TransButton i18nKey="confirm" />
        </Button>
      }
      open={isOpen}
      onClose={onClose}
      maxWidth={false}
    >
      <Typography>{messageBody}</Typography>
    </Modal>
  );
};
