import { FC, useCallback, useMemo } from 'react';
import { useModal } from '@fleet/shared/hooks';
import { TransTitle } from 'i18n/trans/title';
import { Button } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import {
  DateField,
  FormProvider,
  Icon,
  Loadable,
  Modal,
  useForm,
} from '@fleet/shared';
import { TransLabel } from 'i18n/trans/label';
import { useDispatch, useSelector } from 'store/utils';
import {
  getBooking,
  makeOnHoldBookingOffer,
  patchNewOnHoldBookingTime,
  putBookingOnHold,
  setOnHoldBookingTime,
} from 'features/booking/bookingActions';
import { makeStyles } from '@mui/styles';
import { putOnHoldBookingLoadingSelector } from 'features/loading/loadingSelectors';
import { Typography } from '@mui/material';
import {
  currentBookingSelector,
  isTravelPassBookingSelector,
  onHoldOfferSelector,
} from 'features/booking/bookingSelectors';
import { FormApi } from 'final-form';
import {
  PurchaserDetailsPayload,
  updatePurchaserDetails,
} from 'features/trip/tripActions';
import { updateTab } from 'features/tabs/tabsActions';
import { IS_DS_AT } from 'utils/flags';
import { activeTabSelector } from 'features/tabs/tabsSelector';

const useStyles = makeStyles(
  () => ({
    paper: {
      margin: 0,
      maxWidth: 'none',
      width: 600,
    },
    alertCard: {
      marginTop: '16px',
    },
  }),
  { name: 'OnHoldBookingModal' }
);

interface OnHoldBookingModalProps {
  edit?: boolean;
  goToNextStep?: () => void;
  payerDetailsForm?: FormApi<
    PurchaserDetailsPayload,
    Partial<PurchaserDetailsPayload>
  >;
}

export const OnHoldBookingModal: FC<OnHoldBookingModalProps> = ({
  edit,
  goToNextStep,
  payerDetailsForm,
}) => {
  const FORM_ID = 'onHoldBookingModalForm';
  const classes = useStyles();
  const booking = useSelector(currentBookingSelector);
  const loading = useSelector(putOnHoldBookingLoadingSelector);
  const onHoldOffer = useSelector(onHoldOfferSelector);
  const { open, onOpen, onClose } = useModal();
  const dispatch = useDispatch();
  const isTravelPassBooking = useSelector(isTravelPassBookingSelector);
  const currentTab = useSelector(activeTabSelector);
  const preparedOnHoldOffer = useMemo(() => {
    if (IS_DS_AT) return onHoldOffer || currentTab.onHoldOffer;
    return onHoldOffer;
  }, [currentTab.onHoldOffer, onHoldOffer]);
  const confirmOnHoldBooking = useCallback(
    async ({ onHoldBookingDate }) => {
      if (!onHoldBookingDate) {
        return;
      }

      !IS_DS_AT &&
        (await dispatch(
          patchNewOnHoldBookingTime({
            validUntil: new Date(onHoldBookingDate).toISOString(),
            offerId: preparedOnHoldOffer?.id,
          })
        ).unwrap());

      if (edit) {
        onClose();
        await dispatch(getBooking(booking!.id)).unwrap();
      } else {
        if (payerDetailsForm) {
          await dispatch(
            updatePurchaserDetails({
              bookingId: booking!.id,
              ...payerDetailsForm?.getState().values,
            })
          ).unwrap();
          await dispatch(putBookingOnHold(preparedOnHoldOffer!.id))
            .unwrap()
            .then(() => {
              dispatch(updateTab({ isCompleted: true }));
              dispatch(setOnHoldBookingTime(onHoldBookingDate));
              goToNextStep?.();
            });
        }
      }
    },
    [
      dispatch,
      preparedOnHoldOffer,
      edit,
      onClose,
      booking,
      payerDetailsForm,
      goToNextStep,
    ]
  );

  const { form, handleSubmit } = useForm<{ onHoldBookingDate: string }>({
    onSubmit: confirmOnHoldBooking,
  });

  const makeOnHoldOffer = useCallback(async () => {
    try {
      const offer =
        (IS_DS_AT && preparedOnHoldOffer) ||
        (await dispatch(makeOnHoldBookingOffer()).unwrap());
      const onHoldTime = offer.increasedTTL || booking?.confirmableUntil;
      form.change('onHoldBookingDate', onHoldTime);
      onOpen();
    } catch (e) {}
  }, [booking?.confirmableUntil, dispatch, form, onOpen, preparedOnHoldOffer]);

  const handleClose = useCallback(() => {
    onClose();
    if (IS_DS_AT) {
      dispatch(
        updateTab({
          onHoldOffer: preparedOnHoldOffer,
          purchaserData: payerDetailsForm?.getState().values,
        })
      );
    }
  }, [onClose, dispatch, preparedOnHoldOffer, payerDetailsForm]);

  if (isTravelPassBooking || (edit && IS_DS_AT)) {
    return null;
  }

  return (
    <>
      {edit ? (
        <Button variant="text" onClick={onOpen} sx={{ p: 0 }}>
          <Typography variant="body2" sx={{ ml: '8px', pl: 0, mr: 'auto' }}>
            <TransButton i18nKey="edit" />
          </Typography>
        </Button>
      ) : (
        <Button
          startIcon={<Icon name="save" />}
          variant="outlined"
          disabled={payerDetailsForm && payerDetailsForm.getState().invalid}
          onClick={makeOnHoldOffer}
        >
          <TransButton i18nKey="putBookingOnHold" />
        </Button>
      )}
      {open && (
        <Modal
          classes={{
            paper: classes.paper,
          }}
          title={<TransTitle i18nKey="putBookingOnHold" />}
          open={open}
          disableBackdropClick
          onClose={handleClose}
          actionButton={
            <Button
              form={FORM_ID}
              type="submit"
              variant="contained"
              onClick={confirmOnHoldBooking}
              disabled={!IS_DS_AT && !edit && !onHoldOffer?.id}
            >
              <TransButton i18nKey="putBookingOnHold" />
            </Button>
          }
        >
          <Loadable loading={loading}>
            <FormProvider form={form}>
              <form onSubmit={handleSubmit} id={FORM_ID}>
                <DateField
                  minDate={new Date()}
                  disabled={IS_DS_AT}
                  showTimeInput
                  name="onHoldBookingDate"
                  label={<TransLabel i18nKey="onHoldUntil" />}
                />
              </form>
            </FormProvider>
          </Loadable>
        </Modal>
      )}
    </>
  );
};
