import { Box, Stack, Typography, TypographyProps } from '@mui/material';
import { FC, ReactNode } from 'react';
import _get from 'lodash/get';

interface TagProps {
  bold?: boolean;
  color?: string;
  subColor?: string;
  variant?: TypographyProps['variant'];
  text?: ReactNode;
  title?: ReactNode;
  icon?: ReactNode;
}

interface TagGroupProps extends Omit<TagProps, 'text'> {
  title?: ReactNode;
  texts?: Array<ReactNode>;
}

export const Tag: FC<TagProps> = ({
  color = 'secondary',
  subColor = 'main',
  bold,
  variant = 'body1',
  text,
  title,
  icon,
}) => {
  const preparedText = typeof text === 'number' ? `${text}` : text;
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        width: 'fit-content',
        padding: '0.25rem 0.6125rem',
        background: (theme) => _get(theme.palette, [color, subColor]),
        borderRadius: '0.25rem',
      }}
    >
      {icon && <Box mr={1}>{icon}</Box>}
      <Typography
        variant={variant}
        color="white"
        fontWeight={bold ? 'bold' : 'normal'}
        noWrap
        component="span"
      >
        {title ? (
          <Stack direction="row" divider={<>: </>}>
            <Typography fontWeight="600" variant={variant}>
              {title}
            </Typography>
            {preparedText}
          </Stack>
        ) : (
          preparedText
        )}
      </Typography>
    </Stack>
  );
};

export const TagGroup: FC<TagGroupProps> = ({ texts, title, ...rest }) => {
  if (!texts?.length) return null;
  return (
    <>
      {texts.map((text, idx) => (
        <Tag key={idx} text={text} {...(idx === 0 && { title })} {...rest} />
      ))}
    </>
  );
};
