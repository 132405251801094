import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { activeTabSelector } from 'features/tabs/tabsSelector';
import { useSelector } from 'store/utils';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { FC, useMemo } from 'react';
import _trimStart from 'lodash/trimStart';
import _trim from 'lodash/trim';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      borderRadius: 4,
      padding: '0.875rem 1.5rem',
      border: `thin solid ${theme.palette.divider}`,
    },
  }),
  { name: 'TravelPassOverview' }
);

interface TravelPassOverviewProps {}
export const TravelPassOverview: FC<TravelPassOverviewProps> = () => {
  const classes = useStyles();
  const booking = useSelector(currentBookingSelector);
  const currentTab = useSelector(activeTabSelector);
  const travelPassAdmissions = useMemo(
    () => booking?.nonTripOffers.flatMap(({ admissions }) => admissions) ?? [],
    [booking?.nonTripOffers]
  );

  if (!currentTab) return null;

  return (
    <>
      {travelPassAdmissions.map(
        ({ id, productSummary, summary, price, appliedPassengerTypes }) => (
          <Stack
            key={id}
            className={classes.root}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
              <Typography fontWeight={600}>{_trim(productSummary)}</Typography>
              <Typography variant="body2">
                {[
                  _trimStart(summary, productSummary),
                  appliedPassengerTypes?.[0]?.description,
                ]
                  .filter(Boolean)
                  .join(', ')}
              </Typography>
            </Stack>
            <Typography variant="h2">
              {`${price.amount} ${price.currency}`}
            </Typography>
          </Stack>
        )
      )}
    </>
  );
};
