import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransLabel = createTrans({
  ACCOMPANYING_DOG: (
    <Trans i18nKey="label.ACCOMPANYING_DOG" defaults="Accompanying dog" />
  ),
  ADMISSION_MULTI_RIDE: (
    <Trans i18nKey="label.ADMISSION_MULTI_RIDE" defaults="Journey pass" />
  ),
  ADMISSION_PASS: (
    <Trans i18nKey="label.ADMISSION_PASS" defaults="Period pass" />
  ),
  AIR_TRAFFIC: <Trans i18nKey="label.AIR_TRAFFIC" defaults="Air traffic" />,
  AISLE: <Trans i18nKey="label.AISLE" defaults="Aisle" />,
  BED: <Trans i18nKey="label.bed" defaults="Bed in compartment" />,
  BERTH: <Trans i18nKey="label.bed" />,
  BLOCKED: <Trans i18nKey="label.BLOCKED" defaults="Blocked" />,
  BUS: <Trans i18nKey="label.BUS" defaults="Bus" />,
  CANCELLED: <Trans i18nKey="label.CANCELLED" defaults="Cancelled" />,
  CARD: <Trans i18nKey="label.CARD" defaults="Card" />,
  COMPARTMENT: <Trans i18nKey="label.compartment" defaults="Compartment" />,
  'CONCESSION_TYPE.BLINDED_SOLDIER': (
    <Trans
      i18nKey="label.CONCESSION_TYPE.BLINDED_SOLDIER"
      defaults="Blinded soldier"
    />
  ),
  'CONCESSION_TYPE.CURRENT_EMPLOYEE': (
    <Trans
      i18nKey="label.CONCESSION_TYPE.CURRENT_EMPLOYEE"
      defaults="Current employee"
    />
  ),
  'CONCESSION_TYPE.CURRENT_EMPLOYEE_INTERSTATE': (
    <Trans
      i18nKey="label.CONCESSION_TYPE.CURRENT_EMPLOYEE_INTERSTATE"
      defaults="Current employee interstate"
    />
  ),
  'CONCESSION_TYPE.DVA': (
    <Trans i18nKey="label.CONCESSION_TYPE.DVA" defaults="DVA" />
  ),
  'CONCESSION_TYPE.EXDEFENCE': (
    <Trans i18nKey="label.CONCESSION_TYPE.EXDEFENCE" defaults="Ex-defence" />
  ),
  'CONCESSION_TYPE.FORMER_EMPLOYEE': (
    <Trans
      i18nKey="label.CONCESSION_TYPE.FORMER_EMPLOYEE"
      defaults="Former employee"
    />
  ),
  'CONCESSION_TYPE.JOB_SEEKER': (
    <Trans i18nKey="label.CONCESSION_TYPE.JOB_SEEKER" defaults="Job seeker" />
  ),
  'CONCESSION_TYPE.OTHER_STUDENT': (
    <Trans
      i18nKey="label.CONCESSION_TYPE.OTHER_STUDENT"
      defaults="Other student"
    />
  ),
  'CONCESSION_TYPE.PENSIONER': (
    <Trans i18nKey="label.CONCESSION_TYPE.PENSIONER" defaults="Pensioner" />
  ),
  'CONCESSION_TYPE.SENIOR': (
    <Trans i18nKey="label.CONCESSION_TYPE.SENIOR" defaults="Senior" />
  ),
  'CONCESSION_TYPE.TCEC_STUDENT': (
    <Trans
      i18nKey="label.CONCESSION_TYPE.TCEC_STUDENT"
      defaults="TCEC student"
    />
  ),
  'CONCESSION_TYPE.VISION_IMPAIRED': (
    <Trans
      i18nKey="label.CONCESSION_TYPE.VISION_IMPAIRED"
      defaults="Vision impaired"
    />
  ),
  CORPORATE: <Trans i18nKey="label.CORPORATE" defaults="Corporate" />,
  COUCHETTE: <Trans i18nKey="label.COUCHETTE" defaults="Couchette" />,
  EASY_ACCESS: <Trans i18nKey="label.EASY_ACCESS" defaults="Easy access" />,
  FEMALE: <Trans i18nKey="label.FEMALE" defaults="Female" />,
  FERRY: <Trans i18nKey="label.FERRY" defaults="Ferries, boats and cruises" />,
  FULL_FLEXIBLE: (
    <Trans i18nKey="label.FULL_FLEXIBLE" defaults="Full flexible" />
  ),
  HIGH_SPEED_TRAIN: (
    <Trans
      i18nKey="label.HIGH_SPEED_TRAIN"
      defaults="High speed and express trains"
    />
  ),
  INTERCITY_REGIONAL: (
    <Trans
      i18nKey="label.INTERCITY_REGIONAL"
      defaults="Regional and InterCity trains"
    />
  ),
  LADIES: <Trans i18nKey="label.LADIES" defaults="Ladies" />,
  LOCAL_BUS: <Trans i18nKey="label.LOCAL_BUS" defaults="Local buses" />,
  LOCAL_TRAIN: <Trans i18nKey="label.LOCAL_TRAIN" defaults="Local trains" />,
  LONG_DISTANCE: (
    <Trans i18nKey="label.LONG_DISTANCE" defaults="Long-distance buses" />
  ),
  LOWER_BED: <Trans i18nKey="label.LOWER_BED" defaults="Lower bed" />,
  MALE: <Trans i18nKey="label.MALE" defaults="Male" />,
  MEN: <Trans i18nKey="label.MEN" defaults="Men" />,
  MIDDLE_BED: <Trans i18nKey="label.MIDDLE_BED" defaults="Middle bed" />,
  MIDDLE_SEAT: <Trans i18nKey="label.MIDDLE_SEAT" defaults="Middle seat" />,
  MIXED: <Trans i18nKey="label.MIXED" defaults="Mixed" />,
  NEAR_ANIMALS: <Trans i18nKey="label.NEAR_ANIMALS" defaults="Near pets" />,
  NEAR_BICYCLE_AREA: (
    <Trans i18nKey="label.NEAR_BICYCLE_AREA" defaults="Near bicycle area" />
  ),
  NEAR_WHEELCHAIR: (
    <Trans i18nKey="label.NEAR_WHEELCHAIR" defaults="Near wheelchair area" />
  ),
  NEAR_WHEELCHAIR_AREA: <Trans i18nKey="label.NEAR_WHEELCHAIR" />,
  NEED_PRM_SUPPORT: (
    <Trans i18nKey="label.NEED_PRM_SUPPORT" defaults="Need PRM support" />
  ),
  NIGHT_TRAIN: <Trans i18nKey="label.NIGHT_TRAIN" defaults="Night train" />,
  NO: <Trans i18nKey="label.NO" defaults="No" />,
  NON_FLEXIBLE: <Trans i18nKey="label.NON_FLEXIBLE" defaults="Non flexible" />,
  NOT_ENOUGH_PLACES: (
    <Trans i18nKey="label.NOT_ENOUGH_PLACES" defaults="Not enough places" />
  ),
  NOT_ENOUGH_VOUCHERS: (
    <Trans
      i18nKey="label.NOT_ENOUGH_VOUCHERS"
      defaults="Not enough vouchers!"
    />
  ),
  OFFER_PAX_TYPES_CANNOT_BE_SOLD_ALONE: (
    <Trans
      i18nKey="label.OFFER_PAX_TYPES_CANNOT_BE_SOLD_ALONE"
      defaults="Offer pax types cannot be sold alone!"
    />
  ),
  ON_HOLD: <Trans i18nKey="label.ON_HOLD" defaults="On hold" />,
  PAID: <Trans i18nKey="label.PAID" defaults="Paid" />,
  POWER: <Trans i18nKey="label.POWER" defaults="Power" />,
  PROMO: <Trans i18nKey="label.PROMO" defaults="Promo" />,
  REFUNDED: <Trans i18nKey="label.REFUNDED" defaults="Refunded" />,
  RELEASED: <Trans i18nKey="label.RELEASED" defaults="Released" />,
  SEAT: <Trans i18nKey="label.seat" defaults="Seat" />,
  SEMI_FLEXIBLE: (
    <Trans i18nKey="label.SEMI_FLEXIBLE" defaults="Semi flexible" />
  ),
  SHARED_TAXI: <Trans i18nKey="label.SHARED_TAXI" defaults="Shared taxi" />,
  SHIP: <Trans i18nKey="label.SHIP" defaults="Ship" />,
  SILENCE: <Trans i18nKey="label.SILENCE" defaults="Silence" />,
  SOLD_OUT: <Trans i18nKey="label.SOLD_OUT" defaults="Sold out" />,
  SUBWAY: <Trans i18nKey="label.SUBWAY" defaults="Subways" />,
  TABLE: <Trans i18nKey="label.TABLE" defaults="Table" />,
  TAXI: <Trans i18nKey="label.TAXI" defaults="Taxi" />,
  TRAIN: <Trans i18nKey="label.TRAIN" defaults="Train" />,
  TRAM: <Trans i18nKey="label.TRAM" defaults="Tram" />,
  TRAMS: <Trans i18nKey="label.TRAMS" defaults="Trams and light rail" />,
  TRAVEL_PASS: <Trans i18nKey="label.TRAVEL_PASS" defaults="Travel pass" />,
  UNACCOMPANIED_MINOR: (
    <Trans i18nKey="label.UNACCOMPANIED_MINOR" defaults="Unaccompanied minor" />
  ),
  UNDERGROUND: <Trans i18nKey="label.UNDERGROUND" defaults="Metro" />,
  UNPAID: <Trans i18nKey="label.UNPAID" defaults="Unpaid" />,
  UPPER_BED: <Trans i18nKey="label.UPPER_BED" defaults="Upper bed" />,
  USB: <Trans i18nKey="label.USB" defaults="USB" />,
  WHEELCHAIR: <Trans i18nKey="label.WHEELCHAIR" defaults="Wheelchair" />,
  WHEELCHAIR_AND_SEAT: (
    <Trans
      i18nKey="label.WHEELCHAIR_AND_SEAT"
      defaults="Wheel chair and seat"
    />
  ),
  WHEELCHAIR_AREA: (
    <Trans i18nKey="label.WHEELCHAIR_AREA" defaults="Wheelchair area" />
  ),
  WINDOW: <Trans i18nKey="label.WINDOW" defaults="Window" />,
  WITHOUT_ANIMALS: <Trans i18nKey="label.WITHOUT_ANIMALS" defaults="No pets" />,
  WITH_ANIMALS: <Trans i18nKey="label.WITH_ANIMALS" defaults="Pets allowed" />,
  WITH_CONDITIONS: (
    <Trans i18nKey="label.WITH_CONDITIONS" defaults="With conditions" />
  ),
  X: <Trans i18nKey="label.MIXED" />,
  YES: <Trans i18nKey="label.YES" defaults="Yes" />,
  age: <Trans i18nKey="label.age" defaults="Age" />,
  airport: <Trans i18nKey="label.airport" defaults="Airport" />,
  applyCardToAllPassengers: (
    <Trans
      i18nKey="label.applyCardToAllPassengers"
      defaults="Apply card to all passengers"
    />
  ),
  bookingFee: (
    <Trans i18nKey="label.bookingFee" defaults="Including booking fee" />
  ),
  bus: <Trans i18nKey="label.bus" defaults="Bus" />,
  compartmentNr: (
    <Trans i18nKey="label.compartmentNr" defaults="Compartment nr:" />
  ),
  corporateAgreementCode: (
    <Trans
      i18nKey="label.corporateAgreementCode"
      defaults="Corporate agreement code: {{code}}"
    />
  ),
  created: <Trans i18nKey="label.created" defaults="Created: {{date}}" />,
  creditCard: <Trans i18nKey="label.creditCard" defaults="Credit card" />,
  currentPassenger: (
    <Trans i18nKey="label.currentPassenger" defaults="Current passenger" />
  ),
  details: <Trans i18nKey="label.details" defaults="Details" />,
  email: <Trans i18nKey="label.email" defaults="Email" />,
  external: <Trans i18nKey="label.external" defaults="External" />,
  ferry: <Trans i18nKey="label.ferry" defaults="Ferry" />,
  freeSeating: <Trans i18nKey="label.freeSeating" defaults="Free seating" />,
  invoice: <Trans i18nKey="label.invoice" defaults="Invoice" />,
  lastModified: (
    <Trans i18nKey="label.lastModified" defaults="Last modified: {{date}}" />
  ),
  legNumberHint: (
    <Trans i18nKey="label.legNumberHint" defaults="(Leg {{number}})" />
  ),
  metro: <Trans i18nKey="label.metro" defaults="Metro" />,
  noPreference: <Trans i18nKey="label.noPreference" defaults="No preference" />,
  notAvailable: <Trans i18nKey="label.notAvailable" defaults="Not available" />,
  notification: <Trans i18nKey="label.notification" defaults="Notification" />,
  numberOfPassengers: (
    <Trans i18nKey="label.numberOfPassengers" defaults="Number of passengers" />
  ),
  off: <Trans i18nKey="label.off" defaults="Off" />,
  on: <Trans i18nKey="label.on" defaults="On" />,
  onHoldUntil: <Trans i18nKey="label.onHoldUntil" defaults="On hold until" />,
  passenger: <Trans i18nKey="label.passenger" defaults="Passenger" />,
  passengerWithNumber: (
    <Trans i18nKey="label.passengerWithNumber" defaults="Passenger {{nr}}" />
  ),
  passengerType: (
    <Trans i18nKey="label.passengerType" defaults="Passenger type" />
  ),
  payByLink: <Trans i18nKey="label.payByLink" defaults="Pay by link" />,
  paymentDone: (
    <Trans i18nKey="label.paymentDone" defaults="Payment was successful" />
  ),
  paymentFailed: (
    <Trans
      i18nKey="label.paymentFailed"
      defaults="Cancelled / unsuccessful payment"
    />
  ),
  paymentPending: (
    <Trans i18nKey="label.paymentPending" defaults="Waiting for the payment" />
  ),
  pdfDownload: <Trans i18nKey="label.pdfDownload" defaults="PDF download" />,
  prmNeed: <Trans i18nKey="label.prmNeed" defaults="PRM need" />,
  prmNeeds: <Trans i18nKey="label.prmNeeds" defaults="PRM needs" />,
  promoCode: (
    <Trans i18nKey="label.promoCode" defaults="Promo code: {{code}}" />
  ),
  sendToPayer: (
    <Trans i18nKey="label.sendToPayer" defaults="Send to payer {{postfix}}" />
  ),
  sms: <Trans i18nKey="label.sms" defaults="SMS" />,
  selectAllPassengers: (
    <Trans
      i18nKey="label.selectAllPassengers"
      defaults="Select all passenger(s)"
    />
  ),
  taxi: <Trans i18nKey="label.taxi" defaults="Taxi" />,
  totalForAllPassengers: (
    <Trans
      i18nKey="label.totalForAllPassengers"
      defaults="Total for all passengers"
    />
  ),
  train: <Trans i18nKey="label.train" defaults="Train" />,
  tram: <Trans i18nKey="label.tram" defaults="Tram" />,
  unmrSsr: <Trans i18nKey="label.unmrSsr" defaults="Unaccompanied Minor SSR" />,
});
